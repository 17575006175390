import {combineReducers} from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import menu from './menu/reducer';
import socket from './socket/reducer';
import config from './config/reducer';
import tutorial from './tutor/reducer'
import convertion from './converter/reducer';

export default combineReducers({
  auth,
  user,
  menu,
  socket,
  config,
  tutorial,
  convertion,
});