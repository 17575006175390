import styled from 'styled-components';
import {lighten} from 'polished';

import {$} from '~/styles/global';

export const Interactions = styled.div`
  h1 {
    margin-bottom: 30px;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    div {
      width: calc(33% - 20px);
      padding: 10px 20px;
      margin: 10px;
      border: 1px dashed ${lighten(0.5,$.colorC)};
      h2 {
        margin: 0 0 20px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          padding: 5px;
          button {
            width: 100%;
            padding: 20px;
            background: ${$.colorC};
            color: ${$.white};
            border-radius: 5px;
            border: 1px solid ${$.colorC};
            &:hover {
              background: ${$.white};
              color: ${$.colorC};
            }
          }
        }
      }
    }
  }
`;