import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';

import api from '~/services/api';

import {Block,Status} from '~/pages/Dashboard/styles';

import reload from '~/assets/icons/reload.svg';
import user from '~/assets/user.svg';
import TopLoader from '~/assets/loading.gif';

export default function Units() {
  const config = useSelector(state=>state.config.value);
  const [topLoader,setTopLoader] = useState(true);
  useEffect(()=>{
    async function GetInitState(){
      const responseUser = await api.get('users/count');
      setUsers(responseUser.data.users);
      const responseConvertion = await api.get('convertions/count');
      setConvertions(responseConvertion.data.convertions);
      setTopLoader(false);
    }
    GetInitState();
  },[]);
  const [users,setUsers] = useState(0);
  const [convertions,setConvertions] = useState(0);
  return (
    <Block Little>
      {topLoader?(
        <>
          <h2>{config.LANG['Current Plan']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <h2>{config.LANG['Current Plan']}</h2>
          <Status>
            <div>
              <span className="reload"><img src={reload} alt={config.LANG['Conversions']} /></span>
              <strong><span>{config.CONVERT_NUMBER-convertions}</span>/{config.CONVERT_NUMBER}</strong>
              <p>{config.LANG['Available conversions']}</p>
            </div>
            <div>
              <img src={user} alt={config.LANG['Users']} />
              <strong><span>{config.USER_NUMBER-users}</span>/{config.USER_NUMBER}</strong>
              <p>{config.LANG['Available users']}</p>
            </div>
          </Status>
        </>
      )}
    </Block>
  );
}
