import React,{useState,useMemo,useCallback} from 'react';
import {useSelector} from 'react-redux';
import {parseISO,formatDistance} from 'date-fns';
import pt from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';

import {FormRow,FormColumn} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';

export default function Recover({editor,recover,handleRecoverRevision}) {
  const config = useSelector(state=>state.config.value);
  // STATE =====
  const [openRecover,setOpenRecover] = useState(false);
  const [recovered,setRecovered] = useState(false);
  // MEMO =====
  const recoverDate = useMemo(()=>{
    if(recover){
      if(recover.date){
        const options = {addSuffix:true};
        if(config.LANGUAGE==='pt_br') {
          options.locale = pt;
        } else if(config.LANGUAGE==='es_es') {
          options.locale = es;
        }
        return formatDistance(
          parseISO(recover.date),
          new Date(),
          options
        );
      }
    }
    return '';
  },[recover,config]);
  // CALLBACK =====
  const handleOpenRecover = useCallback(()=>setOpenRecover(!openRecover),[openRecover]);
  const handleRecover = useCallback(()=>{
    if(openRecover&&editor) RecoverWork();
    function RecoverWork(){
      editor.setContent(recover.content);
      handleRecoverRevision(editor);
      setRecovered(true);
      handleOpenRecover();
    }
  },[openRecover,editor,handleOpenRecover,handleRecoverRevision]);
  return(<>
    {recover&&!recovered&&(<button className="noHover" onDoubleClick={handleOpenRecover} style={{marginLeft:'50px'}}> </button>)}
    <PopUp Tiny Open={openRecover} CallBack={handleOpenRecover}>
      <button type="button" onClick={handleOpenRecover}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>Trabalho Offline</h1>
      <h2>Recuperar</h2>
      <p>Identificamos no seu computador um trabalho que não foi salvo {recoverDate}.</p><br/>
      <p><strong>Essa será a última oportunidade de recuperá-lo.</strong></p><br/>
      <p>Caso queira recuperá-lo, selecione abaixo:</p>
      <br/><br/>
      <FormRow>
        <FormColumn>
          <button className="add" type="button" onClick={handleRecover}>{config.LANG['YES']}</button>
        </FormColumn>
        <FormColumn>
          <button className="delete" type="button" onClick={handleOpenRecover}>{config.LANG['NO']}</button>
        </FormColumn>
      </FormRow>
    </PopUp>
  </>);
}