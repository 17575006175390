import React,{useCallback} from 'react';
import {useSelector} from 'react-redux';

import {Share} from '~/components/InternalForm';

import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';

export default function ShareComponent({Open,Close,postId}) {
  const config = useSelector(state=>state.config.value);
  
  const shareURL = `${window.location.origin}/share/${postId}`;
  const sharePassword = process.env.REACT_APP_SHARE_PASSWORD||`Validar@Materiais`;
  const handleCopy = useCallback((type)=>{
    var copyText = document.getElementById('linker');
    if(type==='password') copyText = document.getElementById("passworder");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("Copy");
  },[]);
  return (
    <PopUp Tiny Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <Share>
        <h1>{config.LANG['Preview Link']}</h1>
        <p>{config.LANG['Submit the link below for viewing']}.<br/>
        {config.LANG['Use the password provided for authorization']}:</p>
        <div className="link">
          <label>{config.LANG['Link']}</label>
          <div>
            <input id="linker" type="text" value={shareURL} />
            <button type="button" onClick={()=>{handleCopy('link');}}>{config.LANG['Copy']}</button>
          </div>
        </div>
        <div className="password">
          <label>{config.LANG['Password']}</label>
          <div>
            <input id="passworder" type="text" value={sharePassword} />
            <button type="button" onClick={()=>{handleCopy('password');}}>{config.LANG['Copy']}</button>
          </div>
        </div>
      </Share>
    </PopUp>
  );
}