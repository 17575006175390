import React from 'react';
import {useSelector} from 'react-redux';

const Header = ({name, description}) => {
    const config = useSelector(state=>state.config.value);

    return (
        <header>
            <h1>{config.LANG[`${name}`]}</h1>
            <h3>{config.LANG[`${description}`]}</h3>
        </header>
    );
    // Activities Report
}

export default Header;