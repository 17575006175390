export default {
  // ~/page/SignIn
  'Welcome': 'Bienvenido',
  'Login to access': 'Inicia sesión para acceder',
  'Free trial registration': 'Prueba gratis',
  'register to access': 'Registrarse para acceder',
  'Your email': 'Tu correo electrónico',
  'Full name': 'Nombre completo',
  'Your secret password': 'Tu contraseña secreta',
  'Loading': 'Cargando',
  'Next': 'Próximo',
  'company': 'Empresa o institución',
  'contact phone': 'Telefono para contacto',
  'information at realize': '¿Quieres recibir boletines de noticias?',
  'Login': 'Iniciar sesión',
  'Registration': 'Registrar',
  'Name is required': 'Se requiere el nombre',
  'Phone is required': 'Se requiere teléfono',
  'company name is mandatory': 'El nombre de la empresa es obligatorio',
  'Forgot your password?': '¿Olvidaste tu contraseña?',
  'Free trial':'Prueba gratis',
  'Email is required': 'Correo electronico es requerido',
  'Enter a valid email': 'Introduzca un correo electrónico válido',
  'Password is required': 'Se requiere contraseña y al menos 6 dígitos',
  'Authentication failed, check your data!': '¡La autenticación falló, verifique sus datos!',
  // ~/page/Password
  'Redefine password': 'Redefinir contraseña',
  'Fill in your new password below.': 'Complete su nueva contraseña a continuación.',
  'New password': 'Nueva contraseña',
  'Confirm your password': 'Confirmar la contraseña',
  'Type the same password above': 'Ingrese la misma contraseña arriba',
  'Redefine': 'Redefinir',
  'At least 6 characters': 'Al menos 6 caracteres',
  'Password is different from the one entered above.': 'La contraseña es diferente de la ingresada arriba.',
  'your password has been changed. Now perform your Login.': 'tu contraseña ha sido cambiada. Ahora realice su inicio de sesión.',
  'Incorrect information. Try again.': 'Información incorrecta. Inténtalo de nuevo.',

  'Recover Password': 'Recuperar contraseña',
  'Enter your email below to reset your password.': 'Ingrese su correo electrónico a continuación para restablecer su contraseña.',
  'Type your e-mail': 'Escribe tu correo electrónico',
  'Recover': 'Recuperar',
  'Back to Login': 'Volver al inicio de sesión',
  'We sent a password change email to': 'Enviamos un correo electrónico de cambio de contraseña a',
  'Email does not exist. Try again.': 'El correo electrónico no existe. Inténtalo de nuevo.',
  // ~/components/Menu
  'Dashboard': 'Panel',
  'Users': 'Usuarios',
  'Projects': 'Proyectos',
  'Logout': 'Salir',
  // ~/components/Profile
  'Edit Profile': 'Editar Perfil',
  'Settings': 'Ajustes',
  'Procedures': 'Procedimientos',
  'Templates': 'Templates',
  // ~/components/Notifications
  'No activity yet.': 'Aún no hay actividad.',
  'NotificationsTitle': 'Notificaciones',
  'Notifications': 'NOTIFICACIONES',
  'HistoricTitle': 'Histórico',
  'Historic': 'HISTÓRICO',
  'Historic Report': 'Reporte de Actividades',
  'Activities': 'Actividades',
  'Activities Report': 'Nuevas Notificaciones',
  'NotificationsEmail': 'Recibir por correo electrónico',
  // ~/page/Dashboard/components/helloworld
  'Hello': 'Hola',
  'You have': 'Tienes',
  'Project(s) in progress.': 'Proyecto(s) en progreso.',
  'Access a project to continue working!': '¡Acceda a un proyecto para seguir trabajando!',
  // ~/page/Dashboard/components/projects
  'Project': 'Proyecto',
  'subjects': 'asignaturas',
  'access': 'acceder',
  'No projects found!': 'No se encontraron proyectos!',
  'Backward': 'Vuelve',
  'Forward': 'Avanzar',
  // ~/page/Dashboard/components/planConvertion
  'Available conversions': 'Conversiones disponibles',
  // ~/page/Dashboard/components/planUser
  'Available users': 'Usuarios disponibles',
  // ~/page/Overview
  'Overview': 'Visión general',
  // ~/page/Overview/components/areas
  'Areas': 'Areas',
  'Search': 'Buscar',
  'No area found!': '¡No se encontró area!',
  // ~/page/Overview/components/subjects  
  'Subjects': 'Asignaturas',
  'No subject found!': '¡No se encontró asignatura!',
  // ~/page/Overview/components/units
  'Units': 'Unidades',
  'No units found!': '¡No se encontraron unidades!',
  // ~/page/Overview/components/delegates
  'My Units': 'Mis Unidades',
  // ~/page/Overview/components/followers
  'My Favorites': 'Mis Favoritos',
  // ~/page/Overview/components/status
  'Production Status': 'Estado de Producción',
  'Save': 'Guardar',
  'Report': 'Reporte',
  // ~/page/Overview/components/convertions
  'Latest conversions': 'Últimas conversiones',
  'By': 'Por',
  'No conversions found!': 'No se encontraron conversiones!',
  // ~/page/Overview/components/plan
  'Current Plan': 'Plan Actual',
  'Conversions': 'Conversiones',
  // ~/page/Profile
  'My Profile': 'Mi Perfil',
  'My data': 'Mis datos',
  'Full Name': 'Nombre Completo',
  'Email': 'Correo Electrónico',
  'Your e-mail address': 'Tu correo electrónico',
  'Change profile picture': 'Cambiar foto de perfil',
  'Current Password': 'Contraseña actual',
  'Required Fields': 'Campos Obligatórios',
  'Update Profile': 'Actualización del Perfil',
  'Required field': 'Campo obligatorio',
  'Profile updated successfully!': '¡Perfil actualizado con éxito!',
  'Error updating profile, check your data!': '¡Error al actualizar el perfil, verifique sus datos!',
  'The selected users will have their profile updated': 'Los usuarios seleccionados tendrán su perfil actualizado',
  // ~/components/AvatarInput
  'Remove Image': 'Quita la imagen',
  'Choose image': 'Elige imagen',
  // ~/page/Users
  'DATE': 'FECHA',
  'TRASH': 'BASURA',
  'Block': 'Bloc',
  'List': 'Lista',
  'We have sent a creation email to': 'Enviamos un correo electrónico de creación a',
  'Check the user limit.': 'Verificar el límite de usuario.',
  'Successfully deleted users!': '¡Usuarios eliminados con éxito!',
  'Users successfully recovered!': '¡Usuarios recuperados con éxito!',
  'User successfully deleted!': '¡Usuario eliminado con éxito!',
  'User successfully recovered!': '¡Usuario recuperado con éxito!',
  'Check the number of users on your plan.': 'Verifique la cantidad de usuarios en su plan.',
  'An error has occurred. Try again.': 'Se ha producido un error. Inténtalo de nuevo.',
  'List of': 'Lista de',
  'User': 'Usuario',
  'Add': 'Añadir',
  'Delete': 'Eliminar',
  'Duplicate': 'Duplicar',
  'Function': 'Función',
  'No users found!': '¡No se encontraron usuarios!',
  'Close': 'Cierra',
  'Password': 'Contraseña',
  'Profile': 'Perfil',
  'Are you sure you want to delete': '¿Estás seguro de que deseas eliminar a',
  'Are you sure you want to recover': '¿Estás seguro de que deseas recuperar a',
  'these': 'estos',
  'this': 'este',
  'They will be saved in the trash and you can recover them later.': 'Se guardarán en la basura y podrás recuperarlos más tarde.',
  'It will be saved in the trash and you can recover it later.': 'Se guardará en la basura y podrás recuperarlo más tarde.',
  'YES': 'SI',
  'NO': 'NO',
  'Edit': 'Editar',
  'More': 'Más',
  'Administrador': 'Administrador',
  'Diretor': 'Director',
  'Gerente': 'Gerente',
  'Editor': 'Editor',
  'Conteudista': 'Contendiente',
  'Obligations': 'Obligaciones',
  'Indicate Obligations': 'Indicar Obligaciones',
  'The selected users will be appointed for the following obligations:': 'Los usuarios seleccionados serán nombrados para las siguientes obligaciones:',
  'Indicate': 'Indicar',
  'Users have been successfully nominated for the obligation': 'Los usuarios han sido nominados con éxito para la obligación',
  'Users have been successfully removed from the obligation': 'Los usuarios han sido eliminados exitosamente de la obligación',
  // ~/page/Users/edit
  'Redefine data': 'Redefinir datos',
  // ~/page/Projects
  'successfully created!': 'creado con éxito.',
  'successfully edited!': 'editado con éxito.',
  'successfully deleted!': 'eliminados con éxito.',
  'successfully recovered!': 'recuperados con éxito.',
  'SINGLE successfully deleted!': 'eliminado con éxito.',
  'SINGLE successfully recovered!': 'recuperado con éxito.',
  'View': 'Ver',
  'Name': 'Nombre',
  // ~/components/Rules
  'Customization': 'Personalización',
  'Themes': 'Temas',
  'Nothing found': 'Nada encontrado',
  'Update Theme': 'Actualizar Tema',
  'Are you sure you want to choose this theme?': '¿Seguro que quieres elegir este tema?',
  // ~/page/Areas
  'Area': 'Area',
  'Knowledge Areas': 'Areas de Conocimiento',
  'Knowledge Area': 'Area de Conocimiento',
  'FEMALE successfully created!': 'creada con éxito.',
  'FEMALE successfully edited!': 'editada con éxito.',
  'FEMALE successfully deleted!': 'eliminadas con éxito.',
  'FEMALE successfully recovered!': 'recuperadas con éxito.',
  'FEMALE SINGLE successfully deleted!': 'eliminada con éxito.',
  'FEMALE SINGLE successfully recovered!': 'recuperada con éxito.',
  'from': 'del',
  'Color': 'Color',
  'FEMALE these': 'estas',
  'FEMALE this': 'esta',
  'FEMALE They will be saved in the trash and you can recover them later.': 'Se guardarán en la basura y podrás recuperarlas más tarde.',
  'FEMALE It will be saved in the trash and you can recover it later.': 'Se guardará en la basura y podrás recuperarla más tarde.',
  // ~/page/Areas/edit
  'Update': 'Actualizar',
  'Change': 'Cambiar',
  'Current': 'Actual',
  // ~/page/Areas/edit/projects
  'All': 'Todos los',
  'FEMALE All': 'Todas las',
  'Selected': 'Seleccionado',
  'Select': 'Seleccione',
  'Are you sure you want to choose a new Project for this Area?': '¿Está seguro de que desea elegir un nuevo Proyecto para esta Area?',
  // ~/page/Subjects
  'Subject': 'Asignatura',
  'Are you sure you want to choose a new Area for this Subject?': '¿Estás seguro de que deseas elegir una nueva Area para esta Asignatura?',
  // ~/page/Units
  'Unity': 'Unidad',
  'Delegate': 'Delegar',
  'Status': 'Estado',
  'Create': 'Crear',
  'DUPLICATE': 'DUPLICADA',
  'DELETE': 'CANCELAR',
  'delegates': 'Usuarios delegados',
  'personalize': 'Personalizar',
  'content': 'Conteúdo',
  'template': 'Modelo',
  'Duplicate Unit': 'Unidad Duplicada',
  'Delegate Responsible': 'Delegar a los Encargados',
  'Attention': 'Atención',
  'Duplicate message': 'Seleccione lo que desea duplicar',
  'The users below will be delegated as responsible for the selected units': 'Los usuarios a continuación serán delegados como responsables de las unidades seleccionadas',
  'Exclude': 'Eliminar',
  // ~/pages/Units/index/delegates
  'was successfully delegated as responsible!': 'fue delegado con éxito como responsable!',
  'has been removed as responsible successfully!': 'ha sido eliminado como responsable con éxito!',
  'Remove': 'Quitar',
  'Are you sure you want to choose a new Discipline for this Unit?': '¿Estás seguro de que deseas elegir una nueva Asignatura para esta Unidad?',
  // ~/pages/Units/view
  'Personalize': 'Personalizar',
  'Go to Editor': 'Ir al editor',
  'Calculating': 'Calculando',
  'The percentage of change identified since the last conversion is': 'El porcentaje de cambio identificado desde la última conversión es',
  'The maximum allowed for a free conversion is': 'El máximo permitido para una conversión gratuita es',
  'Therefore, you will not be charged for a new conversion of your plan!': 'Por lo tanto, no se le cobrará por una nueva conversión de su plan.',
  // ~/pages/Units/view/converter
  'Unit successfully converted!': 'Unidad convertida con éxito!',
  'Conversion': 'Conversión',
  'Convert': 'Convertir',
  'Convert unit': 'Convertir unidad',
  'Are you sure you want to convert this unit?': '¿Seguro que quieres convertir esta unidad?',
  'A conversion will be discounted from the amount provided for your plan when converting this unit.': 'Se descontará una conversión del monto proporcionado para su plan al convertir esa unidad.',
  'Converting': 'Convirtiendo',
  // ~/pages/Units/view/convertions
  'Conversions of': 'Conversiones de',
  // ~/pages/Units/view/medias
  'Downloads': 'Downloads',
  'Download': 'Download',
  'Download the created conversion': 'Descargue la conversión creada',
  'Convert this unit to download': 'Convierta esta unidad para descargar',
  // ~/pages/Units/view/status
  'conversion allowed': 'conversión permitida',
  'under development': 'en desarrollo',
  'select step': 'seleccione paso',
  // ~/pages/Units/view/delegateusers/delegates
  'Responsible': 'Encargados',
  'No delegated users for this unit': 'No hay usuarios delegados para esta unidad',
  // ~/pages/Units/extras
  'Custom unit successfully!': 'Unidad personalizada con éxito!',
  'Customize Unit': 'Personalizar Unidad',
  'Import': 'Importar',
  'Customize by areas': 'Personalizar por áreas',
  'Customize by disciplines': 'Personalizar por disciplinas',
  'Custom units for the project': 'Unidades personalizadas para el proyecto',
  'Current theme options': 'Opciones de tema actuales',
  'Import Customization': 'Importación de Personalización',
  'Import Customization from another Unit': 'Importar personalización desde otra Unidad',
  // ~/pages/Units/editor
  'Backup': 'Historial',
  'Format': 'Formatear',
  'Share': 'Compartir',
  'Mode': 'Modo',
  'Preview Link': 'Enlace de vista previa',
  'Spelling': 'Corrección Ortográfica',
  'Word Counter': 'Contador de Palabras',
  'The conversion may take a long time to complete':'La conversión puede tardar bastante en completarse. Mientras tanto, puede navegar por el sistema',
  'Backup of Editions': 'Copia de Seguridad',
  'Resources': 'Recursos',
  'Edit Interaction': 'Editar Interacción',
  'Comments': 'Comentarios',
  'Comment': 'Comentario',
  'SelectUsersTitle': 'Etiquetar usuarios',
  'SelectUsers': 'Seleccionar usuarios',
  'delete': 'eliminar',
  'none': 'ningún',
  'This interaction is another theme.': 'Esta interacción es de otra tema.',
  'Change the theme to edit this interaction.': 'Cambia el tema para editar esta interacción.',
  'Corrupted interaction. Create it again. You may have deleted a line or important information.': 'Interacción corrupta. Crea de nuevo. Es posible que haya eliminado una línea o información importante.',
  'Content saved successfully!': '¡Contenido guardado con éxito!',
  'Processing': 'Procesando',
  'Reload': 'Recargar',
  'Save to view your work': 'Guardar para ver tu trabajo',
  'Interactions': 'Interacciones',
  'Insert': 'Insertar',
  'Backups': 'Backups',
  'Save this file to create a new backup': 'Guarde este archivo para crear una nueva copia de seguridad',
  'If you recover a backup, you will lose your work done that was not saved': 'Al recuperar una copia de seguridad, perderá el trabajo no guardado',
  'Preview': 'Vista previa',
  'Submit the link below for viewing': 'Envíe el siguiente enlace para ver',
  'Use the password provided for authorization': 'Use la contraseña provista para la autorización',
  'Link': 'Link',
  'Copy': 'Copiar',
  'Delete Interaction': 'Eliminar interacción',
  'Are you sure you want to delete this interaction?': '¿Estás seguro de que deseas eliminar esta interacción?',
  'You will not be able to recover it later': 'No podrás recuperarlo más tarde',
  'Number of pages': 'Número de páginas',
  'Number of words': 'Número de palabras',
  'Number of characters': 'Número de caracteres',
  'Full text': 'Texto completo',
  'Add Comment': 'Agregar comentario',
  'Write your comment here.': 'Escribe tu comentario aqui.',
  'No backups found!': '¡No se encontraron copias de seguridad!',
  'Topic': 'Tema',
  'Title': 'Título',
  'Subtitle Original': 'Subtítulo',
  'Quote': 'Cita',
  // ~/pages/Units/editor/state
  'Current step': 'Paso actual',
  'End Step': 'Finalizar Paso',
  // ~/pages/Units/revisor
  'Smart Reviewer': 'Revisor Inteligente',
  'Searching for Errors': 'Buscando Errores',
  'Spell Check': 'Corrector Ortográfico',
  'Error identified': 'Error identificado',
  'Text for correction': 'Texto para corrección',
  'Correction options': 'Opciones de corrección',
  'Code': 'Código',
  'Delete Error': 'Eliminar Error',
  'Are you sure you want to delete this error?': '¿Estás seguro de que deseas eliminar este error?',
  'Search Errors': 'Buscar Errores',
  'Correct': 'Corrige',
  'You must have some saved content to enter spell check mode.': 'Debe tener contenido guardado para ingresar al modo de corrección ortográfica.',
  'There are interactions of another theme.': 'Hay interacciones de otro tema.',
  'They were not loaded correctly.': 'No fueron cargados correctamente.',
  'Corrupted interaction. There are interactions with formatting errors. Return to the editor and correct the interactions.': 'Interacción corrupta. Hay interacciones con errores de formato. Regrese al editor y corrija las interacciones.',
  // ~/pages/Share
  'Incorrect password.': 'Contraseña incorrecta.',
  'Authentication': 'Autenticación',
  'Check': 'Cheque',
  'Resource not started': 'Recurso no iniciado',
  // ~/components/Pixabay
  'Images': 'Imágenes',
  'Free Image Stock': 'Banco de Imágenes Gratis',
  'Term': 'Término',
  'Enter the term to filter.': 'Ingrese el término para filtrar.',
  'Term Language': 'Término Idioma',
  'Portuguese': 'Portugués',
  'English': 'Inglés',
  'Spanish': 'Español',
  'All Single': 'Todos',
  'Type': 'Tipo',
  'Photo': 'Fotografía',
  'Illustration': 'Ilustración',
  'Vector': 'Vector',
  'Orientation': 'Orientación',
  'Horizontal': 'Horizontal',
  'Vertical': 'Vertical',
  'Category': 'Categoría',
  'Backgrounds': 'Backgrounds',
  'Fashion': 'Fashion',
  'Nature': 'Naturaleza',
  'Science': 'Ciencias',
  'Education': 'Educación',
  'Feelings': 'Sentimientos',
  'Health': 'Salud',
  'People': 'Personas',
  'Religion': 'Religión',
  'Places': 'Lugares',
  'Animals': 'Animales',
  'Industry': 'Industria',
  'Computer': 'Computadora',
  'Food': 'Comida',
  'Sports': 'Deportes',
  'Transportation': 'Transporte',
  'Travel': 'Viaje',
  'Buildings': 'Edificios',
  'Business': 'Negocio',
  'Music': 'Música',
  'Filter': 'Filtrar',
  'No images found!': '¡No se encontraron imágenes!',
  // ~/components/Init
  'The system will enter maintenance mode in minutes.': 'El sistema entrará en modo de mantenimiento en minutos.',
  'Save your work so you will not lose your progress.': 'Guarde su trabajo para no perder su progreso.',
  'The system has been successfully updated!': '¡El sistema ha sido actualizado con éxito!',
  'Incorrect Version': 'Versión incorrecta',
  'You are working on an outdated version.': 'Estás trabajando en una versión desactualizada.',
  'Save your work and': 'Guarda tu trabajo y',
  'Click here to update': 'Haga clic aquí para actualizar',
  'Editing Unit': 'Editando la Unidad',
  "There are other people editing this unit in another window. Take care not to replace other people's work.": 'Hay otras personas editando esta unidad en otra ventana. Tenga cuidado de no reemplazar el trabajo de otras personas.',
  // ~/components/Init
  'Uploading file': 'Cargando archivo',
  'An error has occurred. Try again or check the file size. Decrease the file size, if necessary.': 'Ocurrio un error. Intente nuevamente o verifique el tamaño del archivo. Disminuya el tamaño del archivo, si es necesario.',
  'Image Stock': 'Bancos de Imágenes',
  'Choose': 'Elige',
  // ~/components/Fields/VideoInput
  'Video': 'Vídeo',
  // ~/components/Fields/ZipInput
  'The package must have an index.html file at its root that will load the interaction.': 'El paquete debe tener un archivo index.html en su raíz que cargará la interacción.',
  'Package': 'Paquete',
  // ~/components/Fields/AudioInput
  'Audio': 'Audio',
  // ~/components/Fields/ImageEditor
  'Subtitle': 'Subtitular',
  'Source': 'Fuente',
  'Paragraph': 'Párrafo',
  'Formatting': 'Formateo',
  'Insert image caption here': 'Insertar título de imagen aquí',
  'Source: Type the source of the image here.': 'Fuente: escriba la fuente de la imagen aquí.',
  // ~/components/ZenDesk
  'Hello Support Realize, I have a problem.': 'Hola Soporte Realize, tengo un problema.',
  // ~/components/ImportUsers
  'DOWNLOAD TEMPLATE SPREADSHEET': 'DESCARGAR FICHA MODELO',
  'IMPORT SPREADSHEET': 'IMPORTAR FICHA',
  'add multiple users': 'agregar varios usuarios',
  'Import User Spreadsheet': 'Importar ficha de Usuarios',
  'See below how to complete and import the spreadsheet to register users': 'Vea a continuación cómo completar e importar la ficha para registrar usuarios',
  'See below the users who will be registered in the system': 'Vea a continuación los usuarios que se registrarán en el sistema',
  'REGISTER USERS': 'REGISTRAR USUARIOS',
  'CANCEL': 'CANCELAR',
  'Your spreadsheet is inconsistent. Try again.': 'Tu ficha es inconsistente. Inténtalo de nuevo.',
  'Failed to import the spreadsheet. Contact support.': 'No se pudo importar la ficha. Soporte de contacto.',
  'The selected file has the wrong extension.': 'El archivo seleccionado tiene la extensión incorrecta.',
  'Spreadsheet imported successfully.': 'La ficha se importó correctamente.',
  'There are e-mails already registered in the system.': 'Ya hay correos electrónicos registrados en el sistema.',
  'Duplicate emails': 'Correos electrónicos duplicados',
  // ~/components/ImportProjetcs
  'add multiple projects': 'agregar varios proyectos',
  'Import Project Spreadsheet': 'Importar ficha del Proyectos',
  'See below how to complete and import the spreadsheet to register projects': 'Vea a continuación cómo completar e importar la ficha para registrar proyectos:',
  'See below the projects who will be registered in the system': 'Vea a continuación los proyectos que se registrarán en el sistema',
  'REGISTER PROJECTS': 'REGISTRAR PROYECTOS',
};