import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding-left: 20px;
  button {
    height: 100%;
    padding: 2.5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0px;
      height: 25px;
    }
  }
`;
