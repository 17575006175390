import { Form } from '@rocketseat/unform';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// import * as Yup from 'yup';
import notify from '~/assets/icons/bell.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import Boolean from '~/components/Fields/Boolean';
import { FormColumn } from '~/components/InternalForm';
import api from '~/services/api';
import AnnouncementItem from './components/announcement';
import Header from './components/Header';
import HistoryItem from './components/History';
import {
  Back, Badge,
  ButtonAnnouncement,
  ButtonContainer, Container,
  ExchangeMenu, NotificationBackground,

  NotificationList
} from './styles';


// const schema = Yup.object().shape({
//   USER_NUMBER: Yup.number('Somente Número').required('Valor obrigatório'),
// });

export default function Notifications({teste}) {
  const config = useSelector(state => state.config.value);

  const profile = useSelector(state=>state.user.profile);
  const socket = useSelector(state=>state.socket.socket);
  
  const [reload,setReload] = useState(false);
  const [open,setOpen] = useState(false);
  const [selectedNotifications,setSelectedNotifications] = useState(true);
  const [selectedHistoric,setSelectedHistoric] = useState(false);
  const [newAnnoucement, setNewAnnoucement] = useState(false);
  const [configUser,setConfigUser] = useState('');
  const [variable, setVariable] = useState({})

  function handleOpen(){
    setOpen(!open);
    setReload(!open);
  }
  
  function handleButtonParent(event){
    event.preventDefault();
    const target = event.target;
    if (target.id !== 'notificationsParent' && target.id !== 'notificationsBadge' && target.id !== 'notificationsImage') {
      return ;
    }
    handleOpen();
    saveStorageOpenAnnouncement();
  }

  const handleSelectedButton = useCallback(() => {
    setSelectedNotifications(state => !state);
  
    setSelectedHistoric(state => !state);
  },[]);

  const saveStorageNewAnnouncement = useCallback(() => {
      localStorage.setItem('@state-new_announcement', true); 
  },[]);
  
  const saveStorageOpenAnnouncement = useCallback(() => {
    localStorage.setItem('@state-new_announcement', false);
    setNewAnnoucement(false);
  },[socket]);

  useEffect(() => {
    api.get('users/settings').then(response => {
      setConfigUser(response.data.receive_emails);
    })
  },[])

  useEffect(() => {
    // api.get('users/settings').then(response => {
    //   setConfigUser(response.data.receive_emails);
    //   setVariable({
    //     NOTIFICATIONS: configUser
    //   })
    // })
    setVariable({
      NOTIFICATIONS: configUser
    })
    api.get('announcements',{
      params:{
        perPage: 1,
      }
    }).then(response => {
     const exist = response.data.list?.find(item => {return item.read === false});
     
     if(exist){
      saveStorageNewAnnouncement()
      }
    });

    const announcement = localStorage.getItem('@state-new_announcement');
    if(announcement === 'true'){
      setNewAnnoucement(true);
    }


    if(socket&&profile){
      socket.on('new_announcement',({user_id})=>{
        if(Number(user_id)===profile.id){
          setNewAnnoucement(true);
          saveStorageNewAnnouncement();
        }
      });
    }
  },[socket,profile,newAnnoucement,configUser]); 
  
  async function handleSetNotifications() {
    setVariable({
      NOTIFICATIONS: !variable.NOTIFICATIONS
    });
    const data = {
      receive_emails: !variable.NOTIFICATIONS
    }
    await api.post('users/settings',data)
 }
 
  const thisClass = useMemo(()=>(open)?'open':'none',[open]);
  
  return (
    <Container id="notificationsParent" onClick={handleButtonParent}>
      
      <>
        <Badge onClick={handleButtonParent} id="notificationsBadge" newAnnoucement={newAnnoucement}>
            <img id="notificationsImage" src={notify} alt={config.LANG['Notifications']}/>
        </Badge>
      </>

      <NotificationBackground className={thisClass}>
        <Back onClick={handleOpen}>
          <img src={rightArrow} alt=""/>
        </Back>
        {/* Menu de contexto */}
          <ExchangeMenu>
            <ButtonAnnouncement selected={selectedNotifications} onClick={handleSelectedButton}>
              {config.LANG['Notifications']}
            </ButtonAnnouncement>
            
            <ButtonAnnouncement selected={selectedHistoric} onClick={handleSelectedButton}>
              {config.LANG['Historic']}
            </ButtonAnnouncement>
          </ExchangeMenu>
         {/* Menu de contexto */}
        <aside>
          {selectedNotifications?
            <>
              {/* aqui vai o botao de email */}
              <Header 
                name={'NotificationsTitle'}
                description={'Activities Report'}
              />
               <ButtonContainer>
                <Form initialData={variable}>
                  <FormColumn className="form-notifications" Line>
                    <button className="button-to-click" onClick={handleSetNotifications}>
                      <Boolean className="notification-button-radios" name="NOTIFICATIONS" />
                      <label className="notifications" htmlFor="NOTIFICATIONS">{config.LANG['NotificationsEmail']}</label>
                    </button>
                  </FormColumn>
                </Form>
              </ButtonContainer>
              
              <NotificationList>
                <AnnouncementItem reload={reload} />
              </NotificationList>
            </>
          ://condicional 
            <>
              {/* <ButtonContainer>
                <Form initialData={variable}>
                  <FormColumn className="form-notifications" Line>
                    <button className="button-to-click" onClick={handleSetNotifications}>
                      <label className="notifications" htmlFor="NOTIFICATIONS">Notificações<br/>pelo e-mail:</label>
                      <Boolean name="NOTIFICATIONS" />
                    </button>
                  </FormColumn>
                </Form>
              </ButtonContainer> */}
              
              <Header 
                name={'HistoricTitle'}
                description={'Historic Report'}
              />
              <NotificationList>
                <HistoryItem reload={reload}/>
              </NotificationList>
            </>
          }
        </aside>

      </NotificationBackground>

    </Container>
  );
}
