import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {$} from '~/styles/global';
import TopLoader from '~/assets/loading.gif';

export default function Projects({posts,topLoader}) {
  const config = useSelector(state=>state.config.value);
  
  return (<>
      {topLoader?(
        <>
          <p className="loaderHolder" style={{width: "100%"}}>
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`}/>
          </p>
        </>
      ):(
        <>
        <ProjectsList>
            {posts.list[0] ? (
              <ul>
                {posts.list.map(post=>(
                  <li key={post.id}>
                    <Link to={`/overview/projects/${post.id}`}>
                      <span className="title">{config.LANG['Project']}</span>
                      <span className="subtitle">{post.name}</span>
                      <span className="bottom">
                        <span>{post.subject} {config.LANG['subjects']}</span>
                        <span>{config.LANG['access']} &#62;</span>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            ):(
              <p>{config.LANG['No projects found!']}</p>
            )}
          </ProjectsList>
        </>
      )}
  </>);
}

const ProjectsList = styled.div`
  width: 100%;
  ul {
    padding: 0 7.5px;
    display: flex;
    flex-wrap: wrap;
    li {
      flex: 1;
      min-width: 25%;
      a {
        color :${$.white};
        border-radius: 5px;
        ${$.gradientProject($)}
        margin: 7.5px;
        box-sizing: border-box;
        padding: 40px 30px;
        &:hover {
          ${$.gradient($)}
        }
        display: flex;
        flex-direction: column;
        .title {
          text-transform: uppercase;
        }
        .subtitle {
          font-weight: ${$.bold};
          ${$.fontSize($,20)}
          margin-bottom: 30px;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  
`;