import {lighten} from 'polished';

import {$} from '~/styles/global';

export function initGenerator(config){
  var language = 'en';
  if(config.LANGUAGE==='pt_br'){
    language = 'pt_BR';
  } else if(config.LANGUAGE==='es_es'){
    language = 'es';
  }
  return {
    language, // https://www.tiny.cloud/get-tiny/language-packages/
    branding: false,
    resize: false,
    height: '100%',
    extended_valid_elements : "error,info,multiple", // Esse converte como inline
    // custom_elements: "error|data", // Esse converte como bloco.
    // skin: "realize",
    // content_css : '../../tinymce/skins/content/realize/content.css',
  };
}

export const style = {
  content_style: `
      body {
        font-family: 'Open Sans', sans-serif;
        color: ${$.colorText($,1)};
        padding: 0 30px;
        max-width: ${$.container};
        margin: 0 auto;
        counter-reset: error;
      }
      /* p[class^="I-"] */
      /* div[data-interaction="true"] */
      div[data-interaction="true"] {
        padding: 20px;
        background: ${$.colorB};
        color: white;
        max-width: 100%;
        margin: 10px auto;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
      }
      div[data-interaction="true"]:after {
        content: '';
        border: 1px dashed ${lighten(0.1,$.colorB)};
        position: absolute;
        margin: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        top: 0px;
        left: 0px;
        border-radius: 5px;
        z-index: 1;
      }
      div[data-interaction="true"]  > div {
        z-index: 2;
        background: ${lighten(0.05,$.colorB)};
        padding: 10px;
        margin-top: 10px;
      }
      .E-TOPICO {
        font-size: 30px;
        font-weight: bold;
        border-bottom: 1px dashed ${$.colorText($,0.5)};
        padding-bottom: 10px;
        text-transform: uppercase;
        margin-top: 50px;
      }
      .quote {
        text-align: justify;
        font-size: 12px;
        padding-left: 30%;
      }
      a {
        color: ${$.colorB};
      }
      error {
        display: inline-block;
        background: ${$.colorText($,0.05)};
        color: ${$.colorText($,0.8)};
        padding: 2.5px 5px;
        border: 1px solid ${$.colorB};
        border-radius: 5px;
        counter-increment: error;
        position: relative;
      }
      error:before {
        content: counter(error);
        content: '•';
        position: absolute;
        width: 14px;
        height: 14px;
        top: -7px;
        left: -7px;
        background: ${$.colorB};
        color: ${$.white};
        border-radius: 1000px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      error[data-code="REPEATED_WORDS"] {
        border: 1px solid #dabf00;
      }
      error[data-code="REPEATED_WORDS"]:before {
        background: #dabf00;
      }
      error[data-code="BARBARISMS"] {
        border: 1px solid #008ada;
      }
      error[data-code="BARBARISMS"]:before {
        background: #008ada;
      }
      /* div[data-interaction="true"] > error {
        display: inline;
        background: inherit;
        color: inherit;
        padding: inherit;
        border: none;
        padding: 0;
      } */
      div[data-interaction="true"] > error:before {
        display: none;
      }
      info {
        display: block;
        border: 1px solid ${$.colorB};
        border-radius: 5px;
        padding: 10px;
        background: ${$.white};
        color: ${$.colorText($)};
        text-align: left;
      }
      info + info {
        margin-top: 10px;
      }
      info[data-structure="multiple"] {
        background: ${lighten(0.07,$.colorB)};
        border: 1px dashed ${lighten(0.15,$.colorB)};
      }
      info[data-type="file"],
      info[data-type|="file"],
      info[data-type="file-pdf"],
      info[data-wordCount="false"],
      info[data-hide="true"] {
        display: none;
      }
      multiple {
        display: block;
      }
      multiple + multiple {
        margin-top: 10px;
      }
    `,
};

export const plugins = {
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
    'charmap codesample media emoticons visualblocks tiny_mce_wiris noneditable', // class="mceNonEditable"
    'paste', // https://www.tiny.cloud/docs/plugins/paste/#paste_postprocess
  ],
};

export function menuGenerator(config,profile){
  return{
    // https://www.tiny.cloud/docs/general-configuration-guide/basic-setup/
    menu: { // https://www.tiny.cloud/docs/demo/custom-menu-item/
      custom: { title: config.LANG['Spelling'], items: 'reload-revision' },
      modos: { title: config.LANG['Mode'], items: 'editor compare' },
    },
    menubar: `custom modos ${(profile&&profile.role.level<=0)&&'tools'}`,
    toolbar: 'status-revision | reload-revision',
    contextmenu: false,
    statusbar: false,
  };
}