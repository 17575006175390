import React, {useState,useEffect,useCallback} from 'react';
import {useSelector} from 'react-redux';
import {parseISO,formatDistance} from 'date-fns';
import pt from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';

import api from '~/services/api';

import {Block} from '~/pages/Dashboard/styles';

import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import TopLoader from '~/assets/loading.gif';
import { Link } from 'react-router-dom';
import {Container} from './style';

function AnnouncementItem({reload}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  const socket = useSelector(state=>state.socket.socket);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  const [read, setRead] = useState(false);
  const [update,setUpdate] = useState(true);
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  
  const handleMarkAsRead = useCallback( async (id)=>{
    await api.patch(`/announcements/${id}`);
    setRead(false);
    // window.location.reload();
   },[read]);
   
  useEffect(()=>{
    api.get('announcements',{
      params:{
        perPage: 50,
        page,
      }
    }).then(response => {
      const options = {addSuffix:true};
        if(config.LANGUAGE==='pt_br') {
          options.locale = pt;
        } else if(config.LANGUAGE==='es_es') {
          options.locale = es;
        }
        const list = response.data.list?.map(announcement=>({
              ...announcement,
              timeDistance: formatDistance(
              parseISO(announcement.createdAt),
              new Date(),
              options
              )
        }));
        setPosts({
          list,
          paged: response.data.paged,
        });
        setRead(true);
        setTopLoader(false);
    });
  },[reload,read,update,page,config.LANGUAGE]);

  useEffect(()=>{
    if(socket&&profile){
      socket.on('new_announcement',({user_id})=>{
        if(Number(user_id)===profile.id){
          handlePage(page);
        }
      });
    }
  },[socket,profile]);

  return (
    <>
      {topLoader?(
        <li>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </li>
      ):(<>
        {posts.list?(<>
            {posts.list.map(post=>(
              <Container 
                key={post._id}
                read={post.read}
                onClick={() => handleMarkAsRead(post._id)}  
              > 
                {post.type === 'announcement'?
                <Link to={`/units/${post.unit_id}/view`} replace>
                    {post.content}
                    <br /><span>{post.timeDistance}</span>
                </Link>
                :
                <Link to={`/units/${post.unit_id}/create`} replace>
                  {post.content}
                  <br /><span>{post.timeDistance}</span>
                </Link>
                }
              </Container>
            ))}
          <Block className="navigation">
            {posts.paged.pages>1&&(
              <nav>
                <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                  <img src={leftArrow} alt={config.LANG['Backward']}/>
                </button>
                <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                  <img src={rightArrow} alt={config.LANG['Forward']}/>
                </button>
              </nav>
            )}
          </Block>
        </>):(<li>{config.LANG['No activity yet.']}</li>)}
      </>)}
    </>
  );
}

export default AnnouncementItem;