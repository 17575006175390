import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
position: relative;
margin-bottom: 10px;
${props=>props.zIndex&&`z-index: ${props.zIndex};`}
  > div {
    position: relative;
    border: 1px solid ${$.colorText($,0.14)};
    border-radius: 5px;
    button {
      width: 100%;
      background: none;
      color: ${$.colorText($)};
      font-weight: ${$.regular};
      border-radius: 0;
      text-align: left;
      margin: 0;
      background: ${$.white};
      height: 34px;
      ${$.fontSize($,12)}
      padding: 0px 15px;
      &:hover {
        background: ${$.colorText($,0.15)};
      }
    }
    > button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px 5px;
    }
    ul {
      position: absolute;
      display: ${props=>props.Open?'block':'none'};
      border: 1px solid ${$.colorText($,0.14)};
      border-top: 0;
      background: ${$.white};
      width: calc(100% + 2px);
      border-radius: 0px 0px 5px 5px;
      padding-top: 1px;
      margin-top: -5px;
      left: -1px;
      max-height: 250px;
      overflow: auto;
      li:last-child {
        button {
          border-radius: 0px 0px 5px 5px;
        }
      }
    }
  }
`;
