import React,{useState,useCallback,useEffect} from 'react';

export function Button({selection,resposta,callback=()=>{},...extra}) {
  const [selected,setSelected] = useState(false);
  useEffect(()=>{
    if(selection!==resposta.value) setSelected(false);
  },[selection,resposta]);
  const handleClicker = useCallback(()=>{
    setSelected(!selected);
    callback(resposta.value);
  },[resposta,selected,callback]);
  return (<>
    <button className={!selected?'outline':'add'} type="button" onClick={handleClicker} {...extra}>
      {resposta.value}
    </button>
  </>);
}