import React,{useCallback} from 'react';
import { useSelector } from 'react-redux';
import { FiArrowRight } from 'react-icons/fi';
import { Container,FeedBackContainer,BackToLogin } from './style';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import InputMask from "react-input-mask";
import { useHistory, Link } from 'react-router-dom';
import { useState } from 'react';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const FreeTrial = () => {
    const config = useSelector(state=>state.config.value);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [tab,setTab] = useState('tab-01');
    const [dataFirstPage, setDataFirstPage] = useState(''); 
    const [tel, setTel] = useState('');
    const [company, setCompany] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [newsLetter, setNewsLetter] = useState(false);


    useEffect(() => {
        loadFreeTrial();
        async function loadFreeTrial(){
            const response = await api.get('variables');  
            const variables = response.data;
            if (variables.FREE_TRIAL !== true) {
                history.push('/');
            }
        }
    },[history])

    const schemaFirstPage = Yup.object().shape({
        //configurar lang
        name: Yup.string().required(config.LANG['Name is required']),
        email: Yup.string().email(config.LANG['Enter a valid email']).required(config.LANG['Email is required']),
        password: Yup.string().min(6).required(config.LANG['Password is required']),
        password_confirmation: Yup.string()
        .oneOf(
            [Yup.ref('password'), null],
            'Confirmação incorreta',
        )
    });
    const schemaFinal = Yup.object().shape({
        company: Yup.string().required(config.LANG['company name is mandatory']),
        telefone: Yup.string().required(config.LANG['Phone is required']),
    });

    const handleTab = useCallback(async (data) => {
        try {
            setDataFirstPage(data);
            setTab('tab-02')
        } catch (error) {
            console.error(error);
        }
    }, []);

    const handleSubmit = useCallback(async (data) => {
        try { 
            setLoading(true);
            const telefone = data.telefone.split('(').join('')
                .split(')').join('')
                .split('-').join('');
    
            const dataFormat = {
                name: dataFirstPage.name,
                email: dataFirstPage.email,
                password: dataFirstPage.password,
                passwordConfirmation: dataFirstPage.password_confirmation,
                company: data.company,
                phone: String(telefone),
                receive_newsletters: newsLetter
            }
               await api.post('/trial', dataFormat);
            //tost de sucesso
            toast.success('Usuário cadastrado com sucesso');
            //
            setLoading(false);
            setEmailError(false);
            setCompany('');
            setTel('');
            setDataFirstPage('');
            history.push('/');
        } catch (error) {
            // console.log(error.message)
            if (error.message === 'Network Error') {
                toast.error('Erro na conexão, tente novamente mais tarde');
                return;
            }
            toast.error('E-mail já cadastrado');
            setLoading(false);
            setTab('tab-01');
            setEmailError(true);
        }
    }, [history,dataFirstPage,newsLetter]);
    
    function handleBackTab() {
        setTab('tab-01')    
    }

    const handleChangeNews = useCallback((value) => {
        setNewsLetter(!value)
    },[])


    return (
        <Container className="slideRight">
            <h1>{config.LANG['Free trial registration']}</h1>
            <p>{config.LANG['register to access']}</p>
            {tab === 'tab-01' ? 
                <Form initialData={dataFirstPage} className="slideRight" schema={schemaFirstPage} onSubmit={handleTab}>
                    <Input name="name" type="text" placeholder={config.LANG['Full name']} autoComplete="off" />
                    <Input style={{color: emailError && '#EE5F5F'}} name="email" type="email" placeholder={config.LANG['Your email']} autoComplete="off" />
                        {/* {emailError && 
                        <BiErrorCircle />}
                         */}
                    <Input name="password" type="password" placeholder={config.LANG['Your secret password']} autoComplete="off" />
                    <Input name="password_confirmation" type="password" placeholder={config.LANG['password_confirmation']} autoComplete="off" />
                    <button className="button-next" type="submit" >{config.LANG['Next']}
                        <FiArrowRight size={19} />
                    </button>
                </Form>
                :
                <div className="slideRight">
                    <Form schema={schemaFinal} onSubmit={handleSubmit}>
                        <Input
                            name="company"
                            type="text"
                            placeholder={config.LANG['company']}
                            autoComplete="off"
                            value={company}
                            onChange={e => setCompany(e.target.value)}
                        />

                        <InputMask
                            name="tel"
                            autoComplete="off"
                            mask="(99)99999-9999"
                            value={tel}
                            id="tel"
                            aria-label="tel"
                            onChange={e => setTel(e.target.value)}
                        >
                            {() => <Input
                                placeholder={config.LANG['contact phone']}
                                name="telefone" />}
                        </InputMask>
                        <div className="checkbox-label">
                            <input
                                type="checkbox"
                                name="informations-at-realize"
                                value={newsLetter}
                                onChange={() => handleChangeNews(newsLetter)}
                                id="informations-at-realize" />
                            <label htmlFor="informations-at-realize">{config.LANG['information at realize']}</label>
                        </div>                     
                        <button type="submit">{loading ? `${config.LANG['Loading']}...` : `${config.LANG['Registration']}`}</button>
                    </Form>
                </div>
            }
            <FeedBackContainer tab02={tab === 'tab-01'? true : false}>
                <button onClick={handleBackTab}>1</button>
                <span className="fadeIn" >2</span>
            </FeedBackContainer>
            <BackToLogin>
                <p>
                    <Link to="/">Voltar para login</Link>
                </p>
            </BackToLogin>
        </Container>
    );
}

export default FreeTrial;