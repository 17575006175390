import styled,{css} from 'styled-components';

import {$} from '~/styles/global';

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    button+button{
      margin-left: 10px;
    }
  }
  form {
    position: relative;
    input {
      width:240px;
      margin-bottom: 0px;
    }
    button {
      position: absolute;
      background: none;
      padding: 7px 15px 0px;
      border: 0;
      margin: 0;
      top: 0;
      right: 0;
      &:hover {
        background: none;
      }
    }
  }
`;

export const ListFilter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${$.colorText($,0.2)};
  a {
    color: ${$.colorText($,0.5)};
    display: inline-block;
    padding: 5px;
    font-weight: ${$.bold};
    margin: 0 10px 0px 0px;
    &:hover {
      opacity: 0.5;
    }
    a + a {
      margin: 0 10px;
    }
    &.selected {
      border-bottom: 1px solid ${$.colorB};
      margin-bottom: -1px;
    }
  }
  div + div {
    a {
      opacity: 0.5;
      &.choosed {
        opacity: 1;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    a + a {
      margin: 0 0px 0px 5px;
    }
  }
`;

export const ListTable = styled.table`
  width: 100%;
  margin-top: 10px;
  border: 0;
  border-spacing: 0px 5px;
  th, td {
    padding: 10px;
    border: 0;
    .checker {
      border: 1px solid ${$.colorText($)};
      background: ${$.white};
      padding: 5.5px;
      border-radius: 3px;
      transform: translateY(-4px);
      &.selected {
        background: ${$.colorC};
        border-color: ${$.colorC};
        position: relative;

        &:before {
          content: '';
          padding: 3px;
          background: ${$.white};
          position: absolute;
          border-radius: 50%;
          top: calc(50% + 0.5px);
          left: calc(50% + 0.1px);
          transform: translateX(-56%) translateY(-55%);
        }
      }
    }
    .linker {
      color: inherit;
    }
    &:first-child {
      text-align: center;
    }
    &.image {
      width: 40px;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #eee;
      }
      button {
        border: 0;
        margin: 0;
        padding: 15px;
        &:hover {
          opacity: 1;
          cursor: default;
        }
      }
    }
    &.link {
      text-align: center;
      a {
        color: ${$.white};
        background: ${$.colorC};
        padding: 5px 10px;
        border-radius: 5px;
        &:hover {
          opacity: 0.7;
        }
      }
      &.build {
        a {
          background: ${$.colorB};
        }
      }
    }
    &.more {
      width: 80px;
      img {
        transform: rotate(90deg);
      }
    }
    &:last-child {
      > a, .action-more {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 0;
        &:hover {
          background: ${$.colorText($,0.1)};
        }
      }
    }
  }
  th {
    text-align: left;
  }
  td {
    height: 50px;
    background-color: ${$.white};
    border-top: 1px solid ${$.colorText($,0.15)};
    border-bottom: 1px solid ${$.colorText($,0.15)};
    &:first-child {
      border-left: 1px solid ${$.colorText($,0.15)};
      border-radius: 5px 0px 0px 5px;
    }
    &:last-child {
      border-right: 1px solid ${$.colorText($,0.15)};
      border-radius: 0px 5px 5px 0px;
    }
  }
  ${props=>props.isBlock&&css`
    display: block;
    thead {
      display: none;
      td {
        display: none;
      }
    }
    tbody {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      tr {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 240px;
        height: 165px;
        padding: 20px;
        margin: 10px;
        background-color: ${$.white};
        border-radius: 5px;
        border: 1px solid ${$.colorText($,0.15)};
        td {
          height: auto;
          background: none;
          margin: 0;
          padding: 0;
          border: 0;
          border-radius: 0;
          text-align: center;
          width: auto;
          &:first-child, &:last-child {
            position: absolute;
            border: 0;
            width: auto;
          }
          &.image {
            width: auto;
          }
          &.link {
            a {
              display: inline-block;
              margin-top: 10px;
              width: 100%;
            }
          }
          &.last {
            margin-top: 10px;
          }
          &.more {
            width: auto;
          }
          &:first-child {
            top: 20px;
            left: 20px;
          }
          &:last-child {
            top: 15px;
            right: 10px;
          }
        }
      }
    }
  `}
`;

export const ItemList = styled.tr`
  td:first-child {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      display: block;
      background: ${props=>props.colorHex};
      height: 100%;
      width: 7px;
      top: 0;
      left: 0;
    }
  }
  span.state {
    ${$.fontSize($,12)}
    text-transform: lowercase;
    background: ${$.colorText($,0.1)};
    padding: 5px 10px;
    border-radius: 1000px;
    color: ${$.colorText($,1)};
    display: flex;
    justify-content: start;
    align-items: center;
    width: max-content;
    margin: 2px auto 0px auto;
    &:before {
      content: '';
      flex-shrink: 0;
      display: inline-block;
      /* background: ${$.colorText($,0.1)}; */
      background: ${$.colorText($,0.3)};
      /* background: ${$.colorB}; */
      /* background: ${$.white}; */
      width: 7px;
      height: 7px;
      margin-right: 10px;
      border-radius: 50%;
      top: 1px;
      position: relative;
    }
  }
  ${props=>props.isBlock&&css`
    position: relative;
    td:first-child {
      &:before {
        display: none;
      }
    }
    ${props.order==='area'&&css`
      td:nth-child(4){
        order: 1;
        button {
          margin-bottom: 10px;
        }
      }
      td:nth-child(2){
        order: 2;
      }
      td:nth-child(3){
        order: 3;
      }
      td:nth-child(5){
        order: 4;
      }
    `}
    &:before {
      position: absolute;
      content: '';
      display: block;
      background: ${props.colorHex};
      height: 100%;
      width: 7px;
      top: 0;
      left: 0;
    }
  `}
`;

export const ListPage = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    margin: 0 10px;
    border: 0;
    background: none;
    opacity: 0.4;
    font-weight: ${$.bold};
    ${$.fontSize($,16)}
    &:hover {
      opacity: 1;
    }
    &.none {
      visibility: hidden;
    }
    &.this {
      opacity: 1;
      cursor: default;
    }
    img {
      height: 15px;
    }
  }
`;

export const ListNone = styled.div`
  margin-top: 50px;
  color: ${$.error};
  text-align: center;
`;

export const MoreHolder = styled.div`
  position: relative;
  ${props=>props.Open&&css`
    background: ${$.colorText($,0.1)};
  `}
`;

export const MorePopup = styled.div`
  position: absolute;
  z-index: 1;
  background: ${$.white};
  border-radius: 5px;
  top: calc(100% - 2px);
  right: 0px;
  min-width: 150px;
  text-align: center;
  box-shadow: 0px 3px 6px #00000036;

  flex-direction: column;
  display: none;
  ${props=>props.Open&&css`
    display: flex;
  `}
  a {
    width: auto;
    padding: 10px;
    color: ${$.colorText($)};
    &:hover {
      background: ${$.colorText($,0.09)};
    }
  }
  &:after {
    display: block;
    content: '';

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 9px 7px;
    border-color: transparent transparent #ffffff transparent;

    position: absolute;
    top: -9px;
    right: 10px;
  }
`;