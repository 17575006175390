import styled,{css} from 'styled-components';

import {$} from '~/styles/global';

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${$.gradient($)}
  padding: 20px;
  color: ${$.white};
  p {
    text-align: center;
  }
  a {
    color: ${$.white};
    text-decoration: underline;
  }
`;

export const Free = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3498db;
  padding: 20px;
  color: ${$.white};
  p {
    text-align: center;
  }
  a {
    color: ${$.white};
    text-decoration: underline;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${$.gradient($)}
  .circled {
    background: ${$.colorWhite};
    width: 100px;
    height: 100px;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 75px;
      /* filter: brightness(3.5); */
    }
  }
  h1, p {
    color: ${$.colorWhite};
  }
  h1, p {
    margin-top: 15px;
  }
  button {
    margin-top: 20px;
    &.whitter {
      &.full {
        width: 100%;
      }
    }
  }
  a {
    ${$.buttonStyle($,css`
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      img {
        margin-right: 10px;
      }
    `)}
  }
  ${props=>props.EditUnit&&css`
    position: absolute;
    background: ${$.colorText($,0.5)};
    top: 0px;
    left: 0px;
    z-index: 10030;
    h1, p {
      color: ${$.colorC};
    }
    > div {
      padding: 20px;
      background: ${$.colorWhite};
      border-radius: 10px;
    }
  `}
`;