import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import api from '~/services/api';

export default function State({postId,openJob,callbackJob}) {
  const config = useSelector(state=>state.config.value);
  const [status,setStatus] = useState([{}]);
  const [estado,setEstado] = useState(false);
  const [finalState,setFinalState] = useState(false);
  // const [projectId,setProjectId] = useState('');
  const [procedure_projectId,setProcedure_projectId] = useState('');

  useEffect(() => {
    let currentData
    //essa rota so pega o atual
    

    api.get(`states/units/${postId}`).then(response => {
      const current = response.data.list[0]
      currentData = {
        access: current.procedure_access,
        id: current.procedure_id,
        name: current.procedure_name,
      }
      if(response.data.paged.totalCount!==0){
        if(response.data.list[0].procedure_name){
          const datas = {
            id: response.data.list[0].procedure_id,
            name: response.data.list[0].procedure_name,
            project_id:response.data.list[0].project_id,
            procedure_project_id: response.data.list[0].procedure_project_id
          }
          setProcedure_projectId(datas.procedure_project_id)
          // setProjectId(datas.project_id)
            setEstado(datas);
            api.get(`procedures/status`,{
              params: {
                project_id: datas.project_id
              }
            }).then(response => {
              if(response.data.length === 0){
                api.get(`procedures/status`).then(response => {
                  const notNull = response.data.filter(item => item.project_id === null);
                  setStatus(notNull)
                  calculateState(notNull,currentData)
                })
              }
              setStatus(response.data)         
              calculateState(response.data,currentData);
            })
        }else{
          api.get(`procedures/status`).then(response => {
            setEstado(response.data[0]);
            // calculateState(response.data,currentData)
          })
        }
      }else{
        api.get(`procedures/status`).then(response => {
          calculateState(response.data,currentData)
        })
      }
      // const datas = {
      //   name: response.data.list[0].procedure_name
      // }
      // setStatus(datas);
      // setResponseStatus(response);
      // calculateState(status,response?.data?.procedure)
    })

  },[postId])

  async function calculateState(statusAtual,estadoAtual){
    for (const key in statusAtual) {
      if(statusAtual[key].name===estadoAtual.name && statusAtual.length===Number(key)+1){
        setFinalState(true);
      }
    }
  }

  
  async function handleState(){
    var value = '';
    try {
      var response;
      if(estado===false){
        value = status[1].id;
        response = await api.post(`states/units/${postId}`,{
          procedure_id:value,
          project_id: Number(procedure_projectId)
        });
      } else {
        for (const key in status) {
          if(estado.id===status[key].id){
            value = status[Number(key)+1].id;
          }
        }
        response = await api.put(`states/units/${postId}`,{
          procedure_id:value,
          project_id: Number(procedure_projectId)
        });
      }
      callbackJob();
      setEstado(response.data.procedure);
      calculateState(status,response.data.procedure);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }

  return (
    <div>
      <span className="status">{config.LANG['Current step']}: <strong>{estado?estado.name:status.name}</strong></span>
      {(finalState)?null:openJob&&(<button className="finalize" type="button" onClick={handleState}>{config.LANG['End Step']}</button>)}
    </div>
  );
}
