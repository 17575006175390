import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import api from '~/services/api';

import {Block,StatusBlock} from '~/pages/Dashboard/styles';

import TopLoader from '~/assets/loading.gif';

export default function Status({postId}) {
  const config = useSelector(state=>state.config.value);
  const [getProjectId, setGetProjectId] = useState([]);
  const [topLoader,setTopLoader] = useState(true);
  const [status,setStatus] = useState([{}]);
  const [estado,setEstado] = useState(false);
  const [getUnitStates, setGetUnitStates] = useState();
  const [projectID,setProjectId] = useState('');

  useEffect(()=>{
    api.get(`states/units/${postId}`).then(response =>{
      setGetProjectId(response.data.list);
      setGetUnitStates(response.data);
      setProjectId(response.data.list[0].procedure_project_id)
    })
  },[postId]);
  
  useEffect(() => {
    const projectId = getProjectId.map(item => item.project_id);
    api.get(`/procedures/status`,{
      params: {
        project_id: projectId
      }
    }).then(response => {
      if(response.data.length === 0){
        if(getUnitStates?.paged?.totalCount!==0){
          setEstado(getUnitStates?.list[0].procedure_name);
          setTopLoader(false);
        }
      }else{
        if(getUnitStates?.list[0].paged?.totalCount!==0){
          setEstado(getUnitStates?.list[0].procedure_name)
          api.get(`procedures`,{
            params: {
              available: true,
              project_id: Number(projectId)
            }
          }).then(response => {
            setStatus(response.data.list);
            setTopLoader(false);
          })
        }
      }
    setTopLoader(false);
      
    })
  },[getUnitStates,getProjectId]);

  // async function handleSetState(value){
  //   await api.put(`states/units/${postId}`,{procedure_id:value,});
  //   const response = await api.get(`states/units/${postId}`)
  //     setGetUnitStates(response.data);
  //   // })
  // }
  
  async function handleState(value){
    try {
      var response;
      if(estado===false){
        //rota que seta
        response = await api.post(`states/units/${postId}`,{procedure_id:value,
          project_id: Number(projectID)
        });
      } else {
        response = await api.put(`states/units/${postId}`,{
          procedure_id:value,
          project_id: Number(projectID)
        });
      }
      setEstado(response.data.procedure.name);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  return (
    <Block Full>
      {topLoader?(
        <>
          <h2>{config.LANG['Production Status']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <h2>{config.LANG['Production Status']}</h2>
          <StatusBlock>
            {status.map((s,index)=>(
              <li key={index}>
                <strong>{index+1}. {s.name}</strong>
                {(estado===s.name||(estado===false&&s.name===status[0].name))
                ?(<p className="state">{(status.length===index+1)?config.LANG['conversion allowed']:config.LANG['under development']}</p>)
                :null}
                <button type="button" onClick={()=>handleState(s.id)}>{config.LANG['select step']}...</button>
              </li>
            ))}
          </StatusBlock>
        </>
      )}
    </Block>
  );
}
