import React,{useCallback,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import api from '~/services/api';

import Async from '~/services/Async';

import {Backups} from '~/components/Backups';

import BackupsList from '~/pages/Units/compare/popup/backups/list';

import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

class Content {
  constructor(){
    this.clear();
  }
  read(){
    const {html,text} = this;
    return {html,text};
  }
  update({html,text}){
    this.html = html;
    this.text = text;
  }
  clear(){
    this.html = undefined;
    this.text = undefined;
  }
}
const content = new Content();

export default function BackupsComponent({Open,Close,editor,postId,Load,loadCompare,handleDeconstructInteraction}) {
  const config = useSelector(state=>state.config.value);
  useEffect(()=>{
    content.clear();
  },[postId]);
  const handleCompare = useCallback(async(html)=>{
    if(Open&&editor) restoreVersion();
    async function restoreVersion(){
      Load(true);
      // ================
      // Save and mantain the same first content
      // ================
      const {html:htmlOld} = content.read();
      if(!htmlOld) content.update({
        html: editor.getContent(),
        text: editor.getContent()
      });

      // ================
      // Apply the selected backup code and deconstruct the interaction in order to get it's text
      // ================
      editor.resetContent(html);
      await Async(res=>handleDeconstructInteraction(res));

      // ================
      // Gets all the backup's text
      // ================
      var element = editor.getBody();
      var elements = element.querySelectorAll(':scope > *');
      var buildHTML = '';
      Array.prototype.forEach.call(elements, function(el, i){
        buildHTML += `${el.textContent} `;
      });

      // ================
      // Build the endpoint structure
      // ================
      const current = content.read().html;
      const other = buildHTML;

      // ================
      // API Post
      // ================
      try {
        const {data:{compare}} = await api.post(`compare`,{
          current,
          other
        });

        // ================
        // Read all the responses
        // ================
        var finalHTML = '';
        var isInsideTag = false;
        for (let index = 0; index < compare.length; index++) {
          
          // ================
          // Read all the characters
          // ================
          var openChangeTag = false;
          const compared = compare[index];
          for (var i = 0; i < compared.value.length; i++) {
            const character = compared.value[i];
            const nextCharacter = compared.value[i+1];
            if(character==='<') isInsideTag = true;
            if(character==='>') isInsideTag = false;

            if(isInsideTag){
              if(!compared.removed) finalHTML += character;
            } else {
              if(compared.added){
                if(character!=='>'){
                  if(nextCharacter!=='<'&&nextCharacter!==undefined){
                    if(!openChangeTag) finalHTML += `<span class="added">`;
                    openChangeTag = true;
                    finalHTML += character;
                  } else {
                    finalHTML += `${character}</span>`;
                    openChangeTag = false;
                  }
                } else {
                  finalHTML += character;
                }
              }
              else if(compared.removed){
                if(character!=='>'){
                  if(nextCharacter!=='<'&&nextCharacter!==undefined){
                    if(!openChangeTag) finalHTML += `<span class="removed">`;
                    openChangeTag = true;
                    finalHTML += character;
                  } else {
                    finalHTML += `${character}</span>`;
                    openChangeTag = false;
                  }
                } else {
                  finalHTML += character;
                }
              }
              else {
                finalHTML += character;
              }
            }
            // console.log('finalHTML',finalHTML);
          }
        }
        editor.resetContent(finalHTML);
      } catch(err){
        toast.error(config.LANG['Incorrect information. Try again.']);
      }
      Close();
      Load(false);
    }
  },[Open,editor,Close,Load,config.LANG]);
  return (
    <PopUp isFront Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <Backups>
        <h1>{config.LANG['Backups']}</h1>
        <p>Selecione uma das versões antigas salvas no Backup para comparar com a atual.</p>
        <div>
          {loadCompare
            ?(<p className="loaderHolder">
                <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
              </p>)
            :(<BackupsList reload={Open} postId={postId} callback={handleCompare} />)
          }
        </div>
      </Backups>
    </PopUp>
  );
}

// function checkIsInsideTag(string){
//   var finalValue = undefined;
//   for (var i = 0; i < string.length; i++) {
//     const letter = string[i];
//     if(letter==='<'){
//       finalValue = true;
//     }
//     if(letter==='>') {
//       finalValue = false;
//     }
//   }
//   return finalValue;
// }