import styled from 'styled-components';

import {$} from '~/styles/global';

export const ContentTitle = styled.div`
  height: 42px;
`;

export const Title = styled.h1`
  display: inline-block;
`;

export const IconHelp = styled.a`
  display: inline-block;
  vertical-align: sub;
  margin-left: 5px;
  margin-right: 5px;
  color: ${$.colorC};
`; 
