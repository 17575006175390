import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Form,Input} from '@rocketseat/unform';
import {toast} from 'react-toastify';
import {Title,Subtitle} from '~/components/Container';
import api from '~/services/api';
import {Row,Block,List,Item,Perfil,TitleContainer} from '~/pages/Dashboard/styles';
import TopLoader from '~/assets/loading.gif';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import search from '~/assets/icons/search.svg';

export default function Jobs({selectedList,postsId,openOutside,namesOfProject}) {
  const config = useSelector(state=>state.config.value);
  // INIT

  const [topLoader,setTopLoader] = useState(true);
  // USERS
  const [projectID,setProjectID] = useState('');

  const [page,setPage] = useState(1);
  // PAGE

  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }

  // SEARCH
  const [searched,setSearched] = useState('');
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }

  // POSTS
  const [posts,setPosts] = useState({
    users: [],
    paged: {}
  });

  const [update,setUpdate] = useState(true);

  useEffect(() => {
    api.get(`procedures`,{
      params: {
        perPage: 10,
        page:page,
        search: searched,
        project_id: Number(selectedList),
      }}).then(response => {
        if(response.data.list.length === 0){
          setProjectID('');
        }else{
          setProjectID(selectedList);
        }
      })
  },[selectedList,searched,page])
  

  useEffect(()=>{
    if(projectID === '' || projectID === '0'){
      api.get(`procedures`,{
        params:{
          perPage: 10,
          page,
          search: searched,
          project_id: 0
        }
      }).then(response => {
        const datasOfProject_isIsNull = response.data.list.filter(item => item.project_id === null);
        const dataObject = {
          list: datasOfProject_isIsNull,
          paged: response.data.paged
        }
        setPosts(dataObject);
        setTopLoader(false);
      })
    }else{
      api.get(`procedures`,{
            params:{
              perPage: 10,
              page,
              project_id: Number(projectID),
              search: searched,
            }
          }).then(response => {
            if(response.data.list.length === 0){
              api.get(`procedures`,{
                params:{
                  perPage: 10,
                  page,
                  search: searched,
                }
              }).then(response => {
                const datasOfProject_isIsNull = response.data.list.filter(item => item.project_id === null);
                const dataObject = {
                  list: datasOfProject_isIsNull,
                  paged: response.data.paged
                }
                setPosts(dataObject);
                setTopLoader(false);
              })
            }else{
              setPosts(response.data);
              setTopLoader(false);
            }
          });
    }
  },[update,page,searched,selectedList,projectID]);

  // HANDLE JOBS
  async function handleAddJob(procedure){
    const updatingUsers = [];
    for (const key in postsId) { updatingUsers.push(Number(key)); }
    try {
      const oldFeedback = {...feedback};
      if(procedure.project_id !==null){
        await api.put(`jobs/bulk/users`,{
          users: updatingUsers,
          procedure_id:procedure.id,
          project_id: Number(selectedList),
          available: true,
        });
      }else{
        await api.put(`jobs/bulk/users`,{
          users: updatingUsers,
          procedure_id:procedure.id,
          project_id: 0,
          available: true,
        });
      }
      oldFeedback[procedure.id] = true;
      setFeedback(oldFeedback);
      toast.success(`${config.LANG['Users have been successfully nominated for the obligation']} ${procedure.name} `);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  async function handleDeleteJob(procedure){
    const updatingUsers = [];
    for (const key in postsId) { updatingUsers.push(Number(key)); }
    try {
      const oldFeedback = {...feedback};
      await api.put(`jobs/bulk/users`,{
        users: updatingUsers,
        procedure_id: procedure.id,
        project_id: Number(selectedList),
        available: false,
      });
      delete oldFeedback[procedure.id];
      setFeedback(oldFeedback);
      toast.success(`${config.LANG['Users have been successfully removed from the obligation']} ${procedure.name}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  // FEEDBACK
  const [feedback,setFeedback] = useState({});
  useEffect(()=>{
    async function Setup(){
      if(!openOutside){
        setFeedback({});
      }
    }
    Setup();
  },[openOutside]);
  return (
    <Row>
      {topLoader?(
        <>
          <Block Full>
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          </Block>
        </>
      ):(
        <>
          <Block Full>
            <div>
            <TitleContainer>
              <Title>{config.LANG['Production Status']} <span>({posts.paged.totalCount})</span></Title>
              <Subtitle>{config.LANG['from']} {namesOfProject}</Subtitle>
            </TitleContainer>
              <Form onSubmit={handleSearch}>
                <Input name="search" placeholder={`${config.LANG['Search']}…`} />
                <button type="submit">
                  <img src={search} alt={config.LANG['Search']} />
                </button>
              </Form>
            </div>
              {posts.list[0]?(
                <List>
                  {posts ? posts.list.map(items =>(
                      <Item key={items.id}>
                      <Perfil>
                        <div>
                          <strong>{items.name}</strong>
                        </div>
                        {!feedback[items.id]?(
                          <button type="button" onClick={()=>handleAddJob(items)}>{config.LANG['Indicate']}</button>
                        ):(
                          <button className="remove" type="button" onClick={()=>handleDeleteJob(items)}>{config.LANG['Remove']}</button>
                        )}
                      </Perfil>
                    </Item>
                  )) : posts.list.map(post=>(
                    <Item key={post.id}>
                      <Perfil>
                        <div>
                          <strong>{post.name}</strong>
                        </div>
                        {!feedback[post.id]?(
                          <button type="button" onClick={()=>handleAddJob(post)}>{config.LANG['Indicate']}</button>
                        ):(
                          <button className="remove" type="button" onClick={()=>handleDeleteJob(post)}>{config.LANG['Remove']}</button>
                        )}
                      </Perfil>
                    </Item>
                  )) }
                </List>
              ):(
                <p>{config.LANG['No users found!']}</p>
              )}
            {posts.paged.pages>1&&(
              <nav>
                <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                  <img src={leftArrow} alt={config.LANG['Backward']} />
                </button>
                <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                  <img src={rightArrow} alt={config.LANG['Forward']} />
                </button>
              </nav>
            )}
          </Block>
        </>
      )}
    </Row>
  );
}
