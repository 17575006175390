import styled, {css} from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
`;

export const Badge = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: ${$.white};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: relative;
  &:before {
    display: ${props => props.newAnnoucement ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 10px;
    right: 10px;
    width: 9px;
    height: 9px;
    background: ${$.colorB};
    border: 2px solid ${$.white};
    border-radius: 50%;
    box-sizing: content-box;
  }
`;

export const NotificationBackground = styled.div`
  z-index: 10;
  position: fixed;
  background: ${$.white};
  width: 375px;
  height: 100%;
  top: 0px;
  transition: right 1s;
  right: -400px;
  border: 0;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.15);

  display: flex;
  flex-direction: column;
  cursor: default;
  &.open {
    right: 0px;
  }
  aside {
    height: 100%;
    overflow: auto;
    header {
      padding: 30px 40px 0px;
    }
  }
`;

export const ExchangeMenu = styled.div`
  margin-top: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 80px;
  margin-right: 80px;

  border: 0px;
  border-bottom: 1px solid ${$.colorText($,0.25)};
`;

export const ButtonAnnouncement = styled.button`
    font-weight: ${$.regular};
    padding: 12px;
    color: ${$.colorText($,0.6)};
    border: 0;
    border-bottom:  2.5px solid transparent;
    background: none;
    font-size: 15px;
    transition: 0.2s;

    ${props => props.selected && css`
      font-weight: ${$.bold};
      color: ${$.colorText($)};
      border-bottom: 2.5px solid ${$.colorB};
    `}
`;

export const Back = styled.button`
  position: absolute;
  width: 25px;
  height: 40px;
  left: -25px;
  top: 30px;
  border-radius: 5px 0px 0px 5px;
  background: ${$.white};
  border: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const NotificationList = styled.ul`
  li {
    padding: 30px 40px;
    &+li{
      border-top: 1px solid ${$.colorText($,0.2)};
    }
    span {
      display: inline-block;
      margin-top: 10px;
      color: ${$.colorText($,0.4)};
    }
  }
  div.navigation {
    margin: 0;
    box-sizing: border-box;
    border: none;
    width: 100%;
    padding: 0;
    nav {
      width: 100%;
      button {
        background: transparent;
        color: ${$.colorText($)};
        font-weight: ${$.bold};
        &.this {
          cursor: default;
          opacity: 0.7;
        }
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  /* margin: 25px 40px; */
  form{
    .form-notifications{
      padding: 0;
    }
  }
  .button-to-click{
    display:flex;
    padding: 5px;
    align-items:center;
    justify-content: center;
    /* border: 1px solid black; */
    background: transparent;
    & > div{
      transform: scale(0.7);
    }
     .notifications{
        color: rgba(80,80,80,0.5);
        font-size: 14px;
        margin-right: 10px;
        margin-bottom: 0;
      }
  }
`; 