import React,{useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Form} from '@rocketseat/unform';
import * as Yup from 'yup';

import {DataEncoder} from '~/pages/Units/editor/interaction/functions';

import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import Fields from '~/pages/Units/editor/fields';

import close from '~/assets/icons/close.svg';

export default function AddToolTip({Open,Close,editor,postId}) {
  const config = useSelector(state=>state.config.value);
  const handleInsertToolTip = useCallback((data)=>{
    if(Open&&editor) InsertToolTip();
    function InsertToolTip(){
      data = DataEncoder(data);
      editor.focus();
      const selectedContext = editor.selection.getContent();
      editor.selection.setContent(`<span class="tooltip" data-text="${data.text}" data-modal="${data.modal}">${selectedContext}</span>`);
      Close();
    }
  },[Open,editor,Close]);
  // SCHEMAS =====
  const schema = Yup.object().shape({
    text: Yup.string().required(config.LANG['Required field']),
    modal: Yup.boolean(),
  });
  const initialData = {};
  return (
    <PopUp Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>Adicionar ToolTip</h1>
      <h2></h2>
      <Form id="form_send" initialData={initialData} schema={schema} onSubmit={handleInsertToolTip}>
        <FormRow>
          <FormColumn Line>
            <Fields field={{label:'Texto',required:true,name:'text',placeholder:'Insira o texto.',type:'editor'}} /><br />
            <Fields field={{label:'Modal',name:'modal',type:'boolean'}} />      
          </FormColumn>
        </FormRow>
        <SubmitRow>
          <p>*{config.LANG['Required Fields']}</p>
          <div>
            <button type="submit">{config.LANG['Insert']}</button>
          </div>
        </SubmitRow>
      </Form>
    </PopUp>
  );
}