export default {
  // ~/page/SignIn
  'Welcome': 'Bem-vindo',
  'Free trial registration':'Teste gratuito',
  'Login to access': 'Faça o login para acessar',
  'register to access': 'Faça o cadastro para acessar',
  'Your email': 'Seu email',
  'Full name': 'Nome completo',
  'Name is required': 'Nome é obrigatório',
  'company name is mandatory': 'Nome da empresa é obrigatório',
  'Phone is required': 'O telefone é obrigatório',
  'Your secret password': 'Sua senha secreta',
  'password_confirmation':'Confirme sua senha',
  'Loading': 'Carregando',
  'Login': 'Login',
  'Next': 'Próximo',
  'company': 'Empresa ou Instituição',
  'contact phone': 'Telefone para contato',
  'information at realize': 'Deseja receber informativos da realize?',
  'Forgot your password?': 'Esqueceu sua senha?',
  'Registration': 'Cadastrar',
  'Free trial': 'Teste gratuito',
  'Email is required': 'O email é obrigatório',
  'Enter a valid email': 'Insira um email válido',
  'Password is required': 'A senha é obrigatória e no mínimo de 6 dígitos',
  'Authentication failed, check your data!': 'Falha na autenticação, verifique os seus dados!',
  // ~/page/Password
  'Redefine password': 'Redefinir senha',
  'Fill in your new password below.': 'Preencha abaixo sua nova senha.',
  'New password': 'Nova senha',
  'Confirm your password': 'Confirme sua senha',
  'Type the same password above': 'Digite a mesma senha acima',
  'Redefine': 'Redefinir',
  'At least 6 characters': 'No mínimo 6 caracteres',
  'Password is different from the one entered above.': 'Senha digitada é diferente da informada acima.',
  'your password has been changed. Now perform your Login.': 'sua senha foi alterada. Agora realize o seu Login.',
  'Incorrect information. Try again.': 'Informações incorretas. Tente novamente.',

  'Recover Password': 'Recuperar senha',
  'Enter your email below to reset your password.': 'Digite o e-mail abaixo para redefinir a sua senha.',
  'Type your e-mail': 'Digite seu email',
  'Recover': 'Recuperar',
  'Back to Login': 'Voltar para Login',
  'We sent a password change email to': 'Enviamos um email de alteração de senha para',
  'Email does not exist. Try again.': 'Email inexistente. Tente novamente.',
  // ~/components/Menu
  'Dashboard': 'Painel',
  'Users': 'Usuários',
  'Projects': 'Projetos',
  'Logout': 'Sair',
  // ~/components/Profile
  'Edit Profile': 'Editar Perfil',
  'Settings': 'Configurações',
  'Procedures': 'Procedimentos',
  'Templates': 'Templates',
  // ~/components/Notifications
  'No activity yet.': 'Nenhuma atividade ainda.',
  'NotificationsTitle': 'Notificações',
  'Notifications': 'NOTIFICAÇÕES',
  'HistoricTitle': 'Histórico',
  'Historic': 'HISTÓRICO',
  'Historic Report': 'Histórico de Atividade',
  'Activities': 'Atividades',
  'Activities Report': 'Novas Notificações',
  'NotificationsEmail': 'Receber por email',
  // ~/page/Dashboard/components/helloworld
  'Hello': 'Olá',
  'You have': 'Você tem',
  'Project(s) in progress.': 'Projeto(s) em andamento.',
  'Access a project to continue working!': 'Acesse um projeto para continuar trabalhando!',
  // ~/page/Dashboard/components/projects
  'Project': 'Projeto',
  'subjects': 'disciplinas',
  'access': 'acessar',
  'No projects found!': 'Nenhum projeto encontrado!',
  'Backward': 'Voltar',
  'Forward': 'Avançar',
  // ~/page/Dashboard/components/planConvertion
  'Available conversions': 'Conversões disponíveis',
  // ~/page/Dashboard/components/planUser
  'Available users': 'Usuários disponíveis',
  // ~/page/Overview
  'Overview': 'Visão geral',
  // ~/page/Overview/components/areas
  'Areas': 'Áreas',
  'Search': 'Pesquisar',
  'No area found!': 'Nenhuma área encontrada!',
  // ~/page/Overview/components/subjects
  'Subjects': 'Disciplinas',
  'No subject found!': 'Nenhuma disciplina encontrada!',
  // ~/page/Overview/components/units
  'Units': 'Unidades',
  'No units found!': 'Nenhuma unidade encontrada!',
  // ~/page/Overview/components/delegates
  'My Units': 'Minhas Unidades',
  // ~/page/Overview/components/followers
  'My Favorites': 'Meus Favoritos',
  // ~/page/Overview/components/status
  'Production Status': 'Status de Produção',
  'Save': 'Salvar',
  'Report': 'Relatório',
  // ~/page/Overview/components/convertions
  'Latest conversions': 'Últimas conversões',
  'By': 'Por',
  'No conversions found!': 'Nenhuma conversão encontrada!',
  // ~/page/Overview/components/plan
  'Current Plan': 'Plano Atual',
  'Conversions': 'Conversões',
  // ~/page/Profile
  'My Profile': 'Meu Perfil',
  'My data': 'Meus dados',
  'Full Name': 'Nome Completo',
  'Email': 'E-mail',
  'Your e-mail address': 'Seu endereço de e-mail',
  'Change profile picture': 'Trocar imagem de perfil',
  'Current Password': 'Senha Atual',
  'Required Fields': 'Campos Obrigatórios',
  'Update Profile': 'Atualizar Perfil',
  'Required field': 'Campo obrigatório',
  'Profile updated successfully!': 'Perfil atualizado com sucesso!',
  'Error updating profile, check your data!': 'Erro ao atualizar perfil, confira seus dados!',
  'The selected users will have their profile updated': 'Os usuários selecionados terão o seu perfil atualizado',
  // ~/components/AvatarInput
  'Remove Image': 'Remover Imagem',
  'Choose image': 'Escolher imagem',
  // ~/page/Users
  'DATE': 'DATA',
  'TRASH': 'LIXEIRA',
  'Block': 'Bloco',
  'List': 'Lista',
  'We have sent a creation email to': 'Enviamos um email de criação para',
  'Check the user limit.': 'Verifique o limite de usuários.',
  'Successfully deleted users!': 'Usuários deletados com sucesso!',
  'Users successfully recovered!': 'Usuários recuperados com sucesso!',
  'User successfully deleted!': 'Usuário deletado com sucesso!',
  'User successfully recovered!': 'Usuário recuperado com sucesso!',
  'Check the number of users on your plan.': 'Verifique a quantidade de usuários do seu plano.',
  'An error has occurred. Try again.': 'Algum erro ocorreu. Tente novamente.',
  'List of': 'Lista de',
  'User': 'Usuário',
  'Add': 'Adicionar',
  'Delete': 'Deletar',
  'Duplicate': 'Duplicar',
  'Function': 'Função',
  'No users found!': 'Nenhum usuário encontrado!',
  'Close': 'Fechar',
  'Password': 'Senha',
  'Profile': 'Perfil',
  'Are you sure you want to delete': 'Tem certeza que deseja deletar',
  'Are you sure you want to recover': 'Tem certeza que deseja recuperar',
  'these': 'estes',
  'this': 'este',
  'They will be saved in the trash and you can recover them later.': 'Eles ficarão salvos dentro da lixeira e será possível recuperá-los depois.',
  'It will be saved in the trash and you can recover it later.': 'Ele será salvo na lixeira e você poderá recuperá-lo mais tarde.',
  'YES': 'SIM',
  'NO': 'NÃO',
  'Edit': 'Editar',
  'More': 'Mais',
  'Administrador': 'Administrador',
  'Diretor': 'Diretor',
  'Gerente': 'Gerente',
  'Editor': 'Editor',
  'Conteudista': 'Conteudista',
  'Obligations': 'Obrigações',
  'Indicate Obligations': 'Indicar Obrigações',
  'The selected users will be appointed for the following obligations:': 'Os usuários selecionados serão indicados para as seguintes obrigações:',
  'Indicate': 'Indicar',
  'Users have been successfully nominated for the obligation': 'Os usuários foram indicados com sucesso para a obrigação',
  'Users have been successfully removed from the obligation': 'Os usuários foram removidos com sucesso da obrigação',
  // ~/page/Users/edit
  'Redefine data': 'Redefinir dados',
  // ~/page/Projects
  'successfully created!': 'criado com sucesso!',
  'successfully edited!': 'editado com sucesso!',
  'successfully deleted!': 'deletados com sucesso!',
  'successfully recovered!': 'recuperados com sucesso!',
  'SINGLE successfully deleted!': 'deletado com sucesso!',
  'SINGLE successfully recovered!': 'recuperado com sucesso!',
  'View': 'Visualizar',
  'Name': 'Nome',
  // ~/components/Rules
  'Customization': 'Personalização',
  'Themes': 'Temas',
  'Nothing found': 'Nada encontrado',
  'Update Theme': 'Atualizar Tema',
  'Are you sure you want to choose this theme?': 'Tem certeza que deseja escolher esse tema?',
  // ~/page/Areas
  'Area': 'Área',
  'Knowledge Areas': 'Áreas de Conhecimento',
  'Knowledge Area': 'Área de Conhecimento',
  'FEMALE successfully created!': 'criada com sucesso!',
  'FEMALE successfully edited!': 'editada com sucesso!',
  'FEMALE successfully deleted!': 'deletadas com sucesso!',
  'FEMALE successfully recovered!': 'recuperadas com sucesso!',
  'FEMALE SINGLE successfully deleted!': 'deletada com sucesso!',
  'FEMALE SINGLE successfully recovered!': 'recuperada com sucesso!',
  'from': 'de',
  'Color': 'Cor',
  'FEMALE these': 'estas',
  'FEMALE this': 'esta',
  'FEMALE They will be saved in the trash and you can recover them later.': 'Elas ficarão salvas dentro da lixeira e será possível recuperá-las depois.',
  'FELAME It will be saved in the trash and you can recover it later.': 'Ela será salva na lixeira e você poderá recuperá-la mais tarde.',
  // ~/page/Areas/edit
  'Update': 'Atualizar',
  'Change': 'Alterar',
  'Current': 'Atual',
  // ~/page/Areas/edit/projects
  'All': 'Todos os',
  'FEMALE All': 'Todas as',
  'Selected': 'Selecionado',
  'Select': 'Selecionar',
  'Are you sure you want to choose a new Project for this Area?': 'Tem certeza que deseja escolher um novo Projeto para essa Área?',
  // ~/page/Subjects
  'Subject': 'Disciplina',
  'Are you sure you want to choose a new Area for this Subject?': 'Tem certeza que deseja escolher uma nova Área para essa Disciplina?',
  // ~/page/Units
  'Unity': 'Unidade',
  'Delegate': 'Delegar',
  'Status': 'Status',
  'Create': 'Construir',
  'DUPLICATE': 'DUPLICAR',
  'DELETE': 'CANCELAR',
  'delegates': 'Usuários delegados',
  'personalize': 'Personalizar',
  'content': 'Conteúdo',
  'template': 'Template',
  'Duplicate Unit': 'Duplicar Unidade',
  'Delegate Responsible': 'Delegar Responsáveis',
  'Duplicate message': 'Selecione o que você deseja duplicar',
  'Attention': 'Atenção',
  'The users below will be delegated as responsible for the selected units': 'Os usuários abaixo serão delegados como responsáveis das unidades selecionadas',
  'Exclude': 'Excluir',
  // ~/pages/Units/index/delegates
  'was successfully delegated as responsible!': 'foi delegado como responsável com sucesso!',
  'has been removed as responsible successfully!': 'foi removido como responsável com sucesso!',
  'Remove': 'Remover',
  'Are you sure you want to choose a new Discipline for this Unit?': 'Tem certeza que deseja escolher uma nova Disciplina para essa Unidade?',
  // ~/pages/Units/view
  'Personalize': 'Personalizar',
  'Go to Editor': 'Ir para o Editor',
  'Calculating': 'Calculando',
  'The percentage of change identified since the last conversion is': 'A porcentagem de alteração identificada desde a última conversão é de',
  'The maximum allowed for a free conversion is': 'O máximo permitido para uma conversão gratuita é de',
  'Therefore, you will not be charged for a new conversion of your plan!': 'Sendo assim, não será cobrada uma nova conversão do seu plano!',
  // ~/pages/Units/view/converter
  'Unit successfully converted!': 'Unidade convertida com sucesso!',
  'Conversion': 'Conversão',
  'Convert': 'Converter',
  'Convert unit': 'Converter unidade',
  'Are you sure you want to convert this unit?': 'Tem certeza que deseja converter essa unidade?',
  'A conversion will be discounted from the amount provided for your plan when converting this unit.': 'Uma conversão será descontada da quantidade fornecida para o seu plano ao converter essa unidade.',
  'Converting': 'Convertendo',
  // ~/pages/Units/view/convertions
  'Conversions of': 'Conversões de',
  // ~/pages/Units/view/medias
  'Downloads': 'Downloads',
  'Download': 'Download',
  'Download the created conversion': 'Faça download da conversão realizada',
  'Convert this unit to download': 'Converta essa unidade para fazer download',
  // ~/pages/Units/view/status
  'conversion allowed': 'conversão permitida',
  'under development': 'em desenvolvimento',
  'select step': 'selecionar etapa',
  // ~/pages/Units/view/delegateusers/delegates
  'Responsible': 'Responsáveis',
  'No delegated users for this unit': 'Nenhum usuário delegado para essa unidade',
  // ~/pages/Units/extras
  'Custom unit successfully!': 'Unidade personalizada com sucesso!',
  'Customize Unit': 'Personalizar Unidade',
  'Custom units for the project': 'Unidades Personalizada para o projeto',
  'Import': 'Importar',
  'Customize by disciplines': 'Customizar por disciplinas',
  'Customize by areas': 'Customizar por áreas',
  'Current theme options': 'Opções do tema atual',
  'Import Customization': 'Importar Personalização',
  'Import Customization from another Unit': 'Importar Personalização de outra Unidade',
  // ~/pages/Units/editor
  'Backup': 'Histórico',
  'Format': 'Formatar',
  'Share': 'Compartilhar',
  'Mode': 'Modo',
  'Preview Link': 'Link de Visualização',
  'The conversion may take a long time to complete':'A conversão pode demorar para ser finalizada. Enquanto isso você pode navegar pelo sistema',
  'Spelling': 'Revisão Ortográfica',
  'Word Counter': 'Contador de Palavras',
  'Backup of Editions': 'Backup de Edições',
  'Resources': 'Recursos',
  'Edit Interaction': 'Editar Interação',
  'Comments': 'Comentários',
  'Comment': 'Comentário',
  'SelectUsersTitle': 'Marcar usuários',
  'SelectUsers': 'Selecione os usuários',
  'delete': 'apagar',
  'none': 'nenhum',
  'This interaction is another theme.': 'Essa interação é de outro tema.',
  'Change the theme to edit this interaction.': 'Mude o tema para editar essa interação.',
  'Corrupted interaction. Create it again. You may have deleted a line or important information.': 'Interação corrompida. Crie novamente. Você pode ter deletado alguma linha ou informação importante.',
  'Content saved successfully!': 'Conteúdo salvo com sucesso!',
  'Processing': 'Processando',
  'Reload': 'Recarregar',
  'Save to view your work': 'Salve para visualizar o seu trabalho',
  'Interactions': 'Interações',
  'Insert': 'Inserir',
  'Backups': 'Backups',
  'Save this file to create a new backup': 'Salve esse arquivo para criar um novo backup',
  'If you recover a backup, you will lose your work done that was not saved': 'Ao recuperar um backup, você perderá o trabalho realizado não salvo',
  'Preview': 'Pré-visualizar',
  'Submit the link below for viewing': 'Envie o link abaixo para visualização',
  'Use the password provided for authorization': 'Utilize a senha disponibilizada para autorização',
  'Link': 'Link',
  'Copy': 'Copiar',
  'Delete Interaction': 'Deletar Interação',
  'Are you sure you want to delete this interaction?': 'Tem certeza que deseja deletar essa interação?',
  'You will not be able to recover it later': 'Não será possível recuperá-la depois',
  'Number of pages': 'Quantidade de páginas',
  'Number of words': 'Quantidade de palavras',
  'Number of characters': 'Quantidade de caracteres',
  'Full text': 'Texto na integra',
  'Add Comment': 'Adicionar Comentário',
  'Write your comment here.': 'Escreva aqui o comentário.',
  'No backups found!': 'Nenhum backup encontrado!',
  'Topic': 'Tópico',
  'Title': 'Título',
  'Subtitle Original': 'Subtítulo',
  'Quote': 'Citação',
  // ~/pages/Units/editor/state
  'Current step': 'Etapa atual',
  'End Step': 'Finalizar Etapa',
  // ~/pages/Units/revisor
  'Smart Reviewer': 'Revisor Inteligente',
  'Searching for Errors': 'Buscando Erros',
  'Spell Check': 'Correção Ortográfica',
  'Error identified': 'Erro identificado',
  'Text for correction': 'Texto para correção',
  'Correction options': 'Opções de correção',
  'Code': 'Código',
  'Delete Error': 'Deletar Erro',
  'Are you sure you want to delete this error?': 'Tem certeza que deseja deletar esse erro?',
  'Search Errors': 'Buscar Erros',
  'Correct': 'Corrigir',
  'You must have some saved content to enter spell check mode.': 'Você deve ter algum conteúdo salvo para entrar no modo revisão ortográfica.',
  'There are interactions of another theme.': 'Existem interações de outro tema.',
  'They were not loaded correctly.': 'Elas não foram carregadas corretamente.',
  'Corrupted interaction. There are interactions with formatting errors. Return to the editor and correct the interactions.': 'Interação corrompida. Existem interações com erro de formatação. Retorne ao editor e corrija as interações.',
  // ~/pages/Share
  'Incorrect password.': 'Senha incorreta.',
  'Authentication': 'Autenticação',
  'Check': 'Verificar',
  'Resource not started': 'Recurso não iniciado',
  // ~/components/Pixabay
  'Images': 'Imagens',
  'Free Image Stock': 'Banco de Imagens Livre',
  'Term': 'Termo',
  'Enter the term to filter.': 'Digite o termo para filtrar.',
  'Term Language': 'Idioma do Termo',
  'Portuguese': 'Português',
  'English': 'Inglês',
  'Spanish': 'Espanhol',
  'All Single': 'Todos',
  'Type': 'Tipo',
  'Photo': 'Foto',
  'Illustration': 'Ilustração',
  'Vector': 'Vetor',
  'Orientation': 'Orientação',
  'Horizontal': 'Horizontal',
  'Vertical': 'Vertical',
  'Category': 'Categoria',
  'Backgrounds': 'Backgrounds',
  'Fashion': 'Fashion',
  'Nature': 'Natureza',
  'Science': 'Ciência',
  'Education': 'Educação',
  'Feelings': 'Sentimentos',
  'Health': 'Saúde',
  'People': 'Pessoas',
  'Religion': 'Religião',
  'Places': 'Locais',
  'Animals': 'Animais',
  'Industry': 'Indústria',
  'Computer': 'Computação',
  'Food': 'Comida',
  'Sports': 'Esportes',
  'Transportation': 'Transporte',
  'Travel': 'Viagem',
  'Buildings': 'Construções',
  'Business': 'Negócios',
  'Music': 'Música',
  'Filter': 'Filtrar',
  'No images found!': 'Nenhuma imagem encontrada!',
  // ~/components/Init
  'The system will enter maintenance mode in minutes.': 'O sistema entrará em manutenção em minutos.',
  'Save your work so you will not lose your progress.': 'Salve o seu trabalho para não perder o seu progresso.',
  'The system has been successfully updated!': 'O sistema foi atualizado com sucesso!',
  'Incorrect Version': 'Versão Incorreta',
  'You are working on an outdated version.': 'Você está trabalhando em uma versão desatualizada.',
  'Save your work and': 'Salve o seu trabalho e',
  'Click here to update': 'Clique aqui para atualizar',
  'Editing Unit': 'Editando Unidade',
  "There are other people editing this unit in another window. Take care not to replace other people's work.": 'Existem outras pessoas editando essa unidade em outra janela. Tome cuidado para não substituir o trabalho alheio.',
  // ~/components/Init
  'Uploading file': 'Carregando arquivo',
  'An error has occurred. Try again or check the file size. Decrease the file size, if necessary.': 'Ocorreu um erro. Tente novamente ou verifique o tamanho do arquivo. Diminuia o tamanho do arquivo, se necessário.',
  'Image Stock': 'Bancos de Imagem',
  'Choose': 'Escolher',
  // ~/components/Fields/VideoInput
  'Video': 'Vídeo',
  // ~/components/Fields/ZipInput
  'The package must have an index.html file at its root that will load the interaction.': 'O pacote deverá ter em sua raiz um arquivo index.html que irá carregar a interação.',
  'Package': 'Pacote',
  // ~/components/Fields/AudioInput
  'Audio': 'Áudio',
  // ~/components/Fields/ImageEditor
  'Subtitle': 'Legenda',
  'Source': 'Fonte',
  'Paragraph': 'Parágrafo',
  'Formatting': 'Formatação',
  'Insert image caption here': 'Insira aqui a legenda da imagem',
  'Source: Type the source of the image here.': 'Fonte: Escreva aqui a fonte da imagem.',
  // ~/components/ZenDesk
  'Hello Support Realize, I have a problem.': 'Olá Suporte Realize, eu tenho um problema.',
  // ~/components/ImportUsers
  'DOWNLOAD TEMPLATE SPREADSHEET': 'DOWNLOAD PLANILHA MODELO',
  'IMPORT SPREADSHEET': 'IMPORTAR PLANILHA',
  'add multiple users': 'adicionar vários usuários',
  'Import User Spreadsheet': 'Importar planilha de Usuários',
  'See below how to complete and import the spreadsheet to register users': 'Veja abaixo como preencher e importar a planilha para cadastro dos usuários',
  'See below the users who will be registered in the system': 'Veja abaixo os usuários que serão cadastrados no sistema',
  'REGISTER USERS': 'CADASTRAR USUÁRIOS',
  'CANCEL': 'CANCELAR',
  'Your spreadsheet is inconsistent. Try again.': 'Sua planilha está inconsistente. Tente novamente.',
  'Failed to import the spreadsheet. Contact support.': 'Ocorreu uma falha ao importar a planilha. Entre em contato com o suporte.',
  'The selected file has the wrong extension.': 'O arquivo selecionado está com a extensão incorreta.',
  'Spreadsheet imported successfully.': 'Planilha importada com sucesso.',
  'There are e-mails already registered in the system.': 'Existem e-mails já cadastrados no sistema.',
  'Duplicate emails': 'E-mails duplicados',
  // ~/components/ImportProjetcs
  'add multiple projects': 'adicionar vários projetos',
  'Import Project Spreadsheet': 'Importar planilha de Projetos',
  'See below how to complete and import the spreadsheet to register projects': 'Veja abaixo como preencher e importar a planilha para cadastro de projetos',
  'See below the projects who will be registered in the system': 'Veja abaixo os projetos que serão cadastrados no sistema',
  'REGISTER PROJECTS': 'CADASTRAR PROJETOS',
};