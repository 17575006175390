import {createGlobalStyle,css,keyframes} from 'styled-components';
import {darken} from 'polished';

import 'react-toastify/dist/ReactToastify.css';

export const $ = {
  // COLORS
    colorA: '#C13663',
    colorB: '#EE5F6D',
    colorC: '#414C5D',
    colorD: '#505050',
    colorE: '#032E42',
    colorGray: '#B5B5B5',
    colorWhite: '#F2F3F5',
    white: '#ffffff',
    correct: '#59da81',
    error: '#EE5F5F',


    //TemaDark
      // colorA: '#414C5D',
      // colorB: '#414C5D',
      // colorC: '#ffffff',
      // colorD: '#ffffff',
      // colorWhite: '#505050',
      // white: '#3e3e3e',
  // TYPOGRAPHY
    font: 14,
    bold: 700,
    regular: 400,
  // LAYOUT
    container: 920,
    animation: '0.35s',
    animate: '0.6s',
  // MIXINS
    buttonStyle: ($,plus='',hover='')=>css`
      margin: 5px 0 0;
      padding: 0 25px;
      height: 48px;
      background: ${$.colorC};
      font-weight: bold;
      color: ${$.white};
      border: 0;
      border-radius: 5px;
      transition: background 0.2s;
      ${plus}
      &:hover {
        background: ${darken(0.1,$.colorC)};
        ${hover}
      }
    `,
    fontSize: ($,s=14)=>css`font-size: ${s/$.font}em;`,
    fontRelated: ($,s=14,t=14)=>css`font-size: ${s/t}em;`,
    gradient: $=>css`background: linear-gradient(-45deg,${$.colorA},${$.colorB});`,
    gradientProject: $=>css`background: linear-gradient(-45deg,${$.colorC},${$.colorE});`,
    colorText: ($,n=0.7)=>`rgba(80, 80, 80, ${n})`,
    //TemaDark
      // colorText: ($,n=0.7)=>`rgba(255, 255, 255, ${n})`
};

export const fadeIn = keyframes`
  from {
    filter: opacity(0);
  }
  to {
    filter: opacity(1);
  }
`;

export const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`;

export const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`;

export const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translate(10px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
`;

export const fadesAwayslideLeft = keyframes`
  from {
    opacity: 1;
    transform: translate(0px, 0);
  }
  to {
    opacity: 0;
    transform: translate(10px, 0);
  }
`;

export const slideRight = keyframes`
  from {
    opacity: 0;
    transform: translate(-10px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
`;

export const rotation = keyframes`
    from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
`;

export default createGlobalStyle`
  /* ===== IMPORT ===== */
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&display=swap');
    
  /* ===== BASE ===== */
    :root{
      --animate: 0.4s;
      --animateLow: 2s;
    }
    
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
    }
    *:focus {
      outline: 0;
    }

    html,body,#root {
      height: 100%;
    }

    body {
      -webkit-font-smoothing: antialiased;
      color: ${$.colorText($)};
    }
    body, input, button, textarea {
      font: ${$.font}px 'Open Sans', sans-serif;
    }
    a {
      text-decoration: none;
    }
    ul {
      list-style: none;
    }
    button {
      cursor: pointer;
    }
  
  /* ===== TEXT ===== */
    h1 {
      ${$.fontSize($,30)}
      color: ${$.colorC};
    }
    h2 {
      ${$.fontSize($,20)}
      color: ${$.colorC};
      margin: 20px 0;
    }
    h3 {
      ${$.fontSize($)}
      color: ${$.colorText($,0.5)};
      text-transform: uppercase;
    }
    hr.separator {
      border: none;
      border-bottom: 1px solid ${$.colorText($,0.1)};
      margin: 50px 0px;
    }

  

  /* ===== FORMULARY ===== */
    form {
      label {
        margin: 0 0 10px;
        font-weight: ${$.bold};
        color: ${$.colorC};
      }
      input {
        background: ${$.white};
        border: 1px solid ${$.colorText($,0.15)};
        border-radius: 5px;
        height: 48px;
        padding: 0 15px;
        color: ${$.colorText($)};
        margin: 0 0 10px;
        &::placeholder {
          color: ${$.colorText($,0.4)};
        }
      }
      textarea {
        width: 100%;
        resize: none;
        background: ${$.white};
        border: 1px solid ${$.colorText($,0.15)};
        border-radius: 5px;
        height: 96px;
        padding: 15px;
        color: ${$.colorText($)};
        margin: 0 0 10px;
        &::placeholder {
          color: ${$.colorText($,0.4)};
        }
      }
      span {
        color: ${$.error};
        align-self: flex-start;
        margin: 0 0 10px;
        ${$.fontSize($,12)}
      }
      button {
        ${$.buttonStyle($)}
      }
    }

  /* ===== BUTTONS ====== */
    button.whitter {
      ${$.buttonStyle($,css`
        color: ${$.colorC};
        background: ${$.white};
      `,css`
        background: ${darken(0.15,$.white)};
      `)}
    }
    button.icon {
      ${$.buttonStyle($,css`
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
      `)}
    }
    button.add {
      ${$.buttonStyle($,css`
        background: ${$.correct};
      `,css`
        background: ${darken(0.15,$.correct)};
      `)}
    }
    button.noHover {
      ${$.buttonStyle($,css`
        background: none;
        height: auto;
      `,css`
        background: none!important;
      `)}
    }
    button.delete {
      ${$.buttonStyle($,css`
        background: ${$.error};
      `,css`
        background: ${darken(0.15,$.error)};
      `)}
    }
    button.visibility {
      ${$.buttonStyle($,css`
        visibility: hidden;
      `)}
    }
    button.outline {
      ${$.buttonStyle($,css`
        background: none;
        color: ${$.colorC};
        border: 1px solid ${$.colorText($,0.2)};
        &.delete {
          ${$.fontSize($,11)}
          height: 32px;
          padding: 0 10px;
          text-transform: uppercase;
        }
      `,css`
        color: ${$.white};
        background: ${$.colorC};
      `)}
    }
    button.normal {
      ${$.buttonStyle($,css`
        border: 1px solid ${$.colorText($,0.2)};
      `)}
    }

  /* ===== LOADING ===== */
    p.loaderHolder {
      text-align: center;
      .toploader {
        max-width: 150px;
      }
    }
    

  /* ===== TOAST ===== */
    .Toastify__toast--success {
      background: ${darken(0.1,$.correct)};
    }
    .Toastify__toast-container{
      z-index: 10020;
    }

  /* ===== TINYMCE ====== */
    body > .tox + .tox,
    body > .tox + div .tox
    body > .tox + div + div .tox {
      z-index: 10010;
    }
    form .tox span {
      align-self: auto;
    }

  /* Animations  */
  .fadeIn {
    animation: ${fadeIn} var(--animate) linear 1;
  }
  .slideDown {
    animation: ${slideDown} var(--animate) linear 1;
  }
  .slideUp {
    animation: ${slideUp} var(--animate) linear 1;
  }
  .slideLeft {
    animation: ${slideLeft} var(--animate) linear 1;
  }
  .fadesAwayslideLeft{
    animation: ${fadesAwayslideLeft} var(--animate) linear 1;
  }
  .slideRight {
    animation: ${slideRight} var(--animate) linear 1;
  }
  .rotationAnimation {
    animation: ${rotation} var(--animateLow) ease-in-out infinite;
  }
  /* ===== TINYMCE COMMENTS ===== */
    .tox .tox-sidebar {
      .comments {
        border-left: 1px solid ${$.colorText($,0.3)};
        height: 100%;
        width: 200px;
        overflow: auto;
        padding-bottom: 42px;
        box-sizing: border-box;
        h1 {
          padding: 30px 30px 0 30px;
          font-weight: bold;
          color: ${$.colorB};
        }
        ul {
          font-size: 0.9em;
          color: ${$.colorText($)};
          li {
            padding: 30px;
            border-bottom: 1px solid ${$.colorText($,0.3)};
            strong {
              font-weight: bold;
              margin-top: 5px;
              display: block;
              &.users {
                span {
                  display: inline-block;
                  background-color: ${$.colorText($,0.08)};
                  color: ${$.colorText($,0.4)};
                  padding: 2.5px 7.5px;
                  margin: 2.5px;
                  text-transform: lowercase;
                  border-radius: 10000px;
                  ${$.fontSize($,10)}
                }
              }
            }
            button {
              cursor: pointer;
              padding: 0px 5px 2.5px 5px;
              /* width: 100%; */
              text-align: center;
              margin-top: 10px;
              background: ${$.colorText($,0.3)};
              border-radius: 5px;
              color: ${$.white};
              font-size: 0.88em;
            }
          }
        }
      }
    }
    /* tutorial */
    .reactour__helper--is-open >span[data-tour-elem=badge]{
        /* display: none; */
        border-radius: 50%;
        height: auto;
        width: 27.78px;
        padding-left: 0.600em;
        padding-right: 0.9000em;
        left: -0.600em;
    }
    .reactour__helper--is-open >div[data-tour-elem=controls]{
        justify-content: center;
    }
    /* MATHTYPE */
    .wrs_modal_dialogContainer.wrs_modal_desktop.wrs_stack {
      display: none;
    }
`;