import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Input,Scope} from '@rocketseat/unform';
import styled, {css} from 'styled-components';

import {$} from '~/styles/global';

import {FormRow,FormColumn} from '~/components/InternalForm';
import Fields from '~/pages/Units/editor/fields';

export default function Multiple({field,editingData}) {
  const config = useSelector(state=>state.config.value);
  const [multiple,setMultiple] = useState(false);
  const [data,setData] = useState([{}]);
  useEffect(()=>{
    async function GetInitState(){
      if(editingData){
        if(editingData[field.name]){
          setMultiple(editingData[field.name].length);
          setData(editingData[field.name]);
          // console.log('editingData[field.name]',editingData[field.name]);
        }
      }
    }
    GetInitState();
  },[editingData]);
  function handleAdd(){
    if(!multiple) setMultiple(true);
    const newData = [...data];
    newData.push({});
    setData(newData);
  }
  return(
    <Multipler>
      <MultipleList>
        <p>{(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.placeholder):(field.lang.placeholder[config.LANGUAGE])}</p>
        <MultipleItem key={0} field={field} editingData={data[0]} index={0} />
        {multiple&&(<>
          {data.map((thisData,index)=>(<>
            {index!==0&&(
              <MultipleItem key={index} field={field} editingData={thisData} index={index} />
            )}
          </>))}
        </>)}
        <button className="add" type="button" onClick={handleAdd}>+ {config.LANG['Add']} {(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.label):(field.lang.label[config.LANGUAGE])}{field.required&&'*'}</button>
      </MultipleList>
    </Multipler>
  );
}

function MultipleItem({field,editingData,index}) {
  const config = useSelector(state=>state.config.value);
  const [available,setAvailable] = useState(field.name);
  function handleDelete(){
    if(available===field.name){
      setAvailable('undefined');
    } else {
      setAvailable(field.name);
    }
  }
  return(
    <Holder Delete={available!==field.name}>
      <Scope path={`${field.name}[${index}]`}>
        <FormRow>
          {field.field.map((multipleField,key)=>(
            <FormColumn key={multipleField.name} Line={((field.field.length===1)||(field.field.length%2!==0&&key===field.field.length-1))||multipleField.full}>
              <Fields field={multipleField} editingData={editingData} multiple={true} />
            </FormColumn>
          ))}
        </FormRow>
        {index!==0?(
          <button className={available===field.name?'delete':'none'} type="button" onClick={handleDelete}>{available===field.name?`- ${config.LANG['Remove']}`:config.LANG['Recover']}</button>
        ):(
          <button className="visibility" type="button">- {config.LANG['Remove']}</button>
        )}
      </Scope>
      <Scope path={`${field.name}-shadow[${index}]`}>
        <Input type="hidden" name="available" value={available!==field.name} />
      </Scope>
    </Holder>
  );
}

const Multipler = styled.div`
`;

const MultipleList = styled.div` 
  background: ${$.white};
  padding: 10px 15px 15px 15px;
  border-radius: 5px;
  border: 1px solid ${$.colorText($,0.15)};
`;

const Holder = styled.div`
  border-radius: 5px;
  background: ${$.colorWhite};
  padding: 10px;
  margin: 5px 0;
  ${props=>props.Delete&&css`
    opacity: 0.5;
  `}

  display: flex;
  justify-content: center;
  align-items: start;
  > div {
    flex: 1;
    > div {
      padding-bottom: 0px;
    }
  }
  button {
    margin-left: 20px;
  }
`;