import React,{useCallback,useEffect,useState} from 'react';
import {useSelector} from 'react-redux';
import {Form} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import {DecodeData,DataEncoder} from '~/pages/Units/editor/interaction/functions';

import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import Fields from '~/pages/Units/editor/fields';

import close from '~/assets/icons/close.svg';

export default function EditToolTip({Open,Close,editor}) {
  const config = useSelector(state=>state.config.value);
  // STATE =====
  const [editingData,setEditingData] = useState(false);
  // EFFECT =====
  useEffect(()=>{
    if(Open&&editor) EditInteraction();
    function EditInteraction(){
      var element = editor.selection.getNode();
      element = CheckName(element);
      function CheckName(el){
        var name = el.nodeName.toLowerCase();
        if(name!=='span'&&el.getAttribute('class')!=='tooltip'){
          var pureText = document.createTextNode(el.textContent);
          var parentEl = el.parentNode;
          parentEl.replaceChild(pureText, el);
          el = CheckName(parentEl);
        }
        return el;
      }
      const text = element.getAttribute('data-text');
      const modal = element.getAttribute('data-modal');
      if(!text||!modal){
        toast.error('Erro ao tentar abrir o Tooltip.');
        return false;
      }
      const getValues = {text,modal};
      for (const key in getValues) {
        getValues[key] = DecodeData(getValues,key);
        if(key==='modal') getValues[key] = JSON.parse(getValues[key]);
      }
      setEditingData(getValues);
    }
    if(!Open) setEditingData(false);
  },[Open,editor,config]);
  // CALLBACK =====
  const handleEditToolTip = useCallback((data)=>{
    if(Open&&editor) EditToolTip();
    function EditToolTip(){
      var element = editor.selection.getNode();
      data = DataEncoder(data);
      element.setAttribute('data-text',data.text);
      element.setAttribute('data-modal',data.modal);
      setEditingData(false);
      Close();
    }
  },[Open,editor,Close]);
  // SCHEMAS =====
  const schema = Yup.object().shape({
    text: Yup.string().required(config.LANG['Required field']),
    modal: Yup.boolean(),
  });
  return (
    <PopUp isFront Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>Editar ToolTip</h1>
      {editingData&&(<>
        <Form id="form_send" initialData={editingData} schema={schema} onSubmit={handleEditToolTip}>
          <FormRow>
            <FormColumn Line>
              <Fields field={{label:'Texto',required:true,name:'text',placeholder:'Insira o texto.',type:'editor'}} editingData={editingData} /><br />
              <Fields field={{label:'Modal',name:'modal',type:'boolean'}} editingData={editingData} />      
            </FormColumn>
          </FormRow>
          <SubmitRow>
            <p>*{config.LANG['Required Fields']}</p>
            <div>
              <button type="submit">{config.LANG['Insert']}</button>
            </div>
          </SubmitRow>
        </Form>
      </>)}
    </PopUp>
  );
}