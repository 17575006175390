import React, {useState,useRef,useEffect} from 'react';
import {Input,useField} from '@rocketseat/unform';

import downArrow from '~/assets/icons/downArrow.svg';

import { Container } from './styles';

export default function Select(fieldValue) {
  const { defaultValue, registerField, error } = useField(fieldValue.name);
  const [value,setvalue] = useState(defaultValue&&defaultValue);

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.value'
      });
    }
  },[ref,registerField,fieldValue.name]);

  useEffect(()=>{
    setvalue(defaultValue&&defaultValue);
  },[defaultValue]);

  // async function handleChange(e){
  //   setvalue(e.target.value);
  // }

  // CLICK OUTSIDE
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // OPEN
  const [open,setOpen] = useState(false);
  const [selected,setSelected] = useState(fieldValue.options[0]);
  
  useEffect(()=>{
    var selectedFound = fieldValue.options.find(function(item,index){
      return item.value === value;
    });
    setSelected(selectedFound);
  },[value,fieldValue.options]);
  

  function handleChange(o){
    setvalue(o.value)
  }
  function handleOpen(){
    setOpen(!open);
  }
  return (<>
    <Container Open={open} ref={wrapperRef} >
      <div>
        <button type="button" onClick={handleOpen}>
          {/* {(selected)?selected?.text:fieldValue.options[0].text} */}
          {(selected)?selected?.text:'Selecionar'}
          <img src={downArrow} alt="Mais"/>
        </button>
        <ul>
          {fieldValue.options.map(o=><li key={o.value}><button type="button" onClick={()=>{handleChange(o);handleOpen();}}>{o.text}</button></li>)}
        </ul>
      </div>
    </Container>
    <div>
      <input type="hidden" data-value={value} ref={ref}/>
      {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={value} />)}
      { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
    </div>
  </>);
}
