import produce from 'immer';

const INITIAL_STATE = {
    announceConvertion: false
};

export default function tutorial(state=INITIAL_STATE,action){
  return produce(state,draft=>{
    switch (action.type){
        case '@convertion/CONVERTION_UPDATE': {
            draft.announceConvertion = action.payload
            break;
        }
        case '@convertion/CONVERTION_END': {
          draft.announceConvertion = action.payload
          break;
        }
      default:
    }
  });
}