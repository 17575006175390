import React, {useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import api from '~/services/api';
import {Convert} from '~/pages/Units/styles';
import {Block,Status} from '~/pages/Dashboard/styles';
import {FormRow,FormColumn} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import reload from '~/assets/icons/reload.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';
import { convertedUpdate } from 'store/modules/converter/actions';

export default function Converter({postId,callback,topLoader}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  const dispatch = useDispatch();

  // POPUP
  const [converting,setConverting] = useState(false);
  const [openConvert,setOpenConvert] = useState(false);
  const [percentage, setPercentage] = useState(false);
  // const [converted,setConverted] = useState(convertions)
  const [hasEdit,setHasEdit] = useState(false);
  const [convertions,setConvertions] = useState(0);


  useEffect(() => {
    api.get(`edits/units/${postId}`).then(response => {
      setHasEdit(response.data)
    });
    api.get('convertions/count').then(response => {
      setConvertions(response.data.convertions);
    });
  }, [postId]);

  async function GetConvertionNumber(){
    const response = await api.get('convertions/count');
    setConvertions(response.data.convertions);
  }

  async function handleOpenConvert(){
    const lastOpenConvert = openConvert;
    setOpenConvert(!openConvert);
    if(!lastOpenConvert) {
      setPercentage(false);
      try {
        console.time("compare");
        const response = await api.post(`convertions/units/${postId}/compare`);
        console.timeEnd("compare");
        setPercentage(response.data);
      } catch(err){
        console.timeEnd("compare");
        toast.error(config.LANG['Incorrect information. Try again.']);
        setConverting(false);
      }
    }
  }
  
  async function handleConvert(){
    setConverting(true);
    try {
      const responseT = await api.get(`rules/units/${postId}`);
      await api.post(`convertions/units/${postId}`,{
        template: responseT.data.path
      });

      handleOpenConvert();
      callback();
      GetConvertionNumber();
      toast.success(config.LANG['Unit successfully converted!']);
      setConverting(false);
      const announceConvertion = true;
      dispatch(convertedUpdate(announceConvertion));
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
      setConverting(false);
    }
  }
  return (<>
    <Block Big>
      {topLoader?(
        <>
          <h2>{config.LANG['Conversion']}:</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <h2>{config.LANG['Conversion']}:</h2>
          <Status Full={(profile&&!profile.role.level<=2)}>
            <div>
              <strong><span>{config.CONVERT_NUMBER-convertions}</span>/{config.CONVERT_NUMBER}</strong>
              <p>{config.LANG['Available conversions']}</p>
            </div>
            {(profile&&profile.role.level<=2&&hasEdit)&&(<>
              <div>
                <Convert onClick={handleOpenConvert}>
                  <img src={reload} alt={config.LANG['Conversions']} />
                  {config.LANG['Convert']}
                </Convert>
              </div>
            </>)}
          </Status>
        </>
      )}
    </Block>
    {(profile&&profile.role.level<=2)&&(<>
      <PopUp Tiny Open={openConvert} CallBack={handleOpenConvert}>
        <button type="button" onClick={handleOpenConvert}>
          <img src={close} alt={config.LANG['Close']} />
        </button>
        <h1>{config.LANG['Convert unit']}</h1>
        <br/><br/>
        <p>{config.LANG['Are you sure you want to convert this unit?']}</p><br/>
        {(!percentage)?(<>
          <br/><br/>
          <p align="center">{config.LANG['Calculating']}...</p>
        </>):(<>
          {(!converting)?(<>
            {percentage.number!=='none'?(<>
              <p>{config.LANG['The percentage of change identified since the last conversion is']}: <strong>{percentage.formated}</strong></p><br/>
              <p>{config.LANG['The maximum allowed for a free conversion is']}: <strong>25.00%</strong></p><br/>
              {(percentage.number<0.75)?(<>
                <p>{config.LANG['A conversion will be discounted from the amount provided for your plan when converting this unit.']}</p>
              </>):(<>
                <p>{config.LANG['Therefore, you will not be charged for a new conversion of your plan!']}</p>
              </>)}
              <br/><br/>
            </>):(<>
              <p>{config.LANG['A conversion will be discounted from the amount provided for your plan when converting this unit.']}</p>
              <br/><br/>
            </>)}
            <FormRow>
              <FormColumn>
                <button className="add" type="button" onClick={handleConvert}>{config.LANG['YES']}</button>
              </FormColumn>
              <FormColumn>
                <button className="delete" type="button" onClick={handleOpenConvert}>{config.LANG['NO']}</button>
              </FormColumn>
            </FormRow>
          </>):(<p align="center">{config.LANG['Converting']}...</p>)}
        </>)}

      </PopUp>
    </>)}
  </>);
}