import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
  > div {
    position: relative;
    border: 1px solid ${$.colorText($,0.14)};
    border-radius: 5px;
    button {
      width: 100%;
      background: none;
      color: ${$.colorText($)};
      font-weight: ${$.regular};
      border-radius: 0;
      text-align: left;
      margin: 0;
      background: ${$.white};
      &:hover {
        background: ${$.colorText($,0.15)};
      }
      p{
        flex: 1;
      }
    }
    > button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px 5px;
    }
    ul {
      position: absolute;
      display: ${props=>props.Open?'block':'none'};
      border: 1px solid ${$.colorText($,0.14)};
      border-top: 0;
      background: ${$.white};
      width: calc(100% + 2px);
      border-radius: 0px 0px 5px 5px;
      padding-top: 1px;
      margin-top: -5px;
      left: -1px;
      max-height: 250px;
      overflow: auto;

      button{
        display: flex;
        align-items: center;
      }

      li:last-child {
        button {
          border-radius: 0px 0px 5px 5px;
        }
      }
    }
  }
`;

export const AvatarImg = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border-radius: 50%;
`;


export const SelectContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const ContentSelect = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background: ${$.colorText($,0.1)};
  padding: 8px; 
  border-radius: 5px;
  color: ${$.colorText($)};
  font-weight: ${$.bold}; 

  p{
    margin-right: 8px;
  }

  button{
    background: transparent;
    color: ${$.colorText()};
    margin: 0;
    padding: 0;
    height: 15px;
    &:hover{
      background: transparent;
    }
  }
  
`;