import React, {useState,useEffect,useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Form,Input} from '@rocketseat/unform';
import {toast} from 'react-toastify';

import api from '~/services/api';

import { Container } from './styles';

import AutoRender from '~/pages/Units/editor/autorender';

export default function Share({match:{params:{id:postId}}}) {
  const config = useSelector(state=>state.config.value);
  const [preview,setPreview] = useState(false);
  const [post,setPost] = useState(false);
  const [open,setOpen] = useState(false);
  const [first,setFirst] = useState(true);
  const sharePassword = process.env.REACT_APP_SHARE_PASSWORD||`Validar@Materiais`;
  
  useEffect(()=>{
    async function GetInitState(){

      try {
        const responseE = await api.get(`edits/units/${postId}/share`);
        setPreview(responseE.data.preview_url);
        setPost(responseE.data.unit);
        setFirst(false);
      } catch(err){
        setFirst(true);
      }
    }
    GetInitState();
  },[postId]);
  
  async function handleSubmit({password}){
    if(password===sharePassword){
      setOpen(true);
    } else {
      toast.error(config.LANG['Incorrect password.']);
    }
  }

  const handleFirst = useCallback(()=>{
    setFirst(!first);
  },[]);
  return (<Container>
    {post?<AutoRender postId={postId} post={post} handleReload={handleFirst} />:<></>}
    {(!open)?(<Form className="cover" onSubmit={handleSubmit}>
      <h1>{config.LANG['Authentication']}</h1>
      <Input name="password" placeholder={config.LANG['Password']} autoComplete="off" />
      <button className="delete" type="submit">{config.LANG['Check']}</button>
    </Form>):(<>
      {
        first
          ?(<p>{config.LANG['Resource not started']}</p>)
          :(<iframe title="preview-share" src={preview}></iframe>)
      }
    </>)}
  </Container>);
}