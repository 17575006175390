import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import rightArrow from '~/assets/icons/rightArrow.svg';

import * as S from './styles';

const Accordion = ({title, children}) => {
  const config = useSelector(state=>state.config.value);
  const [active, setActive] = useState(false);

  const toogleActive = () => {setActive(!active)};

  return (
    <S.AccordionSection>
      <S.AccordionItem onClick={toogleActive}>
        <S.AccordionTitle>{title}</S.AccordionTitle>
        <span className={active ? 'accordion-icon rotate' : 'accordion-icon'}>
          <img src={rightArrow} alt={config.LANG['Forward']}/>
        </span>
      </S.AccordionItem>

      <S.AccordionContent active={active}>
        {children}
      </S.AccordionContent>
    </S.AccordionSection>
  );
};

export default Accordion;
