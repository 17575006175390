import React from 'react';
import {useSelector} from 'react-redux';
import {Form} from '@rocketseat/unform';
import {FiHelpCircle} from 'react-icons/fi'

import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';

import Fields from '~/pages/Units/editor/fields';

import {ContentTitle,IconHelp,Title} from './styles';

export default function FormInteraction({selectedInteraction,schema,editingData,CallBack}) {
  const config = useSelector(state=>state.config.value);
  return (
    <>
      <div>
        {selectedInteraction.info?(
          <>
            <ContentTitle>
              <Title>{(config.LANGUAGE==='pt_br'||selectedInteraction.lang===false)?(selectedInteraction.name):((selectedInteraction.lang)&&selectedInteraction.lang[config.LANGUAGE])}</Title>
              <IconHelp title="Como utilizar este recurso?" target="_blank" rel="noopener noreferrer" href={selectedInteraction.info}>
                <FiHelpCircle size={24}/>
              </IconHelp>
            </ContentTitle>
          </>
        ):(
          <Title>{(config.LANGUAGE==='pt_br'||selectedInteraction.lang===false)?(selectedInteraction.name):((selectedInteraction.lang)&&selectedInteraction.lang[config.LANGUAGE])}</Title>
        )}
        {selectedInteraction.intro&&(<>
          <br /><br />
          <p>{selectedInteraction.intro}</p>
        </>)}
      </div>
      <h2> </h2>
      <Form id="form_send" schema={schema} initialData={editingData} onSubmit={CallBack}>
        <FormRow>
          {selectedInteraction.field?.map((field,key)=>
            <FormColumn key={field.name} Line={(
                (selectedInteraction.field.length===1)
                ||(selectedInteraction.field.length%2!==0&&key===selectedInteraction.field.length-1)
                ||field.type==='file'
              )||field.full}>
              <Fields field={field} editingData={editingData} />
            </FormColumn>
          )}
        </FormRow>

        <SubmitRow>
          <p>*{config.LANG['Required field']}</p>
          <div>
            <button type="submit">{config.LANG['Insert']}</button>
          </div>
        </SubmitRow>
      </Form>
    </>
  );
}