import api from '~/services/api';

export async function Sanitize({editor,res,postId,sendComment,noSaving=false}){
  var element = editor.getBody();
  var elements = element.querySelectorAll(':scope > *');
  Array.prototype.forEach.call(elements, function(el, i){
    if(el.getAttribute('data-interaction')==='true'){
      el.removeAttribute('style');
      var problems = el.querySelectorAll('span, em, strong, b, i');
      Array.prototype.forEach.call(problems, function(problem, i){
        var pureText = document.createTextNode(problem.textContent);
        problem.parentNode.replaceChild(pureText, problem);
      });
      var divs = el.querySelectorAll('div');
      Array.prototype.forEach.call(divs, function(div, i){
        var pureText = div.textContent;
        if(!pureText.includes('{')){
          div.parentNode.removeChild(div);
        }
      });
    } else if(el.classList.contains('E-TOPICO')){
      var pureText = el.textContent;
      if(!noSaving) el.innerHTML = pureText;
    }
  });
  // COMMENTS =====
  if(sendComment){
    var commentsUsers = element.querySelectorAll('[data-users]');
    const selectedUsers = {};
    Array.prototype.forEach.call(commentsUsers, function(el, i){
      var comments = el.getAttribute('data-comments');
      comments = (comments)?JSON.parse(comments):comments;
      if(comments){
        for (const key in comments) {
          if(comments[key].users){
            for (const key2 in comments[key].users) {
              if(!comments[key].users[key2].sent){
                selectedUsers[comments[key].users[key2].value]=true;
                comments[key].users[key2].sent = true;
              }
            }
          }
        }
      }
      el.setAttribute('data-comments',JSON.stringify(comments));
      el.removeAttribute('data-users');
    });
    const sendUsers = [];
    for (const key in selectedUsers) {
      sendUsers.push({user_id:Number(key)});
    }
    if(sendUsers.length>0){
      await api.post(`comments/units/${postId}`,{
        comments: sendUsers,
      });
    }
  }
  res(editor.getContent());
}