export function updateTutorial(first_access){
    return {
      type: '@tutor/UPDATE_TUTORIAL',
      payload: {first_access}
    };
}
  
export function updateTutorialEnd(first_access) {
  return {
    type: '@tutor/UPDATE_TUTORIAL_END',
    payload: {first_access}
  }
}