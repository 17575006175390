import React,{useCallback,useEffect,useState} from 'react';
import {useSelector} from 'react-redux';
import {Form} from '@rocketseat/unform';
import * as Yup from 'yup';

import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import Fields from '~/pages/Units/editor/fields';

import close from '~/assets/icons/close.svg';
import api from '~/services/api';


export default function Comments({Open,Close,CallBack,editor,postId}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  const [listUsers, setListUsers] = useState([]);

  const handleInsertComment = useCallback((data)=>{
    if(Open&&editor) InsertComment();
    function InsertComment(){
      var element = editor.selection.getNode();
      var comments = element.getAttribute('data-comments');
      if(!comments){
        comments = [];
      } else {
        comments = JSON.parse(comments);
      }
      const newComment = {
        comment: data.comment,
        name: profile.name,
      };
      if(data.users){
        element.setAttribute('data-users','true');
        newComment.users = JSON.parse(data.users);
      }
      comments.push(newComment);
      element.setAttribute('data-comments', JSON.stringify(comments));
      // https://www.tiny.cloud/docs/api/tinymce.dom/tinymce.dom.selection/#select
      editor.selection.select(element.parentNode);
      setTimeout(() => {
        editor.selection.select(element);
        Close();
        CallBack(editor);
      }, 100);
    }
  },[Open,editor,CallBack,Close,profile]);
  // SCHEMAS =====

  useEffect(() => {
    api.get(`users/unit/${postId}`).then(response => {
      setListUsers(response.data);
    });
  },[postId]);

  const schemaComment = Yup.object().shape({
    comment: Yup.string().required(config.LANG['Required field']),
    users: Yup.string(),
  });
  const initialData = {};
  return (
    <PopUp Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>{config.LANG['Add Comment']}</h1>
      {/* <h2></h2> */}
      <Form id="form_send" initialData={initialData} schema={schemaComment} onSubmit={handleInsertComment}>
        <FormRow>
          <FormColumn Line>
            <Fields field={{label:config.LANG['Comment'],required:true,name:'comment',placeholder:config.LANG['Write your comment here.'],type:'textarea'}} />      
            {(!listUsers.message)&&<Fields field={{
              type: 'select-category',
              name: 'users',
              label: config.LANG['SelectUsersTitle'],
              options: listUsers.map(item => (
                {
                  text: item.name,
                  value: item.id,
                  avatar_url: item.avatar_url,
                  jobs: item.jobs,
              }))
            }}/>}
          </FormColumn>
        </FormRow>
        <SubmitRow>
          <p>*{config.LANG['Required Fields']}</p>
          <div>
            <button type="submit">{config.LANG['Insert']}</button>
          </div>
        </SubmitRow>
      </Form>
    </PopUp>
  );
}