import { Form, Input } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import close from '~/assets/icons/close.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import search from '~/assets/icons/search.svg';
import TopLoader from '~/assets/loading.gif';
import { Title } from '~/components/Container';
import { FormColumn, FormRow } from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import { Block, Item, List } from '~/pages/Dashboard/styles';
import api from '~/services/api';


export default function Rules({post,postId}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  // RULE
  const [rule,setRule] = useState(false);
  const [templateId,setTemplateId] = useState(false);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  useEffect(()=>{
    GetInitState();
  }, []);
  
  async function GetParent(string, id) {

    if (string === 'areas') {
      const response = await api.get(`areas/${id}`);
      const parentId = response.data.project.id;
      try {
        const responseT = await api.get(`rules/single/projects/${parentId}`);
        setTemplateId(responseT.data.template.id);
      } catch(err){}
    } else if (string === 'subjects') {

      const response = await api.get(`subjects/${id}`);
      const parentId = response.data.area.id;
      try {

        const responseT = await api.get(`rules/single/areas/${parentId}`);
        setTemplateId(responseT.data.template.id);
      } catch(err){
        GetParent('areas',parentId);
      }
    } else if (string === 'units') {

      const response = await api.get(`units/${id}`);
      const parentId = response.data.unit.subject.id;
      try {
        const responseT = await api.get(`rules/single/subjects/${parentId}`);
        setTemplateId(responseT.data.template.id);
      } catch (err) {

        GetParent('subjects',parentId);
      }
    }
  }
  async function GetInitState(){
    try {
      const responseT = await api.get(`rules/single/${post}/${postId}`);
      setTemplateId(responseT.data.template.id);
      setRule(responseT.data.id);
    } catch(err){
      GetParent(post,postId);
    }

    setUpdate(!update);
  }
  // PAGES
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState(null);
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  useEffect(() => {
    async function getPosts(){
      const response = await api.get(`templates`,{
        params:{
          perPage: 8,
          page:page,
          search: searched,
        }
      });
      setPosts(response.data);
      setTopLoader(false);
    }
    getPosts();
  },[update,page,searched]);
 
  // UPDATE
  const [openUpdate,setOpenUpdate] = useState(false);
  function handleOpenUpdate(){
    setOpenUpdate(!openUpdate);
  }
  const [selectUpdate,setSelectUpdate] = useState(false);
  function handleSelectUpdate(e){
    const {value} = e.target;
    setSelectUpdate(value);
    handleOpenUpdate();
  }

  async function handleUpdate(){
    // console.log(selectUpdate);
    if(!rule){
      await api.post(`rules/single/${post}/${postId}`,{
        template_id: selectUpdate
      });
    } else {
      await api.put(`rules/${rule}`,{
        template_id: selectUpdate
      });
    }
    GetInitState();
    handleOpenUpdate();
  }
  return (<>
    {(profile&&profile.role.level<=1)&&(<>
      <hr className="separator" />
      <Title>{config.LANG['Customization']}</Title><br />
      <Block Full Lefter>
        {topLoader?(
          <>
            <h2>{config.LANG['Themes']}</h2>
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          </>
        ):(
          <>
            <div>
              <h2>{config.LANG['Themes']} ({posts.paged.totalCount})</h2>
              <Form onSubmit={handleSearch}>
                <Input name="search" placeholder={`${config.LANG['Search']}…`} />
                <button type="submit">
                  <img src={search} alt={config.LANG['Search']} />
                </button>
              </Form>
            </div>
              {posts.list[0] ? (
                <List Squared>
                  {posts.list.map(post=>(
                    <Item key={post.id}>
                      <button type="button" className={templateId?post.id===templateId&&'Selected':post.default&&'Selected'} onClick={templateId?post.id!==templateId&&handleSelectUpdate:!post.default&&handleSelectUpdate} value={post.id} data-text={config.LANG['Selected']}>{post.name}</button>
                    </Item>
                  ))}
                </List>
              ):(
                <p>{config.LANG['Nothing found']}</p>
              )}
            {posts.paged.pages>1&&(
              <nav>
                <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                  <img src={leftArrow} alt={config.LANG['Backward']} />
                </button>
                <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                  <img src={rightArrow} alt={config.LANG['Forward']} />
                </button>
              </nav>
            )}
          </>
        )}
      </Block>
      
      <PopUp Tiny Open={openUpdate} CallBack={handleOpenUpdate}>
        <button type="button" onClick={handleOpenUpdate}>
          <img src={close} alt={config.LANG['Close']} />
        </button>
        <h1>{config.LANG['Update Theme']}</h1>
        <br/><br/>
        <p>{config.LANG['Are you sure you want to choose this theme?']}</p><br/>
        <br/><br/>
        <FormRow>
          <FormColumn>
            <button className="add" type="button" onClick={handleUpdate}>{config.LANG['YES']}</button>
          </FormColumn>
          <FormColumn>
            <button className="delete" type="button" onClick={handleOpenUpdate}>{config.LANG['NO']}</button>
          </FormColumn>
        </FormRow>
      </PopUp>
    </>)}
  </>);
}
