import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SocketHandleUser from '~/components/Init/components/sockethandleuser';
import ZenDesk from '~/components/ZenDesk';
import lang from '~/lang';
import api from '~/services/api';
import history from '~/services/history';
import { updateConfig } from '~/store/modules/config/actions';
import { Error, Free } from './styles';


export default function Init({path,children}) {
  const config = useSelector(state => state.config.value);
  const profile = useSelector(state=>state.user.profile);
  const dispatch = useDispatch();
  const socket = useSelector(state => state.socket.socket);
  //estado para msg de free
  const [free, setFree] = useState(false);
  const [daysToEnd, setDaysToEnd] = useState(''); 
  // CONFIG ============================================================
  useEffect(()=>{
    async function GetConfig(){
      const response = await api.get('variables');   
      // if (profile !== null) {
        if (profile?.role.level === 5) {
          //rota
          //if 
          //desloga
          api.get('/trial/expires').then(response => {
            setDaysToEnd(response.data)
          })
          setFree(true);
        }  
      // }
      const variables = response.data;
      if(variables.COMPANY_LOGO) variables.COMPANY_LOGO = variables.COMPANY_LOGO_OBJECT.url;
      variables.LANG = lang[variables.LANGUAGE];
      dispatch(updateConfig(variables));
    }
    GetConfig();
  },[profile]);

  // UPDATE VERSION ============================================================
  const [wrongVersion,setWrongVersion] = useState(false);
  const [updating,setUpdating] = useState(false);
  useEffect(()=>{
    var version_frontend = '3.60';
    if(socket){
      socket.on('check_version',data=>{
        setWrongVersion(version_frontend!==data);
      });
      socket.on('system_updating',()=>{
        setUpdating(true);
        WarningMessage('The system will enter maintenance mode in minutes.');
        WarningMessage('Save your work so you will not lose your progress.');
      });
      socket.on('system_updated',()=>{
        setUpdating(false);
        WarningMessage('The system has been successfully updated!');
      });
      socket.on('kick_user', () => {
        setFree(false);
      })
    }
  },[socket]);

  function Warning(string,configer){
    toast.info(configer.LANG[string],{
      position: "top-right",
      autoClose: false,
      draggable: false,
    });
  }
  const WarningMessage = useCallback((string) => Warning(string,config), [config]);

  // LOCATION ============================================================
  const urlData = useMemo(()=>history.location.pathname.split('/').filter((item,index)=>item!==''),[path,history]);
  useEffect(()=>{
    if(socket){
      socket.on('refresh_location',data=>{
        socket.emit('location',{urlData});
      });
    }
  },[socket]);
  useEffect(()=>{
    if(socket) socket.emit('location',{urlData});
  },[socket,urlData]);

  // ADMIN MESSAGE ============================================================
  useEffect(()=>{
    if(socket){
      socket.on('admin_message',({message})=>{
        toast.info(`${message}`,{
          position: "top-right",
          autoClose: false,
          draggable: false,
        });
      });
    }
  },[socket]);
  return (
    <>
      {wrongVersion&&(<>
        <Error>
          <p><strong>{config.LANG['Incorrect Version']} - </strong> {config.LANG['You are working on an outdated version.']}<br/> {config.LANG['Save your work and']} <a href=".">{config.LANG['Click here to update']}</a></p>
        </Error>
      </>)}
      {free && (
        <>
          <Free>
            <p>
              <strong>Período de teste - </strong> Seu acesso de teste ira expirar em {daysToEnd.daysUntilExpiration} dias 😲 <br />
              Entre em contato com  <a href="mailto:contato@realize.pro.br">contato@realize.pro.br</a>  para adquirir um plano
              {/* <a href=".">{config.LANG['Click here to update']}</a> */}
            </p>
          </Free>
        </>
      )}
      {updating&&(<>
        <Error>
          <p><strong>{config.LANG['Attention']} - </strong> {config.LANG['The system will enter maintenance mode in minutes.']}<br/> {config.LANG['Save your work so you will not lose your progress.']}</p>
        </Error>
      </>)}
      <SocketHandleUser />
      {children}
      <ZenDesk />
    </>
  );
}
