import React from 'react';
import {useSelector} from 'react-redux';


import {Block,StatusBig} from '../styles';

import bigU from '~/assets/icons/bigU.svg';
import TopLoader from '~/assets/loading.gif';

export default function Units({topLoader,users}) {
  const config = useSelector(state=>state.config.value);

  return (
    <Block Middle>
      {topLoader?(
        <>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <StatusBig>
            <div>
              <img src={bigU} alt="" />
              <div>
                <strong><span>{config.USER_NUMBER-users}</span>/{config.USER_NUMBER}</strong>
                <p>{config.LANG['Available users']}</p>
              </div>
            </div>
          </StatusBig>
        </>
      )}
    </Block>
  );
}
