import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import { LinksContainer } from './style';

import {signInRequest} from '~/store/modules/auth/actions';

export default function SignIn() {
  const config = useSelector(state=>state.config.value);
  const loading = useSelector(state=>state.auth.loading);
  const dispatch = useDispatch();
  function handleSubmit({email,password}){
    dispatch(signInRequest(email,password,config));
  }
  // SCHEMAS =====
  const schema = Yup.object().shape({
    email: Yup.string().email(config.LANG['Enter a valid email']).required(config.LANG['Email is required']),
    password: Yup.string().required(config.LANG['Password is required'])
  });
  return (
    <>
      <h1>{config.LANG['Welcome']}</h1>
      <p>{config.LANG['Login to access']}</p>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="email" type="email" placeholder={config.LANG['Your email']} autoComplete="off" />
        <Input name="password" type="password" placeholder={config.LANG['Your secret password']} autoComplete="off" />
        <button type="submit">{loading ? `${config.LANG['Loading']}...` : config.LANG['Login']}</button>
        {config.FREE_TRIAL === true ?
          <LinksContainer>
            <Link to="/trial">{config.LANG['Free trial']}</Link>
            <Link to="/password">{config.LANG['Forgot your password?']}</Link>
          </LinksContainer>
          :
          <Link to="/password">{config.LANG['Forgot your password?']}</Link>

        }
        
      </Form>

    </>
  );
}
