import React, {useState,useEffect,useMemo,useCallback} from 'react';
import {useSelector} from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import {toast} from 'react-toastify';

import history from '~/services/history';

import api from '~/services/api';

import Async from '~/services/Async';

import {Container,Canvas,Header,Splitter,Preview,Loading} from '~/components/Container';
import Device from '~/components/Device';
import {LoadRevision} from '~/pages/Units/revisor/styles';

import Multiple from '~/pages/Units/editor/multiple';
import Reload from '~/pages/Units/editor/reload';

import EditError from '~/pages/Units/revisor/popup/editerror';
import DeleteError from '~/pages/Units/revisor/popup/deleteerrors';

import logoWhite from '~/assets/logoWhite.svg';

import Robot from '~/assets/robot.svg';

import State from '~/pages/Units/editor/state';
import Save from '~/pages/Units/editor/save';
import Back from '~/pages/Units/editor/back';
import {
  initGenerator,
  style,
  plugins,
  menuGenerator,
} from '~/pages/Units/revisor/config';
import {Sanitize} from '~/pages/Units/revisor/sanitize';
import {
  DeconstructInteraction,
  RemoveError,
  ClearErrorInteractionName,
} from '~/pages/Units/revisor/functions';
import {
  languageToolDataGenerator,
  LanguageTool,
} from '~/pages/Units/revisor/languagetool';

export default function UnitRevisor({match:{params:{id:postId}}}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  // START =====
  const [LoadRevise,setLoadRevise] = useState(true);
  const [revision,setRevision] = useState(false);
  const [iframe,setIframe] = useState(false);
  const [preview,setPreview] = useState(false);
  const [loaded,setLoaded] = useState(false);
  const [post,setPost] = useState({});
  const [stage,setStage] = useState(null);
  const [interactions,setInteractions] = useState([]);
  const [template,setTemplate] = useState(false);
  useEffect(()=>{
    GetInitState();
    async function GetInitState(){
      setLoaded(false);
      const postPromise = api.get(`units/${postId}`);
      const templatePromise = api.get(`rules/units/${postId}`);
      const jobPromise = new Promise(async(resolve,reject) => {
        try {
          await api.get(`jobs/units/${postId}`);
          resolve(true);
        } catch(err){
          resolve(false);
        }
      });
      const editPromise = new Promise(async(resolve,reject) => {
        try {
          const response = await api.get(`edits/units/${postId}`);
          resolve(response);
        } catch(err){
          resolve(false);
        }
      });
      Promise.all([
        postPromise,
        templatePromise,
        jobPromise,
        editPromise,
      ]).then(async(values) => {
        const [
          {data:postData},
          {data:templateData},
          jobData,
          editData,
        ] = values;
        setPost(postData.unit);
        setTemplate(templateData);
        setJobOk(jobData);
        if(editData!==false){
          setContent(editData.data.html);
          setIframe(true);
          setPreview(editData.data.preview_url);
        } else {
          toast.error(config.LANG['You must have some saved content to enter spell check mode.']);
          history.push(`/units/${postId}/create`);
        }
        const {path} = templateData;
        const response = await api.get(`interactions/`,{params:{template:path,unit_id:postId}});
        setInteractions(response.data);
        setLoaded(true);
      });
    }
  },[postId,config]);
  // LANGUAGETOOL =====
  const languageToolData = useMemo(()=>languageToolDataGenerator(config),[config]);
  // REVISION =====
  const StartRevision = useCallback((editor)=>{
    Revise();
    async function Revise(){
      await Async(res=>LanguageTool(editor,res,languageToolData));
      await Async(res=>ClearErrorInteractionName(editor,res));
      setLoadRevise(false);
    }
  },[languageToolData]);
  const NewRevision = useCallback((editor)=>{
    Revise();
    async function Revise(){
      setLoadRevise(true);
      await Async(res=>RemoveError(editor,res));
      StartRevision(editor);
    }
  },[StartRevision]);
  const handleRecoverRevision = useCallback(async(editor)=>{
    setLoadRevise(true);
    await Async(res=>DeconstructInteraction(config,editor,interactions,res));
    StartRevision(editor);
  },[interactions,config,StartRevision]);
  // CONFIG =====
  const [content,setContent] = useState(``);
  const init = useMemo(()=>initGenerator(config),[config]);
  const menu = useMemo(()=>menuGenerator(config,profile),[config,profile]);
  const setup = {
    setup: (editor) => {
      setStage(editor);
      editor.ui.registry.addMenuItem('editor', {
        icon: 'edit-block', // spell-check
        text: config.LANG['Editor'],
        onAction: function () {
          history.push(`/units/${postId}/create`);
        }
      });
      editor.ui.registry.addMenuItem('revisor', {
        icon: 'spell-check',
        text: config.LANG['Spelling'],
        onAction: function () {
          history.push(`/units/${postId}/revise`);
        }
      });
      // editor.ui.registry.addMenuItem('compare', {
      //   icon: 'settings',
      //   text: 'Comparação',
      //   onAction: function () {
      //     history.push(`/units/${postId}/compare`);
      //   }
      // });
      editor.ui.registry.addMenuItem('reload-revision', {
        icon: 'reload',
        text: config.LANG['Search Errors'],
        onAction: function () {
          NewRevision(editor);
        }
      });
      editor.ui.registry.addButton('reload-revision', {
        icon: 'reload', // spell-check
        text: config.LANG['Search Errors'],
        onAction: function () {
          NewRevision(editor);
        }
      });
      editor.ui.registry.addButton('status-revision', {
        icon: 'spell-check',
        text: config.LANG['Smart Reviewer'],
        onAction: function () {},
        onSetup: function (buttonApi) {
          buttonApi.setDisabled(true);
        }
      });
      // ERROR DEAL
      editor.ui.registry.addButton('edit-error', {
        icon: 'checkmark',
        text: config.LANG['Correct'],
        onAction: function () {
          handleOpenEdit();
        }
      });
      editor.ui.registry.addButton('delete-error', {
        icon: 'close',
        onAction: function () {
          handleOpenDelete();
        }
      });
      editor.ui.registry.addContextToolbar('edit-error-listener', {
        predicate: function (node) {
          return node.nodeName.toLowerCase() === 'error';
        },
        items: 'edit-error | delete-error',
        position: 'node',
        scope: 'node'
      });
      editor.on(`keydown`, async function(args){
        args.preventDefault();
      });
    }, // https://www.tiny.cloud/docs/advanced/events/
    init_instance_callback : function(editor) {
      // console.log("Editor: " + editor.id + " is now initialized.");
      // console.log('editor.ui.registry.getAll(',editor.ui.registry.getAll());
      // for (var key in editor.ui.registry.getAll().buttons) {
      //   var button = editor.ui.registry.getAll().buttons[key];
      //   console.log(button);
      // }
    },
  };
  // UPDATE CONTENT ON EDITOR =====
  const handleEditorChange = useCallback(async(editorValue,editor)=>{
    setContent(editorValue);
    if(!revision) {
      setRevision(true);
      await Async(res=>DeconstructInteraction(config,editor,interactions,res));
      StartRevision(editor);
    }
  },[revision,interactions,config,StartRevision]);
  // JOBS =====
  const [jobOk,setJobOk] = useState(false);
  const openJob = useMemo(()=>{
    if(config.HIDE_JOB){
      if(profile&&profile.role.level<=3){} else {
        if(!jobOk) return false;
      }
    }
    return true;
  },[config,profile,jobOk]);
  const callbackJob = useCallback(async()=>{
    try {
      await api.get(`jobs/units/${postId}`);
      setJobOk(true);
    } catch(err){
      setJobOk(false);
      if(config.HIDE_JOB){
        if(profile&&profile.role.level<=3){} else {
          history.push(`/units/${postId}/view`);
        }
      }
    }
  },[postId,config,profile]);
  // SAVE AND RELOAD =====
  const [saveStatus,setSaveStatus] = useState(true);
  const handleReload = useCallback(async()=>{
    const backupPreview = `${preview}`;
    setPreview('');
    if(preview===false){
      const response = await api.get(`edits/units/${postId}`);
      setPreview(response.data.preview_url);
    } else {
      setTimeout(() => {
        setPreview(backupPreview);
      }, 100);
    }
  },[postId,preview]);
  // EDIT ERROR =====
  const [openEdit,setOpenEdit] = useState(false);
  const handleOpenEdit = useCallback(()=>setOpenEdit(!openEdit),[openEdit]);
  // DELETE ERROR =====
  const [openDelete,setOpenDelete] = useState(false);
  const handleOpenDelete = useCallback(()=>setOpenDelete(!openDelete),[openDelete]);
  // NOT SAVED =====
  const [notSaved,setNotSaved] = useState(false);
  // DEVICE =====
  const [device,setDevice] = useState('0.5');
  useEffect(()=>{
    const viewEditorSize = localStorage.getItem('@RealizeSystem-viewEditorSize');
    if(viewEditorSize) setDevice(viewEditorSize);
  },[postId]);
  const handleDevice = useCallback((data)=>{
    setDevice(data);
    localStorage.setItem('@RealizeSystem-viewEditorSize',data);
  }, []);
  
  return (
    <>
    <Loading fade={loaded}>
      <img src={logoWhite} alt="Realize"/>
      <h1>{config.LANG['Loading']}...</h1>
    </Loading>
    {loaded&&(
      <>
        <Canvas saving={!saveStatus?true:false}>
          <Header>
            <div className="middleWord">
              <div>
                <Back postId={postId} notSaved={notSaved} />
                <Save 
                  postId={postId}
                  editor={stage}
                  template={template}
                  content={content}
                  Sanitize={Sanitize}

                  saveStatus={saveStatus} setSaveStatus={setSaveStatus}
                  LoadRevise={LoadRevise} handleRecoverRevision={handleRecoverRevision}

                  setNotSaved={setNotSaved}

                  openJob={openJob}
                  setIframe={setIframe}
                  handleReload={handleReload}
                />
              </div>
              <State postId={postId} openJob={openJob} callbackJob={callbackJob} />
            </div>
            <div className="middleWord">
              <Device device={device} callback={handleDevice} />
              <Multiple postId={postId} post={post} handleReload={handleReload} />
              <Reload iframe={iframe} handleReload={handleReload} />
            </div>
          </Header>
          <Splitter>
            <LoadRevision Loading={!LoadRevise}>
              <p>
                <img className="toploader" src={Robot} alt=""/><br/>
                <strong>{config.LANG['Smart Reviewer']}</strong><br/>
                {config.LANG['Searching for Errors']}
              </p>
            </LoadRevision>
            <Container device={device} isEditor={true} className={!post.available&&`unavailable`}>
              <Editor
                initialValue={content}
                init={{
                  ...init,
                  ...style,
                  ...plugins,
                  ...menu,
                  ...setup
                }}
                onEditorChange={handleEditorChange}
              />
            </Container>
            <Preview device={device}>
              {iframe?(
                <iframe title="preview-revisor" src={preview}></iframe>
              ):(
                <div>{config.LANG['Save to view your work']}.</div>
              )}
            </Preview>
          </Splitter>
        </Canvas>
        <EditError Open={openEdit} Close={handleOpenEdit} editor={stage} />
        <DeleteError Open={openDelete} Close={handleOpenDelete} editor={stage} />
      </>
    )}
    </>
  );
}