import React, {useState,useEffect,useMemo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import api from '~/services/api';
import {CheckboxSection} from './styles';
import {Container,Title,Subtitle} from '~/components/Container';
import {ListHeader,ListFilter,ListTable,ListPage,ListNone,ItemList} from '~/components/InternalList';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import add from '~/assets/icons/add.svg';
import search from '~/assets/icons/search.svg';
import grid from '~/assets/icons/grid.svg';
import list from '~/assets/icons/list.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

import More from '~/pages/Units/index/more';
import Delegates from '~/pages/Units/index/delegates';

import ObjectSize from '~/util/ObjectSize';

export default function Units({match:{params:{id:parentId}}}) {
  const [idUnitDuplicate,setIdUnitDuplicate] = useState(false);

  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  // INIT
  const url = `units/subjects/${parentId}`;
  const url_action = `units`;
  const [topLoader,setTopLoader] = useState(true);
  const [parent,setParent] = useState({});
  const [status,setStatus] = useState([{}]);

  useEffect(()=>{
    api.get(`subjects/${parentId}`).then(response => {
      setParent(response.data);
    })
    //verificar se precisa de logica para pegar pelo projeto
    api.get(`procedures/status`).then(response => {
      setStatus(response.data);
    })
    
  },[parentId]);

  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState('');
  const [searchFinal,setSearchedFinal] = useState(null);
  async function handleSearch({search}){
    setSearchedFinal(search);
    setSearched(search);
    handlePage(1);
  }
  function handleSearchChanges(e){
    setSearchedFinal(e.target.value);
  }
  // FILTERS
  const filterStart = [
    { href: 'date', url: `${url}`, order: 'id', ordernate: 'DESC', text: config.LANG['DATE'], class: 'selected'},
    { href: 'asc', url: `${url}`, order: 'name', ordernate: 'ASC', text: 'A—Z', class: null},
    { href: 'desc', url: `${url}`, order: 'name', ordernate: 'DESC', text: 'Z—A', class: null},
    { href: 'trash', url: `${url}/trash`, order: 'id', ordernate: 'DESC', text: config.LANG['TRASH'], class: null}  
  ];
  useEffect(()=>{
    setFilters(filterStart);
  },[config]);

  const [filters,setFilters] = useState(filterStart);
  async function handleFilter(e,href){
    e&&e.preventDefault();
    const newFilters = filters.map(filter=>{
      if(filter.href===href){
        filter.class='selected';
      } else {
        filter.class=null;
      }
      return filter;
    });
    setFilters(newFilters);
    handleSearch({search:''});
  }
  // VIEW
  useEffect(()=>{
    async function GetView(){
      var viewFilter = localStorage.getItem('viewFilter');
      if(viewFilter) handleView({preventDefault:()=>true},viewFilter);
    }
    GetView();
  },[]);
  const [views,setViews] = useState([
    {'href':'grid','src':grid,'text':config.LANG['Block'],'class':null},
    {'href':'list','src':list,'text':config.LANG['List'],'class':'choosed'}
  ]);
  const [isBlock,setIsBlock] = useState(false);
  function handleView(e,href){
    e.preventDefault();
    localStorage.setItem('viewFilter',href);
    const newViews = views.map(view=>{
      if(view.href===href){
        view.class='choosed';
      } else {
        view.class=null;
      }
      return view;
    });
    setViews(newViews);
    setIsBlock((href==='grid')?true:false);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  //aqui depois
  
  useEffect(()=>{
    setChecked({});
    setDeleteAll(false);
    
    const filter = filters.find(function(item,index){
      return item['class']==='selected';
    });

    api.get(filter.url,{
      params: {
        order: filter.order,
        ordernate: filter.ordernate,
        perPage: 20,
        page,
        search: searched,
      }
    }).then(response => {
      setPosts(response.data);
    });
    setTopLoader(false);

  },[update,searched,filters,page]);
 
  
  const pageArray = useMemo(()=>{
    const thisArray = [];
    for (let i = 1; i <= posts.paged.pages; i++) {
      thisArray.push(i);
    }
    return thisArray;
  },[posts]);
  // CREATE
  const [openCreate,setOpenCreate] = useState(false);
  function handleOpenCreate(){
    setOpenCreate(!openCreate);
  }
  async function handleAdd(data){
    try {
      data.subject_id = parentId;
      const reply = await api.post(`/${url_action}`,data);

      handleFilter(null,'date');

      handleOpenCreate();

      toast.success(`${config.LANG['Unity']} ${reply.data.name} ${config.LANG['FEMALE successfully created!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  // CHECK
  const [checked,setChecked] = useState({});
  function handleChecked(u){
    const newChecked = checked;
    if(checked[u]){
      delete newChecked[u];
    } else {
      newChecked[u] = true;
    }
    var countChecked = 0;
    for (const key in newChecked) {
      key==='essa função é'&&console.log('sem utilidade');
      countChecked++;
    }
    if(countChecked!==0){
      setDeleteAll(true);
    } else {
      setDeleteAll(false);
    }
    setChecked({...newChecked});
  }
  function handleCheckAll(conditional){
    if(conditional){
      setChecked({});
      setDeleteAll(false);
    } else {
      if(posts.list[0]) {
        const newChecked = {};
        for (const key in posts.list) {
          newChecked[posts.list[key].id] = true;
        }
        setChecked({...newChecked});
        setDeleteAll(true);
      }
    }
  }
  // DELETE ALL
  const [selectedToDuplicate, setSelectedToDuplicate] = useState([]);
  const [deleteAll,setDeleteAll] = useState(false);
  const [openDelete,setOpenDelete] = useState(false);
  function handleOpenDelete(){
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    const updatingItems = [];
    for (const key in checked) { updatingItems.push(Number(key)); }
    try {
      await api.put(`/${url_action}/bulk`,{
        ids: updatingItems,
        available: false,
      });
      toast.success(`${config.LANG['Units']} ${config.LANG['FEMALE successfully deleted!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  async function handleRecover(){
    setPosts({
      list: [],
      paged: {}
    });
    const updatingItems = [];
    for (const key in checked) { updatingItems.push(Number(key)); }
    try {
      await api.put(`/${url_action}/bulk`,{
        ids: updatingItems,
        available: true,
      });
      toast.success(`${config.LANG['Units']} ${config.LANG['FEMALE successfully recovered!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  // DELETE SINGLE
  const [deleteSingle,setDeleteSingle] = useState(false);
  const [openDeleteSingle,setOpenDeleteSingle] = useState(false);
  const [openDuplicate,setOpenDuplicate] = useState(false);
  function handleOpenDeleteSingle(){
    setOpenDeleteSingle(!openDeleteSingle);
  }

  function handleOpenDuplicate(){
    setOpenDuplicate(!openDuplicate)
  }

  async function handleDeleteSingle(){
    try {
      await api.delete(`/${url_action}/${deleteSingle.id}`);
      toast.success(`${config.LANG['Unity']} ${config.LANG['FEMALE SINGLE successfully deleted!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  async function handleRecoverSingle(){
    try {
      const thisPost = posts.list.find(u=>u.id===parseInt(deleteSingle.id));
      await api.put(`/${url_action}/${deleteSingle.id}`,{
        available: true,
        role_id: thisPost.role_id
      });
      toast.success(`${config.LANG['Unity']} ${config.LANG['FEMALE SINGLE successfully recovered!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  function handleCallbackMore({type,payload}){
    if(type==='delete'){
      setDeleteSingle(payload.post);
      handleOpenDeleteSingle();
    }
    if(type==='duplicate'){
      setIdUnitDuplicate(payload.post.id)
      handleOpenDuplicate();
    }
  }
  // DELEGATE
  const [openDelegate,setOpenDelegate] = useState(false);
  function handleOpenDelegate(){
    setOpenDelegate(!openDelegate);
  }
  // SCHEMAS =====
  const schemaCreate = Yup.object().shape({
    name: Yup.string().required(config.LANG['Required field'])
  });

  let elements = [];
  const handleInputChange = useCallback((event) => {
    const response = elements.find((item,index) => {
      return item === event.target.value;
    });
    if(response){
      elements.splice(elements.indexOf(response),1);
      return;
    }
    elements.push(event.target.value);
    setSelectedToDuplicate(elements);
  },[]);

  const handleSubmitDuplicate = useCallback( async () => {
    try {
       await api.post(`/units/duplicate/${idUnitDuplicate}`,{
        fields: selectedToDuplicate
      })
      handleFilter(null,'date');
    } catch (error) {
      console.error(error);
    }
    handleOpenDuplicate()
  },[idUnitDuplicate,selectedToDuplicate])
  // RENDER
    return (
      <>
        {topLoader?(
          <Container>
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          </Container>
        ):(
          <Container>
            <Link to={`/areas/${parent.area&&parent.area.id}/subjects`}>
              <img src={leftArrow} alt={config.LANG['Backward']} />
              {config.LANG['Subjects']}
            </Link>
            <Title>{config.LANG['Units']} <span>({posts.paged.totalCount})</span></Title>
            <Subtitle>{config.LANG['from']} {parent.name}</Subtitle>

            <ListHeader>
              <div>
                {(profile&&profile.role.level<=2)&&(<>
                  <button className="icon add" type="button" onClick={handleOpenCreate}>
                    <img src={add} alt={config.LANG['Add']} />
                    {config.LANG['Add']} {config.LANG['Unity']}
                  </button>
                  {deleteAll&&(<>
                    <button className="outline delete" type="button" onClick={handleOpenDelete}>
                      {
                        !filters.find(f=>f.href==='trash'&&f.class!==null)
                          ?config.LANG['Exclude']
                          :config.LANG['Recover']
                      }
                      </button>
                    <button className="outline delete" type="button" onClick={handleOpenDelegate}>{config.LANG['Delegate']}</button>
                  </>)}
                </>)}
              </div>
              <Form onSubmit={handleSearch}>
                <Input onChange={handleSearchChanges} name="search" placeholder={`${config.LANG['Search']}…`} value={searchFinal} />
                <button type="submit">
                  <img src={search} alt={config.LANG['Search']} />
                </button>
              </Form>
            </ListHeader>

            <ListFilter>
              <div>
                {filters.map(filter=>(
                  <a key={filter.href} href={filter.href} className={filter.class||'none'} onClick={(e)=>handleFilter(e,filter.href)}>{filter.text}</a>
                ))}
              </div>
              <div>
                {views.map(view=>(
                  <a key={view.href} href={view.href} className={view.class||'none'} onClick={(e)=>handleView(e,view.href)}><img src={view.src} alt={view.text}/></a>
                ))}
              </div>
            </ListFilter>

            
            {posts.list[0]?(
              <ListTable isBlock={isBlock}>
                <thead>
                  <tr>
                    <th>
                      {(profile&&profile.role.level<=2)&&(<>
                        <button className={`checker${(ObjectSize(checked)===posts.list.length)?` selected`:''}`} type="button" onClick={()=>handleCheckAll(ObjectSize(checked)===posts.list.length)}></button>
                      </>)}
                    </th>
                    <th>
                      <h3>{config.LANG['Units']}</h3>
                    </th>
                    <th>
                      <h3>{config.LANG['Conversions']}</h3>
                    </th>
                    <th>
                      <h3>{config.LANG['Status']}</h3>
                    </th>
                    <th/>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {posts.list.map(p=>(
                    <ItemList order={'areainterna'} colorHex={p.subject.area.color} isBlock={isBlock} key={p.id}>
                      <td>
                        {(profile&&profile.role.level<=2)&&(<>
                          <button className={`checker${checked[p.id]?` selected`:''}`} type="button" onClick={()=>handleChecked(p.id)}></button>
                        </>)}
                      </td>
                      <td>
                        <p><Link className="linker" to={`/units/${p.id}/view`}><strong>{p.name}</strong></Link></p>
                      </td>
                      <td>
                        <p>{p.convertions.length||0} {config.LANG['Conversions']}</p>
                      </td>
                      <td>
                        <p><span className="state">{p.state&&p.state.procedure&&p.state.procedure.name||status[0].name}</span></p>
                      </td>
                      <td className="link build last">
                        <Link to={`/units/${p.id}/view`}>{config.LANG['Create']}</Link>
                      </td>
                      <td className="more">
                        {(profile&&profile.role.level<=2)&&(<>
                          <More post={p} callback={handleCallbackMore} />
                        </>)}
                      </td>
                    </ItemList>
                  ))}
                </tbody>
              </ListTable>
            ):(
              <ListNone>{config.LANG['No units found!']}</ListNone>
            )}

            {posts.paged.pages>1&&(
              <ListPage data-totalpage={posts.paged.pages} data-page={posts.paged.page}>
                <div>
                  <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>handlePage(1)}>
                    <img src={leftArrow} alt={config.LANG['Backward']} />
                    <img src={leftArrow} alt=""/>
                  </button>

                  <button className={posts.paged.page!==1?null:'none'} type="button">
                    <img src={leftArrow} onClick={()=>handlePage(page-1)} alt={config.LANG['Backward']} />
                  </button>
                </div>

                <div>
                  {pageArray.map(i=>
                    <button key={i} className={i===posts.paged.page?'this':null} type="button" onClick={()=>i!==posts.paged.page&&handlePage(i)}>{i}</button>
                  )}
                </div>

                <div>
                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(page+1)}>
                    <img src={rightArrow} alt={config.LANG['Forward']} />
                  </button>

                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(posts.paged.pages)}>
                    <img src={rightArrow} alt={config.LANG['Forward']} />
                    <img src={rightArrow} alt=""/>
                  </button>
                </div>
              </ListPage>
            )}
            
            {(profile&&profile.role.level<=2)&&(<>
              <PopUp Open={openCreate} CallBack={handleOpenCreate}>
                <button type="button" onClick={handleOpenCreate}>
                  <img src={close} alt={config.LANG['Close']} />
                </button>
                <Form schema={schemaCreate} onSubmit={handleAdd}>
                  <h2>{config.LANG['Add']} {config.LANG['Unity']}</h2>
                  <FormRow>
                    <FormColumn Line>
                      <label htmlFor="name">{config.LANG['Name']}:*</label>
                      <Input name="name" placeholder={config.LANG['Name']} autoComplete="off" />
                    </FormColumn>
                  </FormRow>
                  
                  <SubmitRow>
                    <p>*{config.LANG['Required Fields']}</p>
                    <div>
                      <button type="submit">{config.LANG['Add']}</button>
                    </div>
                  </SubmitRow>
                </Form>
              </PopUp>

              <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
                <button type="button" onClick={handleOpenDelete}>
                  <img src={close} alt={config.LANG['Close']} />
                </button>
                {
                  !filters.find(f=>f.href==='trash'&&f.class!==null)
                    ?(
                      <>
                        <h1>{config.LANG['Delete']} {config.LANG['Units']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to delete']} {config.LANG['FEMALE these']} {config.LANG['Units']}?</p><br/>
                        <p>{config.LANG['FELAME They will be saved in the trash and you can recover them later.']}</p>
                        <br/><br/>
                      </>
                    )
                    :(
                      <>
                        <h1>{config.LANG['Recover']} {config.LANG['Units']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to recover']} {config.LANG['FEMALE these']} {config.LANG['Units']}?</p><br/>
                        <br/><br/>
                      </>
                    )
                }
                <FormRow>
                  <FormColumn>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?(<button className="add" type="button" onClick={handleDelete}>{config.LANG['YES']}</button>)
                        :(<button className="add" type="button" onClick={handleRecover}>{config.LANG['YES']}</button>)
                    }
                  </FormColumn>
                  <FormColumn>
                    <button className="delete" type="button" onClick={handleOpenDelete}>{config.LANG['NO']}</button>
                  </FormColumn>
                </FormRow>
              </PopUp>

              <PopUp Tiny Open={openDeleteSingle} CallBack={handleOpenDeleteSingle}>
                <button type="button" onClick={handleOpenDeleteSingle}>
                  <img src={close} alt={config.LANG['Close']}/>
                </button>
                {
                  !filters.find(f=>f.href==='trash'&&f.class!==null)
                    ?(
                      <>
                        <h1>{config.LANG['Delete']} {config.LANG['Unity']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to delete']} {config.LANG['FEMALE this']} {config.LANG['Unity']}?</p><br/>
                        <p>{config.LANG['FEMALE It will be saved in the trash and you can recover it later.']}</p>
                        <br/><br/>
                      </>
                    )
                    :(
                      <>
                        <h1>{config.LANG['Recover']} {config.LANG['Unity']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to recover']} {config.LANG['FEMALE this']} {config.LANG['Unity']}?</p><br/>
                        <br/><br/>
                      </>
                    )
                }
                <FormRow>
                  <FormColumn>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?(<button className="add" type="button" onClick={handleDeleteSingle}>{config.LANG['YES']}</button>)
                        :(<button className="add" type="button" onClick={handleRecoverSingle}>{config.LANG['YES']}</button>)
                    }
                  </FormColumn>
                  <FormColumn>
                    <button className="delete" type="button" onClick={handleOpenDeleteSingle}>{config.LANG['NO']}</button>
                  </FormColumn>
                </FormRow>
              </PopUp>
              
              <PopUp Tiny Open={openDuplicate} CallBack={handleOpenDuplicate}>
                <button type="button" onClick={handleOpenDuplicate}>
                  <img src={close} alt={config.LANG['Close']}/>
                </button>
                <h1>{config.LANG['Duplicate Unit']}</h1>
                <br/><br/>
                <h3>{config.LANG['Duplicate message']}</h3>
                <CheckboxSection>
                    <div>
                      <label htmlFor="delegates">{config.LANG['delegates']}
                        <input onChange={handleInputChange} value="delegate" id="delegates" type="checkbox"/>
                        <span></span>
                      </label>
                    </div>
                    <div>
                      <label htmlFor="personalize">{config.LANG['personalize']}
                        <input onChange={handleInputChange} value="extra" id="personalize" type="checkbox"/>
                        <span></span>
                      </label>
                    </div>
                    <div>
                      <label htmlFor="content">{config.LANG['content']}
                        <input onChange={handleInputChange} value="edit" id="content" type="checkbox"/>
                        <span></span>
                      </label>
                    </div>
                    <div>
                      <label htmlFor="template">{config.LANG['template']}
                        <input onChange={handleInputChange} value="rule" id="template" type="checkbox"/>
                        <span></span>
                      </label>
                    </div>
                </CheckboxSection>
                <FormRow>
                  <FormColumn>
                    <button onClick={handleSubmitDuplicate} className="add">{config.LANG['DUPLICATE']}</button>
                  </FormColumn>
                  <FormColumn>
                    <button onClick={handleOpenDuplicate} className="delete">{config.LANG['DELETE']}</button>
                  </FormColumn>
                </FormRow>

              </PopUp>
              
              <PopUp Open={openDelegate} CallBack={handleOpenDelegate}>
                <button type="button" onClick={handleOpenDelegate}>
                  <img src={close} alt={config.LANG['Close']}/>
                </button>
                <h1>{config.LANG['Delegate Responsible']}</h1>
                <br/><br/>
                <h3>{config.LANG['Attention']}</h3>
                <p>{config.LANG['The users below will be delegated as responsible for the selected units']}</p><br/>
                <Delegates postsId={checked} openOutside={openDelegate} />
                <br/><br/>
              </PopUp>
            </>)}
          </Container>
        )}
      </>
    );
}