import React, {useState,useRef,useEffect} from 'react';
import {useField} from '@rocketseat/unform';

import api from '~/services/api';

import { Container, Circle } from './styles';

export default function Select() {
  const {defaultValue,registerField} = useField('color');
  const [colors,setColors] = useState([
    '#82EBD7',
    '#5276ED',
    '#B282EB',
    '#EDED4F',
    '#81BEEA',
    '#EF6B6B',
    '#82EB86',
    '#E782EB',
    '#FFBD27'
  ]);
  const [color,setValue] = useState(defaultValue?defaultValue.level:null);
  const ref = useRef();
  useEffect(()=>{
    async function GetInitState(){
      const response = await api.get('colors');
      setColors(response.data);

      const theValue = color!==null
        ?colors.find(r=>r===color)
        :colors[0];
      setValue(theValue);
    }
    GetInitState();
  },[]);
  useEffect(()=>{
    if(ref.current){
      registerField({
        name: 'color',
        ref: ref.current,
        path: 'dataset.color'
      });
    }
  },[ref,registerField]);
  useEffect(()=>{
    async function GetInitState(){
      const theValue = defaultValue!==null&&defaultValue!==undefined
        ?colors.find(r=>r===defaultValue)
        :colors[0];
      setValue(theValue);
    }
    GetInitState();
  },[defaultValue]);
  function handleChange(r){
    setValue(r);
  }
  return (
    <Container>
      <div>
        <ul>
          {colors.map(el=>
            <li key={el}>
              <Circle 
                className={el===color?'selected':'clear'}
                type="button"
                colorHex={el}
                onClick={()=>{handleChange(el)}}
              ></Circle>
            </li>
          )}
        </ul>
      </div>
      <input type="hidden" id="color" data-color={color&&color} ref={ref} />
    </Container>
  );
}
