import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import api from '~/services/api';
import {Block,List,Item} from '~/pages/Dashboard/styles';
import search from '~/assets/icons/search.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import TopLoader from '~/assets/loading.gif';

export default function Delegates({parentId}) {
  const config = useSelector(state=>state.config.value);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState('');
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  
  useEffect(()=>{
    api.get(`delegates/projects/${parentId}`,{
          params:{
            perPage: 5,
            page:page,
            search: searched,
          }
        }).then(response => {
          setPosts(response.data);
          setTopLoader(false);
        });
  },[update,parentId,searched,page]);
 

  return (
    <Block Middle>
      {topLoader?(
        <>
          <h2>{config.LANG['My Units']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <div>
            <h2>{config.LANG['My Units']} ({posts.paged.totalCount})</h2>
            <Form onSubmit={handleSearch}>
              <Input name="search" placeholder={`${config.LANG['Search']}…`} />
              <button type="submit">
                <img src={search} alt={config.LANG['Search']} />
              </button>
            </Form>
          </div>
            {posts.list[0]?(
              <List>
                {posts.list.map(post=>(
                  <Item key={post.unit.id} color={post.unit.subject.area.color}>
                    <Link to={`/units/${post.unit.id}/view`}>{post.unit.name}</Link>
                  </Item>
                ))}
              </List>
            ):(
              <p>{config.LANG['No units found!']}</p>
            )}
          {posts.paged.pages>1&&(
            <nav>
              <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                <img src={leftArrow} alt={config.LANG['Backward']} />
              </button>
              <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                <img src={rightArrow} alt={config.LANG['Forward']} />
              </button>
            </nav>
          )}
        </>
      )}
    </Block>
  );
}
