import React,{useEffect,useState} from 'react';
import api from '~/services/api';

import Language from '~/components/Language';
import Profile from '~/components/Profile';
import Notifications from '~/components/Notifications';
import { Container } from './styles';
import {useSelector} from 'react-redux';


export default function Header() {
  const [teste,setTest] = useState(false);
  const profile = useSelector(state=>state.user.profile);

  useEffect(() => {
    api.get('announcements',{
      params:{
        perPage: 1,
      }
    }).then(response => {
     const exist = response.data.list?.find(item => {return item.read === false});
     
     if(exist){
        setTest(true)
      }
    });
    
  },[profile])



  return (
    <Container>
      <Language />
      <Profile />
      <Notifications teste={teste}/>
    </Container>
  );
}
