import styled from 'styled-components';
import { darken} from 'polished';
import {$} from '~/styles/global';

const ColorText = $.colorText($);

export const Container = styled.li`
    display: flex;
    padding: 0px !important;
    &+li {
        border-top: 1px solid ${$.colorText($,0.2)};
    }
    a {
        display: block;
        position: relative;
        padding: 20px 40px;
        text-decoration: none;
        color: ${props => props.read ? ColorText : darken(0.8,ColorText) };
        &:before {
            display: ${props=>props.read?'none':'block'};
            content: '';
            position: absolute;
            top: 50%;
            left: 15px;
            padding: 5px;
            border-radius: 50%;
            background: ${$.colorB};
            transform: translate(0px, -50%);
        }
    }
`;