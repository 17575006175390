import produce from 'immer';

const INITIAL_STATE = {
    first_access: false
};

export default function tutorial(state=INITIAL_STATE,action){
  return produce(state,draft=>{
    switch (action.type){
        case '@tutor/UPDATE_TUTORIAL': {
            draft.first_access = true
            break;
        }
        case '@tutor/UPDATE_TUTORIAL_END': {
          draft.first_access = false
          break;
        }
      default:
    }
  });
}