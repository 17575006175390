import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.nav`
  background: ${$.white};
  border: 1px solid ${$.colorText($,0.2)};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
  }
  a, button {
    background: none;
    padding: 17px;
    border: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.navigate {
      filter: brightness(0.1) opacity(0.3);
      transition: filter ${$.animation};
      border-right: 3px solid transparent;
      span {
        margin-top: 10px;
        ${$.fontSize($,12)};
        opacity: 0;
        transition: opacity ${$.animation};
      }
      &:hover, &[data-selected="selected"] {
        filter: brightness(1) opacity(1);
        color: ${$.colorB};
        border-right: 3px solid ${$.colorB};
        span {
          opacity: 1;
        }
      }
    }
  }
`;
