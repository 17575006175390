import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';

import history from '~/services/history';
import api from '../../services/api';

import {signUpRequest} from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  email: Yup.string().email('Insira um email válido').required('O email é obrigatório'),
  password: Yup.string().min(6,'No mínimo 6 caracteres').required('A senha é obrigatória')
});

export default function SignUp() {
  const dispatch = useDispatch();
  useEffect(()=>{
    async function checkInitPermission(){
      try{
        const response = await api.get(`/init`);
        const {success} = response.data;
        if(success!=="Open") history.push('/');
      } catch(err){
        history.push('/');
      }
    }
    checkInitPermission();
  },[]);
  function handleSubmit({name,email,password}){
    dispatch(signUpRequest(name,email,password));
  }
  return (
    <>
      <h1>Administrador</h1>
      <p>Primeiro cadastro</p>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="name" placeholder="Nome Completo" autoComplete="off" />
        <Input name="email" type="email" placeholder="Seu e-mail" autoComplete="off" />
        <Input name="password" type="password" placeholder="Sua senha secreta" autoComplete="off" />
        <button type="submit">Criar Conta</button>
        <Link to="/">Já tenho login</Link>
      </Form>
    </>
  );
}
