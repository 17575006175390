import Async from '~/services/Async';

import {RemoveError,ReconstructInteraction} from '~/pages/Units/revisor/functions';

export async function Sanitize({editor,res,config}){
  const backupContent = editor.getContent();
  await Async(res=>RemoveError(editor,res));
  await Async(res=>ReconstructInteraction(config,editor,res));
  const savingContent = editor.getContent();
  editor.setContent(backupContent);
  res(savingContent);
}

