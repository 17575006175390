import React,{useState,useEffect,useCallback} from 'react';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';

import {createSchema,DataEncoder} from '~/pages/Units/editor/interaction/functions';

import {Interactions} from '~/components/Interactions';
import PopUp from '~/components/PopUp';

import FormInteraction from '~/pages/Units/editor/interaction/forminteraction';

import leftArrow from '~/assets/icons/leftArrow.svg';
import close from '~/assets/icons/close.svg';

export default function AddInteraction({
  Open,
  Close,
  editor,
  interactions,
}) {
  const config = useSelector(state=>state.config.value);
  // STATE =====
  const [schema,setSchema] = useState(Yup.object().shape({}));
  const [selectedInteraction,setSelectedInteraction] = useState({});
  const [editingData,setEditingData] = useState({});
  const [isInteractions,setIsInteractions] = useState(true);
  // EFFECT =====
  useEffect(()=>{
    setEditingData({});
    if(!Open) setIsInteractions(true);
  },[Open]);
  // CALLBACK =====
  const handleAddInteraction = useCallback((data)=>{
    if(Open&&editor) EditInteraction();
    function EditInteraction(){
      // var element = editor.selection.getNode();
      data = DataEncoder(data);
      editor.insertContent(`<div 
        class="${selectedInteraction.slug}"
        data-interaction="true"
      ><div>${JSON.stringify(data)}</div>${selectedInteraction.name}</div>\n
      <p>&nbsp;</p>`);
      setEditingData({});
      setIsInteractions(true);
      Close();
    }
  },[Open,editor,config,selectedInteraction]);
  const handleSelectInteraction = useCallback((interaction)=>{
    if(Open) SelectInteraction();
    function SelectInteraction(){
      const thisSchema = createSchema(interaction,config);
      setSchema(Yup.object().shape(thisSchema));
      setSelectedInteraction(interaction);
      setIsInteractions(false);
    }
  },[Open]);
  return (
    <PopUp isFront isInteractions={isInteractions} Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt="Fechar"/>
      </button>
      {isInteractions
      ?(
        <Interactions>
          <h1>{config.LANG['Interactions']}</h1>
          <div>
            {interactions.map(group=>(
              <div key={group.name}>
                <h2>{(config.LANGUAGE==='pt_br'||group.lang===false)?(group.name):(group.lang[config.LANGUAGE])}</h2>
                <ul>
                  {group.interactions.map(interaction=>(
                    <li key={interaction.slug}>
                      <button 
                        type="button" 
                        onClick={()=>handleSelectInteraction(interaction)}
                      >{(config.LANGUAGE==='pt_br'||interaction.lang===false)?(interaction.name):(interaction.lang[config.LANGUAGE])}</button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Interactions>
      )
      :(
        <>
          <button type="button" className="back" onClick={()=>setIsInteractions(true)}>
            <img src={leftArrow} alt={config.LANG['Backward']} />
            {config.LANG['Backward']}
          </button>
          <FormInteraction
            selectedInteraction={selectedInteraction}
            schema={schema}
            editingData={editingData}
            CallBack={handleAddInteraction}
          />
        </>
      )}
    </PopUp>
  );
}