import produce from 'immer';

const INITIAL_STATE = {
  socket: null
};

export default function user(state=INITIAL_STATE,action){
  return produce(state,draft=>{
    switch (action.type){
      case '@socket/SET_SOCKET_SUCCESS': {
        draft.socket = action.payload.socket;
        break;
      }
      case '@auth/SIGN_OUT': {
        state.socket.emit('disconnect_this');
        // console.tron.log('ENTROU NO SIGN_OUT');
        // console.tron.log(state);
        draft.socket = null;
        break;
      }
      default:
    }
  });
}