import React, {useCallback,useState,useMemo} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
// import { BsQuestionCircle } from 'react-icons/bs';

import {signOut} from '~/store/modules/auth/actions';
import { toast } from 'react-toastify';
import { updateTutorial} from '../../store/modules/tutor/actions';
import { Container } from './styles';

import user from '~/assets/user.svg';
import downArrow from '~/assets/icons/downArrow.svg';
import { useEffect } from 'react';
import api from '~/services/api';

export default function Profile() {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state => state.user.profile);
  const histoty = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [receive_emails, setReceive_emails] = useState(false);

  function handleOpen(){
    setOpen(!open);
  }
  function handleSignOut(e){
    e.preventDefault();
    dispatch(signOut());
  }
  const navClass = useMemo(() => (open) ? 'open' : 'none', [open]);

  useEffect(() => {
    if (profile?.role.level === 5) {
      api.get('users/settings').then(response => {
          setReceive_emails(response.data.receive_emails);
        })
        // setIsTourOpen(true)
      }
  },[profile])


  const showTuTor = useCallback( async () => {
    try {
      histoty.push('/dashboard')
      const data = {
        first_access: true,
        receive_emails: receive_emails,
      }
      await api.post(`users/settings`, data);
      dispatch(updateTutorial(receive_emails));
      } catch (error) {
        toast.error('Opss, ocorreu um erro. Tente novamente');
      }
  },[receive_emails,dispatch,histoty]);

  return (
    <Container isOpen={open} onClick={handleOpen}>
      <img className="user" src={(profile&&profile.avatar)?profile.avatar.url||user:user} alt={profile&&profile.name}/>
      <strong>{profile&&profile.name}</strong>
      <img src={downArrow} alt=""/>
      <nav className={navClass}>
        <Link to="/profile">{config.LANG['Edit Profile']}</Link>
        {(profile&&profile.role.level<=0)&&(<>
          <Link to="/configurations">{config.LANG['Settings']}</Link>
          <Link to="/procedures">{config.LANG['Procedures']}</Link>
          <Link to="/templates">{config.LANG['Templates']}</Link>
        </>)}
        {profile?.role.level === 5 &&
        <button onClick={showTuTor} className="help-tutorial">
          Tutorial
        </button>
      }
        <button className="help-tutorial" onClick={handleSignOut}>{config.LANG['Logout']}</button>
      </nav>
    </Container>
  );
}
