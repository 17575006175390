import React from 'react';
import {useSelector} from 'react-redux';
import {Input} from '@rocketseat/unform';

import ImageInput from '~/components/Fields/ImageInput';
import PDFInput from '~/components/Fields/PDFInput';
import VideoInput from '~/components/Fields/VideoInput';
import AudioInput from '~/components/Fields/AudioInput';
import ZipInput from '~/components/Fields/ZipInput';
import JsonInput from '~/components/Fields/JsonInput';
import FileDynamicInput from '~/components/Fields/FileDynamicInput';
import Textarea from '~/components/Fields/Textarea';
import MicroEditor from '~/components/Fields/MicroEditor';
import ImageEditor from '~/components/Fields/ImageEditor';
import InlineEditor from '~/components/Fields/InlineEditor';
import Multiple from '~/components/Fields/Multiple';
import Boolean from '~/components/Fields/Boolean';
import Colorpicker from '~/components/Fields/Colorpicker';
import Select from '~/components/Fields/Select';
import SelectCategory from '~/components/Fields/SelectCategory';

export default function Fields({field,editingData,...extra}) {
  const config = useSelector(state=>state.config.value);
  return (<>
    <label htmlFor="name">{(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.label):(field.lang.label[config.LANGUAGE])}{field.required&&'*'}</label>
    {field.type==='text'&&<Input name={field.name} placeholder={(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.placeholder):(field.lang.placeholder[config.LANGUAGE])} autoComplete="off" />}
    {field.type==='file'&&<ImageInput name={field.name} {...extra} />}
    {field.type==='file-pdf'&&<PDFInput name={field.name} {...extra} />}
    {field.type==='file-video'&&<VideoInput name={field.name} {...extra} />}
    {field.type==='file-audio'&&<AudioInput name={field.name} {...extra} />}
    {field.type==='file-zip'&&<ZipInput name={field.name} {...extra} />}
    {field.type==='file-json'&&<JsonInput name={field.name} {...extra} />}
    {field.type==='file-dynamic'&&<FileDynamicInput name={field.name} {...extra} />}
    {field.type==='textarea'&&<Textarea name={field.name} placeholder={(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.placeholder):(field.lang.placeholder[config.LANGUAGE])} {...extra} />}
    {field.type==='editor'&&<MicroEditor name={field.name} placeholder={(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.placeholder):(field.lang.placeholder[config.LANGUAGE])} {...extra} />}
    {field.type==='editor-img'&&<ImageEditor name={field.name} placeholder={(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.placeholder):(field.lang.placeholder[config.LANGUAGE])} {...extra} />}
    {field.type==='editor-inline'&&<InlineEditor name={field.name} placeholder={(config.LANGUAGE==='pt_br'||field.lang===undefined)?(field.placeholder):(field.lang.placeholder[config.LANGUAGE])} {...extra} />}
    {field.type==='multiple'&&(<Multiple field={field} editingData={editingData} />)}
    {field.type==='boolean'&&<Boolean name={field.name} {...extra} />}
    {field.type==='colorpicker'&&<Colorpicker name={field.name} {...extra} />}
    {field.type==='select'&&<Select name={field.name} options={field.options} {...extra} />}
    {field.type==='select-category'&&<SelectCategory name={field.name} options={field.options} {...extra} />}
  </>);
}

// function objectIsEmpty(obj) {
//   return Object.keys(obj).length === 0 && obj.constructor === Object;
// }