import React, {useCallback,useEffect,useRef,useState} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import close from '~/assets/icons/close.svg';
import download from '~/assets/icons/download.svg';
import downloadGreen from '~/assets/icons/dowload_green.svg';
import TopLoader from '~/assets/loading.gif';
import {Container} from '~/components/Container';
import {FormRow,FormColumn} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import api from '~/services/api';
import Async from '~/services/Async';
import isEmail from '~/util/isEmail';
import SheetToJson from '~/util/SheetToJson';

import * as S from './styles';

export default function ImportUsers({Close}) {
  const config = useSelector(state=>state.config.value);
  const [emails,setEmails] = useState([]);
  const [roles,setRoles] = useState([]);
  const [table,setTable] = useState(false);
  const [users,setUsers] = useState([]);
  const [topLoader,setTopLoader] = useState(false);
  const inputFileRef = useRef(null);
  const [openSpreadsheet,setOpenSpreadsheet] = useState(false);

  useEffect(() => {
    api.get('roles').then(response => {
      setRoles(response.data);
    })
  }, []);
    
  const handleOpenSpreadsheet = useCallback(()=>setOpenSpreadsheet(!openSpreadsheet),[openSpreadsheet]);

  const handleClickSpreadsheet = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  const handleDownloadSpreadsheet = useCallback(() => {
    window.location.href = `${window.location.origin}/spreadsheets/usuarios.xlsx`; 
  }, []);

  const handleReturnRoleId = profile => {
    const role = roles.find(role => {
      if (role.name === profile) {
        return role.id;
      }
    });

    if (typeof role === 'undefined') {
      return false;
    }

    return role.id;
  }

  const handleFileChangeSpreadsheet = useCallback(async (e) => {
    if (e.target.files[0]){
      const extension = e.target.files[0].type;

      if (extension === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const spreadsheet = e.target.files[0];

        try {
          const firstColumn = 0;
          const lastColumn = 2;
          const spreadsheetInJson = await Async((response) => SheetToJson(response, spreadsheet, firstColumn, lastColumn));
          const duplicatedEmails = [];
          const usersFormatted = [];
          let hasError = false;

          for(const row of spreadsheetInJson) {
            const nameSpreadsheet = row['Nome completo'].trim();
            const emailSpreadsheet = row['Email'].trim();
            const profileSpreadsheet = row['Perfil'].trim();

            const role_id = handleReturnRoleId(profileSpreadsheet);

            if (!role_id || !profileSpreadsheet || !nameSpreadsheet || !isEmail(emailSpreadsheet)) {
              hasError = true;
              break;
            }

            const checkEmailExists = usersFormatted.filter(user => user.email === emailSpreadsheet);

            if (checkEmailExists.length > 0) {
              duplicatedEmails.push({ email: emailSpreadsheet });
            } else {
              usersFormatted.push({
                name: nameSpreadsheet,
                email: emailSpreadsheet,
                profile: profileSpreadsheet,
                role_id,
              });
            }
          }

          if (duplicatedEmails.length > 0) {
            setTopLoader(true);
            setEmails(duplicatedEmails);
            toast.error(`${config.LANG['There are e-mails already registered in the system.']}`);
          }

          if (hasError) {
            inputFileRef.current.value = ""; 
            toast.error(`${config.LANG['Your spreadsheet is inconsistent. Try again.']}`);
          } else {
            setUsers(usersFormatted);
            setTable(true);
          }
        } catch (err) {
          toast.error(`${config.LANG['Failed to import the spreadsheet. Contact support.']}`);
        }
      } else {
        toast.error(`${config.LANG['The selected file has the wrong extension.']}`);
      }
    }
  }, [config.LANG, handleReturnRoleId]);

  const handleUsersBulkInsert = async () => {
    try {
      setTopLoader(true);
      const usersData = [];

      for (const user in users) {
        const { email, name, role_id } = users[user];
        usersData.push({ email, name, role_id });
      }

      await api.post(`/users/bulk`, { users: usersData });
      setTopLoader(false);
      handleOpenSpreadsheet();
      Close();
      toast.success(`${config.LANG['Spreadsheet imported successfully.']}`);
    } catch(err){
      if ('email' in err.response.data) {
        const duplicatedEmails = [];

        err.response.data.email.forEach(email => {
          duplicatedEmails.push({ email });
        });

        setEmails(duplicatedEmails);
        toast.error(`${config.LANG['There are e-mails already registered in the system.']}`);
      } else {
        setTopLoader(false);
        handleOpenSpreadsheet();  
        toast.error(`${config.LANG['Incorrect information. Try again.']}`);
      }
    }

    setUsers([]);
    setTable(false);
  }

  const handleCancel = useCallback(() => {
    setUsers([]);
    setTable(false);
    setTopLoader(false);
    handleOpenSpreadsheet();
  }, [handleOpenSpreadsheet]);

  return (
    <>
      <div>
        <S.Button type="button" onClick={handleOpenSpreadsheet}>
          <img src={downloadGreen} alt={config.LANG['Download']}/>
          {config.LANG['add multiple users']}
        </S.Button>
      </div>

      <PopUp Open={openSpreadsheet} CallBack={handleOpenSpreadsheet}>
        {topLoader?(
          <>
            {emails.length?(
              <>
                <button type="button" onClick={handleCancel}>
                  <img src={close} alt={config.LANG['Close']} />
                </button>
              
                <Container>
                  <h1>{config.LANG['Duplicate emails']}</h1>
                  
                  <S.EmailContainer>
                    {emails.map((item, index) => (
                      <S.EmailContent key={index}>
                        <p>
                          {item.email}
                        </p>
                      </S.EmailContent>
                    ))}
                  </S.EmailContainer>
                </Container>
              </>
            ):(
              <Container>
                <p className="loaderHolder">
                  <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
                </p>
              </Container>
            )}
          </>
        ):(
          <>
            <button type="button" onClick={handleOpenSpreadsheet}>
              <img src={close} alt={config.LANG['Close']} />
            </button>

            {table?(
              <>
                <h1>Preview</h1>
                <br/>
                <p>{config.LANG['See below the users who will be registered in the system']}:</p>
                <br/>

                <S.Table>
                  <thead>
                    <tr>
                      <th>{config.LANG['Name']}</th>
                      <th>{config.LANG['Email']}</th>
                      <th>{config.LANG['Profile']}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.profile}</td>
                      </tr>
                    ))}
                  </tbody>
                </S.Table>

                <FormRow>
                  <FormColumn>
                    <button className="icon add" type="button" onClick={handleUsersBulkInsert}>
                      {config.LANG['REGISTER USERS']}
                    </button>
                  </FormColumn>
                  <FormColumn>
                    <button className="icon delete" type="button" onClick={handleCancel}>
                      {config.LANG['CANCEL']}
                    </button>
                  </FormColumn>
                </FormRow>
              </>
            ):(
              <>
                <h1>{config.LANG['Import User Spreadsheet']}</h1>
                <br/>
                <p>{config.LANG['See below how to complete and import the spreadsheet to register users']}:</p>
                <br/>
                {/* <S.IFrame 
                  src="https://www.youtube.com/embed/x9xBKM1A-nU" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                /> */}
                <br/><br/>

                <FormRow>
                  <FormColumn>
                    <button className="icon normal" type="button" onClick={handleDownloadSpreadsheet}>
                      <img src={download} alt={config.LANG['Download']} />
                      {config.LANG['DOWNLOAD TEMPLATE SPREADSHEET']}
                    </button>
                  </FormColumn>
                  <FormColumn>
                    <input hidden type="file" ref={inputFileRef} accept=".xlsx" onChange={handleFileChangeSpreadsheet}/>
                    <button className="icon add" type="button" onClick={handleClickSpreadsheet}>
                      <img src={download} alt={config.LANG['Download']} style={{transform: "rotate(180deg)"}} />
                      {config.LANG['IMPORT SPREADSHEET']}
                    </button>
                  </FormColumn>
                </FormRow>
              </>
            )}
          </>
        )}
      </PopUp>
    </>
  );
}
