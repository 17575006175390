import * as XLSX from 'xlsx';

export default async function(response, spreadsheet, firstColumn, lastColumn) {
  let reader = new FileReader();
  reader.readAsArrayBuffer(spreadsheet);

  reader.onload = async (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });

    workbook.SheetNames.forEach(async (sheetName) => {
      let range = XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref']);
      range.s.c = firstColumn;
      range.e.c = lastColumn;
      const new_range = XLSX.utils.encode_range(range);
      let json = await XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], { range: new_range });
    
      response(json);
    });
  }
}