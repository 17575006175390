import React, {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import {Container,Title} from '~/components/Container';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import Boolean from '~/components/Fields/Boolean';

import leftArrow from '~/assets/icons/leftArrow.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  path: Yup.string().required('O caminho é obrigatório'),
  default: Yup.boolean().required('O Atual é obrigatório')
});

export default function EditTemplate({match:{params:{id:postId}}}) {
  const [topLoader,setTopLoader] = useState(true);
  const [post,setPost] = useState({});
  const [openDelete,setOpenDelete] = useState(false);
  useEffect(()=>{
    async function GetInitState(){
      const response = await api.get(`templates/${postId}`);
      setPost(response.data);
      setTopLoader(false);
    }
    GetInitState();
  },[postId]);
  function handleOpenDelete(){
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    try {
      await api.delete(`/templates/${postId}`);
      toast.success(`Template deletado com sucesso!`);
      history.push('/templates');
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
  }
  async function handleRecover(){
    try {
      const response = await api.put(`/templates/${postId}`,{
        available: true,
        role_id: post.role_id
      });
      toast.success(`Template ${response.data.name} recuperado com sucesso!`);
      history.push('/templates');
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
  }
  async function handleSubmit(data){
    try {
      const response = await api.put(`/templates/${postId}`,data);
      const {name} = response.data;
        toast.success(`Template ${name} editado com sucesso!`);
        history.push('/templates');
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
  }
  return (
    <>
      {topLoader?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt="Carregando..."/>
          </p>
        </Container>
      ):(
        <Container className={!post.available&&`unavailable`}>
          <Link to="/templates">
            <img src={leftArrow} alt="Voltar"/>
            Templates
          </Link>
          <Title>Editar Template</Title>
          {/* <h2></h2> */}
          <Form schema={schema} initialData={post} onSubmit={handleSubmit}>

            <FormRow>
              <FormColumn>
                <label htmlFor="name">Nome:*</label>
                <Input name="name" placeholder="Nome" autoComplete="off" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="path">Caminho:*</label>
                <Input name="path" placeholder="Caminho do arquivo" autoComplete="off" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="default">Atual:*</label>
                <Boolean name="default" />
              </FormColumn>
            </FormRow>
            
            <SubmitRow>
              <p>*Campos Obrigatórios</p>
              <div>
                {
                  post.available
                    ?(<button className="delete" type="button" onClick={handleOpenDelete}>Deletar</button>)
                    :(<button className="add" type="button" onClick={handleOpenDelete}>Recuperar</button>)
                }
                <button type="submit">Atualizar Template</button>
              </div>
            </SubmitRow>
          </Form>

          <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
            <button type="button" onClick={handleOpenDelete}>
              <img src={close} alt="Fechar"/>
            </button>
            {
              post.available
                ?(
                  <>
                    <h1>Deletar Template</h1>
                    <br/><br/>
                    <p>Tem certeza que deseja deletar esse template?</p><br/>
                    <p>Ele ficará salvo dentro da lixeira e será possível recuperá-lo depois.</p>
                    <br/><br/>
                  </>
                )
                :(
                  <>
                    <h1>Recuperar Template</h1>
                    <br/><br/>
                    <p>Tem certeza que deseja recuperar este template?</p>
                    <br/><br/>
                  </>
                )
            }
            <FormRow>
              <FormColumn>
                {
                  post.available
                    ?(<button className="add" type="button" onClick={handleDelete}>SIM</button>)
                    :(<button className="add" type="button" onClick={handleRecover}>SIM</button>)
                }
              </FormColumn>
              <FormColumn>
                <button className="delete" type="button" onClick={handleOpenDelete}>NÃO</button>
              </FormColumn>
            </FormRow>
          </PopUp>
        </Container>
      )}
    </>
  );
}
