import styled,{keyframes,css} from 'styled-components';

import {$} from '~/styles/global';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: relative;
  img.user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #eee;
  }
  strong {
    padding: 0 10px 0 15px;
  }
  ${props=>props.isOpen&&css`
    background: ${$.white}!important;
  `}
  nav {
    position: absolute;
    width: calc(100% + 2px);
    top: calc(100% - 1px);
    left: -1px;
    display: ${props=>(props.isOpen)?'flex':'none'};
    flex-direction: column;
    background: ${$.white};
    border: 1px solid ${$.colorText($,0.2)};
    border-top: 0;
    z-index: 1;
    &.open {
      animation: ${show} ${$.animation} linear 1;
    }
    a {
      display: inline-block;
      width: 100%;
      text-align: left;
      padding: 10px 20px;
      color: ${$.colorText($)};
      &:hover {
        background: ${$.colorWhite};
      }
    }
  }

  .help-tutorial{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    border: 1px solid transparent;
    background: transparent;
    color: ${$.colorText($)};
    padding: 10px 20px;
    svg{
      color: ${$.colorD}
    }
    &:hover{
      background: ${$.colorWhite};
    }
  }
`;