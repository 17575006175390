import React, {useState,useEffect,useCallback} from 'react';
// import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';
import {ButtonToBack} from '../states-by-project/styles';
import {Container,Title} from '~/components/Container';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import Boolean from '~/components/Fields/Boolean';

import leftArrow from '~/assets/icons/leftArrow.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  order: Yup.string().required('A ordem é obrigatória'),
  access: Yup.boolean().required('O Acesso é obrigatório')
});

export default function EditProcedure({match:{params:{id:postId}}}) {
  const [topLoader,setTopLoader] = useState(true);
  const [post,setPost] = useState({});
  const [openDelete,setOpenDelete] = useState(false);
  useEffect(()=>{
    async function GetInitState(){
      const response = await api.get(`procedures/${postId}`);
      setPost(response.data[0]);
      setTopLoader(false);
    }
    GetInitState();
  },[postId]);
  function handleOpenDelete(){
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    try {
      await api.delete(`/procedures/${postId}`);
      toast.success(`Procedimento deletado com sucesso!`);
      history.push('/procedures');
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
  }
  async function handleRecover(){
    try {
      const response = await api.put(`/procedures/${postId}`,{
        available: true,
        role_id: post.role_id
      });
      toast.success(`Procedimento ${response.data.name} recuperado com sucesso!`);
      history.push('/procedures');
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
  }
  async function handleSubmit(data){
    try {
      const response = await api.put(`/procedures/${postId}`,data);
      const {name} = response.data;
        toast.success(`Procedimento ${name} editado com sucesso!`);
        history.push('/procedures');
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
  }
  const GoBack = useCallback(() =>{
    history.goBack();
  },[]);

  return (
    <>
      {topLoader?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt="Carregando..."/>
          </p>
        </Container>
      ):(
        <Container className={!post.available&&`unavailable`}>
          <ButtonToBack onClick={GoBack}>
            <img src={leftArrow} alt="Voltar"/>
            Procedimentos
          </ButtonToBack>
          <Title>Editar Procedimento</Title>
          {/* <h2></h2> */}
          <Form schema={schema} initialData={post} onSubmit={handleSubmit}>

            <FormRow>
              <FormColumn>
                <label htmlFor="name">Nome:*</label>
                <Input name="name" placeholder="Nome" autoComplete="off" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="order">Ordem:*</label>
                <Input type="number" name="order" placeholder="Ordem" autoComplete="off" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="access">Acesso:*</label>
                <Boolean name="access" />
              </FormColumn>
            </FormRow>
            
            <SubmitRow>
              <p>*Campos Obrigatórios</p>
              <div>
                {
                  post.available
                    ?(<button className="delete" type="button" onClick={handleOpenDelete}>Deletar</button>)
                    :(<button className="add" type="button" onClick={handleOpenDelete}>Recuperar</button>)
                }
                <button type="submit">Atualizar Procedimento</button>
              </div>
            </SubmitRow>
          </Form>

          <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
            <button type="button" onClick={handleOpenDelete}>
              <img src={close} alt="Fechar"/>
            </button>
            {
              post.available
                ?(
                  <>
                    <h1>Deletar Procedimento</h1>
                    <br/><br/>
                    <p>Tem certeza que deseja deletar esse procedimento?</p><br/>
                    <p>Ele ficará salvo dentro da lixeira e será possível recuperá-lo depois.</p>
                    <br/><br/>
                  </>
                )
                :(
                  <>
                    <h1>Recuperar Procedimento</h1>
                    <br/><br/>
                    <p>Tem certeza que deseja recuperar este procedimento?</p>
                    <br/><br/>
                  </>
                )
            }
            <FormRow>
              <FormColumn>
                {
                  post.available
                    ?(<button className="add" type="button" onClick={handleDelete}>SIM</button>)
                    :(<button className="add" type="button" onClick={handleRecover}>SIM</button>)
                }
              </FormColumn>
              <FormColumn>
                <button className="delete" type="button" onClick={handleOpenDelete}>NÃO</button>
              </FormColumn>
            </FormRow>
          </PopUp>
        </Container>
      )}
    </>
  );
}
