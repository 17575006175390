import React, {useState} from 'react';

import {Row} from '~/pages/Dashboard/styles';

import Delegates from '~/pages/Units/view/delegatesusers/delegates';
import Users from '~/pages/Units/view/delegatesusers/users';

export default function DelegatesUsers({postId}) {
  const [changed,setChanged] = useState(true);
  function handleChanged(){
    setChanged(!changed);
  }
  return (
    <Row>
      <Users postId={postId} callback={handleChanged} />
      <Delegates postId={postId} changed={changed} />
    </Row>
  );
}
