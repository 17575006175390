import React,{useState,useCallback,useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Form,Input} from '@rocketseat/unform';
import {toast} from 'react-toastify';

import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import {Corrections} from '~/pages/Units/revisor/popup/editerror/styles';

import {Button} from '~/pages/Units/revisor/popup/editerror/components/button';

import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';

export default function EditError({Open,Close,editor}) {
  const config = useSelector(state=>state.config.value);
  // STATE =====
  const [messageError,setMessageError] = useState(``);
  const [replacementsError,setReplacementsError] = useState([{}]);
  const [selection,setSelection] = useState('');
  const [code,setCode] = useState('');
  // EFFECT =====
  useEffect(()=>{
    if(Open&&editor) EditError();
    function EditError(){
      var element = editor.selection.getNode();
      element = errorValidate(element);
      var message = element.getAttribute('data-message');
      var replacements = JSON.parse(decodeURI(element.getAttribute('data-replacements')));
      var text = element.textContent;
      var codeR = element.getAttribute('data-code');
      setCode(codeR);
      setSelection(text);
      setMessageError(message);
      setReplacementsError(replacements);
      function errorValidate(el){
        if(el.nodeName.toLowerCase()!=='error'){
          el = errorValidate(el.parentNode);
        }
        return el;
      }
    }
  },[Open,editor]);
  // CALLBACK =====
  const handleAddCorrection = useCallback((data)=>{
    if(Open&&editor) Add();
    function Add(){
      var element = editor.selection.getNode();
      if(element.nodeName.toLowerCase()==='body'){
        toast.error(config.LANG['An error has occurred. Try again.']);
      } else {
        if(element.textContent!==data.correction){
          var pureText = document.createTextNode(data.correction);
          element.parentNode.replaceChild(pureText,element);
          editor.focus();
          const content = editor.getContent();
          editor.setContent(content);
          editor.nodeChanged();
        }
      }
      Close();
    }
  }, [Open, editor, Close, config.LANG]);
  
  const handleUpdateInput = useCallback((e)=>{
    setSelection(e.target.value);
  },[]);
  const handleSelection = useCallback((string)=>{
    setSelection(string);
  },[]);
  // SCHEMA =====
  const schema = Yup.object().shape({
    correction: Yup.string().required(config.LANG['Required field'])
  });
  return (
    <PopUp isFront Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>{config.LANG['Spell Check']}</h1>
      <h2>{config.LANG['Error identified']}:</h2>
      <p>• {messageError}</p><br/>
      <Form id="form_send" schema={schema} onSubmit={handleAddCorrection}>
        <FormRow>
          <FormColumn Line>
            <label>{config.LANG['Text for correction']}:</label>
            <Input type="text" name="correction" value={selection} onChange={handleUpdateInput} />
          </FormColumn>
          {replacementsError.length>0&&(<>
            <FormColumn Line>
              <label>{config.LANG['Correction options']}:</label>
              <Corrections>
                {replacementsError.map(resposta=>(
                  <Button selection={selection} callback={handleSelection} resposta={resposta} key={resposta.value} />
                ))}
              </Corrections>
            </FormColumn>
          </>)}
        </FormRow>
        <SubmitRow>
          <p><strong>{config.LANG['Code']}:</strong> {code}</p>
          <div>
            <button type="submit">{config.LANG['Insert']}</button>
          </div>
        </SubmitRow>
      </Form>
    </PopUp>
  );
}