import {toast} from 'react-toastify';

export default class saveTimer {
  constructor(){
    this.seconds = 1000;
    this.minutes = 60000;

    this.time = 15;
    this.measure = this.minutes;

    this.interval = false;
    this.started = false;
  }
  StartTimer(message){
    if(!this.started){
      this.started = true;
      this.interval = setTimeout(() => {
        toast.info(message.split('$TIMER$').join(this.time),{
          position: "top-right",
          autoClose: false,
          draggable: false,
        });
      }, this.time*this.measure);
    }
  }
  StopTimer(){
    this.started = false;
    clearTimeout(this.interval);
  }
}