import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
  background: ${$.white};
  border: 1px solid ${$.colorText($,0.14)};
  border-radius: 5px;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px;
  }
`;

export const Circle = styled.button`
  padding: 20px;
  height: auto;
  background: ${props=>props.colorHex};
  border-radius: 50%;
  border: 5px solid ${$.white};
  margin: 2.5px;
  &:hover {
    background:${props=>props.colorHex};
    opacity: 0.8;
  }
  &.selected {
    box-shadow: 0px 0px 0px 1px ${$.colorText($,0.4)};
  }
`;
