export function updateProfileRequest(data,config){
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: {data,config}
  };
}

export function updateProfileSuccess(profile){
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: {profile}
  };
}

export function updateProfileFailure(){
  return {
    type: '@user/UPDATE_PROFILE_FAILURE'
  };
}