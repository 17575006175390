import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';

import axios from 'axios';
import {toast} from 'react-toastify';

import {Block,List,Item} from '~/pages/Dashboard/styles';

import Select from '~/components/Pixabay/filters/Select';

import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import TopLoader from '~/assets/loading.gif';

export default function Pixabay({Appear,callback,...extra}) {
  const config = useSelector(state=>state.config.value);
  // APPEAR
  const [show,setShow] = useState(true);
  
  useEffect(() => {
    setShow((state) => !state);
  },[Appear]);
  
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  const [update,setUpdate] = useState(true);

  
  // GET POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [page,setPage] = useState(1);
  const [searchedTerm,setSearchedTerm] = useState('Estudo');
  const [searchedType,setSearchedType] = useState('all');
  const [searchedOrientation,setSearchedOrientation] = useState('all');
  const [searchedCategory,setSearchedCategory] = useState('all');
  const [searchedLang,setSearchedLang] = useState('pt');
  
  // PAGES
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // FILTERS
  async function handleFilter(e){
    e.preventDefault();

    setSearchedTerm(term);
    setSearchedType(type);
    setSearchedOrientation(orientation);
    setSearchedCategory(category);
    setSearchedLang(lang);
    setPage(1);
    setUpdate(!update);
  }
  // TERM
  const [term,setTerm] = useState('');
  async function handleTerm(e){
    setTerm(e.target.value);
  }
  // TYPE
  const [type,setType] = useState('all');
  async function handleType(value){
    setType(value);
  }
  // TYPE
  const [orientation,setOrientation] = useState('all');
  async function handleOrientation(value){
    setOrientation(value);
  }
  // CATEGORY
  const [category,setCategory] = useState('all');
  async function handleCategory(value){
    setCategory(value);
  }
  // LANG
  const [lang,setLang] = useState('pt');
  async function handleLang(value){
    setLang(value);
  }
  // CLICK
  function handleClick(url){
    callback(url);
  }

  useEffect(() => {
    function getPosts(){
      const pixabay = axios.create({
        baseURL: 'https://pixabay.com',
        timeout: 100000,
      });
      var perPage = 20;
      pixabay.get('api/', {
        params: {
          key: '16629152-743eb5195c30edb89356f3e11',
          q: searchedTerm,
          image_type: searchedType,
          orientation: searchedOrientation,
          category: searchedCategory,
          lang: searchedLang,
          page,
          per_page: perPage,
        }
      })
      .then(function (response) {
          //handle success
          var pages = response.data.total/perPage;
          if(parseInt(pages)<pages){
            pages = parseInt(pages)+1;
          }
          setPosts({
            list: response.data.hits,
            paged:{
              perPage,
              totalCount: response.data.total,
              pages,
              page:page,
            },
          });
          setTopLoader(false);
      })
      .catch(function (response) {
          //handle error
          toast.error(config.LANG['An error has occurred. Try again.']);
          setTopLoader(false);
      });
    }
    getPosts();
  },[update,config.LANG,page,searchedTerm,searchedType,searchedOrientation,searchedCategory,searchedLang]);

  return (<>
    {show&&(
      <Block {...extra}>
        {topLoader?(
          <>
            <h2>{config.LANG['Images']}</h2>
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          </>
        ):(
          <>
            <div>
              <h2>{config.LANG['Free Image Stock']} ({posts.paged.totalCount})</h2>
            </div>
              <div className="division">
                <div className="searcher">
                  <label htmlFor="term">{config.LANG['Term']}:</label>
                  <input id="term" onChange={handleTerm} value={term} name="term-stock" placeholder={config.LANG['Enter the term to filter.']} />
                  <label>{config.LANG['Term Language']}:</label>
                  <Select callback={handleLang} zIndex={4} data={[
                    {
                      name: config.LANG['Portuguese'],
                      value: 'pt'
                    },
                    {
                      name: config.LANG['English'],
                      value: 'en'
                    },
                    {
                      name: config.LANG['Spanish'],
                      value: 'es'
                    }
                  ]} />
                  <label>{config.LANG['Type']}:</label>
                  <Select callback={handleType} zIndex={3} data={[
                    {
                      name: config.LANG['All Single'],
                      value: 'all'
                    },
                    {
                      name: config.LANG['Photo'],
                      value: 'photo'
                    },
                    {
                      name: config.LANG['Illustration'],
                      value: 'illustration'
                    },
                    {
                      name: config.LANG['Vector'],
                      value: 'vector'
                    },
                  ]} />
                  <label>{config.LANG['Orientation']}:</label>
                  <Select callback={handleOrientation} zIndex={2} data={[
                    {
                      name: config.LANG['All Single'],
                      value: 'all'
                    },
                    {
                      name: config.LANG['Horizontal'],
                      value: 'horizontal'
                    },
                    {
                      name: config.LANG['Vertical'],
                      value: 'vertical'
                    }
                  ]} />
                  <label>{config.LANG['Category']}:</label>
                  <Select callback={handleCategory} zIndex={1} data={[
                    {
                      name: config.LANG['All Single'],
                      value: 'all'
                    },
                    {
                      name: config.LANG['Backgrounds'],
                      value: 'backgrounds'
                    },
                    {
                      name: config.LANG['Fashion'],
                      value: 'fashion'
                    },
                    {
                      name: config.LANG['Nature'],
                      value: 'nature'
                    },
                    {
                      name: config.LANG['Science'],
                      value: 'science'
                    },
                    {
                      name: config.LANG['Education'],
                      value: 'education'
                    },
                    {
                      name: config.LANG['Feelings'],
                      value: 'feelings'
                    },
                    {
                      name: config.LANG['Health'],
                      value: 'health'
                    },
                    {
                      name: config.LANG['People'],
                      value: 'people'
                    },
                    {
                      name: config.LANG['Religion'],
                      value: 'religion'
                    },
                    {
                      name: config.LANG['Places'],
                      value: 'places'
                    },
                    {
                      name: config.LANG['Animals'],
                      value: 'animals'
                    },
                    {
                      name: config.LANG['Industry'],
                      value: 'industry'
                    },
                    {
                      name: config.LANG['Computer'],
                      value: 'computer'
                    },
                    {
                      name: config.LANG['Food'],
                      value: 'food'
                    },
                    {
                      name: config.LANG['Sports'],
                      value: 'sports'
                    },
                    {
                      name: config.LANG['Transportation'],
                      value: 'transportation'
                    },
                    {
                      name: config.LANG['Travel'],
                      value: 'travel'
                    },
                    {
                      name: config.LANG['Buildings'],
                      value: 'buildings'
                    },
                    {
                      name: config.LANG['Business'],
                      value: 'business'
                    },
                    {
                      name: config.LANG['Music'],
                      value: 'music'
                    }
                  ]} />
                  <button type="button" onClick={handleFilter}>{config.LANG['Filter']}</button>
                </div>
                {posts.list[0]?(
                  <List Image>
                    {posts.list.map(post=>(
                      <Item key={post.id} onClick={()=>handleClick(post.largeImageURL)}> 
                        <img src={post.previewURL} alt="" />
                      </Item>
                    ))}
                    {posts.paged.pages>1&&(
                      <nav>
                        <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                          <img src={leftArrow} alt={config.LANG['Backward']} />
                        </button>
                        <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                          <img src={rightArrow} alt={config.LANG['Forward']} />
                        </button>
                      </nav>
                    )}
                  </List>
                ):(
                  <p>{config.LANG['No images found!']}</p>
                )}
              </div>
          </>
        )}
      </Block>
    )}
  </>);
}
