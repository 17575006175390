import styled,{css} from 'styled-components';
import {$} from '~/styles/global';

export const ButtonToBack = styled.button`
    border: none;
    color: ${$.colorText($,0.9)};
    margin-bottom: 10px;
    img{
        margin-right: 11px;
    }
`;
export const Subtitle = styled.h2`
  margin: 0;
  h1 + & {
    margin-top: -20px;
    margin-bottom: 40px;
    ${props=>props.noTop&&css`
      margin-top: 0px;
    `}
  }
`;