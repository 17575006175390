import React, {useState,useRef,useEffect} from 'react';
import {Input,useField} from '@rocketseat/unform';

import {$} from '~/styles/global';

import { Container } from './styles';

export default function Boolean(fieldValue) {
  const { defaultValue, registerField, error } = useField(fieldValue.name);
  const [value,setvalue] = useState(defaultValue?Number(defaultValue):0);

  const ref = useRef();

  useEffect(() => {
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.value'
      });
    }
  }, [ref, registerField]);
  
  useEffect(() => {
    setvalue(defaultValue?Number(defaultValue):0);
  },[defaultValue]);

  async function handleChange(e){
    if(value===0){
      setvalue(1);
    } else {
      setvalue(0);
    }
  }
  return (
    <Container>
      <label
        style={{ background: value===1 && $.correct }}
        className={`react-switch-label${(value===1)?' open':''}`}
        onClick={handleChange}
      >
        <span className={`react-switch-button`} />
      </label>

      <input type="hidden" data-value={value} ref={ref}/>
      {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={value} />)}
      { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
    </Container>
  );
}
