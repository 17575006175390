import React,{useState,useCallback,useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import {createSchema,DecodeData,DataEncoder} from '~/pages/Units/editor/interaction/functions';

import PopUp from '~/components/PopUp';

import FormInteraction from '~/pages/Units/editor/interaction/forminteraction';

import close from '~/assets/icons/close.svg';

export default function EditInteraction({Open,Close,editor,interactions}) {
  const config = useSelector(state=>state.config.value);
  // STATE =====
  const [schema,setSchema] = useState(Yup.object().shape({}));
  const [selectedInteraction,setSelectedInteraction] = useState({});
  const [editingData,setEditingData] = useState({});
  // EFFECT =====
  useEffect(()=>{
    if(Open&&editor) EditInteraction();
    function EditInteraction(){
      var element = editor.selection.getNode();
      element = CheckName(element);
      function CheckName(el){
        var name = el.nodeName.toLowerCase();
        if(name!=='div'){
          var pureText = document.createTextNode(el.textContent);
          var parentEl = el.parentNode;
          parentEl.replaceChild(pureText, el);
          el = CheckName(parentEl);
        }
        return el;
      }
      var className = element.getAttribute('class');
      var result;
      interactions.map(group=>{
        group.interactions.map(interaction=>{
          if(className===interaction.slug)result=interaction;
        });
      });
      if(!result){
        toast.error(config.LANG['This interaction is another theme.']);
        toast.error(config.LANG['Change the theme to edit this interaction.']);
        return false;
      }
      handleSelectInteraction(result);
      var getValues;
      if(element.querySelector('div')){
        getValues = JSON.parse(element.querySelector('div').textContent);
        for (const key in getValues) {
          getValues[key] = DecodeData(getValues,key);
        }
      } else {
        toast.error(config.LANG['Corrupted interaction. Create it again. You may have deleted a line or important information.']);
      }
      setEditingData(getValues);
    }
  },[Open,editor,interactions,config]);
  useEffect(()=>{
    if(!Open) setEditingData({});
  },[Open]);
  // CALLBACK =====
  const handleEditInteraction = useCallback((data)=>{
    if(Open&&editor) EditInteraction();
    function EditInteraction(){
      var element = editor.selection.getNode();
      while(element.firstChild) element.removeChild(element.firstChild);
      data = DataEncoder(data);
      editor.insertContent(`<div>${JSON.stringify(data)}</div>${(config.LANGUAGE==='pt_br'||selectedInteraction.lang===undefined)?(selectedInteraction.name):(selectedInteraction.lang[config.LANGUAGE])}</div>`);
      setEditingData({});
      Close();
    }
  },[Open,editor,config,selectedInteraction]);
  const handleSelectInteraction = useCallback((interaction)=>{
    if(Open) SelectInteraction();
    function SelectInteraction(){
      const thisSchema = createSchema(interaction,config);
      setSchema(Yup.object().shape(thisSchema));
      setSelectedInteraction(interaction);
    }
  },[Open]);
  return (
    <PopUp isFront Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      {!(Object.keys(editingData).length === 0 && editingData.constructor === Object)&&(
        <FormInteraction
          selectedInteraction={selectedInteraction}
          schema={schema}
          editingData={editingData}
          CallBack={handleEditInteraction}
        />
      )}
    </PopUp>
  );
}