import React,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {$} from '~/styles/global';

import AutoRender from '~/pages/Units/editor/autorender';

export default function Multiple({postId,post,handleReload}) {
  const config = useSelector(state=>state.config.value);
  const socket = useSelector(state=>state.socket.socket);

  const [length,setLength] = useState(0);
  const [unitUsers,setUnitUsers] = useState('');
  useEffect(()=>{
    if(socket){
      socket.emit('enter-unit');
      socket.on('unit_checker',()=>{socket.emit('enter-unit');});

      socket.on('unit_refresh',()=>{
        socket.emit('join-unit',{unit_id:postId});
      });

      socket.on('unit_number',data=>{
        setLength(data.length);
        if(data.users){
          var usersString = '';
          for (const key in data.users) {
            if (data.users.hasOwnProperty(key)) {
              const element = data.users[key];
              if(usersString!=='') usersString+=', ';
              usersString += element;
            }
          }
          setUnitUsers(` Usuários: ${usersString}.`);
        }
      });
    }

    // componentWillUnmount
    return () => {
      if(socket){
        socket.off('unit_checker');
        socket.off('unit_refresh');
        socket.off('unit_number');

        socket.emit('leave-unit');
      }
    }
  },[socket,postId,]);
  return (
    <>
      <AutoRender postId={postId} post={post} handleReload={handleReload} />
      {length>1&&(<>
        <MultipleButton>
          <button className="error" type="button">{config.LANG['Editing Unit']}: <strong>{length} {config.LANG['People']}</strong></button>
          <div className="information">
            <p><strong>{config.LANG['Attention']}:</strong> {config.LANG["There are other people editing this unit in another window. Take care not to replace other people's work."]}{unitUsers}</p>
          </div>
        </MultipleButton>
      </>)}
    </>
  );
}

export const MultipleButton = styled.div`
  position: relative;
  button {
    color: ${$.white};
    background: none;
    border: 0;
    padding: 10px;
    ${$.fontSize($,16)}
    &.error {
      background: ${$.error};
    }
    &:hover {
      background: ${$.white};
      color: ${$.colorC};
      img {
        filter: inherit;
      }
    }
  }
  .information {
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    color: ${$.white};
    background: #000000eb;
    box-sizing: border-box;
    padding: 10px;
  }
  &:hover {
    .information {
      display: block;
    }
  }
`;