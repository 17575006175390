import React, {useState,useRef,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Input,useField} from '@rocketseat/unform';

import api from '~/services/api';

import { Container } from './styles';

import logo from '~/assets/logo.svg';
import logoWhite from '~/assets/logoWhite.svg';

export default function ZipInput(fieldValue) {
  const config = useSelector(state=>state.config.value);
  const { defaultValue, registerField, error } = useField(fieldValue.name);

  const [file,setFile] = useState(defaultValue&&defaultValue);
  const [preview,setPreview] = useState(defaultValue&&defaultValue);

  const [loading,setLoading] = useState('');

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.file'
      });
    }
  },[ref,registerField]);

  useEffect(()=>{
    if(defaultValue==='undefined'){
      setFile(null);
      setPreview(null);
    } else {
      setFile(defaultValue&&defaultValue);
      setPreview(defaultValue&&defaultValue);
    }
  },[defaultValue]);

  async function handleChange(e){
    setLoading(`${config.LANG['Uploading file']}...`);
    const data = new FormData();

    data.append('file',e.target.files[0]);

    try {
      const response = await api.post('files',data);

      const {url} = response.data;

      setFile(url);
      setPreview(url);
      setLoading('');
    } catch(err){
      setLoading(config.LANG['An error has occurred. Try again or check the file size. Decrease the file size, if necessary.']);
    }
  }
  function handleRemove(){
    setFile(null);
    setPreview(null);
  }
  return (
    <Container>
      <p><strong>{config.LANG['Attention']}:</strong> {config.LANG['The package must have an index.html file at its root that will load the interaction.']}</p><br/>
      <div>
        <label htmlFor={`filer_${fieldValue.name}`}>
          <img src={preview?logo:logoWhite} alt=""/>
          <input 
            type="file"
            id={`filer_${fieldValue.name}`}
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
            data-file={file}
            onChange={handleChange}
            ref={ref}
          />
        </label>
        <div>
          <button type="button" onClick={handleRemove}>{config.LANG['Remove']} {config.LANG['Package']}</button>
          <label htmlFor={`filer_${fieldValue.name}`}>{config.LANG['Choose']} {config.LANG['Package']}</label>
        </div>
      </div>
      {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={file} />)}
      { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
      <p>{loading}</p>
      {/* <p>{preview?'Arquivo anexado!':'Nenhum arquivo anexado.'}</p> */}
    </Container>
  );
}
