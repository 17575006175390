import styled from 'styled-components';
import {$} from '~/styles/global';


export const SelectContainer = styled.select`
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 260px;
    height: 30px;
    border: 1px solid rgba(80,80,80,0.15);
    background: transparent;
    border-radius: 5px;
    color: ${$.colorText($,0.6)};
    padding-left: 8px;
`;