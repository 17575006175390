import React, {useState,useRef,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Input,useField} from '@rocketseat/unform';
import { Editor } from '@tinymce/tinymce-react';

import { Container } from './styles';

export default function InlineEditor(fieldValue) {
  const config = useSelector(state=>state.config.value);
  var language = 'en';
  if(config.LANGUAGE==='pt_br'){
    language = 'pt_BR';
  } else if(config.LANGUAGE==='es_es'){
    language = 'es';
  }
  const initialValue = '';

  const { defaultValue, registerField, error } = useField(fieldValue.name);

  const [value,setvalue] = useState(defaultValue?`${defaultValue}`:initialValue);

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.value'
      });
    }
  },[ref,registerField]);

  useEffect(()=>{
    setvalue(defaultValue?`${defaultValue}`:initialValue);
  },[defaultValue]);

  async function handleChange(content){
    setvalue(content.split('<p>').join('').split('</p>').join(''));
  }
  return (
    <Container>
      <div>
        <Editor // https://www.tiny.cloud/docs/demo/inline/
          placeholder={fieldValue.placeholder}
          initialValue={value}
          init={{
            language,
            height: 24,
            width: '100%',
            inline: true,
            menubar: false,
            statusbar: false,
            plugins: [
              'paste',
            ],
            // PASTE PLUGIN
            paste_auto_cleanup_on_paste : true,
            paste_remove_styles: true,
            paste_remove_styles_if_webkit: true,
            paste_strip_class_attributes: true,
            paste_word_valid_elements: "b,strong,i,em,h1,h2",
            paste_postprocess: function(plugin, args) {
              var span = document.createElement('span');
              span.textContent = args.node.textContent;
              args.node = span;
              ClearCommentsInsidePaste();
              function ClearCommentsInsidePaste(){
                var allElements = args.node.querySelectorAll(':scope *[data-comments]');
                Array.prototype.forEach.call(allElements, function(el, i){
                  if(el.getAttribute('data-comments')){
                    el.removeAttribute('data-comments');
                  }
                });
              }
            },
            toolbar: `code bold italic removeformat`,
            contextmenu: "link image imagetools table spellchecker",
            setup: (editor) => {
              editor.on(`keydown`,function(args){
                if(args.key==='Enter'){
                  args.preventDefault();
                }
              });
            },
          }}
          onEditorChange={handleChange}
        />
        <input type="hidden" data-value={value} ref={ref}/>
        {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={value} />)}
        { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
      </div>
    </Container>
  );
}
