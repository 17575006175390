import styled from 'styled-components';
import { $ } from '../../styles/global';

export const Container = styled.div`
   .button-next{
       display: flex;
       align-items: center;
       justify-content:center;
       svg{
           margin-left: 4px;
       }
   }
   .checkbox-label{
        display: flex;
        align-items: center;
        justify-content:center;
       label{
           margin-left: 5px;
       }
   }
`;


export const FeedBackContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 14px;
    button{
        margin-right: 10px;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        color: ${props => props.tab02 ? '#FFFF' : `${$.colorE}`};

        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content:center;
        border: 3px solid ${props => !props.tab02 ? `${$.colorGray}`: 'transparent'};
        background: ${props => props.tab02 ? `${$.colorE}`:'transparent'};

    }
    span{
        margin-right: 10px;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        color: ${props => props.tab02 ? `${$.colorE}`: '#FFFF'};
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content:center;
        border: 3px solid ${props => props.tab02 ? `${$.colorGray}`: 'transparent'};
        background: ${props => props.tab02 ? 'transparent': `${$.colorE}`};
    }
`;

export const BackToLogin = styled.footer`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
        margin-left: 5px;
        text-decoration: none;
        color: ${$.colorE};
        transition: all 0.2s;
        &:hover{
            opacity: 0.8;
        }
    }  
`;