export default class Timer {
  constructor(){
    this.seconds = 1000;
  }
  register(action=()=>{}){
    if(this.action) clearTimeout(this.action);
    this.action = setTimeout(function(){
      action();
    }, (!this.action)?0:this.seconds);
  }
}
