import React, {useState,useRef,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Input,useField} from '@rocketseat/unform';
import { Editor } from '@tinymce/tinymce-react';

import api from '~/services/api';

import { Container } from './styles';

export default function MicroEditor(fieldValue) {
  const config = useSelector(state=>state.config.value);
  var language = 'en';
  if(config.LANGUAGE==='pt_br'){
    language = 'pt_BR';
  } else if(config.LANGUAGE==='es_es'){
    language = 'es';
  }
  const { defaultValue, registerField, error } = useField(fieldValue.name);

  const [value,setvalue] = useState(defaultValue&&defaultValue);

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.value'
      });
    }
  },[ref,registerField]);

  useEffect(()=>{
    setvalue(defaultValue&&defaultValue);
  },[defaultValue]);

  async function handleChange(content){
    setvalue(content);
  }
  return (
    <Container>
      <div>
        <Editor
          placeholder={fieldValue.placeholder}
          initialValue={value}
          init={{
            language,
            height: 48,
            inline: false,
            menubar: false,
            statusbar: false,
            // skin: "realize",
            // content_css : '../../tinymce/skins/content/realize/content.css',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
              `charmap codesample media emoticons autoresize tiny_mce_wiris mathtype-editor`,
              'paste',
            ],
            // PASTE PLUGIN
            paste_auto_cleanup_on_paste : true,
            paste_remove_styles: true,
            paste_remove_styles_if_webkit: true,
            paste_strip_class_attributes: true,
            paste_word_valid_elements: "b,strong,i,em,h1,h2",
            paste_postprocess: function(plugin, args) {
              // console.log(args.node);
              // args.node.setAttribute('id', '42');
              var elements = args.node.querySelectorAll(':scope > *');
              Array.prototype.forEach.call(elements, function(el, i){
                if(el.getAttribute('data-interaction')==='true'){
                  el.parentNode.removeChild(el);
                } else {
                  console.log(el);
                  SanitizeElement(el)
                }
              });
              function SanitizeElement(elx){
                var tag = elx.nodeName.toLowerCase();
                if(tag==='div'){
                  console.log(elx);
                  var p = document.createElement('p');
                  p.textContent = elx.textContent;
                  elx.parentNode.replaceChild(p, elx);
                }
                var attributes = elx.attributes;
                // DELETE CLASS ATTRIBUTE
                if(elx.getAttribute('class')){
                  elx.removeAttribute('class');
                }
                // DELETE ALL ATTRIBUTES EXCEPT...
                if(attributes.length!==0){
                  for (let index = 0; index < attributes.length; index++) {
                    var attribute = attributes[index].name;
                    if(attribute!=='href'&&attribute!=='src'&&attribute!=='alt'&&attribute!=='title'){
                      elx.removeAttribute(attribute);
                    }
                  }
                }
                // SANITIZE CHILDS
                var elementsx = elx.querySelectorAll(':scope > *');
                if(elementsx.length>0){
                  Array.prototype.forEach.call(elementsx, function(elx, i){
                    SanitizeElement(elx);
                  });
                }
              }
              ClearCommentsInsidePaste();
              function ClearCommentsInsidePaste(){
                var allElements = args.node.querySelectorAll(':scope *[data-comments]');
                Array.prototype.forEach.call(allElements, function(el, i){
                  if(el.getAttribute('data-comments')){
                    el.removeAttribute('data-comments');
                  }
                });
              }
            },
            toolbar:
              `styleselect | 
              bold italic link removeformat | 
              alignleft aligncenter alignright alignjustify |
              bullist numlist table |
              charmap add-mathtype | 
              image image-format`,
            contextmenu: "link image imagetools table spellchecker",
            images_upload_handler: function (blobInfo, success, failure) {
              async function LoadImageServer(){
                const data = new FormData();
                var fileName;
                if(typeof(blobInfo.blob().name)!==undefined){
                    fileName = blobInfo.blob().name;
                } else {
                    fileName = blobInfo.filename();
                }
                data.append('file', blobInfo.blob(), fileName);
        
                const response = await api.post('files',data);
                success(response.data.url);
              }
              LoadImageServer();
            },
            formats: {
              legenda: { block: 'h4', attributes: { class: 'T-FIGURA'} },
              fonte: { block: 'p', attributes: { class: 'pequeno'} },
              paragraph: { block: 'p', attributes: { class: ''} },
            },
            style_formats: [
              { title: config.LANG['Subtitle'], format: 'legenda' },
              { title: config.LANG['Source'], format: 'fonte' },
              { title: config.LANG['Paragraph'], format: 'paragraph' },
            ],
            setup: (editor) => {
              editor.ui.registry.addMenuButton('image-format', {
                text: config.LANG['Formatting'],
                icon: 'edit-image',
                fetch: function (callback) {
                  var items = [
                    {
                      type: 'menuitem',
                      text: config.LANG['Subtitle'],
                      onAction: function () {
                        editor.insertContent(`<h4 class="T-FIGURA">${config.LANG['Insert image caption here']}</h4>`);
                      }
                    },
                    {
                      type: 'menuitem',
                      text: config.LANG['Source'],
                      onAction: function () {
                        editor.insertContent(`<p class="pequeno">${config.LANG['Source: Type the source of the image here.']}</p>`);
                      }
                    },
                  ];
                  callback(items);
                }
              });
            },
          }}
          onEditorChange={handleChange}
        />
        <input type="hidden" data-value={value} ref={ref}/>
        {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={value} />)}
        { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
      </div>
    </Container>
  );
}
