import React, {useState,useRef,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useField} from '@rocketseat/unform';

import api from '~/services/api';

import { Container } from './styles';

import user from '~/assets/user.svg';

export default function AvatarInput() {
  const config = useSelector(state=>state.config.value);

  const { defaultValue, registerField, error } = useField('avatar');

  const [file,setFile] = useState(defaultValue&&defaultValue.id);
  const [preview,setPreview] = useState(defaultValue&&defaultValue.url);

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: 'avatar_id',
        ref: ref.current,
        path: 'dataset.file'
      });
    }
  },[ref,registerField]);

  useEffect(()=>{
    setFile(defaultValue&&defaultValue.id);
    setPreview(defaultValue&&defaultValue.url);
  },[defaultValue]);

  async function handleChange(e){
    const data = new FormData();

    data.append('file',e.target.files[0]);

    const response = await api.post('files',data);

    const {id,url} = response.data;

    setFile(id);
    setPreview(url);
  }
  function handleRemove(){
    setFile(null);
    setPreview(null);
  }
  return (
    <Container>
      <div>
        <label htmlFor="avatar">
          <img src={preview||user} alt="aqui é foto ou imagem do avatar"/>
          <input 
            type="file"
            id="avatar"
            accept="image/*"
            data-file={file}
            onChange={handleChange}
            ref={ref}
          />
        </label>
        <div>
          <button type="button" onClick={handleRemove}>{config.LANG['Remove Image']}</button>
          <label htmlFor="avatar">{config.LANG['Choose image']}</label>
        </div>
        {error&& <span className="error">{error}</span> }
      </div>
    </Container>
  );
}
