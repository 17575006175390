import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {parseISO,formatDistance} from 'date-fns';
import pt from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';

import api from '~/services/api';

import {Block} from '~/pages/Dashboard/styles';

import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import TopLoader from '~/assets/loading.gif';

export default function HistoryItem({reload}) {
  const config = useSelector(state=>state.config.value);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  const [update,setUpdate] = useState(true);
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // POSTS
 
  useEffect(() => {
    async function getPosts(){
      try {
        const response = await api.get(`notifications`,{
          params:{
            perPage: 50,
            page:page,
          }
        });
        const options = {addSuffix:true};
        if(config.LANGUAGE==='pt_br') {
          options.locale = pt;
        } else if(config.LANGUAGE==='es_es') {
          options.locale = es;
        }
        const list = response.data.list?.map(notification=>({
          ...notification,
          timeDistance: formatDistance(
            parseISO(notification.createdAt),
            new Date(),
            options
          )
        }));
        setPosts({
          list,
          paged: response.data.paged,
        });
      } catch(err){}
      setTopLoader(false);
    }
    getPosts();
  },[update,reload,config.LANGUAGE,page]);
  
  
  return (
    <>
      {topLoader?(
        <li>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </li>
      ):(<>
        {posts.list?(<>
            {posts.list.map(post=>(
              <li key={post._id}>
                {post.content}
                <br /><span>{post.timeDistance}</span>
              </li>
            ))}
          <Block className="navigation">
            {posts.paged.pages>1&&(
              <nav>
                <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                  <img src={leftArrow} alt={config.LANG['Backward']}/>
                </button>
                <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                  <img src={rightArrow} alt={config.LANG['Forward']}/>
                </button>
              </nav>
            )}
          </Block>
        </>):(<li>{config.LANG['No activity yet.']}</li>)}
      </>)}
    </>
  );
}
