import { Form, Input } from '@rocketseat/unform';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import leftArrow from '~/assets/icons/leftArrow.svg';
import AvatarInput from '~/components/AvatarInput';
import { Container, Title } from '~/components/Container';
import { FormColumn, FormRow, Separator, SubmitRow } from '~/components/InternalForm';
import { updateProfileRequest } from '~/store/modules/user/actions';


export default function Profile() {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile); // PEGA DO REDUX
  const dispatch = useDispatch();
  function handleSubmit(data) {
    if(data.avatar_id===undefined){
      data.avatar_id = null;
    }
    dispatch(updateProfileRequest(data,config));
  }
  // SCHEMAS =====
  const schema = Yup.object().shape({
    name: Yup.string().required(config.LANG['Required field']),
    email: Yup.string().email('Insira um email válido').required(config.LANG['Email is required']),
    oldPassword: Yup.string(),
    password: Yup.string().when('oldPassword',(oldPassword,field)=>
      oldPassword!==''?field.min(6,config.LANG['At least 6 characters']).required(config.LANG['Password is required']):field
    ),
    confirmPassword: Yup.string().when('password',(password,field)=>
      password?field.required(config.LANG['Type the same password above']).oneOf([Yup.ref('password')],'Password is different from the one entered above.'):field
    ),
    avatar_id: Yup.number()
  });
  return (
    <Container>
      <Link to="/dashboard">
        <img src={leftArrow} alt={config.LANG['Backward']}/>
        {config.LANG['Dashboard']}
      </Link>
      <Title>{config.LANG['My Profile']}</Title>
      <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>

        <h2>{config.LANG['My data']}:</h2>
        <FormRow>
          <FormColumn>
            <label htmlFor="name">{config.LANG['Full Name']}:*</label>
            <Input name="name" placeholder={config.LANG['Full Name']} />
            <label htmlFor="email">{config.LANG['Email']}:*</label>
            <Input name="email" type="email" placeholder={config.LANG['Your e-mail address']} />
          </FormColumn>
          <FormColumn>
            <label htmlFor="avatar_id">{config.LANG['Change profile picture']}:</label>
            <AvatarInput name="avatar_id" />
          </FormColumn>
        </FormRow>

        <Separator />

        <h2>{config.LANG['Redefine password']}:</h2>
        <FormRow>
          <FormColumn>
            <label htmlFor="oldPassword">{config.LANG['Current Password']}:</label>
            <Input type="text" autoComplete="off" name="oldPassword" placeholder={config.LANG['Current Password']} />
          </FormColumn>
          <FormColumn>
            <label htmlFor="password">{config.LANG['New password']}:</label>
            <Input type="password" name="password" placeholder={config.LANG['New password']} />
          </FormColumn>
          <FormColumn></FormColumn>
          <FormColumn>
            <label htmlFor="password">{config.LANG['Confirm your password']}:</label>
            <Input type="password" name="confirmPassword" placeholder={config.LANG['Confirm your password']} />
          </FormColumn>
        </FormRow>
        
        <SubmitRow>
          <p>*{config.LANG['Required Fields']}</p>
          <button type="submit">{config.LANG['Update Profile']}</button>
        </SubmitRow>
      </Form>
    </Container>
  );
}
