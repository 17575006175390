import React, {useState,useEffect,useMemo,useCallback} from 'react';
import {useSelector} from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import {toast} from 'react-toastify';

import history from '~/services/history';

import api from '~/services/api';

import Async from '~/services/Async';

import {Container,Canvas,Header,Splitter,Preview,Loading} from '~/components/Container';
import Device from '~/components/Device';
import {LoadRevision} from '~/pages/Units/revisor/styles';

import Multiple from '~/pages/Units/editor/multiple';
import Reload from '~/pages/Units/editor/reload';

import Backups from '~/pages/Units/compare/popup/backups';

import logoWhite from '~/assets/logoWhite.svg';

import Robot from '~/assets/robot.svg';

import Back from '~/pages/Units/editor/back';
import {
  initGenerator,
  style,
  plugins,
  menuGenerator,
} from '~/pages/Units/compare/config';
import {
  DeconstructInteraction,
} from '~/pages/Units/revisor/functions';

export default function UnitRevisor({match:{params:{id:postId}}}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  // START =====
  const [deconstruct,setDeconstruct] = useState(false);
  const [iframe,setIframe] = useState(false);
  const [preview,setPreview] = useState(false);
  const [loaded,setLoaded] = useState(false);
  const [post,setPost] = useState({});
  const [stage,setStage] = useState(null);
  const [interactions,setInteractions] = useState([]);
  useEffect(()=>{
    GetInitState();
    async function GetInitState(){
      setLoaded(false);
      const postPromise = api.get(`units/${postId}`);
      const templatePromise = api.get(`rules/units/${postId}`);
      const editPromise = new Promise(async(resolve,reject) => {
        try {
          const response = await api.get(`edits/units/${postId}`);
          resolve(response);
        } catch(err){
          resolve(false);
        }
      });
      Promise.all([
        postPromise,
        templatePromise,
        editPromise,
      ]).then(async(values) => {
        const [
          {data:postData},
          {data:templateData},
          editData,
        ] = values;
        setPost(postData.unit);
        if(editData!==false){
          setContent(editData.data.html);
          setIframe(true);
          setPreview(editData.data.preview_url);
        } else {
          toast.error('Você deve ter algum conteúdo salvo para usar o modo comparação.');
          history.push(`/units/${postId}/create`);
        }
        const {path} = templateData;
        const response = await api.get(`interactions/`,{params:{template:path,unit_id:postId}});
        setInteractions(response.data);
        setTimeout(() => {
          setLoaded(true);
        }, 600);
      });
    }
  },[postId,config]);
  // CONFIG =====
  const [content,setContent] = useState(``);
  const init = useMemo(()=>initGenerator(config),[config]);
  const menu = useMemo(()=>menuGenerator(config,profile),[config,profile]);
  const setup = {
    setup: (editor) => {
      setStage(editor);
      editor.ui.registry.addMenuItem('editor', {
        icon: 'edit-block', // spell-check
        text: config.LANG['Editor'],
        onAction: function () {
          history.push(`/units/${postId}/create`);
        }
      });
      editor.ui.registry.addMenuItem('revisor', {
        icon: 'spell-check',
        text: config.LANG['Spelling'],
        onAction: function () {
          history.push(`/units/${postId}/revise`);
        }
      });
      editor.ui.registry.addMenuItem('compare', {
        icon: 'settings',
        text: 'Comparação',
        onAction: function () {
          history.push(`/units/${postId}/compare`);
        }
      });
      editor.ui.registry.addButton('compare-to', {
        icon: 'restore-draft',
        text: 'Comparar com...',
        onAction: function () {
          handleOpenBackup();
        },
      });
      editor.on(`keydown`, async function(args){
        args.preventDefault();
      });
    }, // https://www.tiny.cloud/docs/advanced/events/
    init_instance_callback : function(editor) {
      // console.log("Editor: " + editor.id + " is now initialized.");
      // console.log('editor.ui.registry.getAll(',editor.ui.registry.getAll());
      // for (var key in editor.ui.registry.getAll().buttons) {
      //   var button = editor.ui.registry.getAll().buttons[key];
      //   console.log(button);
      // }
    },
  };
  // RELOAD =====
  const handleReload = useCallback(async()=>{
    const backupPreview = `${preview}`;
    setPreview('');
    if(preview===false){
      const response = await api.get(`edits/units/${postId}`);
      setPreview(response.data.preview_url);
    } else {
      setTimeout(() => {
        setPreview(backupPreview);
      }, 100);
    }
  },[postId,preview]);
  // UPDATE CONTENT ON EDITOR =====
  const handleEditorChange = useCallback(async(editorValue,editor)=>{
    setContent(editorValue);
    if(!deconstruct) {
      setDeconstruct(true);
      await Async(res=>DeconstructInteraction(config,editor,interactions,res));
    }
  },[deconstruct,interactions,config]);
  const handleDeconstructInteraction = useCallback(async(res)=>{
    if(stage) DeconstructInteraction(config,stage,interactions,res)
  },[stage,interactions,config]);
  // BACKUPS =====
  const [openBackup,setOpenBackup] = useState(false);
  const handleOpenBackup = useCallback(()=>setOpenBackup(!openBackup),[openBackup]);
  // LOAD =====
  const [loadCompare,setLoadCompare] = useState(false);
  const handleLoadCompare = useCallback((loadValue)=>setLoadCompare(loadValue),[]);
  // DEVICE =====
  const [device,setDevice] = useState('0.5');
  useEffect(()=>{
    const viewEditorSize = localStorage.getItem('@RealizeSystem-viewEditorSize');
    if(viewEditorSize) setDevice(viewEditorSize);
  },[]);
  const handleDevice = useCallback((data)=>{
    setDevice(data);
    localStorage.setItem('@RealizeSystem-viewEditorSize',data);
  },[]);
  return (
    <>
    <Loading fade={loaded}>
      <img src={logoWhite} alt="Realize"/>
      <h1>{config.LANG['Loading']}...</h1>
    </Loading>
    {loaded&&(
      <>
        <Canvas saving={false}>
          <Header>
            <div className="middleWord">
              <div>
                <Back postId={postId} notSaved={false} />
              </div>
            </div>
            <div className="middleWord">
              <Device device={device} callback={handleDevice} />
              <Multiple postId={postId} post={post} handleReload={handleReload} />
              <Reload iframe={iframe} handleReload={handleReload} />
            </div>
          </Header>
          <Splitter>
            <LoadRevision BehindModal Loading={!loadCompare}>
              <p>
                <img className="toploader" src={Robot} alt=""/><br/>
                <strong>Comparador Inteligente</strong><br/>
                Buscando diferenças
              </p>
            </LoadRevision>
            <Container device={device} isEditor={true} className={!post.available&&`unavailable`}>
              <Editor
                initialValue={content}
                init={{
                  ...init,
                  ...style,
                  ...plugins,
                  ...menu,
                  ...setup
                }}
                onEditorChange={handleEditorChange}
              />
            </Container>
            <Preview device={device}>
              {iframe?(
                <iframe title="preview-compare" src={preview}></iframe>
              ):(
                <div>{config.LANG['Save to view your work']}.</div>
              )}
            </Preview>
          </Splitter>
        </Canvas>
        <Backups Open={openBackup} Close={handleOpenBackup} editor={stage} postId={postId} Load={handleLoadCompare} loadCompare={loadCompare} handleDeconstructInteraction={handleDeconstructInteraction} />
      </>
    )}
    </>
  );
}