import styled from 'styled-components';
import {lighten} from 'polished';

import {$} from '~/styles/global';

export const Backups = styled.div`
  h1 {
    margin-bottom: 30px;
    & + p {
      margin-top: -10px;
      margin-bottom: 20px;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    > div {
      padding: 5px 10px;
      margin: 5px;
      border: 1px dashed ${lighten(0.5,$.colorC)};

      display: flex;
      align-items: center;
      justify-content: space-between;

      &.navigation {
        box-sizing: border-box;
        border: none;
        width: 100%;
        padding: 0;
        nav {
          width: 100%;
          button {
            background: transparent;
            color: ${$.colorText($)};
            font-weight: ${$.bold};
            &.this {
              cursor: default;
              opacity: 0.7;
            }
          }
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      h3 {
        margin: 10px;
        color: ${$.colorD};
      }
      img.user {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #eee;
        margin-right: 10px;
      }
      button {
        color: ${$.white};
        background: ${$.colorB};
        border: none;
        padding: 5px 10px;
        border-radius: 5px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;