import React, {useState,useEffect,useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { Share} from '~/components/InternalForm';
import api from '~/services/api';
import {Downloads} from '~/pages/Units/styles';
import {Block} from '~/pages/Dashboard/styles';
import { FiCheckCircle } from 'react-icons/fi';
import reload from '~/assets/icons/reload.svg';
import download from '~/assets/icons/download.svg';
import TopLoader from '~/assets/loading.gif';
import {convertedUpdateEnd } from 'store/modules/converter/actions';

export default function Medias({postId,update}) {
  const config = useSelector(state => state.config.value);
  const socket = useSelector(state => state.socket.socket);
  const convertion = useSelector(state => state.convertion);
  const dispatch = useDispatch();
  const [topLoader,setTopLoader] = useState(true);
  const [converted,setConverted] = useState(null);
  const [medias,setMedias] = useState([{}]);
  const [exportUrl, setExportUrl] = useState('');
  const [sw3Link, setSw3Link] = useState('empty');
  const [feedback, setFeedback] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [convertionLoad, setConventionLoad] = useState(true);

  useEffect(() => {
    api.get(`rules/units/${postId}`).then(responseT => {
      api.get(`medias`, {
        params: {
          template: responseT.data.path,
        }
      }).then(response => {
        setMedias(response.data);
      });
    });
    
    try {
      api.get(`convertions/single/units/${postId}`).then(responseC => {
        setConverted(responseC.data);
        setExportUrl(responseC.data.url);
      });
      api.get(`units/${postId}`).then(response => {
        setSw3Link(response.data.unitSettings.s3_url);
      })
    } catch (err) {
    }
    setTopLoader(false);
  }, [convertionLoad,postId,update]);

  useEffect(() => {
    if (socket) {
      socket.on('convert_ended', data => {
          setTimeout(() => {
            setConventionLoad(false);   
            const announceConvertion = false;
            dispatch(convertedUpdateEnd(announceConvertion));
          }, 500);
      });  
    }
  }, [socket]);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(false);
      setTimeout(() => {
        setFeedback(false);
      }, 200);
    }, 4000);
  },[feedback])
 
  const handleCopy = useCallback((type) => {
    setAnimation(true)
    setFeedback(true);
    var copyText = document.getElementById('container-copy-link-s3');
    if(type==='password') copyText = document.getElementById("passworder");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("Copy");
  },[]);

  return (<>
    <Block Little>
      {topLoader?(
        <>
          <h2>{config.LANG['Downloads']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
            <h2>{config.LANG['Downloads']}</h2>
          <Downloads>
              {convertion.announceConvertion === true ?
                  (<li>
                    <p className="Convertion">
                      <img className={convertion.announceConvertion && "rotationAnimation"} src={reload} alt={config.LANG['Conversions']} />
                    </p>
                    <p>{config.LANG['The conversion may take a long time to complete']}</p>
                </li>) :
              <>
                {converted ? (
                  <>
                    <li><p className="Convertion"><img src={reload} alt={config.LANG['Conversions']} /></p></li>
                    {medias.map((media,index)=>(
                      <li key={index}>
                        <a href={exportUrl+media.url} target="_blank" rel="noopener noreferrer">
                          <img src={download} alt={config.LANG['Download']} />
                          {media.name}
                        </a>
                      </li>
                    ))}
                      {sw3Link !== undefined &&
                        <li>
                          <Share>
                            <div className="link">
                              <label>Link de embed para compartilhamento</label>
                              <div className="feedbak">
                              <input id="container-copy-link-s3" type="text" value={sw3Link}/>
                                <button id="copy-link-s3" type="button" onClick={()=>{handleCopy('link');}} >{config.LANG['Copy']}</button>
                              </div>
                            {feedback  &&
                              <span className={`copied-link ${feedback === true && 'slideLeft'} ${animation === false && 'fadesAwayslideLeft'}`}>
                              <FiCheckCircle size={20} />  
                              Link copiado</span>}
                            </div>
                          </Share>
                        </li>
                      }
                    <li><p>{config.LANG['Download the created conversion']}.</p></li>
                  </>)
                    :
                  <li>
                      <p className="Convertion">
                        <img src={reload} alt={config.LANG['Conversions']} />
                      </p>
                      <p>{config.LANG['No conversions found!']}</p>
                      <p>{config.LANG['Convert this unit to download']}.</p>
                    </li>}
              </>
            }    
          </Downloads>
        </>
      )}
    </Block>
  </>);
}