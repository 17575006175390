import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';

import api from '~/services/api';

import {Row,Block,List,Item,Perfil} from '~/pages/Dashboard/styles';

import TopLoader from '~/assets/loading.gif';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import search from '~/assets/icons/search.svg';

export default function Import({postsId,callBack}) {
  const config = useSelector(state=>state.config.value);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  const [page,setPage] = useState(1);
  const [searched,setSearched] = useState(null);
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  // PAGE
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }
  // POSTS
  
  // useEffect(()=>{
  //   getPosts();
  // }, [update]);
  
  useEffect(() => {
    async function getPosts(){
      const response = await api.get(`extras/${postsId}/units`,{
        params:{
          perPage: 5,
          page:page,
          search: searched,
        }
      });
      setPosts(response.data);
      setTopLoader(false);
    }
    getPosts();
  }, [page,searched,postsId]);
 
  return (
    <Row>
      {topLoader?(
        <>
          <Block Full Lefter>
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          </Block>
        </>
      ):(
        <>
          <Block Full Lefter>
            
            <div>
              <h2>{config.LANG['Units']} ({posts.paged.totalCount})</h2>
              <Form onSubmit={handleSearch}>
                <Input name="search" placeholder={`${config.LANG['Search']}…`} />
                <button type="submit">
                  <img src={search} alt={config.LANG['Search']} />
                </button>
              </Form>
            </div>
              {posts.list[0]?(
                <List>
                  {posts.list.map(unit=>(
                    <Item key={unit.id} color={unit.subject.area.color}>
                      <Perfil Clear>
                        <Link to={`/units/${unit.id}/view`}>{unit.name}</Link>
                        <button type="button" onClick={()=>callBack(unit)}>{config.LANG['Import']}</button>
                      </Perfil>
                    </Item>
                  ))}
                </List>
              ):(
                <p>{config.LANG['No units found!']}</p>
              )}
            {posts.paged.pages>1&&(
              <nav>
                <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                  <img src={leftArrow} alt={config.LANG['Backward']} />
                </button>
                <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                  <img src={rightArrow} alt={config.LANG['Forward']} />
                </button>
              </nav>
            )}
          </Block>
        </>
      )}
    </Row>
  );
}
