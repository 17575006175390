import React from 'react';
import PropTypes from 'prop-types';
import {Route,Redirect} from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';
import EmptyLayout from '~/pages/_layouts/empty';

import Init from '~/components/Init';

import {store} from '~/store';

export default function RouteWrapper({
  component: Component,
  isShare,
  isPrivate,
  isEmpty,
  level,
  ...rest
}){
  // return (
  //   <Route {...rest} render={props=>(
  //     <ClosedLayout/>
  //   )} />
  // );

  const {signed} = store.getState().auth;

  if(!isShare){

    if(!signed&&isPrivate){
      return <Redirect to="/" />;
    }

    if(signed&&!isPrivate){
      return <Redirect to="/dashboard" />;
    }

    if(signed&&isPrivate){
      const {profile:{role}} = store.getState().user;
      if(level!==undefined&&role.level>level){
        return <Redirect to="/dashboard" />;
      }
    }
    
  }

  var Layout = signed ? DefaultLayout : AuthLayout;

  if(isEmpty){ Layout = EmptyLayout; }

  return (<>
    <Route {...rest} render={props=>(
      <Init {...rest}>
        <Layout>
          <Component {...props} />
        </Layout>
      </Init>
    )} />
  </>);
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  Component: PropTypes.oneOfType([PropTypes.element,PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  Component: <div />
};