import React, {useState,useRef,useEffect,Fragment,useCallback} from 'react';
import { useSelector } from 'react-redux';
import {Input,useField} from '@rocketseat/unform';
import downArrow from '~/assets/icons/downArrow.svg';
import UsersImg from '~/assets/user.svg'
import {FiX} from 'react-icons/fi';
import { Container,SelectContainer,ContentSelect,AvatarImg } from './styles';


export default function SelectCategory(fieldValue) {
  const config = useSelector(state=>state.config.value);
  const { defaultValue, registerField, error } = useField(fieldValue.name);
  const [valuesSelect, setValuesSelect] = useState(defaultValue&&defaultValue);

  const ref = useRef();

  useEffect(()=>{
    // console.log(fieldValue)
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.value'
      });
    }
  },[ref,registerField,fieldValue.name]);

  useEffect(()=>{
    setValuesSelect(defaultValue&&defaultValue);
  },[defaultValue]);

  // CLICK OUTSIDE
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // OPEN
  const [open,setOpen] = useState(false);

  const handleChange = useCallback((option) => {
    const fieldsValues = fieldValue.options;
    const isArray = Array.isArray(valuesSelect);
    if(isArray){
      setValuesSelect([...valuesSelect,{
        text: option.text,
        jobs: option.jobs,
        value: option.value,
        avatar_url: option.avatar_url
      }]);
    } else {
      setValuesSelect([{
        text: option.text,
        jobs: option.jobs,
        value: option.value,
        avatar_url: option.avatar_url
      }]);
    }
    let selectedFound = fieldsValues.find(function(item,_){
      return item.value === option.value;
    });
    fieldsValues.splice(fieldsValues.indexOf(selectedFound),1);
  },[valuesSelect,fieldValue.options]);
  const handleOpen = useCallback(() => {
    setOpen(!open);
  },[open])
  const handleDelete  = useCallback((value) => {   
    const response = valuesSelect.find((item,index) => {
      return item.value === value.value;
    })
    valuesSelect.splice(valuesSelect.indexOf(response),1);
    fieldValue.options.push({
      text: value.text,
      jobs: value.jobs,
      value: value.value,
      avatar_url: value.avatar_url,
    });
    setValuesSelect([...valuesSelect]);
  },[valuesSelect,fieldValue.options]);
 return (<>
    <Container Open={open} ref={wrapperRef} >
      <div>
        <button type="button" onClick={handleOpen}>
          <p>
            {config.LANG['SelectUsers']}
          </p> 
          <img src={downArrow} alt="Mais"/>
        </button> 
        <ul>
          {fieldValue.options.map(option=>(
          <li key={option.value}>
            <button type="button" onClick={()=>{handleChange(option);handleOpen();}}>
              <AvatarImg src={option.avatar_url ? option.avatar_url : UsersImg} alt="Foto do usuario"/>
                <p>
                  {option.text} 
                  <br/> 
                  <i>{option.jobs}</i>
                </p>
            </button>
          </li>))}
        </ul>
      </div>
    </Container>
    <div>
      <input type="hidden" data-value={JSON.stringify(valuesSelect)} ref={ref}/>
      {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={valuesSelect} />)}
      { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
    </div>
    <SelectContainer>
     {valuesSelect?.map((item,index) => (
        <Fragment key={index}>
          {item &&
            <ContentSelect>
              <p>
                {item.text}
              </p>
              <button type="button" onClick={() => handleDelete(item)}>
                <FiX size={16} />
              </button>
            </ContentSelect>
          } 
        </Fragment>
      ))}
    </SelectContainer>
  </>);
}