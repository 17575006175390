import React,{useState,useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import history from '~/services/history';

import {FormRow,FormColumn} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';

export default function Back({postId,notSaved}) {
  const config = useSelector(state=>state.config.value);
  // STATE =====
  const [openBack,setOpenBack] = useState(false);
  // CALLBACK =====
  const handleOpenBack = useCallback(()=>setOpenBack(!openBack),[openBack]);
  const checkReturn = useCallback(async(e)=>{
    if(notSaved) {
      e.preventDefault();
      handleOpenBack();
    }
  },[notSaved,handleOpenBack]);
  const handleBack = useCallback(async(e)=>{
    history.push(`/units/${postId}/view`);
  },[postId]);
  return(<>
    <Link to={`/units/${postId}/view`} onClick={checkReturn}>
      <img src={leftArrow} alt={config.LANG['Backward']} />
      {config.LANG['Backward']}
    </Link>
    <PopUp Tiny Open={openBack} CallBack={handleOpenBack}>
      <button type="button" onClick={handleOpenBack}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>Conteúdo não salvo</h1>
      <h2>Sair sem salvar?</h2>
      <p>Você tem certeza que deseja sair sem salvar?</p><br/>
      <p>Esse conteúdo poderá ser perdido e não será possível recuperá-lo posteriormente.</p>
      <br/><br/>
      <FormRow>
        <FormColumn>
          <button className="add" type="button" onClick={handleBack}>{config.LANG['YES']}</button>
        </FormColumn>
        <FormColumn>
          <button className="delete" type="button" onClick={handleOpenBack}>{config.LANG['NO']}</button>
        </FormColumn>
      </FormRow>
    </PopUp>
  </>);
}