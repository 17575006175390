/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form} from '@rocketseat/unform';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import api from '~/services/api';

import {Container,Title,Divider} from '~/components/Container';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import Fields from '~/pages/Units/editor/fields';

import Import from '~/pages/Units/extras/import';

import close from '~/assets/icons/close.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import TopLoader from '~/assets/loading.gif';

const LOAD_DALEY = 1800;


export default function ZEditUnit({ match: { params: { id: postId } } }) {
  // console.log("postId", postId);
  const config = useSelector(state=>state.config.value);
  const [topLoader,setTopLoader] = useState(true);
  const [post,setPost] = useState({});
  // INIT
  const [schema,setSchema] = useState(Yup.object().shape({}));
  const [fields,setFields] = useState(false);
  const [extra,setExtra] = useState(false);
  const [editingData,setEditingData] = useState({});
  const [template, setTemplate] = useState(true);
  // const [actual, setActual] = useState('');
  const [unitTemplate, setUnitTemplate] = useState('');
  const [unitExtra, setUnitExtra] = useState('');
  const [extraIsTemplate, setIsTemplate] = useState('');

  // recriando get unit details, such as projectId/areaId/subjectId
  // recriando get template default

  useEffect(() => {
    async function getUnitDetails() {
        const {data:{unit}} = await api.get(`units/${postId}`);
        setPost(unit);
        const {data} = await api.get(`rules/units/${postId}`);
        setTemplate(data);
    }
    getUnitDetails();
  }, [postId]);

  // recriando get template extras

  useEffect(() => {
    async function getTemplateExtras(){
        const {data:{id:extraId,value_json,unit}} = await api.get(`/extras/custom/bylevel`, {
            params: {
                unit_id: postId,
                template: template.path,
            }
        });
        setUnitExtra(unit);
        setExtra(extraId);
        setEditingData(value_json);
        setIsTemplate(true);
        const {data:{field:fields}} = await api.get(`extras`,{
            params: {
                template: template.path,
            }
        });
        setFields(fields);
        const thisSchema = {};
        fields.map(field => {
            thisSchema[field.name] = readSchema(field);
            if(field.type==='multiple'){
                thisSchema[`${field.name}-shadow`] = readSchema({...field,name:`${field.name}-shadow`,field:[{name:'available',required:false}],required:false});
            }
        });
        setSchema(Yup.object().shape(thisSchema));
        setTopLoader(false);
    }
    if(template!=='') getTemplateExtras();
  }, [template, postId]);

  /*

  useEffect(() => {
    api.get(`rules/units/${postId}`).then(response => {
      // setActual(response.data);
      setTemplate(response.data);
    });
    api.get(`units/${postId}`).then(response => {
      setPost(response.data.unit)
    });
  }, [postId]);
  
  useEffect(() => {
    async function getInit() {
      try {
        const responseUnit = await api.get('/extras/custom/bylevel', {
          params: {
            unit_id: postId,
          }
        });
        setUnitExtra(responseUnit.data.unit);
        setUnitTemplate(responseUnit.data.template)
        setExtra(responseUnit.data.id);
        setEditingData(responseUnit.data.value_json);
        setIsTemplate(true);
      } catch (error) {
      }
    }
    getInit();
  }, [postId]);

  useEffect(() => {
    setTimeout(() => {
      api.get(`extras`,{
        params: {
          template: template.path,
          }
      }).then(response => {
        if (unitTemplate === true) {
          const dataProcess = ProcessData(response.data.field);
          setEditingData(dataProcess);
        }
        setFields(response.data.field);
        
        const thisSchema = {};
          response.data.field.map(field => {
            thisSchema[field.name] = readSchema(field);
            if(field.type==='multiple'){
              thisSchema[`${field.name}-shadow`] = readSchema({...field,name:`${field.name}-shadow`,field:[{name:'available',required:false}],required:false});
            }
          });
          setSchema(Yup.object().shape(thisSchema));
      });  
    }, 500);
    setTimeout(() => {
      setTopLoader(false);
    }, LOAD_DALEY);
  }, [template, extraIsTemplate,unitTemplate, postId]);

  */

  function readSchema(field){
    if(field.type==='multiple'){
      const multipleSchema = {};
      field.field.map(multipleField=>{
        multipleSchema[multipleField.name] = readSchema(multipleField);
        // if(multipleField.type==='multiple'){
        //   multipleSchema[`${multipleField.name}-shadow`] = readSchema({...multipleField,name:`${multipleField.name}-shadow`,field:[{name:'available',required:false}],required:false});
        // }
      });
      if(field.required){
        return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
          Yup.object().shape(multipleSchema)
        ).required(config.LANG['Required field']);
      } else {
        return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
          Yup.object().shape(multipleSchema)
        );
      }
    } else if(field.required){
      return Yup.string().required(config.LANG['Required field']);
    } else {
      return Yup.string();
    }
  }
  /*function ProcessData(array){
    var object = {};
    for (let i in array) {
      let item = array[i];
      if(item.type==='multiple'){
        object[item.name] = ProcessData(item.field);
      } else {
        object[item.name] = item.default;
      }
    }
    return object;
  }*/
  async function handleSubmit(data) {
    try {
      await api.post(`rules/single/units/${postId}`, {
        template_id: template.id
      });
      if (unitExtra === false) {
        await api.post(`/extras/custom/bylevel`, {
          unit_id: postId,
          template_id: template.id,
          value_json: data
      });

      } else {

        await api.put(`extras/${extra}`,{
          value_json: data,
        });

      }

      toast.success(config.LANG['Custom unit successfully!']);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }

  // IMPORT
  const [openImport,setOpenImport] = useState(false);
  function handleOpenImport(){
    setOpenImport(!openImport);
  }
  async function handleAddImport(unit){
    setEditingData(unit.extra.value);
    handleOpenImport();
  }
  return (
    <>
      {topLoader?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </Container>
      ):(
        <Container className={!post.available&&`unavailable`}>
          <Link to={`/units/${postId}/view`}>
            <img src={leftArrow} alt={config.LANG['Backward']} />
            {config.LANG['Unity']}
          </Link>
          <Divider>
            <Title>{config.LANG['Customize Unit']}</Title>

            <div>
              <button type="submit" onClick={handleOpenImport}>{config.LANG['Import']}</button>
            </div>
          </Divider>
          <h2>{config.LANG['Current theme options']}</h2>
          <Form schema={schema} initialData={editingData} onSubmit={handleSubmit}>
            <FormRow>
              {fields&&fields.map((field,key)=>
                <FormColumn key={field.name} Line={(
                    (fields.length===1)
                    ||(fields.length%2!==0&&key===fields.length-1)
                    ||field.type==='file'
                  )||field.full}>
                  <Fields field={field} editingData={editingData} />
                </FormColumn>
              )}
            </FormRow>
            
            <SubmitRow>
              <p>*{config.LANG['Required Fields']}</p>
              <div>
                <button type="submit">{config.LANG['Update']}</button>
              </div>
            </SubmitRow>
          </Form>
          <PopUp Open={openImport} CallBack={handleOpenImport}>
            <button type="button" onClick={handleOpenImport}>
              <img src={close} alt={config.LANG['Close']}/>
            </button>
            <h1>{config.LANG['Import Customization']}</h1>
            <br/><br/>
            <p>{config.LANG['Import Customization from another Unit']}</p>
            <Import postsId={template.id} callBack={handleAddImport} />
            <br/><br/>
          </PopUp>
        </Container>
      )}
    </>
  );
}
