import React from 'react';
import {useSelector} from 'react-redux';

import reloadImg from '~/assets/icons/reload.svg';

export default function Reload({iframe,handleReload}){
  const config = useSelector(state=>state.config.value);
  return(<>
    {iframe&&(
      <button className="reload" type="button" onClick={handleReload}>
        <img src={reloadImg} alt=""/>
        {config.LANG['Reload']}
      </button>
    )}
  </>);
}