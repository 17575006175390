import React, {useState,useEffect,useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import api from '~/services/api';

import {Container,Title} from '~/components/Container';
import {ListHeader,ListFilter,ListTable,ListPage,ListNone,ItemList} from '~/components/InternalList';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import add from '~/assets/icons/add.svg';
import search from '~/assets/icons/search.svg';
import grid from '~/assets/icons/grid.svg';
import list from '~/assets/icons/list.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

import More from '~/pages/Procedures/index/more';

import ObjectSize from '~/util/ObjectSize';

const schemaCreate = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  order: Yup.string().required('A ordem é obrigatória')
});

export default function Procedures() {
  const url = `procedures`;
  const filterStart = [
    { href: 'date', url: `${url}`, available: true, order: 'order', ordernate: 'ASC', text: 'DATA', class: 'selected'},
    { href: 'asc', url: `${url}`, available: true, order: 'name', ordernate: 'ASC', text: 'A—Z', class: null},
    { href: 'desc', url: `${url}`, available: true, order: 'name', ordernate: 'DESC', text: 'Z—A', class: null},
    { href: 'trash', url: `${url}`, available: false, order: 'id', ordernate: 'DESC', text: 'LIXEIRA', class: null}  
  ];

  const profile = useSelector(state => state.user.profile);

  const [topLoader,setTopLoader] = useState(true);
  const [searched,setSearched] = useState('');
  const [searchFinal,setSearchedFinal] = useState(null);
  const [page,setPage] = useState(1);
  const [filters,setFilters] = useState(filterStart);
  const [views, setViews] = useState([
    {'href':'grid','src':grid,'text':'Bloco','class':null},
    {'href':'list','src':list,'text':'Lista','class':'choosed'}
  ]);
  const [isBlock,setIsBlock] = useState(false);
  
  async function handlePage(i) {
    setPage(i);
    setUpdate(!update);
  }

  async function handleSearch({ search }) {
    setSearchedFinal(search);
    setSearched(search);
    handlePage(1);
  }
  function handleSearchChanges(e){
    setSearchedFinal(e.target.value);
  }
  // FILTERS
  

  async function handleFilter(e,href){
    e&&e.preventDefault();
    const newFilters = filters.map(filter=>{
      if(filter.href===href){
        filter.class='selected';
      } else {
        filter.class=null;
      }
      return filter;
    });
    setFilters(newFilters);
    handleSearch({search:''});
  }
  // VIEW
  useEffect(()=>{
    async function GetView(){
      var viewFilter = localStorage.getItem('viewFilter');
      if(viewFilter) handleView({preventDefault:()=>true},viewFilter);
    }
    GetView();
  },[]);
  
  function handleView(e,href){
    e.preventDefault();
    localStorage.setItem('viewFilter',href);
    const newViews = views.map(view=>{
      if(view.href===href){
        view.class='choosed';
      } else {
        view.class=null;
      }
      return view;
    });
    setViews(newViews);
    setIsBlock((href==='grid')?true:false);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  
  const filter = filters.find(function(item,index){
    return item['class']==='selected';
  });

  useEffect(() => {
    async function getPosts() {
      setChecked({});
      setDeleteAll(false);
      const response = await api.get(filter.url,{
        params:{
          order: filter.order,
          ordernate: filter.ordernate,
          perPage: 10,
          page:page,
          search: searched,
          project_id: 0,
          available: filter.available
        }
      });
      setPosts(response.data);
      setTopLoader(false);
    }
    getPosts();
  },[update,filter.url,page,searched,filter.available,filter.ordernate,filter.order]);
  
  
  const pageArray = useMemo(() => {
    const thisArray = [];
    for (let i = 1; i <= posts.paged.pages; i++) {
      thisArray.push(i);
    }
    return thisArray;
  },[posts]);
  // CREATE
  const [openCreate,setOpenCreate] = useState(false);
  function handleOpenCreate(){
    setOpenCreate(!openCreate);
  }
  async function handleAdd(data){
    try {
      const reply = await api.post(`/${url}`,data);

      handleFilter(null,'date');

      handleOpenCreate();

      toast.success(`Procedimento ${reply.data.name} criado com sucesso!`);
    } catch(err){
      toast.error('Informações incorretas. Tente novamente.');
    }
  }
  // CHECK
  const [checked,setChecked] = useState({});
  function handleChecked(u){
    const newChecked = checked;
    if(checked[u]){
      delete newChecked[u];
    } else {
      newChecked[u] = true;
    }
    var countChecked = 0;
    for (const key in newChecked) {
      key==='essa função é'&&console.log('sem utilidade');
      countChecked++;
    }
    if(countChecked!==0){
      setDeleteAll(true);
    } else {
      setDeleteAll(false);
    }
    setChecked({...newChecked});
  }
  function handleCheckAll(conditional){
    if(conditional){
      setChecked({});
      setDeleteAll(false);
    } else {
      if(posts.list[0]) {
        const newChecked = {};
        for (const key in posts.list) {
          newChecked[posts.list[key].id] = true;
        }
        setChecked({...newChecked});
        setDeleteAll(true);
      }
    }
  }
  // DELETE ALL
  const [deleteAll,setDeleteAll] = useState(false);
  const [openDelete,setOpenDelete] = useState(false);
  function handleOpenDelete(){
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    const updatingItems = [];
    for (const key in checked) { updatingItems.push(Number(key)); }
    try {
      await api.put(`${url}/bulk`,{
        ids: updatingItems,
        available: false,
      });
      toast.success(`Procedimentos deletados com sucesso!`);
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  async function handleRecover(){
    setPosts({
      list: [],
      paged: {}
    });
    const updatingItems = [];
    for (const key in checked) { updatingItems.push(Number(key)); }
    try {
      await api.put(`${url}/bulk`,{
        ids: updatingItems,
        available: true,
      });
      toast.success(`Procedimentos recuperados com sucesso!`);
    } catch(err){
      toast.error('Algum erro ocorreu. Tente novamente.');
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  // DELETE SINGLE
  const [deleteSingle,setDeleteSingle] = useState(false);
  const [openDeleteSingle,setOpenDeleteSingle] = useState(false);
  function handleOpenDeleteSingle(){
    setOpenDeleteSingle(!openDeleteSingle);
  }
  async function handleDeleteSingle(){
    try {
      await api.delete(`/${url}/${deleteSingle.id}`);
      toast.success(`Procedimento deletado com sucesso!`);
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  async function handleRecoverSingle(){
    try {
      const thisPost = posts.list.find(u=>u.id===parseInt(deleteSingle.id));
      await api.put(`/${url}/${deleteSingle.id}`,{
        available: true,
        role_id: thisPost.role_id
      });
      toast.success(`Procedimento recuperado com sucesso!`);
    } catch(err){
      toast.error('Algum erro ocorreu. Tente novamente.');
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  function handleCallbackMore({type,payload}){
    if(type==='delete'){
      setDeleteSingle(payload.post);
      handleOpenDeleteSingle();
    }
  }
  // RENDER
    return (
      <>
      {topLoader?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt="Carregando..."/>
          </p>
        </Container>
      ):(
          <Container>
            <Link to="/dashboard">
              <img src={leftArrow} alt="Voltar"/>
              Painel
            </Link>
            <Title>Procedimentos <span>({posts.paged.totalCount})</span></Title>

            <ListHeader>
              <div>
                {(profile&&profile.role.level<=1)&&(<>
                  <button className="icon add" type="button" onClick={handleOpenCreate}>
                    <img src={add} alt="Adicionar"/>
                    Adicionar Procedimento
                  </button>
                  {deleteAll&&<button className="outline delete" type="button" onClick={handleOpenDelete}>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?'Deletar'
                        :'Recuperar'
                    }
                  </button>}
                </>)}
              </div>
              <Form onSubmit={handleSearch}>
                <Input onChange={handleSearchChanges} name="search" placeholder="Pesquisar…" value={searchFinal} />
                <button type="submit">
                  <img src={search} alt="Buscar"/>
                </button>
              </Form>
            </ListHeader>

            <ListFilter>
              <div>
                {filters.map(filter=>(
                  <a key={filter.href} href={filter.href} className={filter.class||'none'} onClick={(e)=>handleFilter(e,filter.href)}>{filter.text}</a>
                ))}
              </div>
              <div>
                {views.map(view=>(
                  <a key={view.href} href={view.href} className={view.class||'none'} onClick={(e)=>handleView(e,view.href)}><img src={view.src} alt={view.text}/></a>
                ))}
              </div>
            </ListFilter>

            
            {posts.list[0]?(
              <ListTable isBlock={isBlock}>
                <thead>
                  <tr>
                    <th>
                      {(profile&&profile.role.level<=1)&&(<>
                        <button className={`checker${(ObjectSize(checked)===posts.list.length)?` selected`:''}`} type="button" onClick={()=>handleCheckAll(ObjectSize(checked)===posts.list.length)}></button>
                      </>)}
                    </th>
                    <th>
                      <h3>Procedimento</h3>
                    </th>
                    <th>
                      <h3 align="center">Ordem</h3>
                    </th>
                    <th>
                      <h3 align="center">Acesso</h3>
                    </th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {posts.list.map(p=>(
                    <>
                    {p.project_id === null ? 
                    
                      <ItemList order={'procedures'} colorHex={p.color} isBlock={isBlock} key={p.id}>
                        <td>
                          {(profile&&profile.role.level<=1)&&(<>
                            <button className={`checker${checked[p.id]?` selected`:''}`} type="button" onClick={()=>handleChecked(p.id)}></button>
                          </>)}
                        </td>
                        <td>
                          <p><strong>{p.name}</strong></p>
                        </td>
                        <td>
                          <p align="center">{p.order}</p>
                        </td>
                        <td>
                          <p align="center">{p.access?'Sim':'Não'}</p>
                        </td>
                        <td className="more">
                          {(profile&&profile.role.level<=1)&&(<>
                            <More post={p} callback={handleCallbackMore} />
                          </>)}
                        </td>
                      </ItemList>
                    : ''}
                    </>
                  ))}
                </tbody>
              </ListTable>
            ):(
              <ListNone>Nenhum procedimento encontrada!</ListNone>
            )}

            {posts.paged.pages>1&&(
              <ListPage data-totalpage={posts.paged.pages} data-page={posts.paged.page}>
                <div>
                  <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>handlePage(1)}>
                    <img src={leftArrow} alt="Voltar Página"/>
                    <img src={leftArrow} alt="Voltar Página"/>
                  </button>

                  <button className={posts.paged.page!==1?null:'none'} type="button">
                    <img src={leftArrow} onClick={()=>handlePage(page-1)} alt="Voltar Página"/>
                  </button>
                </div>

                <div>
                  {pageArray.map(i=>
                    <button key={i} className={i===posts.paged.page?'this':null} type="button" onClick={()=>i!==posts.paged.page&&handlePage(i)}>{i}</button>
                  )}
                </div>

                <div>
                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(page+1)}>
                    <img src={rightArrow} alt="Avançar Página"/>
                  </button>

                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(posts.paged.pages)}>
                    <img src={rightArrow} alt="Avançar Página"/>
                    <img src={rightArrow} alt="Avançar Página"/>
                  </button>
                </div>
              </ListPage>
            )}
            
            {(profile&&profile.role.level<=1)&&(<>
              <PopUp Open={openCreate} CallBack={handleOpenCreate}>
                <button type="button" onClick={handleOpenCreate}>
                  <img src={close} alt="Fechar"/>
                </button>
                <Form schema={schemaCreate} onSubmit={handleAdd}>
                  <h2>Criar Procedimento</h2>
                  <FormRow>
                    <FormColumn>
                      <label htmlFor="name">Nome:*</label>
                      <Input name="name" placeholder="Nome" autoComplete="off" />
                    </FormColumn>
                    <FormColumn>
                      <label htmlFor="order">Ordem:*</label>
                      <Input type="number" name="order" placeholder="Ordem" autoComplete="off" />
                    </FormColumn>
                  </FormRow>
                  
                  <SubmitRow>
                    <p>*Campos Obrigatórios</p>
                    <div>
                      <button type="submit">Adicionar</button>
                    </div>
                  </SubmitRow>
                </Form>
              </PopUp>

              <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
                <button type="button" onClick={handleOpenDelete}>
                  <img src={close} alt="Fechar"/>
                </button>
                {
                  !filters.find(f=>f.href==='trash'&&f.class!==null)
                    ?(
                      <>
                        <h1>Deletar Procedimentos</h1>
                        <br/><br/>
                        <p>Tem certeza que deseja deletar estes procedimentos?</p><br/>
                        <p>Eles ficarão salvos dentro da lixeira e será possível recuperá-los depois.</p>
                        <br/><br/>
                      </>
                    )
                    :(
                      <>
                        <h1>Recuperar Procedimentos</h1>
                        <br/><br/>
                        <p>Tem certeza que deseja recuperar estes procedimentos?</p><br/>
                        <br/><br/>
                      </>
                    )
                }
                <FormRow>
                  <FormColumn>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?(<button className="add" type="button" onClick={handleDelete}>SIM</button>)
                        :(<button className="add" type="button" onClick={handleRecover}>SIM</button>)
                    }
                  </FormColumn>
                  <FormColumn>
                    <button className="delete" type="button" onClick={handleOpenDelete}>NÃO</button>
                  </FormColumn>
                </FormRow>
              </PopUp>
              
              <PopUp Tiny Open={openDeleteSingle} CallBack={handleOpenDeleteSingle}>
                <button type="button" onClick={handleOpenDeleteSingle}>
                  <img src={close} alt="Fechar"/>
                </button>
                {
                  !filters.find(f=>f.href==='trash'&&f.class!==null)
                    ?(
                      <>
                        <h1>Deletar Procedimento</h1>
                        <br/><br/>
                        <p>Tem certeza que deseja deletar esse procedimento?</p><br/>
                        <p>Ele ficará salvo dentro da lixeira e será possível recuperá-lo depois.</p>
                        <br/><br/>
                      </>
                    )
                    :(
                      <>
                        <h1>Recuperar Procedimento</h1>
                        <br/><br/>
                        <p>Tem certeza que deseja recuperar esse procedimento?</p><br/>
                        <br/><br/>
                      </>
                    )
                }
                <FormRow>
                  <FormColumn>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?(<button className="add" type="button" onClick={handleDeleteSingle}>SIM</button>)
                        :(<button className="add" type="button" onClick={handleRecoverSingle}>SIM</button>)
                    }
                  </FormColumn>
                  <FormColumn>
                    <button className="delete" type="button" onClick={handleOpenDeleteSingle}>NÃO</button>
                  </FormColumn>
                </FormRow>
              </PopUp>
            </>)}
          </Container>
        )}
      </>
    );
}