import React, {useState,useRef,useEffect} from 'react';
import {useSelector} from 'react-redux';

import downArrow from '~/assets/icons/downArrow.svg';

import { Container } from './styles';

export default function Select({data,callback=()=>{},...extra}) {
  const config = useSelector(state=>state.config.value);
  // CLICK OUTSIDE
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // OPEN
  const [open,setOpen] = useState(false);
  const [selected,setSelected] = useState(data[0]);
  function handleChange(o){
    setSelected(o);
    callback(o.value);
  }
  function handleOpen(){
    setOpen(!open);
  }
  return (
    <Container Open={open} ref={wrapperRef} {...extra}>
      <div>
        <button type="button" onClick={handleOpen}>
          {selected.name}
          <img src={downArrow} alt={config.LANG['More']} />
        </button>
        <ul>
          {data.map(o=><li key={o.value}><button type="button" onClick={()=>{handleChange(o);handleOpen();}}>{o.name}</button></li>)}
        </ul>
      </div>
    </Container>
  );
}
