import React, {useState,useEffect,useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import api from '~/services/api';

import {Container,Title} from '~/components/Container';
import ImportProjects from '~/components/ImportProjects';
import {ListHeader,ListFilter,ListTable,ListPage,ListNone,ItemList} from '~/components/InternalList';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import add from '~/assets/icons/add.svg';
import search from '~/assets/icons/search.svg';
import grid from '~/assets/icons/grid.svg';
import list from '~/assets/icons/list.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

import More from '~/pages/Projects/index/more';

import ObjectSize from '~/util/ObjectSize';

export default function Projects() {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  // INIT
  const url = `projects`;
  const [topLoader,setTopLoader] = useState(true);
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState('');
  const [searchFinal,setSearchedFinal] = useState(null);
  async function handleSearch({search}){
    setSearchedFinal(search);
    setSearched(search);
    handlePage(1);
  }
  function handleSearchChanges(e){
    setSearchedFinal(e.target.value);
  }
  // FILTERS
  const filterStart = [
    { href: 'date', url: `${url}`, available: true, order: 'id', ordernate: 'DESC', text: config.LANG['DATE'], class: 'selected'},
    { href: 'asc', url: `${url}`,  available: true, order: 'name', ordernate: 'ASC', text: 'A—Z', class: null},
    { href: 'desc', url: `${url}`, available: true, order: 'name', ordernate: 'DESC', text: 'Z—A', class: null},
    { href: 'trash', url: `${url}`,available: false, order: 'id', ordernate: 'DESC', text: config.LANG['TRASH'], class: null}  
  ];
  useEffect(()=>{
    setFilters(filterStart);
  },[config]);
  const [filters,setFilters] = useState(filterStart);
  async function handleFilter(e,href){
    e&&e.preventDefault();
    const newFilters = filters.map(filter=>{
      if(filter.href===href){
        filter.class='selected';
      } else {
        filter.class=null;
      }
      return filter;
    });
    setFilters(newFilters);
    handleSearch({search:''});
  }
  // VIEW
  useEffect(()=>{
    async function GetView(){
      var viewFilter = localStorage.getItem('viewFilter');
      if(viewFilter) handleView({preventDefault:()=>true},viewFilter);
    }
    GetView();
  },[]);
  const [views,setViews] = useState([
    {'href':'grid','src':grid,'text':config.LANG['Block'],'class':null},
    {'href':'list','src':list,'text':config.LANG['List'],'class':'choosed'}
  ]);
  const [isBlock,setIsBlock] = useState(false);
  function handleView(e,href){
    e.preventDefault();
    localStorage.setItem('viewFilter',href);
    const newViews = views.map(view=>{
      if(view.href===href){
        view.class='choosed';
      } else {
        view.class=null;
      }
      return view;
    });
    setViews(newViews);
    setIsBlock((href==='grid')?true:false);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update, setUpdate] = useState(true);
  
  const filter = filters.find(function(item,index){
    return item['class']==='selected';
  });

  useEffect(() => {
    async function getPosts(){
      setChecked({});
      setDeleteAll(false);
      const response = await api.get(filter.url, {
        params:{
          order: filter.order,
          ordernate: filter.ordernate,
          perPage: 20,
          page:page,
          search: searched,
          available: filter.available
        }
      });
      setPosts(response.data);
      setTopLoader(false);
    }
    getPosts();
  },[update,searched,page,filter.available,filter.url,filter.order,filter.ordernate,filter.href]);
 
  const pageArray = useMemo(()=>{
    const thisArray = [];
    for (let i = 1; i <= posts.paged.pages; i++) {
      thisArray.push(i);
    }
    return thisArray;
  },[posts]);
  // CREATE
  const [openCreate,setOpenCreate] = useState(false);
  function handleOpenCreate(){
    setOpenCreate(!openCreate);
  }
  async function handleAdd(data){
    try {
      const reply = await api.post(`/${url}`,data);

      handleFilter(null,'date');

      handleOpenCreate();

      toast.success(`${config.LANG['Project']} ${reply.data.name} ${config.LANG['successfully created!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  // CHECK
  const [checked,setChecked] = useState({});
  function handleChecked(u){
    const newChecked = checked;
    if(checked[u]){
      delete newChecked[u];
    } else {
      newChecked[u] = true;
    }
    var countChecked = 0;
    for (const key in newChecked) {
      key==='essa função é'&&console.log('sem utilidade');
      countChecked++;
    }
    if(countChecked!==0){
      setDeleteAll(true);
    } else {
      setDeleteAll(false);
    }
    setChecked({...newChecked});
  }
  function handleCheckAll(conditional){
    if(conditional){
      setChecked({});
      setDeleteAll(false);
    } else {
      if(posts.list[0]) {
        const newChecked = {};
        for (const key in posts.list) {
          newChecked[posts.list[key].id] = true;
        }
        setChecked({...newChecked});
        setDeleteAll(true);
      }
    }
  }
  // DELETE ALL
  const [deleteAll,setDeleteAll] = useState(false);
  const [openDelete,setOpenDelete] = useState(false);
  function handleOpenDelete(){
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    const updatingItems = [];
    for (const key in checked) { updatingItems.push(Number(key)); }
    try {
      await api.put(`/${url}/bulk`,{
        ids: updatingItems,
        available: false,
      });
      toast.success(`${config.LANG['Projects']} ${config.LANG['successfully deleted!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  async function handleRecover(){
    setPosts({
      list: [],
      paged: {}
    });
    const updatingItems = [];
    for (const key in checked) { updatingItems.push(Number(key)); }
    try {
      await api.put(`/${url}/bulk`,{
        ids: updatingItems,
        available: true,
      });
      toast.success(`${config.LANG['Projects']} ${config.LANG['successfully recovered!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  // DELETE SINGLE
  const [deleteSingle,setDeleteSingle] = useState(false);
  const [openDeleteSingle,setOpenDeleteSingle] = useState(false);
  function handleOpenDeleteSingle(){
    setOpenDeleteSingle(!openDeleteSingle);
  }
  async function handleDeleteSingle(){
    try {
      await api.delete(`/${url}/${deleteSingle.id}`);
      toast.success(`${config.LANG['Project']} ${config.LANG['SINGLE successfully deleted!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  async function handleRecoverSingle(){
    try {
      const thisPost = posts.list.find(u=>u.id===parseInt(deleteSingle.id));
      await api.put(`/${url}/${deleteSingle.id}`,{
        available: true,
        role_id: thisPost.role_id
      });
      toast.success(`${config.LANG['Project']} ${config.LANG['SINGLE successfully recovered!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  function handleCallbackMore({type,payload}){
    if(type==='delete'){
      setDeleteSingle(payload.post);
      handleOpenDeleteSingle();
    }
  }
  // SCHEMA =====
  const schemaCreate = Yup.object().shape({
    name: Yup.string().required(config.LANG['Required field']),
  });
  // RENDER
    return (
      <>
      {topLoader?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </Container>
      ):(
          <Container>
            <Link to="/dashboard">
              <img src={leftArrow} alt={config.LANG['Backward']} />
              {config.LANG['Dashboard']}
            </Link>
            <Title>{config.LANG['Projects']} <span>({posts.paged.totalCount})</span></Title>

            <ListHeader>
              <div>
                {(profile&&profile.role.level<=1)&&(<>
                  <button className="icon add" type="button" onClick={handleOpenCreate}>
                    <img src={add} alt={config.LANG['Add']} />
                    {config.LANG['Add']} {config.LANG['Project']}
                  </button>
                  {deleteAll&&<button className="outline delete" type="button" onClick={handleOpenDelete}>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?config.LANG['Delete']
                        :config.LANG['Recover']
                    }
                  </button>}
                </>)}
              </div>
              <Form onSubmit={handleSearch}>
                <Input onChange={handleSearchChanges} name="search" placeholder={`${config.LANG['Search']}…`} value={searchFinal} />
                <button type="submit">
                  <img src={search} alt={config.LANG['Search']} />
                </button>
              </Form>
            </ListHeader>

            {(profile&&profile.role.level<=2)&&(<>
              <ImportProjects Close={() => handlePage(1)}/>
            </>)}

            <ListFilter>
              <div>
                {filters.map(filter=>(
                  <a key={filter.href} href={filter.href} className={filter.class||'none'} onClick={(e)=>handleFilter(e,filter.href)}>{filter.text}</a>
                ))}
              </div>
              <div>
                {views.map(view=>(
                  <a key={view.href} href={view.href} className={view.class||'none'} onClick={(e)=>handleView(e,view.href)}><img src={view.src} alt={view.text}/></a>
                ))}
              </div>
            </ListFilter>

            
            {posts.list[0]?(
              <ListTable isBlock={isBlock}>
                <thead>
                  <tr>
                    <th>
                      {(profile&&profile.role.level<=1)&&(<>
                        <button className={`checker${(ObjectSize(checked)===posts.list.length)?` selected`:''}`} type="button" onClick={()=>handleCheckAll(ObjectSize(checked)===posts.list.length)}></button>
                      </>)}
                    </th>
                    <th>
                      <h3>{config.LANG['Project']}</h3>
                    </th>
                    <th>
                      <h3>{config.LANG['Areas']}</h3>
                    </th>
                    <th/>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {posts.list.map(p=>(
                    <ItemList order={'project'} isBlock={isBlock} key={p.id}>
                      <td>
                        {(profile&&profile.role.level<=1)&&(<>
                          <button className={`checker${checked[p.id]?` selected`:''}`} type="button" onClick={()=>handleChecked(p.id)}></button>
                        </>)}
                      </td>
                      <td>
                        <p><Link className="linker" to={`/projects/${p.id}/areas`}><strong>{p.name}</strong></Link></p>
                      </td>
                      <td>
                        <p>{p.area} {config.LANG['Areas']}</p>
                      </td>
                      <td className="link last">
                        <Link to={`/projects/${p.id}/areas`}>{config.LANG['View']}</Link>
                      </td>
                      <td className="more">
                        {(profile&&profile.role.level<=1)&&(<>
                          <More post={p} callback={handleCallbackMore} />
                        </>)}
                      </td>
                    </ItemList>
                  ))}
                </tbody>
              </ListTable>
            ):(
              <ListNone>{config.LANG['No projects found!']}</ListNone>
            )}

            {posts.paged.pages>1&&(
              <ListPage data-totalpage={posts.paged.pages} data-page={posts.paged.page}>
                <div>
                  <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>handlePage(1)}>
                    <img src={leftArrow} alt={config.LANG['Backward']} />
                    <img src={leftArrow} alt=""/>
                  </button>

                  <button className={posts.paged.page!==1?null:'none'} type="button">
                    <img src={leftArrow} onClick={()=>handlePage(page-1)} alt={config.LANG['Backward']} />
                  </button>
                </div>

                <div>
                  {pageArray.map(i=>
                    <button key={i} className={i===posts.paged.page?'this':null} type="button" onClick={()=>i!==posts.paged.page&&handlePage(i)}>{i}</button>
                  )}
                </div>

                <div>
                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(page+1)}>
                    <img src={rightArrow} alt={config.LANG['Forward']} />
                  </button>

                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(posts.paged.pages)}>
                    <img src={rightArrow} alt={config.LANG['Forward']} />
                    <img src={rightArrow} alt=""/>
                  </button>
                </div>
              </ListPage>
            )}
            
            {(profile&&profile.role.level<=1)&&(<>
              <PopUp Open={openCreate} CallBack={handleOpenCreate}>
                <button type="button" onClick={handleOpenCreate}>
                  <img src={close} alt={config.LANG['Close']} />
                </button>
                <Form schema={schemaCreate} onSubmit={handleAdd}>
                  <h2>{config.LANG['Add']} {config.LANG['Project']}</h2>
                  <FormRow>
                    <FormColumn Line>
                      <label htmlFor="name">{config.LANG['Name']}:*</label>
                      <Input name="name" placeholder={config.LANG['Name']} autoComplete="off" />
                    </FormColumn>
                  </FormRow>
                  
                  <SubmitRow>
                    <p>*{config.LANG['Required Fields']}</p>
                    <div>
                      <button type="submit">{config.LANG['Add']}</button>
                    </div>
                  </SubmitRow>
                </Form>
              </PopUp>

              <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
                <button type="button" onClick={handleOpenDelete}>
                  <img src={close} alt={config.LANG['Close']} />
                </button>
                {
                  !filters.find(f=>f.href==='trash'&&f.class!==null)
                    ?(
                      <>
                        <h1>{config.LANG['Delete']} {config.LANG['Projects']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to delete']} {config.LANG['these']} {config.LANG['Projects']}?</p><br/>
                        <p>{config.LANG['They will be saved in the trash and you can recover them later.']}</p>
                        <br/><br/>
                      </>
                    )
                    :(
                      <>
                        <h1>{config.LANG['Recover']} {config.LANG['Projects']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to recover']} {config.LANG['these']} {config.LANG['Projects']}?</p><br/>
                        <br/><br/>
                      </>
                    )
                }
                <FormRow>
                  <FormColumn>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?(<button className="add" type="button" onClick={handleDelete}>{config.LANG['YES']}</button>)
                        :(<button className="add" type="button" onClick={handleRecover}>{config.LANG['YES']}</button>)
                    }
                  </FormColumn>
                  <FormColumn>
                    <button className="delete" type="button" onClick={handleOpenDelete}>{config.LANG['NO']}</button>
                  </FormColumn>
                </FormRow>
              </PopUp>
              
              <PopUp Tiny Open={openDeleteSingle} CallBack={handleOpenDeleteSingle}>
                <button type="button" onClick={handleOpenDeleteSingle}>
                  <img src={close} alt="Fechar"/>
                </button>
                {
                  !filters.find(f=>f.href==='trash'&&f.class!==null)
                    ?(
                      <>
                        <h1>{config.LANG['Delete']} {config.LANG['Project']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to delete']} {config.LANG['this']} {config.LANG['Project']}?</p><br/>
                        <p>{config.LANG['It will be saved in the trash and you can recover it later.']}</p>
                        <br/><br/>
                      </>
                    )
                    :(
                      <>
                        <h1>{config.LANG['Recover']} {config.LANG['Project']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to recover']} {config.LANG['this']} {config.LANG['Project']}?</p><br/>
                        <br/><br/>
                      </>
                    )
                }
                <FormRow>
                  <FormColumn>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?(<button className="add" type="button" onClick={handleDeleteSingle}>{config.LANG['YES']}</button>)
                        :(<button className="add" type="button" onClick={handleRecoverSingle}>{config.LANG['YES']}</button>)
                    }
                  </FormColumn>
                  <FormColumn>
                    <button className="delete" type="button" onClick={handleOpenDeleteSingle}>{config.LANG['NO']}</button>
                  </FormColumn>
                </FormRow>
              </PopUp>
            </>)}
          </Container>
        )}
      </>
    );
}