export default {
  // ~/page/SignIn
  'Welcome': 'Welcome',
  'Login to access': 'Login to access',
  'Your email': 'Your email',
  'Free trial registration': 'Free trial',
  'register to access': 'Register to access',
  'Your secret password': 'Your secret password',
  'Full name': 'Full name',
  'Next': 'Next',
  'company': 'Company or institution',
  'Loading': 'Loading',
  'Name is required': 'Name is required',
  'company name is mandatory': 'Company name is mandatory',
  'contact phone': 'Contact phone',
  'Phone is required': 'Phone is required',
  'Registration': 'Register',
  'information at realize': 'Do you want to receive newsletters from realize?',
  'Login': 'Login',
  'Forgot your password?': 'Forgot your password?',
  'Free trial':'Free trial',
  'Email is required': 'Email is required',
  'Enter a valid email': 'Enter a valid email',
  'Password is required': 'Password is required and without a minimum of 6 digits',
  'Authentication failed, check your data!': 'Authentication failed, check your data!',
  // ~/page/Password
  'Redefine password': 'Redefine password',
  'Fill in your new password below.': 'Fill in your new password below.',
  'New password': 'New password',
  'Confirm your password': 'Confirm your password',
  'Type the same password above': 'Type the same password above',
  'Redefine': 'Redefine',
  'At least 6 characters': 'At least 6 characters',
  'Password is different from the one entered above.': 'Password is different from the one entered above.',
  'your password has been changed. Now perform your Login.': 'your password has been changed. Now perform your Login.',
  'Incorrect information. Try again.': 'Incorrect information. Try again.',

  'Recover Password': 'Recover Password',
  'Enter your email below to reset your password.': 'Enter your email below to reset your password.',
  'Type your e-mail': 'Type your e-mail',
  'Recover': 'Recover',
  'Back to Login': 'Back to Login',
  'We sent a password change email to': 'We sent a password change email to',
  'Email does not exist. Try again.': 'Email does not exist. Try again.',
  // ~/components/Menu
  'Dashboard': 'Dashboard',
  'Users': 'Users',
  'Projects': 'Projects',
  'Logout': 'Logout',
  // ~/components/Profile
  'Edit Profile': 'Edit Profile',
  'Settings': 'Settings',
  'Procedures': 'Procedures',
  'Templates': 'Templates',
  // ~/components/Notifications
  'No activity yet.': 'No activity yet.',
  'NotificationsTitle': 'Notifications',
  'Notifications': 'NOTIFICATIONS',
  'HistoricTitle': 'Historic',
  'Historic': 'HISTORIC',
  'Historic Report': 'Activities Report',
  'Activities': 'Activities',
  'Activities Report': 'New Notifications',
  'NotificationsEmail': 'Receive by email',
  // ~/page/Dashboard/components/helloworld
  'Hello': 'Hello',
  'You have': 'You have',
  'Project(s) in progress.': 'Project(s) in progress.',
  'Access a project to continue working!': 'Access a project to continue working!',
  // ~/page/Dashboard/components/projects
  'Project': 'Project',
  'subjects': 'subjects',
  'access': 'access',
  'No projects found!': 'No projects found!',
  'Backward': 'Backward',
  'Forward': 'Forward',
  // ~/page/Dashboard/components/planConvertion
  'Available conversions': 'Available conversions',
  // ~/page/Dashboard/components/planUser
  'Available users': 'Available users',
  // ~/page/Overview
  'Overview': 'Overview',
  // ~/page/Overview/components/areas
  'Areas': 'Areas',
  'Search': 'Search',
  'No area found!': 'No area found!',
  // ~/page/Overview/components/subjects
  'Subjects': 'Subjects',
  'No subject found!': 'No subject found!',
  // ~/page/Overview/components/units
  'Units': 'Units',
  'No units found!': 'No units found!',
  // ~/page/Overview/components/delegates
  'My Units': 'My Units',
  // ~/page/Overview/components/followers
  'My Favorites': 'My Favorites',
  // ~/page/Overview/components/status
  'Production Status': 'Production Status',
  'Save': 'Save',
  'Report': 'Report',
  // ~/page/Overview/components/convertions
  'Latest conversions': 'Latest conversions',
  'By': 'By',
  'No conversions found!': 'No conversions found!',
  // ~/page/Overview/components/plan
  'Current Plan': 'Current Plan',
  'Conversions': 'Conversions',
  // ~/page/Profile
  'My Profile': 'My Profile',
  'My data': 'My data',
  'Full Name': 'Full Name',
  'Email': 'Email',
  'Your e-mail address': 'Your e-mail address',
  'Change profile picture': 'Change profile picture',
  'Current Password': 'Current Password',
  'Required Fields': 'Required Fields',
  'Update Profile': 'Update Profile',
  'Required field': 'Required field',
  'Profile updated successfully!': 'Profile updated successfully!',
  'Error updating profile, check your data!': 'Error updating profile, check your data!',
  'The selected users will have their profile updated': 'The selected users will have their profile updated',
  // ~/components/AvatarInput
  'Remove Image': 'Remove Image',
  'Choose image': 'Choose image',
  // ~/page/Users
  'DATE': 'DATE',
  'TRASH': 'TRASH',
  'Block': 'Block',
  'List': 'List',
  'We have sent a creation email to': 'We have sent a creation email to',
  'Check the user limit.': 'Check the user limit.',
  'Successfully deleted users!': 'Successfully deleted users!',
  'Users successfully recovered!': 'Users successfully recovered!',
  'User successfully deleted!': 'User successfully deleted!',
  'User successfully recovered!': 'User successfully recovered!',
  'Check the number of users on your plan.': 'Check the number of users on your plan.',
  'An error has occurred. Try again.': 'An error has occurred. Try again.',
  'List of': 'List of',
  'User': 'User',
  'Add': 'Add',
  'Delete': 'Delete',
  'Duplicate': 'Duplicate',
  'Function': 'Function',
  'No users found!': 'No users found!',
  'Close': 'Close',
  'Password': 'Password',
  'Profile': 'Profile',
  'Are you sure you want to delete': 'Are you sure you want to delete',
  'Are you sure you want to recover': 'Are you sure you want to recover',
  'these': 'these',
  'this': 'this',
  'They will be saved in the trash and you can recover them later.': 'They will be saved in the trash and you can recover them later.',
  'It will be saved in the trash and you can recover it later.': 'It will be saved in the trash and you can recover it later.',
  'YES': 'YES',
  'NO': 'NO',
  'Edit': 'Edit',
  'More': 'More',
  'Administrador': 'Administrator',
  'Diretor': 'Director',
  'Gerente': 'Manager',
  'Editor': 'Editor',
  'Conteudista': 'Contentist',
  'Obligations': 'Obligations',
  'Indicate Obligations': 'Indicate Obligations',
  'The selected users will be appointed for the following obligations:': 'The selected users will be appointed for the following obligations:',
  'Indicate': 'Indicate',
  'Users have been successfully nominated for the obligation': 'Users have been successfully nominated for the obligation',
  'Users have been successfully removed from the obligation': 'Users have been successfully removed from the obligation',
  // ~/page/Users/edit
  'Redefine data': 'Redefine data',
  // ~/page/Projects
  'successfully created!': 'successfully created!',
  'successfully edited!': 'successfully edited!',
  'successfully deleted!': 'successfully deleted!',
  'successfully recovered!': 'successfully recovered!',
  'SINGLE successfully deleted!': 'successfully deleted!',
  'SINGLE successfully recovered!': 'successfully recovered!',
  'Personalize': 'Personalize',
  'View': 'View',
  'Name': 'Name',
  // ~/components/Rules
  'Customization': 'Customization',
  'Themes': 'Themes',
  'Nothing found': 'Nothing found',
  'Update Theme': 'Update Theme',
  'Are you sure you want to choose this theme?': 'Are you sure you want to choose this theme?',
  // ~/page/Areas
  'Area': 'Area',
  'Knowledge Areas': 'Knowledge Areas',
  'Knowledge Area': 'Knowledge Area',
  'FEMALE successfully created!': 'successfully created!',
  'FEMALE successfully edited!': 'successfully edited!',
  'FEMALE successfully deleted!': 'successfully deleted!',
  'FEMALE successfully recovered!': 'successfully recovered!',
  'FEMALE SINGLE successfully deleted!': 'successfully deleted!',
  'FEMALE SINGLE successfully recovered!': 'successfully recovered!',
  'from': 'from',
  'Color': 'Color',
  'FEMALE these': 'these',
  'FEMALE this': 'this',
  'FEMALE They will be saved in the trash and you can recover them later.': 'They will be saved in the trash and you can recover them later.',
  'FEMALE It will be saved in the trash and you can recover it later.': 'It will be saved in the trash and you can recover it later.',
  // ~/page/Areas/edit
  'Update': 'Update',
  'Change': 'Change',
  'Current': 'Current',
  // ~/page/Areas/edit/projects
  'All': 'All',
  'FEMALE All': 'All',
  'Selected': 'Selected',
  'Select': 'Select',
  'Are you sure you want to choose a new Project for this Area?': 'Are you sure you want to choose a new Project for this Area?',
  // ~/page/Subjects
  'Subject': 'Subject',
  'Are you sure you want to choose a new Area for this Subject?': 'Are you sure you want to choose a new Area for this Subject?',
  // ~/page/Units
  'Unity': 'Unity',
  'Delegate': 'Delegate',
  'Status': 'Status',
  'Create': 'Create',
  'DUPLICATE': 'DUPLICATE',
  'DELETE': 'CANCEL',
  'delegates': 'Delegated users',
  'personalize': 'Personalize',
  'content': 'Contenido',
  'template': 'Template',
  'Duplicate Unit': 'Duplicate Unit',
  'Delegate Responsible': 'Delegate Responsible',
  'Attention': 'Attention',
  'Duplicate message': 'Select what you want to duplicate',
  'The users below will be delegated as responsible for the selected units': 'The users below will be delegated as responsible for the selected units',
  'Exclude': 'Exclude',
  // ~/pages/Units/index/delegates
  'was successfully delegated as responsible!': 'was successfully delegated as responsible!',
  'has been removed as responsible successfully!': 'has been removed as responsible successfully!',
  'Remove': 'Remove',
  'Are you sure you want to choose a new Discipline for this Unit?': 'Are you sure you want to choose a new Discipline for this Unit?',
  // ~/pages/Units/view
  'Go to Editor': 'Go to Editor',
  'Calculating': 'Calculating',
  'The percentage of change identified since the last conversion is': 'The percentage of change identified since the last conversion is',
  'The maximum allowed for a free conversion is': 'The maximum allowed for a free conversion is',
  'Therefore, you will not be charged for a new conversion of your plan!': 'Therefore, you will not be charged for a new conversion of your plan!',
  // ~/pages/Units/view/converter
  'Unit successfully converted!': 'Unit successfully converted!',
  'Conversion': 'Conversion',
  'Convert': 'Convert',
  'Convert unit': 'Convert unit',
  'Are you sure you want to convert this unit?': 'Are you sure you want to convert this unit?',
  'A conversion will be discounted from the amount provided for your plan when converting this unit.': 'A conversion will be discounted from the amount provided for your plan when converting this unit.',
  'Converting': 'Converting',
  // ~/pages/Units/view/convertions
  'Conversions of': 'Conversions of',
  // ~/pages/Units/view/medias
  'Downloads': 'Downloads',
  'Download': 'Download',
  'Download the created conversion': 'Download the created conversion',
  'Convert this unit to download': 'Convert this unit to download',
  // ~/pages/Units/view/status
  'conversion allowed': 'conversion allowed',
  'under development': 'under development',
  'select step': 'select step',
  // ~/pages/Units/view/delegateusers/delegates
  'Responsible': 'Responsible',
  'No delegated users for this unit': 'No delegated users for this unit',
  // ~/pages/Units/extras
  'Custom unit successfully!': 'Custom unit successfully!',
  'Customize Unit': 'Customize Unit',
  'Import': 'Import',
  'Customize by areas': 'Customize by areas',
  'Customize by disciplines': 'Customize by disciplines',
  'Custom units for the project': 'Custom units for the project',
  'Current theme options': 'Current theme options',
  'Import Customization': 'Import Customization',
  'Import Customization from another Unit': 'Import Customization from another Unit',
  // ~/pages/Units/editor
  'Backup': 'Backup',
  'Format': 'Format',
  'Share': 'Share',
  'Mode': 'Mode',
  'Preview Link': 'Preview Link',
  'Spelling': 'Spelling',
  'Word Counter': 'Word Counter',
  'Backup of Editions': 'Backup of Editions',
  'The conversion may take a long time to complete':'The conversion can take a long time to complete. In the meantime, you can browse the system',
  'Resources': 'Resources',
  'Edit Interaction': 'Edit Interaction',
  'Comments': 'Comments',
  'Comment': 'Comment',
  'SelectUsers': 'Select users',
  'SelectUsersTitle': 'Tag Users',
  'delete': 'delete',
  'none': 'none',
  'This interaction is another theme.': 'This interaction is another theme.',
  'Change the theme to edit this interaction.': 'Change the theme to edit this interaction.',
  'Corrupted interaction. Create it again. You may have deleted a line or important information.': 'Corrupted interaction. Create it again. You may have deleted a line or important information.',
  'Content saved successfully!': 'Content saved successfully!',
  'Processing': 'Processing',
  'Reload': 'Reload',
  'Save to view your work': 'Save to view your work',
  'Interactions': 'Interactions',
  'Insert': 'Insert',
  'Backups': 'Backups',
  'Save this file to create a new backup': 'Save this file to create a new backup',
  'If you recover a backup, you will lose your work done that was not saved': 'If you recover a backup, you will lose your work done that was not saved',
  'Preview': 'Preview',
  'Submit the link below for viewing': 'Submit the link below for viewing',
  'Use the password provided for authorization': 'Use the password provided for authorization',
  'Link': 'Link',
  'Copy': 'Copy',
  'Delete Interaction': 'Delete Interaction',
  'Are you sure you want to delete this interaction?': 'Are you sure you want to delete this interaction?',
  'You will not be able to recover it later': 'You will not be able to recover it later',
  'Number of pages': 'Number of pages',
  'Number of words': 'Number of words',
  'Number of characters': 'Number of characters',
  'Full text': 'Full text',
  'Add Comment': 'Add Comment',
  'Write your comment here.': 'Write your comment here.',
  'No backups found!': 'No backups found!',
  'Topic': 'Topic',
  'Title': 'Title',
  'Subtitle Original': 'Subtitle',
  'Quote': 'Quote',
  // ~/pages/Units/editor/state
  'Current step': 'Current step',
  'End Step': 'End Step',
  // ~/pages/Units/revisor
  'Smart Reviewer': 'Smart Reviewer',
  'Searching for Errors': 'Searching for Errors',
  'Spell Check': 'Spell Check',
  'Error identified': 'Error identified',
  'Text for correction': 'Text for correction',
  'Correction options': 'Correction options',
  'Code': 'Code',
  'Delete Error': 'Delete Error',
  'Are you sure you want to delete this error?': 'Are you sure you want to delete this error?',
  'Search Errors': 'Search Errors',
  'Correct': 'Correct',
  'You must have some saved content to enter spell check mode.': 'You must have some saved content to enter spell check mode.',
  'There are interactions of another theme.': 'There are interactions of another theme.',
  'They were not loaded correctly.': 'They were not loaded correctly.',
  'Corrupted interaction. There are interactions with formatting errors. Return to the editor and correct the interactions.': 'Corrupted interaction. There are interactions with formatting errors. Return to the editor and correct the interactions.',
  // ~/pages/Share
  'Incorrect password.': 'Incorrect password.',
  'Authentication': 'Authentication',
  'Check': 'Check',
  'Resource not started': 'Resource not started',
  // ~/components/Pixabay
  'Images': 'Images',
  'Free Image Stock': 'Free Image Stock',
  'Term': 'Term',
  'Enter the term to filter.': 'Enter the term to filter.',
  'Term Language': 'Term Language',
  'Portuguese': 'Portuguese',
  'English': 'English',
  'Spanish': 'Spanish',
  'All Single': 'All',
  'Type': 'Type',
  'Photo': 'Photo',
  'Illustration': 'Illustration',
  'Vector': 'Vector',
  'Orientation': 'Orientación',
  'Horizontal': 'Horizontal',
  'Vertical': 'Vertical',
  'Category': 'Category',
  'Backgrounds': 'Backgrounds',
  'Fashion': 'Fashion',
  'Nature': 'Nature',
  'Science': 'Science',
  'Education': 'Education',
  'Feelings': 'Feelings',
  'Health': 'Health',
  'People': 'People',
  'Religion': 'Religion',
  'Places': 'Places',
  'Animals': 'Animals',
  'Industry': 'Industry',
  'Computer': 'Computer',
  'Food': 'Food',
  'Sports': 'Sports',
  'Transportation': 'Transportation',
  'Travel': 'Travel',
  'Buildings': 'Buildings',
  'Business': 'Business',
  'Music': 'Music',
  'Filter': 'Filter',
  'No images found!': 'No images found!',
  // ~/components/Init
  'The system will enter maintenance mode in minutes.': 'The system will enter maintenance mode in minutes.',
  'Save your work so you will not lose your progress.': 'Save your work so you will not lose your progress.',
  'The system has been successfully updated!': 'The system has been successfully updated!',
  'Incorrect Version': 'Incorrect Version',
  'You are working on an outdated version.': 'You are working on an outdated version.',
  'Save your work and': 'Save your work and',
  'Click here to update': 'Click here to update',
  'Editing Unit': 'Editing Unit',
  "There are other people editing this unit in another window. Take care not to replace other people's work.": "There are other people editing this unit in another window. Take care not to replace other people's work.",
  // ~/components/Init
  'Uploading file': 'Uploading file',
  'An error has occurred. Try again or check the file size. Decrease the file size, if necessary.': 'An error has occurred. Try again or check the file size. Decrease the file size, if necessary.',
  'Image Stock': 'Image Stock',
  'Choose': 'Choose',
  // ~/components/Fields/VideoInput
  'Video': 'Video',
  // ~/components/Fields/ZipInput
  'The package must have an index.html file at its root that will load the interaction.': 'The package must have an index.html file at its root that will load the interaction.',
  'Package': 'Package',
  // ~/components/Fields/AudioInput
  'Audio': 'Audio',
  // ~/components/Fields/ImageEditor
  'Subtitle': 'Subtitle',
  'Source': 'Source',
  'Paragraph': 'Paragraph',
  'Formatting': 'Formatting',
  'Insert image caption here': 'Insert image caption here',
  'Source: Type the source of the image here.': 'Source: Type the source of the image here.',
  // ~/components/ZenDesk
  'Hello Support Realize, I have a problem.': 'Hello Support Realize, I have a problem.',
  // ~/components/ImportUsers
  'DOWNLOAD TEMPLATE SPREADSHEET': 'DOWNLOAD TEMPLATE SPREADSHEET',
  'IMPORT SPREADSHEET': 'IMPORT SPREADSHEET',
  'add multiple users': 'add multiple users',
  'Import User Spreadsheet': 'Import User Spreadsheet',
  'See below how to complete and import the spreadsheet to register users': 'See below how to complete and import the spreadsheet to register users',
  'See below the users who will be registered in the system': 'See below the users who will be registered in the system',
  'REGISTER USERS': 'REGISTER USERS',
  'CANCEL': 'CANCEL',
  'Your spreadsheet is inconsistent. Try again.': 'Your spreadsheet is inconsistent. Try again.',
  'Failed to import the spreadsheet. Contact support.': 'Failed to import the spreadsheet. Contact support.',
  'The selected file has the wrong extension.': 'The selected file has the wrong extension.',
  'Spreadsheet imported successfully.': 'Spreadsheet imported successfully.',
  'There are e-mails already registered in the system.': 'There are e-mails already registered in the system.',
  'Duplicate emails': 'Duplicate emails',
  // ~/components/ImportProjetcs
  'add multiple projects': 'add multiple projects',
  'Import Project Spreadsheet': 'Import Project Spreadsheet',
  'See below how to complete and import the spreadsheet to register projects': 'See below how to complete and import the spreadsheet to register projects',
  'See below the projects who will be registered in the system': 'See below the projects who will be registered in the system',
  'REGISTER PROJECTS': 'REGISTER PROJECTS',
};