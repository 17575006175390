import React from 'react';
import {useSelector} from 'react-redux';
import {Form} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import api from '~/services/api';

import Select from '~/components/Select';

import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';

export default function Profile({postsId,openOutside}) {
  const config = useSelector(state=>state.config.value);
  // INIT
  async function handleSubmit(data){
    const updatingUsers = [];
    for (const key in postsId) { updatingUsers.push(Number(key)); }
    try {
      await api.put(`/users/bulk`,{
        users: updatingUsers,
        role_id: data.role_id,
      });
      toast.success(`${config.LANG['Users']} ${config.LANG['successfully edited!']}`);
      openOutside();
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  // SCHEMAS =====
  const schema = Yup.object().shape({
    role_id: Yup.number()
  });
  return (<>
    <Form schema={schema} onSubmit={handleSubmit}>
      <FormRow>
        <FormColumn Line>
          <label htmlFor="role_id">{config.LANG['Profile']}:*</label>
          <Select name="role_id" />
        </FormColumn>
      </FormRow>
      
      <SubmitRow>
        <p>*{config.LANG['Required Fields']}</p>
        <div>
          <button type="submit">{config.LANG['Update Profile']}</button>
        </div>
      </SubmitRow>
    </Form>
  </>);
}
