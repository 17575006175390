import React from 'react';

import { BlackOut, Container } from './styles';

export default function PopUp({children,Open,Tiny,isInteractions,isFront,CallBack=function(){}}) {
  // function handleBackOutClick(event){
  //   // event.preventDefault();
  //   const target = event.target;
  //   if (target.id !== 'backoutPopup') {
  //     return ;
  //   }
  //   CallBack();
  // }
  return (
    // <BlackOut id="backoutPopup" isFront Open={Open} onClick={handleBackOutClick}>
    <BlackOut id="backoutPopup" isFront Open={Open}>
      <Container isInteractions={isInteractions} Tiny={Tiny}>
        {children}
      </Container>
    </BlackOut>
  );
}
