import styled,{css} from 'styled-components';

import {$} from '~/styles/global';

export const Share = styled.div`
  .link, .password {
    padding: 15px 0;
    label {
      display: block;
      font-weight: ${$.bold};
      color: ${$.colorC};
      margin-bottom: 10px;
    }
    div {
      display: flex;
      input, button {
        padding: 10px;
      }
      input {
        flex: 1;
        border-radius: 0px;
        border: 1px solid ${$.colorC};
      }
      button {
        background: ${$.colorC};
        border: none;
        color: ${$.white};
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  
  input[id=container-copy-link-s3]{
    border: 1px solid ${$.colorGray} !important;
    /* border-radius: 10px 10px !important; */
  }

  #copy-link-s3{
    ${$.gradient($)};
    font-weight: ${$.bold};
  }

  .feedbak{
    margin-bottom: 10px;
  }
  .copied-link{
    display: flex;
    align-items: center;
    font-weight: 600;
    svg{
      color: ${$.correct};
      margin-right: 5px;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const FormColumn = styled.div`
  width: 50%;
  ${props=>props.Line&&css`
    width: 100%;
    padding-left: 0px!important;
    padding-right: 0px!important;
  `}
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  &:nth-child(even) {
    padding-left: 12.5px;
  }
  &:nth-child(odd) {
    padding-right: 12.5px;
  }
  input+label{
    margin-top: 15px;
  }
`;

export const Separator = styled.hr`
  margin-top: 15px;
  margin-bottom: 30px;
  border: 0;
  border-bottom: 1px solid ${$.colorText($,0.1)};
`;

export const SubmitRow = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    button + button {
      margin-left: 10px;
    }
  }
  .unavailable>& {
    opacity: 1;
  }
`;