import React, {useState,useRef,useEffect} from 'react';
import {Input,useField} from '@rocketseat/unform';

import { Container } from './styles';

export default function Textarea(fieldValue) {
  const { defaultValue, registerField, error } = useField(fieldValue.name);
  const [value,setvalue] = useState(defaultValue&&defaultValue);

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.value'
      });
    }
  },[ref,registerField,fieldValue.name]);

  useEffect(()=>{
    setvalue(defaultValue&&defaultValue);
  },[defaultValue]);

  async function handleChange(e){
    setvalue(e.target.value);
  }
  return (
    <Container>
      <div>
        <textarea onChange={handleChange} placeholder={fieldValue.placeholder} value={value}>{value}</textarea>
        <input type="hidden" data-value={value} ref={ref}/>
        {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={value} />)}
        { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
      </div>
    </Container>
  );
}
