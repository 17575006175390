import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
  background: ${$.colorWhite};
  border: 1px solid ${$.colorText($, 0.2)};
  border-left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &>div{
    height: 70px;
    padding: 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-left: 1px solid ${$.colorText($, 0.2)};
    background: none;
    cursor: pointer;
    transition: background ${$.animation};
    &:hover {
      background: ${$.white};
    }
  }

`;