import styled from 'styled-components';

import {$} from '~/styles/global';

var buttonSizer = 100;
buttonSizer = 70;

export const Container = styled.div`
  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: ${buttonSizer}px;
    height: ${buttonSizer/2}px;
    background: ${$.colorText($,0.3)};
    border-radius: ${buttonSizer}px;
    position: relative;
    transition: background-color .2s;
    margin: 0;
    margin-bottom: 5px;
  }

  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: ${buttonSizer*0.02}px;
    left: ${buttonSizer*0.02}px;
    width: ${(buttonSizer/2)-(buttonSizer*0.05)}px;
    height: ${(buttonSizer/2)-(buttonSizer*0.05)}px;
    border-radius: ${(buttonSizer/2)-(buttonSizer*0.05)}px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 ${buttonSizer*0.02}px 0 rgba(10, 10, 10, 0.29);
  }

  .react-switch-label.open .react-switch-button {
    left: calc(100% - ${buttonSizer*0.02}px);
    transform: translateX(-100%);
  }

  .react-switch-label:active .react-switch-button {
    width: ${(buttonSizer/2)+(buttonSizer*0.1)}px;
  }
`;
