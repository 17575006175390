import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import history from '~/services/history';
import api from '../../services/api';

export default function SignUp(props) {
  const config = useSelector(state=>state.config.value);
  // NEW PASSWORD =====
  async function handleNewPassword({email}){
    try {
      const response = await api.post(`/passwords/`,{
        email
      });
      const {email:emailRequested} = response.data;
      toast.success(`${config.LANG['We sent a password change email to']} ${emailRequested}`);
    } catch(err) {
      toast.error(config.LANG['Email does not exist. Try again.']);
    }
  }
  // CHANGE PASSWORD =====
  const {search} = props.location;
  const query = {};
  if(search) search.split('?')[1].split('&').map(item=>{ return query[item.split('=')[0]] = item.split('=')[1]; });
  async function changePassword({email,code,generate,password,confirmPassword}){
    try {
      const response = await api.put(`/passwords`,{
        email,
        code,
        generate,
        password,
        confirmPassword
      });
      const {name} = response.data;
      toast.success(`${name}, ${config.LANG['your password has been changed. Now perform your Login.']}`);
      history.push('/');
    } catch(err) {
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  // SCHEMAS =====
  const schema = Yup.object().shape({
    email: Yup.string().email(config.LANG['Enter a valid email']).required(config.LANG['Email is required'])
  });
  const schemaPassword = Yup.object().shape({
    email: Yup.string().email(config.LANG['Enter a valid email']).required(config.LANG['Email is required']),
    code: Yup.string(),
    generate: Yup.string(),
    password: Yup.string().min(6,config.LANG['At least 6 characters']).required(config.LANG['Password is required']),
    confirmPassword: Yup.string().required(config.LANG['Password is required']).when('password',(password,field)=>
      password?field.oneOf([Yup.ref('password')],config.LANG['Password is different from the one entered above.']):field
    ),
  });
  if(query.email){
    return (
      <>
        <h1>{config.LANG['Redefine password']}</h1>
        <p>{config.LANG['Fill in your new password below.']}</p>
        <Form schema={schemaPassword} onSubmit={changePassword}>
          <Input name="email" type="hidden" value={query.email} />
          <Input name="code" type="hidden" value={query.code} />
          <Input name="generate" type="hidden" value={query.generate} />
          <label htmlFor="password">{config.LANG['New password']}:</label>
          <Input name="password" type="password" placeholder={config.LANG['New password']} autoComplete="off" />
          <label htmlFor="confirmPassword">{config.LANG['Confirm your password']}:</label>
          <Input name="confirmPassword" type="password" placeholder={config.LANG['Type the same password above']} autoComplete="off" />
          <button type="submit">{config.LANG['Redefine']}</button>
          <Link to="/">{config.LANG['Back to Login']}</Link>
        </Form>
      </>
    );
  } else {
    return (
      <>
        <h1>{config.LANG['Recover Password']}</h1>
        <p>{config.LANG['Enter your email below to reset your password.']}</p>
        <Form schema={schema} onSubmit={handleNewPassword}>
          <label htmlFor="email">{config.LANG['Your email']}:</label>
          <Input name="email" type="email" placeholder={config.LANG['Type your e-mail']} autoComplete="off" />
          <button type="submit">{config.LANG['Recover']}</button>
          <Link to="/">{config.LANG['Back to Login']}</Link>
        </Form>
      </>
    );
  }
}

