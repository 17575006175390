import React,{useCallback} from 'react';
import {useSelector} from 'react-redux';

import {Backups} from '~/components/Backups';

import BackupsList from '~/pages/Units/editor/popup/backups/list';

import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';

export default function BackupsComponent({Open,Close,editor,postId}) {
  const config = useSelector(state=>state.config.value);
  const handleRestore = useCallback((html)=>{
    if(Open&&editor) restoreVersion();
    function restoreVersion(){
      editor.resetContent(html);
      Close();
    }
  },[Open,editor,Close]);
  return (
    <PopUp isFront Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <Backups>
        <h1>{config.LANG['Backups']}</h1>
        <p>{config.LANG['Save this file to create a new backup']}.<br/>
        {`${config.LANG['If you recover a backup, you will lose your work done that was not saved']}`}.</p>
        <div>
          <BackupsList reload={Open} postId={postId} callback={handleRestore} />
        </div>
      </Backups>
    </PopUp>
  );
}