/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Zendesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";

import { $ } from "~/styles/global";

const ZenDesk = () => {
  const config = useSelector((state) => state.config.value);
  const profile = useSelector((state) => state.user.profile);

  const ZENDESK_KEY = "cbf96e12-eeb8-4821-997a-7105a865b59d";
  const setting = {
    color: {
      theme: $.colorC,
    },
    contactOptions: {
      enabled: true,
    },
    offset: { horizontal: "0px", vertical: "25px" },
    contactForm: {
      fields: [
        {
          id: "description",
          prefill: {
            "*": config.LANG["Hello Support Realize, I have a problem."],
          },
        },
      ],
    },
  };
  useEffect(() => {
    var language = "pt-BR";
    if (config.LANGUAGE === "en_us") {
      language = "en-US";
    } else if (config.LANGUAGE === "es_es") {
      language = "es-ES";
    }
    ZendeskAPI("webWidget", "setLocale", language);
    ZendeskAPI("webWidget", "identify", {
      name: profile?.name,
      email: profile?.email,
      organization: config.COMPANY_NAME,
    });
    ZendeskAPI("webWidget", "prefill", {
      name: {
        value: profile?.name,
        readOnly: true,
      },
      email: {
        value: profile?.email,
      },
    });
    ZendeskAPI("webWidget", "updateSettings", setting);
  }, [config, profile]);
  return (
    <>
      {profile && (
        <Zendesk
          zendeskKey={ZENDESK_KEY}
          {...setting}
          onLoaded={() => console.log("is loaded")}
        />
      )}
    </>
  );
};

export default ZenDesk;
