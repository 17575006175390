import styled, {css} from 'styled-components';
import {lighten} from 'polished';

import {$} from '~/styles/global';

export const Container = styled.div`

  width: 100%;
  margin: 0 auto;
  padding: 50px;
  > div {
    width: 100%;
    h1 {
      padding: 0 15px;
    }
    > h2 {
      padding: 0 15px;
    }
  }
  a.linkBack {
    display: block;
    border: 0;
    background: none;
    color: ${$.colorC};
    margin-bottom: 10px;
    padding: 0 15px;
    img {
      margin-right: 10px;
    }
  }
`;

export const JumpTuto = styled.button`
  margin-top: 20px;
  border: 1px solid transparent;
  color: ${$.colorText};
  padding: 5px;
  border-radius: 5px;
  transition: all 0.2s;
  &:hover{
    opacity: 0.7;
  }
`;

export const NavContainer = styled.div`
  nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0px 15px;
    button {
      padding: 10px;
      background: transparent;
      border: 0;
      &.none {
        opacity: 0.2;
        cursor: default;
        &:hover {
          opacity: 0.2;
        }
      }
      img {
        height: 15px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  .projects-container{
    width: 100%;
    display:flex;
    flex-direction: column;
  }
  .first-step{
    width: 40%;
    margin: 15px;
  }
`;

export const Block = styled.div`
  border-radius: 5px;
  border: 1px solid ${$.colorText($,0.15)};
  background: ${$.white};
  margin: 7.5px 15px;
  padding: 20px;
  width: 33.33333%;
  .second-step{
    display: flex;
    flex-direction: column;

    button.report {
    border: none;
    height: 30px;
    padding: 0 10px;
    img {
      margin-right: 10px;
    }
  }
 
  ${props=>props.Lefter&&css`
    margin-left: 0px;
  `}
  ${props=>props.Full&&css`
    width: 100%;
  `}
  ${props=>props.Big&&css`
    width: 60%;
  `}
  ${props=>props.Little&&css`
    width: 40%;
    display: flex;
    flex-direction: column;
  `}
  ${props=>props.Middle&&css`
    width: 50%;
  `}
  ${props=>props.State&&css`
    margin: 5px;
    padding: 10px;
    border: 1px solid ${$.colorText($,0.09)};
    background: ${$.colorText($,0.025)};
    > div {
      align-items: center;
      margin: 0 0 10px;
      > strong {
        margin-right: 10px;
      }
      form {
        min-width: 130px;
        input {
          margin: 0;
        }
      }
    }
  `}
  ${props=>props.NoLeft&&css`
    margin-left: 0px;
  `}
  ${props=>props.NoTop&&css`
    margin-top: 0px;
  `}
  ${props=>props.Stock&&css`
    width: 100%;
    margin: 0px;
    > div {
      .searcher {
        margin-right: 20px;
        width: 25%;
        label {
          display: block;
        }
        input {
          ${$.fontSize($,12)}
          height: 34px;
          width: 100%;
          margin-bottom: 0px;
        }
        > button {
          padding: 0 10px;
          background: ${$.colorC};
          width: 100%;
          height: 34px;
        }
      }
    }
  `}

  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    form {
      position: relative;
      flex: 1;
      max-width: 50%;
      input {
        ${$.fontSize($,12)}
        height: 34px;
        width: 100%;
      }
      button {
        position: absolute;
        top: -3px;
        right: 0;
        background: none;
        padding: 0 10px;
        height: 34px;
      }
    }
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  ul {
    flex: 1;
  }

  nav {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      padding: 10px;
      background: transparent;
      border: 0;
      &.none {
        opacity: 0.2;
        cursor: default;
        &:hover {
          opacity: 0.2;
        }
      }
      img {
        height: 15px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }

  }
  button.report {
    border: none;
    height: 30px;
    padding: 0 10px;
    img {
      margin-right: 10px;
    }
  }
 
  ${props=>props.Lefter&&css`
    margin-left: 0px;
  `}
  ${props=>props.Full&&css`
    width: 100%;
  `}
  ${props=>props.Big&&css`
    width: 60%;
  `}
  ${props=>props.Little&&css`
    width: 40%;
    display: flex;
    flex-direction: column;
  `}
  ${props=>props.Middle&&css`
    width: 50%;
  `}
  ${props=>props.State&&css`
    margin: 5px;
    padding: 10px;
    border: 1px solid ${$.colorText($,0.09)};
    background: ${$.colorText($,0.025)};
    > div {
      align-items: center;
      margin: 0 0 10px;
      > strong {
        margin-right: 10px;
      }
      form {
        min-width: 130px;
        input {
          margin: 0;
        }
      }
    }
  `}
  ${props=>props.NoLeft&&css`
    margin-left: 0px;
  `}
  ${props=>props.NoTop&&css`
    margin-top: 0px;
  `}
  ${props=>props.Stock&&css`
    width: 100%;
    margin: 0px;
    > div {
      .searcher {
        margin-right: 20px;
        width: 25%;
        label {
          display: block;
        }
        input {
          ${$.fontSize($,12)}
          height: 34px;
          width: 100%;
          margin-bottom: 0px;
        }
        > button {
          padding: 0 10px;
          background: ${$.colorC};
          width: 100%;
          height: 34px;
        }
      }
    }
  `}

  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    form {
      position: relative;
      flex: 1;
      max-width: 50%;
      input {
        ${$.fontSize($,12)}
        height: 34px;
        width: 100%;
      }
      button {
        position: absolute;
        top: -3px;
        right: 0;
        background: none;
        padding: 0 10px;
        height: 34px;
      }
    }
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  ul {
    flex: 1;
  }

  nav {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      padding: 10px;
      background: transparent;
      border: 0;
      &.none {
        opacity: 0.2;
        cursor: default;
        &:hover {
          opacity: 0.2;
        }
      }
      img {
        height: 15px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const Status = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 20px;
  div {
    width: 50%;
    ${props=>props.Full&&css`
      width: 100%;
    `}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .reload {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #babec4;
      width: 39.64px;
      height: 39.64px;
      border-radius: 50%;
      img {
        filter: brightness(0.01) invert(1);
        width: 18px;
      }
    }
    > .reload,  > img {
      opacity: 0.5;
    }
    strong {
      color: ${$.colorB};
      ${$.fontSize($,30)}
      span {
        ${$.fontRelated($,40,30)}
      }
    }
    p {
      /* font-weight: ${$.bold};
      text-transform: uppercase; */
    }
    & + div {
      border-left: 1px solid ${$.colorText($,0.2)};
    }
  }
`;

export const List = styled.ul`
  ${props=>props.Image&&css`
    display: flex;
    flex-wrap: wrap;
    border: 1px solid ${$.colorText($,0.15)};
    background: ${$.colorText($,0.05)};
    padding: 2.5px;
    li {
      flex-shrink: 0;
      flex-flow: 0;
      width: 25%;
      padding: 2.5px;
      cursor: pointer;
      img {
        width: 100%;
        background: ${$.white};
        padding: 5px;
        border: 1px solid ${$.colorText($,0.15)};
      }
    }
    nav {
      width: 100%;
    }
  `}
  ${props=>props.Squared&&css`
    display: flex;
    flex-wrap: wrap;
    li {
      flex-shrink: 0;
      flex-flow: 0;
      width: 23%;
      margin: 1%;
      button {
        padding: 2.5px;
        border: 1px solid ${$.colorText($,0.15)};
        color: ${$.colorText($,0.7)};
        background: ${$.colorText($,0.05)};
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 0.6;
        }
        &.Selected {
          cursor: default;
          font-weight: ${$.bold};
          border: 2px solid ${$.correct};
          background: ${lighten(0.3,$.correct)};
          position: relative;
          &:before {
            content: attr(data-text);
            position: absolute;
            ${$.fontSize($,12)};
            padding: 5px 10px;
            background: ${$.correct};
            border-radius: 2.5px;
            bottom: -10px;
            color: ${$.white};
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  `}
`;

export const Item = styled.li`
  padding: 5px;
  a {
    display: block;
    width: 100%;
    color: ${$.colorText($,0.7)};
    &:hover {
      color: ${$.colorText($,1)};
    }
    ${props=>props.color&&css`
      padding-left: 10px;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 3px;
        height: 100%;
        background: ${props.color};
        border-radius: 100px;
        left: 0px;
      }
    `}
    ${props=>props.isConvertion&&css`
      display: flex;
      justify-content: space-between;
      span, em, strong {
        padding-right: 10px;
      }
    `}
  }
`;

export const Perfil = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 0;
  li + li & {
    border-top: 1px solid ${$.colorText($,0.13)};
  }
  > div {
    display: flex;
    align-items: center;
    > * {
      margin-right: 10px;
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 1000px;
      margin-right: 20px
    }
  }
  button {
    font-weight: ${$.bold};
    border: none;
    color: ${$.correct};
    text-transform: lowercase;
    padding: 0px;
    background: none;
    height: auto;
    /* ${props=>props.Clear&&css`
      color: ${$.colorE};
    `} */
    &.remove {
      color: ${$.error};
      &:before {
        content: '-';
      }
    }
    &.selected {
      color: ${$.colorC};
      &:before {
        content: '-';
      }
    }
    &:before {
      content: '+';
      display: inline-block;
      margin-right: 5px;
      ${props=>props.Clear&&css`
        display: none;
      `}
    }
    &:hover {
      opacity: 0.7;
      background: none;
    }
  }
`;

export const StatusBlock = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    width: 23.5%;
    box-sizing: border-box;
  }
  > li {
    width: 23.5%;
    box-sizing: border-box;
    margin: 5px;
    padding: 10px;
    border: 1px solid ${$.colorText($,0.13)};
    background: ${$.colorText($,0.03)};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    strong {
      /* text-align: center;
      display: inline-block;
      margin-bottom: 10px; */
    }
    p.state {
      width: 100%;
      background: ${$.colorB};
      ${$.gradient($)}
      padding: 5px 5px 5px 15px;
      margin: 20px 0px;
      color: ${$.white};
      position: relative;
      box-shadow: 0px 0px 5px 0px ${$.colorText($,0.2)};
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 5px;
        height: 100%;
        top: 0px;
        left: 0px;
        background: ${$.white};
      }
    }
    button {
      background: none;
      border: none;
      color: ${$.colorText($)};
      ${$.fontSize($,12)};
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export const Greetings = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > div {
    flex: 0.5
    h1 {
      color: ${$.colorB};
      padding: 0px !important;
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 10px;
    }
  }
  .imager {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      max-width: 180px;
      max-height: 90%;
    }
  }
`;

export const StatusBig = styled.div`
  flex: 1;
  margin-bottom: 20px;
  padding: 60px 0px;
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      margin-left: 30px;
      strong {
        color: ${$.colorB};
        ${$.fontSize($,30)}
        span {
          ${$.fontRelated($,40,30)}
        }
      }
      p {
        color: ${$.colorText($,0.5)};
        font-weight: ${$.bold};
        text-transform: uppercase;
      }
    }
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  button{
    display: flex;
    margin-left: 12px;
    justify-content: center;
    align-items: center;
    svg{
      margin-right: 6px;
    }
  }
`;

export const TitleContainer = styled.div`
  margin-right: 25px;
`;