import React, {useState,useEffect,useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Link,useHistory} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import {IoMdSettings} from 'react-icons/io';
import api from '~/services/api';

import {Block,List,Item,StatusBlock,ButtonContainer} from '~/pages/Dashboard/styles';

import save from '~/assets/icons/save.svg';
import search from '~/assets/icons/search.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import TopLoader from '~/assets/loading.gif';

export default function Status({parentId}) {
  const config = useSelector(state=>state.config.value);
  const history = useHistory();
  // INIT
  const profile = useSelector(state=>state.user.profile);
  const [topLoader,setTopLoader] = useState(true);
  useEffect(()=>{
    async function GetInitState(){
      const response = await api.get(`procedures/status`,{
        params: {
          project_id: parentId
        }
      });
      if(response.data.length !== 0){
        setStatus(response.data);
      }else{
        const response = await api.get(`procedures/status`);
        const datas = response.data.filter(item => item.project_id === null);
        setStatus(datas);
      }

      await Async(res=>StateSetup(response.data,res));
      setUpdate(!update);
    }
    GetInitState();
  },[parentId]);
  // STATUS
  const [status,setStatus] = useState([{}]);
  async function StateSetup(settedStatus,res){
    // PAGE SETUP
    var pageResult = {};
    settedStatus.map(async (s,index)=>{
      pageResult[s.id] = 1;
    });
    setPage(pageResult);
    // SEARCH SETUP
    var searchResult = {};
    settedStatus.map(async (s,index)=>{
      searchResult[s.id] = '';
    });
    setSearched(searchResult);
    // POST SETUP // https://sequelize.org/v4/manual/tutorial/querying.html
    const postResult = {};
    settedStatus.map(async (s,index)=>{
      postResult[s.id] = {
        list: [],
        paged: {}
      };
    });
    setPosts(postResult);
    res(true);
  }
  // PAGE
  const [page,setPage] = useState(false);
  function handlePage(i, string) {
    var pageResult = {};
    status.map(async (s,index)=>{
      if(s.id===string){
        pageResult[s.id] = i;
      } else {
        pageResult[s.id] = page[s.id];
      }
    });
    setPage(pageResult);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState(false);
  async function handleSearch({search,string}){
    var searchResult = {};
    status.map(async (s,index)=>{
      if(s.id===string){
        searchResult[s.id] = search;
      } else {
        searchResult[s.id] = searched[s.id];
      }
    });
    setSearched(searchResult);
    handlePage(1,string)
  }
  // POSTS
  const [posts,setPosts] = useState(false);
  const [update, setUpdate] = useState(true);
  
  useEffect(() => {
    async function getPosts() {
      try {
        if(status[0].id){
          var postResult = {};
          for (const index in status) {
              var s = status[index];
              const objecter = {
                params: {
                  perPage: 5,
                    filter: s.id,
                    first: (Number(index)===0)?1:'',
                    page: page[s.id] === undefined ? 1 : page[s.id],
                    search: searched[s.id],
                  }
              };
            const response = await api.get(`states/projects/${parentId}`,objecter);
            postResult[s.id] = response.data;
          }
          setPosts(postResult);
          setTopLoader(false);
        }
      } catch (error) {
        console.error(error)
      }
    }
    getPosts();
  },[update,parentId,searched,page,status]);

  // REPORT
  async function handleReport(){
    // const response = await api.get(`states/excel`);
    const response = await api.get(`states/${parentId}/excel`);
    setTimeout(() => {
      window.open(response.data.url, '_blank');
    }, 1000);
  }
  const handlesSetStatus = useCallback(() =>{
    history.push(`/projects/${parentId}/procedures`)
  },[history,parentId]);

  return (
    <Block Full>
      {topLoader?(
        <>
          <h2>{config.LANG['Production Status']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <div>
            <h2>{config.LANG['Production Status']}</h2>
            {(profile&&profile.role.level <= 2)&&(<>
              <ButtonContainer>
                <button className="report" type="button" onClick={handleReport}>
                  <img src={save} alt={config.LANG['Save']} />
                  {config.LANG['Report']}
                </button>
                {profile && profile.role.level === 0 && (
                  <button className="report" type="button" onClick={handlesSetStatus}>
                    <IoMdSettings color="#616161"/>
                    {config.LANG['Settings']}
                  </button>     
                )}  
              </ButtonContainer> 
            </>)}
          </div>
          {posts?(
            <>
              <StatusBlock>
                {status.map((s,index)=>(
                  <Block Full State key={s.id}>
                    <div>
                      <strong>{index+1}. {s.name} ({posts[s.id].paged.totalCount})</strong>
                      <Form onSubmit={({search})=>handleSearch({search,string:s.id})}>
                        <Input name="search" placeholder={`${config.LANG['Search']}…`} />
                        <button type="submit">
                          <img src={search} alt={config.LANG['Search']} />
                        </button>
                      </Form>
                    </div>
                      {posts[s.id].list[0]?(
                        <List>
                          {posts[s.id].list.map(post=>(
                            <Item key={post.id} color={post.subject.area.color}>
                              <Link to={`/units/${post.id}/view`}>{post.name}</Link>
                            </Item>
                          ))}
                        </List>
                      ):(
                        <p>{config.LANG['No units found!']}</p>
                      )}
                    {posts[s.id].paged.pages>1&&(
                      <nav>
                        <button className={posts[s.id].paged.page !== 1 ? null : 'none'} type="button" onClick={() => posts[s.id].paged.page !== 1 && handlePage(posts[s.id].paged.page - 1, s.id)}>
                          <img src={leftArrow} alt={config.LANG['Backward']} />
                        </button>
                        <button className={posts[s.id].paged.page<posts[s.id].paged.pages?null:'none'} type="button" onClick={()=>posts[s.id].paged.page<posts[s.id].paged.pages&&handlePage(posts[s.id].paged.page+1,s.id)}>
                          <img src={rightArrow} alt={config.LANG['Forward']} />
                        </button>
                      </nav>
                    )}
                  </Block>
                ))}
              </StatusBlock>
            </>
          ):(
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          )}
        </>
      )}
    </Block>
  );
}

const Async = (action=function(){},data={}) => new Promise((resolve,reject)=>{
	action(resolve,data);
});
