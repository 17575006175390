import React from 'react';
import { Container,ProjectsList } from './styles';

const ProjectSklaton = () => {
    return (
        <Container>
            <ProjectsList>
                <ul>
                    <li>
                        <p >
                            <br />
                            <br />
                            <br />
                            <br />
                            <br/>
                        </p>
                    </li>
                </ul>
            </ProjectsList>
            <ProjectsList>
                <ul>
                    <li>
                        <p >
                            <br />
                            <br />
                            <br />
                            <br />
                            <br/>
                        </p>
                    </li>
                </ul>
            </ProjectsList>
        </Container>
    );
}

export default ProjectSklaton;