import styled from 'styled-components';
import {$} from '~/styles/global';


export const SelectContainer = styled.select`
    margin-top: 10px;
    width: 100%;
    height: 30px;
    border: 1px solid rgba(80,80,80,0.15);
    background: transparent;
    border-radius: 5px;
    color: ${$.colorText($,0.6)};
    padding-left: 8px;
`;

export const Button = styled.button`
    width: auto;
    padding: 10px;
    background: transparent;
    border: 1px solid transparent;
    color: ${$.colorText};
    &:hover{
        /* opacity: 0.8; */
        background: ${$.colorText($,0.09)};
    }
`;