import React, {useState,useRef,useEffect} from 'react';
import {useField} from '@rocketseat/unform';

import api from '~/services/api';

import { Container } from './styles';

import logoWhite from '~/assets/logoWhite.svg';

export default function AvatarInput() {
  const { defaultValue, registerField, error } = useField('COMPANY_LOGO_OBJECT');

  const [file,setFile] = useState(defaultValue&&defaultValue.id);
  const [preview,setPreview] = useState(defaultValue&&defaultValue.url);

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: 'COMPANY_LOGO',
        ref: ref.current,
        path: 'dataset.file'
      });
    }
  },[ref,registerField]);

  useEffect(()=>{
    setFile(defaultValue&&defaultValue.id);
    setPreview(defaultValue&&defaultValue.url);
  },[defaultValue]);

  async function handleChange(e){
    const data = new FormData();

    data.append('file',e.target.files[0]);

    const response = await api.post('files',data);

    const {id,url} = response.data;

    setFile(id);
    setPreview(url);
  }
  function handleRemove(){
    setFile(null);
    setPreview(null);
  }
  return (
    <Container>
      <div>
        <label htmlFor="logo">
          <img src={preview||logoWhite} alt=""/>
          <input 
            type="file"
            id="logo"
            accept="image/*"
            data-file={file}
            onChange={handleChange}
            ref={ref}
          />
        </label>
        <div>
          <button type="button" onClick={handleRemove}>Remover Imagem</button>
          <label htmlFor="logo">Escolher imagem</label>
        </div>
      </div>
      {error&& <span className="error">{error}</span>}
    </Container>
  );
}
