import styled from 'styled-components';

import {$} from '~/styles/global';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 4px;
  margin-top: 10px;
  text-transform: lowercase;
  color: ${$.correct};
  font-weight: ${$.bold};
  border: none;
  background: none;

  &:hover {
    filter: contrast(0.5);
  }

  img {
    transform: rotate(180deg);
    margin-right: 6px;
    width: 14px;
  }
`;

export const Label = styled.label`
  font-weight: 700;
  color: ${$.correct};
  cursor: pointer; 
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  color: ${$.colorText($)};
  border: 1px solid #ddd;
  border-collapse: collapse;
  text-align: center;
  margin-bottom: 10px;

  thead {
    width: 100%;
    background-color: #dddddd;
    text-transform: uppercase;
    color: ${$.colorText($, 0.8)};
  }

  tbody {
    height: 620px;
    width: 100%;
  }

  tr > th {
    width: 100%;
    height: 70px;
  }

  tr > td {
    width: 100%;
    height: 60px;
  }
  
  tr:nth-child(even) {
    background-color: #f4f4f4;
  }
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 420px;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const EmailContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  background: ${$.colorText($,0.1)};
  padding: 8px; 
  border-radius: 5px;
  color: ${$.colorText($)};
  font-weight: ${$.bold}; 

  p {
    margin-right: 8px;
  }
`;