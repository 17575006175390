import React, {useState,useEffect,useMemo} from 'react';
import {useSelector} from 'react-redux';
import {parseISO,formatDistance} from 'date-fns';
import pt from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';

import api from '~/services/api';

import {Block} from '~/pages/Dashboard/styles';

import userImage from '~/assets/user.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import TopLoader from '~/assets/loading.gif';

export default function BackupsList({postId,callback,reload}) {
  const config = useSelector(state=>state.config.value);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  // useEffect(()=>{
  //   getPosts();
  // },[]);
  // useEffect(()=>{
  //   if(reload) getPosts();
  // },[reload]);
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  // useEffect(()=>{
  //   getPosts();
  // },[update]);

  useEffect(() => {
    async function getPosts(){
      try {
        const response = await api.get(`backups/units/${postId}`,{
          params:{
            perPage: 50,
            page:page,
          }
        });
        const options = {addSuffix:true};
        if(config.LANGUAGE==='pt_br') {
          options.locale = pt;
        } else if(config.LANGUAGE==='es_es') {
          options.locale = es;
        }
        const list = response.data.list?.map(backup=>({
          ...backup,
          timeDistance: formatDistance(
            parseISO(backup.createdAt),
            new Date(),
            options
          )
        }));
        const posts = {
          list,
          paged: response.data.paged,
        };
        setPosts(posts);
      } catch(err){}
      setTopLoader(false);
    }
    getPosts();
  }, [reload,config.LANGUAGE,postId,page]);

  
  const pageArray = useMemo(()=>{
    const thisArray = [];
    for (let i = 1; i <= posts.paged.pages; i++) {
      thisArray.push(i);
    }
    return thisArray;
  },[posts]);
  // HTML =====
  async function handleRestore(id){
    const response = await api.get(`backups/${id}`);
    callback(response.data.html);
  }
  return (
    <>
      {topLoader?(
        <>
          <h2>{config.LANG['Backup']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          {posts.list[0]?(<>
              {posts.list.map(post=>(
                <div key={post.id}>
                  <div>
                    <img className="user" src={(post.user.avatar)?post.user.avatar.url||userImage:userImage} alt={post.user.name}/>
                    <h3>{post.user.name}</h3>
                    <p>{post.timeDistance}</p>
                  </div>
                  <button className="save" type="button" onClick={()=>handleRestore(post.id)}>{config.LANG['Recover']}</button>
                </div>
              ))}
              {posts.paged.pages>1&&(
                <Block className="navigation">
                  <nav>
                    <div>
                      <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>handlePage(1)}>
                        <img src={leftArrow} alt={config.LANG['Backward']} />
                        <img src={leftArrow} alt=""/>
                      </button>

                      <button className={posts.paged.page!==1?null:'none'} type="button">
                        <img src={leftArrow} onClick={()=>handlePage(page-1)} alt={config.LANG['Backward']} />
                      </button>
                    </div>

                    <div>
                      {pageArray.map(i=>
                        <button key={i} className={i===posts.paged.page?'this':null} type="button" onClick={()=>i!==posts.paged.page&&handlePage(i)}>{i}</button>
                      )}
                    </div>

                    <div>
                      <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(page+1)}>
                        <img src={rightArrow} alt={config.LANG['Forward']} />
                      </button>

                      <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(posts.paged.pages)}>
                        <img src={rightArrow} alt={config.LANG['Forward']} />
                        <img src={rightArrow} alt=""/>
                      </button>
                    </div>
                  </nav>
                </Block>
              )}
          </>):(
            <p>Nenhum backup encontrado!</p>
          )}
        </>
      )}
    </>
  );
}