import React, {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import api from '~/services/api';

import {updateConfig} from '~/store/modules/config/actions';

import {Container,Title} from '~/components/Container';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';

import AvatarInput from '~/pages/Configurations/AvatarInput';
import Boolean from '~/components/Fields/Boolean';
import Select from '~/components/Fields/Select';
import Textarea from '~/components/Fields/Textarea';

import leftArrow from '~/assets/icons/leftArrow.svg';
import TopLoader from '~/assets/loading.gif';

import lang from '~/lang';

const schema = Yup.object().shape({
  COMPANY_NAME: Yup.string('Texto obrigatório').required('Valor obrigatório'),
  COMPANY_LOGO: Yup.number('Somente Número'),

  CONVERT_NUMBER: Yup.number('Somente Número').required('Valor obrigatório'),
  USER_NUMBER: Yup.number('Somente Número').required('Valor obrigatório'),

  AVAILABILITY: Yup.boolean('true ou false').required('Valor obrigatório'),
  HIDE_DELEGATED: Yup.boolean('true ou false').required('Valor obrigatório'),
  FREE_TRIAL: Yup.boolean('true ou false').required('Valor obrigatório'),
  LANGUAGE: Yup.string('Texto obrigatório').required('Valor obrigatório'),

  HIDE_JOB: Yup.boolean('true ou false').required('Valor obrigatório'),
});

const schemaMessage = Yup.object().shape({
  message: Yup.string('Texto obrigatório').required('Valor obrigatório'),
});

export default function EditSubject() {
  const config = useSelector(state => state.config.value);
  const dispatch = useDispatch();
  const [variables,setVariables] = useState({});
  const [topLoader, setTopLoader] = useState(true);

  // SOCKET
  const socket = useSelector(state=>state.socket.socket);
  function handleUpdate(e){
    e.preventDefault();
    if(socket){
      socket.emit('admin_update');
    }
  }

  function handleClearUpdate(e){
    e.preventDefault();
    if(socket){
      socket.emit('admin_update_done');
    }
  }

  // INIT  
  useEffect(() => {
    api.get('variables').then(response => {
      setVariables(response.data);
      setTopLoader(false);
    });
  }, []);
  
  async function handleSubmit(data){
    try {
      await api.put('variables',data);
      toast.success(`Configurações salvas com sucesso!`);
      GetConfig();
    } catch(err){
      toast.error('Dados inválidos. Tente novamente.');
    }
  }

  async function GetConfig(){
    const response = await api.get('variables');
    const variables = response.data;
    if(variables.COMPANY_LOGO) variables.COMPANY_LOGO = variables.COMPANY_LOGO_OBJECT.url;
    variables.LANG = lang[variables.LANGUAGE];
    dispatch(updateConfig(variables));
  }

  async function handleMessage(data){
    if(socket){
      socket.emit('admin_message',data);
    }
  }
  return (
    <>
      {topLoader?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </Container>
      ):(
        <Container>
          <Link to={`/dashboard`}>
            <img src={leftArrow} alt={config.LANG['Backward']} />
            {config.LANG['Dashboard']}
          </Link>
          <Title>{config.LANG['Settings']}</Title>
          <h2>Backend - {process.env.REACT_APP_BACKEND}</h2>
          <Form schema={schema} initialData={variables} onSubmit={handleSubmit}>

            <FormRow>
              <FormColumn>
                <label htmlFor="COMPANY_NAME">Nome da Empresa:*</label>
                <Input name="COMPANY_NAME" placeholder="Nome do cliente." autoComplete="off" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="COMPANY_LOGO">Logo da Empresa:*</label>
                <AvatarInput name="COMPANY_LOGO" />
              </FormColumn>

              <FormColumn>
                <label htmlFor="CONVERT_NUMBER">Quantidade de Conversões:*</label>
                <Input type="number" name="CONVERT_NUMBER" placeholder="Quantidade de Conversões" autoComplete="off" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="USER_NUMBER">Quantidade de Usuários:*</label>
                <Input type="number" name="USER_NUMBER" placeholder="Quantidade de Usuários" autoComplete="off" />
              </FormColumn>

              <FormColumn>
                <label htmlFor="AVAILABILITY">Sistema Liberado:*</label>
                <Boolean name="AVAILABILITY" />
              </FormColumn>
              <FormColumn>
                <label htmlFor="HIDE_DELEGATED">Esconder informações para não delegados:*</label>
                <Boolean name="HIDE_DELEGATED" />
              </FormColumn>

              <FormColumn>
                <label htmlFor="HIDE_JOB">Travar edição para apenas quem tem a obrigação:*</label>
                <Boolean name="HIDE_JOB" />
              </FormColumn>
              
              <FormColumn>
                <label htmlFor="FREE_TRIAL">Liberar free trial:*</label>
                <Boolean name="FREE_TRIAL" />
              </FormColumn>
              
              <FormColumn>
                <label htmlFor="LANGUAGE">Idioma:*</label>
                <Select name="LANGUAGE" options={[
                  {text:'Português',value:'pt_br'},
                  {text:'English',value:'en_us'},
                  {text:'Español',value:'es_es'}
                ]} />
              </FormColumn>
            </FormRow>
            
            <SubmitRow>
              <p>*Campos Obrigatórios</p>
              <button type="submit">Atualizar Configurações</button>
            </SubmitRow>
          </Form>
            
          <br/><br/><br/>
          <Title>Ações Administrativas</Title>
          <h2>Avisos:</h2>
          <SubmitRow>
            <div>
              <button className="delete" type="button" onClick={handleUpdate}>
                <span>Avisar Update</span>
              </button>
              <button className="add" type="button" onClick={handleClearUpdate}>
                <span>Limpar Update</span>
              </button>
            </div>
          </SubmitRow>
          <br/><br/>
          <h2>Comunicação:</h2>
          <Form schema={schemaMessage} onSubmit={handleMessage}>
            <FormColumn Line>
              <label htmlFor="message">Mensagem:*</label>
              <Textarea type="number" name="message" placeholder="Mensagem" autoComplete="off" />
            </FormColumn>
            <SubmitRow>
              <p>*Campos Obrigatórios</p>
              <button type="submit">Enviar</button>
            </SubmitRow>
          </Form>
        </Container>
      )}
    </>
  );
}
