import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';
import Menu from '~/components/Menu';

import { Wrapper, Main } from './styles';

export default function DefaultLayout({children}) {
  return (
    <Wrapper>
      <Menu />
      <Main>
        <Header />
        {children}
      </Main>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
};