import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
  > div {
    display: flex;
    label{
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      img {
        height: 80px;
        width: 80px;
        border: 3px solid #eee;
        background: #f7f7f7;
      }
      input {
        display: none;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      button, label {
        width: 100%;
        background: none;
        border: 1px solid ${$.colorText($,0.2)};
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-weight: ${$.regular};
        color: ${$.colorText($,0.7)};
        transition: background ${$.animation};
        margin-bottom: 10px;
        background: ${$.white};
        &:hover {
          background: ${$.colorText($,0.5)};
          color: ${$.white};
          opacity: 1;
        }
      }
    }
  }
`;

export const Parent = styled.div`
  > div {
    display: flex;
  }
`;

export const Button = styled.button`
  background: none;
  border: 1px solid ${$.colorText($,0.2)};
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-weight: ${$.regular};
  color: ${$.colorText($,0.7)};
  transition: background ${$.animation};
  background: ${$.white};
  margin: 8px 0px 12px 10px;
  &:hover {
    background: ${$.colorText($,0.5)};
    color: ${$.white};
    opacity: 1;
  }
`;
