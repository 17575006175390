import styled,{css} from 'styled-components';

import {$} from '~/styles/global';

export const BlackOut = styled.div`
  z-index: 20;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: ${$.colorText($,0.48)};

  display: flex;
  justify-content: center;
  align-items: center;

  ${props=>!props.Open&&css`
    display: none;
  `}

  .unavailable>& {
    opacity: 1;
  }
  ${props=>props.isFront&&css`
    z-index: 10000;
  `}
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: ${$.container}px;
  max-height: 90%;
  overflow: auto;
  background: ${$.white};
  border: 1px solid ${$.colorText($,0.20)};
  border-radius: 5px;
  padding: 50px 80px;
  ${props=>props.Tiny&&css`
    max-width: ${$.container/2}px;
    padding: 25px 40px;
  `}
  ${props=>props.isInteractions&&css`
    width: 90%;
    max-width: inherit;
    max-height: 90%;
    overflow: auto;
  `}
  > button {
    position: absolute;
    right: 10px;
    top: 0px;
    padding: 0 10px;
    background: 0;
    border: 0;
    &:hover {
      opacity: 0.7;
    }
    &.back {
      position: inherit;
      color: ${$.colorC};
      margin-bottom: 10px;
      img {
        margin-right: 10px;
      }
    }
  }
`;
