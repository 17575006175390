import React from 'react';
import {useSelector} from 'react-redux';


import {Block,Greetings} from '../styles';

import TopLoader from '~/assets/loading.gif';
import logoRealize from '~/assets/logo.svg';

export default function HelloWorld({projects,topLoader}) {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);

  return (
    <Block Full>
      {topLoader?(
        <>
          <Greetings>
            <div>
              <h1>{config.LANG['Hello']},</h1>
            </div>
          </Greetings>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`}/>
          </p>
        </>
      ):(
        <>
          <div>
            <Greetings>
              <div>
                <h1>{config.LANG['Hello']}, {profile&&profile.name}</h1>
                <p>{config.LANG['You have']} {projects.paged.totalCount} {config.LANG['Project(s) in progress.']}</p>
                <p>{config.LANG['Access a project to continue working!']}</p>
              </div>
              <div className="imager">
                <img src={config.COMPANY_LOGO?config.COMPANY_LOGO:logoRealize} alt="" />
              </div>
            </Greetings>
          </div>
        </>
      )}
    </Block>
  );
}
