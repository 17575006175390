import React,{useEffect,useState,useMemo,useCallback} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import Async from '~/services/Async';

import api from '~/services/api';

import save from '~/assets/icons/save.svg';

import savetimer from '~/pages/Units/editor/save/timer';

import Recover from '~/pages/Units/editor/popup/recover';

export default function Save({
    postId,
    editor,
    template,
    content,
    Sanitize,

    saveStatus,setSaveStatus,
    first=false,setFirst=function(){},
    LoadRevise=false,handleRecoverRevision=function(){},

    setNotSaved=function(){},

    openJob,
    setIframe,
    handleReload
}) {
  const config = useSelector(state=>state.config.value);
  // MEMO =====
  const saveTimer = useMemo(()=>new savetimer(),[]);
  const localStorageName = useMemo(()=>`@RealizeSystem-nonSavedUnit-${config.COMPANY_NAME}-${postId}`,[config,postId]);
  const recover = useMemo(()=>{
    const data = (saveStatus)?localStorage.getItem(localStorageName):null;
    return (data)?JSON.parse(data):data;
  },[localStorageName,saveStatus]);
  // STATE =====
  const [savedContent,setSavedContent] = useState(false);
  const [projectId,setProjectId] = useState(''); 
  const [procedureId,setProcedureId] = useState('')
  // EFFECT =====
  useEffect(()=>{
    api.get(`units/${postId}`).then(response =>{
      setProjectId(response.data.unit.subject.area.project_id)
    })

    api.get(`states/units/${postId}`).then(response => {
      console.log("response", response.data.list);
      setProcedureId(response.data.list[0].procedure_project_id)
    })

    if(editor) handleContentChange();
    async function handleContentChange(){
      if(!LoadRevise){
        if(!savedContent) {
          setSavedContent(content);
        } else {
          if(savedContent!==content){
            saveTimer.StartTimer('Você está há $TIMER$ minutos sem salvar. Salve a unidade para continuar o seu trabalho.');
            const clearContent = await Async(res=>Sanitize({editor,res,config,postId,noSaving:true}));
            localStorage.setItem(localStorageName,JSON.stringify({content:clearContent,date:new Date()}));
            setNotSaved(true);
          }
        }
      }
    }
  },[editor,content,LoadRevise,savedContent,config,postId]);
  useEffect(()=>{
    // componentWillUnmount
    return () => {saveTimer.StopTimer();}
  },[]);
  // CALLBACK =====
  const handleSave = useCallback(async()=>{
    if(editor&&template) EditInteraction();
    async function EditInteraction(){
      const clearContent = await Async(res=>Sanitize({editor,res,config,postId,sendComment:true}));
      setSaveStatus(false);
      try {
        const url = `edits/units/${postId}`;
        console.log("projectId",projectId)
        const query = {
          template: template.path,
          html: clearContent,
          project_id: Number(procedureId)
        };
        (first)
          ? await api.post(url,query)
          : await api.put(url,query);
        setIframe(true);
        saveTimer.StopTimer();
        localStorage.removeItem(localStorageName);
        setNotSaved(false);
        setTimeout(() => {
          handleReload();
        }, 1000);
        toast.success(config.LANG['Content saved successfully!']);
      } catch(err){
        toast.error(config.LANG['Incorrect information. Try again.']);
      }
      setSaveStatus(true);
      if(first) setFirst(false);
    }
  },[editor,template,first,localStorageName,postId,projectId,procedureId]);
  return (<>
    {(openJob)?(<>
      <button className="save" type="button" onClick={handleSave}>
        {saveStatus?(
          <>
            <img src={save} alt="" />
            {config.LANG['Save']}
          </>
        ):(
          <>
            {config.LANG['Processing']}...
          </>
        )}
      </button>
      <Recover editor={editor} recover={recover} handleRecoverRevision={handleRecoverRevision} />
    </>):(<></>)}
  </>);
}