import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import history from '~/services/history';

import {signOut} from '~/store/modules/auth/actions';
import {updateMenu} from '~/store/modules/menu/actions';

import { Container } from './styles';

import logo from '~/assets/logo.svg';

import dashboard from '~/assets/icons/dashboard.svg';
import users from '~/assets/icons/users.svg';
import areas from '~/assets/icons/areas.svg';
import logout from '~/assets/icons/logout.svg';

export default function Menu() {
  const config = useSelector(state=>state.config.value);
  const menu = useSelector(state=>state.menu.value);
  const profile = useSelector(state=>state.user.profile);
  const dispatch = useDispatch();
  function handleSignOut(e){
    e.preventDefault();
    dispatch(signOut());
  }
  if(
    history.location.pathname==='/dashboard'
    ||history.location.pathname==='/configurations'
    ||history.location.pathname.includes('/procedures')
    ||history.location.pathname.includes('/templates')
    ||history.location.pathname.includes('/overview')
  ){
    dispatch(updateMenu('dashboard'));
  } else if(
    history.location.pathname==='/profile'
    ||history.location.pathname==='/users'
    ||history.location.pathname.includes('/users')
  ){
    dispatch(updateMenu('users'));
  } else {
    dispatch(updateMenu('projects'));
  }
  return (
    <Container>
      <Link to="/dashboard">
        <img src={logo} alt="Realize"/>
      </Link>
      <div data-teste={history}>
        <Link 
          className="navigate"
          to="/dashboard"
          data-selected={menu==='dashboard'?'selected':''}
        >
          <img src={dashboard} alt=""/>
          <span>{config.LANG['Dashboard']}</span>
        </Link>
        {(profile&&profile.role.level<=2)&&(<>
          <Link 
          className="navigate"
          to="/users"
          data-selected={menu==='users'?'selected':''}>
            <img src={users} alt=""/>
            <span>{config.LANG['Users']}</span>
          </Link>
        </>)}
        <Link 
          className="navigate"
          to="/projects"
          data-selected={menu==='projects'?'selected':''}
        >
          <img src={areas} alt=""/>
          <span>{config.LANG['Projects']}</span>
        </Link>
      </div>
      <button className="navigate" type="button" onClick={handleSignOut}>
        <img src={logout} alt=""/>
        <span>{config.LANG['Logout']}</span>
      </button>
    </Container>
  );
}
