/* eslint-disable react-hooks/exhaustive-deps */
import React,{ useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as GlobalContainerStyle from '../../../components/Container/index'; 
import leftArrow from '~/assets/icons/leftArrow.svg';
import { Form } from '@rocketseat/unform';
import * as InternalForm from '~/components/InternalForm';
import * as Yup from 'yup';
import Fields from '~/pages/Units/editor/fields';
import {useParams} from 'react-router-dom';
import TopLoader from '~/assets/loading.gif';
import api from '~/services/api';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

// IMPORT
import PopUp from '~/components/PopUp';
import Import from '~/pages/Units/extras/import';
import close from '~/assets/icons/close.svg';

const END = 1500;

export const ProjectsExtra = () => {
    const config = useSelector(state => state.config.value);
    const [topload, setTopload] = useState(true);
    const [schema,setSchema] = useState(Yup.object().shape({}));
    const [fields,setFields] = useState(false);
    const [actual, setActual] = useState('');
    const [initalDatas, setInitalData] = useState(false);
    const [extrasId, setExtrasId] = useState(0);
    const { id } = useParams();
    const [exist, setExist] = useState(false);
    
    //recriando get template default

    useEffect(() => {
        async function getActualTemplate() {
            const {data:{list:templates}} = await api.get('templates',{
                params: {
                    perPage: 1000,
                }
            });
            try {
                const {data:{template}} = await api.get(`rules/single/projects/${id}`);
                const foundTemplate = templates.find(item => item.id === template.id);
                setActual(foundTemplate);
            } catch(error) {
                console.log(error);
                const foundTemplate = templates.find(item => item.default === true);
                setActual(foundTemplate);
            }
        }
        getActualTemplate();
    }, [id]);

    // recriando get template extras

    useEffect(() => {
        async function getTemplateExtras(){
            const {data:{id:extraId,value_json}} = await api.get(`/extras/custom/bylevel`, {
                params: {
                    project_id: id,
                    template: actual.path,
                }
            });
            setExtrasId(extraId);
            setInitalData(value_json);
            const {data:{field:fields}} = await api.get(`extras`,{
                params: {
                    template: actual.path,
                }
            });
            setFields(fields);
            const thisSchema = {};
            fields.map(field => {
                thisSchema[field.name] = readSchema(field);
                if(field.type==='multiple'){
                    thisSchema[`${field.name}-shadow`] = readSchema({...field,name:`${field.name}-shadow`,field:[{name:'available',required:false}],required:false});
                }
            });
            setSchema(Yup.object().shape(thisSchema));
            setTopload(false);
        }
        if(actual!=='') getTemplateExtras();
    }, [actual, id]);

    /*

    //pegando o template default

    useEffect(() => {
        async function getRules() {
            try {
                const responseRules = await api.get(`rules/single/projects/${id}`);
                const responseAllTemapltes = await api.get('templates');
                const templatePath = responseAllTemapltes.data.list.find(item => item.id === responseRules.data.template.id);
                setActual(templatePath);
            } catch (error) {
                setExist(true);
                console.error(error);
            }
        }
        getRules();
    }, [id]);

    useEffect(() => {
        if (exist) {
            async function getTemplate() {
                const responseTemplates = await api.get('templates');
                console.log('responseTemplates',responseTemplates);
                const getTemplateDefault = responseTemplates.data.list.find(item => item.default === true);
                setActual(getTemplateDefault);
            }
            getTemplate()
        }
    }, [exist,id]);

    //pegando o caminho para setFields
    useEffect(() => {
        let template;
        api.get(`/extras/custom/bylevel`, {
            params: {
                project_id: id,
            }
        }).then(response => {
            template = response.data.template;
            setExtrasId(response.data.id);
            setInitalData(response.data.value_json);
        });
        
        setTimeout(() => {            
            api.get(`extras`,{
                params: {
                    template: actual.path
                }
            }).then(response => {
                if (template) {
                    const dataProcess = ProcessData(response.data.field);
                    setInitalData(dataProcess);
                }
                setFields(response.data.field);
                const thisSchema = {};
                response.data.field.map(field => {
                        thisSchema[field.name] = readSchema(field);
                        if(field.type==='multiple'){
                            thisSchema[`${field.name}-shadow`] = readSchema({...field,name:`${field.name}-shadow`,field:[{name:'available',required:false}],required:false});
                        }
                    });
                setSchema(Yup.object().shape(thisSchema));
            }); 
        }, 200);

        setTimeout(() => {
            setTopload(false);
        }, END);
    }, [actual, id]); */
    
    const handleSubmit = useCallback(async (data) => {
        try {
            await api.post(`rules/single/projects/${id}`, {
                template_id: actual.id
            });
            if (extrasId === undefined) {
                await api.post(`/extras/custom/bylevel`, {
                    project_id: Number(id),
                    template_id: actual.id,
                    value_json: data
                });
            } else {
                await api.put(`extras/${extrasId}`,{
                    value_json: data,
                });
            }
            toast.success('Configurações atualizadas com sucesso');
        } catch (error) {
            toast.error('Ocorreu algum erro ao atualizar os templates, tente novamente');
        }
    }, [actual,id,extrasId]);
    
    function readSchema(field){
        if(field.type==='multiple'){
          const multipleSchema = {};
          field.field.map(multipleField=>{
            multipleSchema[multipleField.name] = readSchema(multipleField);
            // if(multipleField.type==='multiple'){
            //   multipleSchema[`${multipleField.name}-shadow`] = readSchema({...multipleField,name:`${multipleField.name}-shadow`,field:[{name:'available',required:false}],required:false});
            // }
          });
          if(field.required){
            return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
              Yup.object().shape(multipleSchema)
            ).required(config.LANG['Required field']);
          } else {
            return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
              Yup.object().shape(multipleSchema)
            );
          }
        } else if(field.required){
          return Yup.string().required(config.LANG['Required field']);
        } else {
          return Yup.string();
        }
    }
    
    /* function ProcessData(array){
        var object = {};
        for (let i in array) {
          let item = array[i];
          if(item.type==='multiple'){
            object[item.name] = ProcessData(item.field);
          } else {
            object[item.name] = item.default;
          }
        }
        return object;
      } */

      // IMPORT
  const [openImport,setOpenImport] = useState(false);
  function handleOpenImport(){
    setOpenImport(!openImport);
  }
  async function handleAddImport(unit){
    setInitalData(unit.extra.value);
    handleOpenImport();
  }

    return (
        <>
        {topload ?
            (<GlobalContainerStyle.Container>
                <p className="loaderHolder">
                  <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
                </p>
            </GlobalContainerStyle.Container>)
        :
            (<GlobalContainerStyle.Container>
                <Link to={`/projects`}>
                    <img src={leftArrow} alt={config.LANG['Backward']} />
                    {config.LANG['Projects']}
                </Link>
                <GlobalContainerStyle.Divider>
                    <GlobalContainerStyle.Title>Customizar projeto</GlobalContainerStyle.Title>
                    <div>
                        <button type="submit" onClick={handleOpenImport}>{config.LANG['Import']}</button>
                    </div>
                    </GlobalContainerStyle.Divider>
                <h2>{config.LANG['Current theme options']}</h2>
                    <Form schema={schema} initialData={initalDatas} onSubmit={handleSubmit}>
                    <InternalForm.FormRow>
                        {fields && fields.map((field, key) =>
                        <InternalForm.FormColumn key={field.name} Line={(
                            (fields.length===1)
                            ||(fields.length%2!==0&&key===fields.length-1)
                            ||field.type==='file'
                            )||field.full}>
                            <Fields field={field} />
                        </InternalForm.FormColumn>
                        )}
                    </InternalForm.FormRow>
                    <InternalForm.SubmitRow>
                        <p>*{config.LANG['Required Fields']}</p>
                        <div>
                            <button type="submit">{config.LANG['Update']}</button>
                        </div>
                    </InternalForm.SubmitRow>
                </Form>
                <PopUp Open={openImport} CallBack={handleOpenImport}>
                    <button type="button" onClick={handleOpenImport}>
                    <img src={close} alt={config.LANG['Close']}/>
                    </button>
                    <h1>{config.LANG['Import Customization']}</h1>
                    <br/><br/>
                    <p>{config.LANG['Import Customization from another Unit']}</p>
                    <Import postsId={actual.id} callBack={handleAddImport} />
                    <br/><br/>
                </PopUp>
            </GlobalContainerStyle.Container>
        )}
        </>
    );
}