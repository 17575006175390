import React,{useCallback} from 'react';
import {useSelector} from 'react-redux';

import {FormRow,FormColumn} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import close from '~/assets/icons/close.svg';

export default function DeleteToolTip({Open,Close,editor}) {
  const config = useSelector(state=>state.config.value);
  const handleDeleteToolTip = useCallback(()=>{
    if(Open&&editor) DeleteToolTip();
    function DeleteToolTip(){
      var element = editor.selection.getNode();
      element = CheckName(element);
      function CheckName(el){
        var name = el.nodeName.toLowerCase();
        if(name!=='span'&&el.getAttribute('class')!=='tooltip'){
          var pureText = document.createTextNode(el.textContent);
          var parentEl = el.parentNode;
          parentEl.replaceChild(pureText, el);
          el = CheckName(parentEl);
        }
        return el;
      }
      var pureText = document.createTextNode(element.textContent);
      element.parentNode.replaceChild(pureText,element);
      Close();
      editor.focus();
      editor.insertContent('');
    }
  },[Open,editor,Close]);
  return (
    <PopUp Tiny Open={Open} CallBack={Close}>
      <button type="button" onClick={Close}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>Deletar ToolTip</h1>
      <br/><br/>
      <p>Tem certeza que deseja deletar esse ToolTip?</p><br/>
      <p>Não será possível recuperá-lo depois.</p>
      <br/><br/>
      <FormRow>
        <FormColumn>
          <button className="add" type="button" onClick={handleDeleteToolTip}>{config.LANG['YES']}</button>
        </FormColumn>
        <FormColumn>
          <button className="delete" type="button" onClick={Close}>{config.LANG['NO']}</button>
        </FormColumn>
      </FormRow>
    </PopUp>
  );
}