import styled from 'styled-components';
import { $ } from '../global';

export const VideoContainer = styled.div`
margin-top: 20px;
    a{
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${$.colorWhite};
        text-decoration: none;
        transition: all 0.2s;
        font-weight: 600;
        background: ${$.colorA};
        padding: 7px;
        border-radius: 4px;
        svg{
            margin-right: 5px;
        } 
        &:hover{
            opacity: 0.8;
        }
    }
`;