import styled from 'styled-components';
import {lighten} from 'polished';

import {$} from '~/styles/global';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 2px;
  margin-top: 10px;
  text-transform: lowercase;
  color: ${$.correct};
  font-weight: ${$.bold};
  border: none;
  background: none;

  &:hover {
    filter: contrast(0.5);
  }

  img {
    transform: rotate(180deg);
    margin-right: 6px;
    width: 14px;
  }
`;

export const Label = styled.label`
  font-weight: 700;
  color: ${$.correct};
  cursor: pointer; 
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 420px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

export const Card = styled.div`
  transition: 0.4s;
  width: 100%;
  margin: 3px;
  border: 1px solid ${lighten(0.2, $.colorB)};
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  
  .area {
    background: ${$.colorB};
    color: ${$.white};
    margin-top: 0;
    font-weight: ${$.bold};
    text-align: center;
  }

  .subject {
    background: #efefef;
    margin-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  p {
    text-align: left;
    padding: 10px;
    word-break: break-all;
    margin-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
  }
`