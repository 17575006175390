import styled from 'styled-components';
import { $ } from '../../../styles/global';

export const Button = styled.button`
    width: auto;
    padding: 10px;
    background: transparent;
    border: 1px solid transparent;
    color: ${$.colorText};
    &:hover{
        /* opacity: 0.8; */
        background: ${$.colorText($,0.09)};
    }
`;