import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';

import {signOut} from '~/store/modules/auth/actions';

export default function SocketHandleUser() {
  const profile = useSelector(state=>state.user.profile);
  const socket = useSelector(state=>state.socket.socket);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(socket&&profile){
      socket.on('kick_user',({user_id})=>{
        if(Number(user_id)===profile.id) dispatch(signOut());
      });
    }
  },[socket,profile,dispatch]);
  return (<></>);
}