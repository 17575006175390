import styled, { keyframes } from 'styled-components';
import {$ } from '../../../styles/global';

export const Container = styled.div`
    display: flex;
`;


const shimer = keyframes`
    0%{
        background-position: 0% 0%;
    }
    100%{
        background-position: -135% 0%;
    }
`;


export const ProjectsList = styled.div`
width: 100%;
ul {
  padding: 0 7.5px;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1;
    min-width: 25%;
    p {
      color :${$.white};
      border-radius: 5px;
      /* ${$.gradientProject($)} */
      margin: 7.5px;
      box-sizing: border-box;
      padding: 40px 30px;
      background-image: linear-gradient(
          -90deg,
          #C1C6C9 0%,
          #e7edf1 50%,
          #C1C6C9 100%
      );
      background-size: 400% 400%;
      animation: ${shimer} 1.2s ease-in-out infinite;
      /* &:hover {
        ${$.gradient($)}
      } */
      display: flex;
      flex-direction: column;
      .title {
        text-transform: uppercase;
      }
      .subtitle {
        font-weight: ${$.bold};
        ${$.fontSize($,20)}
        margin-bottom: 30px;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
`;
