import styled from 'styled-components';

export const Container = styled.div`
  padding-right: 20px;
  button {
    background: transparent;
    border: none;
    font-size: 20px;
    margin: 5px;
  }
`;
