import styled from 'styled-components';

import {$} from '~/styles/global';

export const Editor = styled.div`
  width: 100%;
  a {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    ${$.gradient($)}
    padding: 50px 30px;
    color: ${$.white};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${$.fontSize($,25)}
    img {
      width: 50px;
      margin-bottom: 25px;
    }
    /* transition: opacity ${$.animation}; */
    opacity: 1;
    &:hover {
      opacity: 0.9;
    }
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      border: 4px dashed ${$.white};
    }
  }
`;

export const Convert = styled.button`
  width: 150px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  color: ${$.white};
  background: ${$.correct};
  font-weight: ${$.bold};
  border: 0;
  ${$.fontSize($,16)}
  border: 1px solid ${$.correct};
  img {
    width: 15px;
    margin-right: 10px;
    filter: contrast(5) invert(1);
  }
  &:hover {
    background: ${$.white};
    color: ${$.colorC};
    border: 1px solid ${$.colorC};
    img {
      filter: contrast(1) invert(0);
    }
  }
`;



export const Downloads = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  li {
    width: 100%;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: ${$.white};
      ${$.gradient($)}
      border-radius: 5px;
      text-align: center;
      font-weight: ${$.bold};
      margin: 5px 0;
      opacity: 1;
      ${$.fontSize($,20)}
      &:hover {
        opacity: 0.9;
      }
      img {
        margin-right: 10px;
      }
    }
    p {
      text-align: center;
      margin: 10px 0;
      width: 100%;
      &.Convertion {
        img {
          width: 50px;
          margin-bottom: 20px;
          filter: saturate(0.5) opacity(0.2);
        }
      }
    }
  }
`;

export const CheckboxSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 28px;
  div{
    display: flex;
    background: ${$.colorText($,0.1)};
    align-items: center;
    margin-top: 12px;
    border-radius: 5px;
    padding: 15px;
    &:checked{
        background-color: red;
    }
    label{
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      font-size: 14px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: ${$.colorText($,0.5)};
      font-weight: ${$.bold};
      flex:1;
      
    }
    input{
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      margin-right: 10px;
      &:checked ~ span{
        background-color: ${$.colorC};
      }
    }
    span{
      position: absolute;
      border: 1px solid rgba(80,80,80,0.5);
      top: -3px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #FFF;
      border-radius: 50%;
      &:after{
        content: "";
        position: absolute;
        top: 3px;
        left: 8px;
        width: 5px;
        height: 10px;
        border: solid white; 
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;