import {toast} from 'react-toastify';

import {DecodeData} from '~/pages/Units/editor/interaction/functions';

export function DeconstructInteraction(config,editor,interactions,res){
  // var element = editor.selection.getNode();
  var element= editor.getBody();
  var elements = element.querySelectorAll(':scope > *');
  Array.prototype.forEach.call(elements, function(el, i){
    if(el.getAttribute('data-interaction')==='true'){
      // console.log(el);
      var div = el.querySelector('div');
      if(div){
        var getValues = JSON.parse(div.textContent);
        for (const key in getValues) {
          getValues[key] = DecodeData(getValues,key);
        }
        // console.log(getValues);
        var fields = GetInteraction(el.getAttribute('class')).field;
        if(!fields){
          toast.error(config.LANG['There are interactions of another theme.']);
          toast.error(config.LANG['They were not loaded correctly.']);
        } else {
          div.innerHTML = TagfyData(getValues,fields);
        }
      } else { toast.error(config.LANG['Corrupted interaction. There are interactions with formatting errors. Return to the editor and correct the interactions.']); }
    }
  });
  function GetInteraction(className){
    var result = {field:false};
    interactions.map(group=>{
      group.interactions.map(interaction=>{
        if(className===interaction.slug)result=interaction;
      });
    });
    return result;
  }
  function TagfyData(object,fields){
    var string = '';
    for (let key in object) {
      var field = fields.find(function(item,index){
        return item['name']===key;
      });
      let isArray = Array.isArray(object[key]);
      if(isArray){
        var arrayString = '';
        for (const i in object[key]) {
          arrayString += `<multiple data-i="${i}">${TagfyData(object[key][i],field.field)}</multiple> `;
        }
        string += `<info data-type="${field.type}" data-wordCount="${field.wordCount}" data-name="${key}" data-structure="multiple">${arrayString}</info> `;
      } else {
        string += `<info data-type="${field.type}" data-wordCount="${field.wordCount}" data-name="${key}">${object[key]}</info> `;
      }
    }
    return string;
  }
  res(true);
}

export function ReconstructInteraction(config,editor,res){
  // var element = editor.selection.getNode();
  var element = editor.getBody();
  var elements = element.querySelectorAll(':scope > *');
  Array.prototype.forEach.call(elements, function(el, i){
    if(el.getAttribute('data-interaction')==='true'){
      // console.log(el);
      var div = el.querySelector(':scope > div');
      if(div){
        var newJSON = GetJSON(div);
        // console.log('newJSON',newJSON);
        div.innerHTML = JSON.stringify(newJSON);
      } else { toast.error(config.LANG['Corrupted interaction. Create it again. You may have deleted a line or important information.']); }
    }
  });
  function GetJSON(parent){
    var newJSON = {};
    var infos = parent.querySelectorAll(':scope > info');
    if(infos.length>0){
      Array.prototype.forEach.call(infos, function(info, i){
        var type = info.getAttribute('data-type');
        var name = info.getAttribute('data-name');
        var value = encodeURI(info.innerHTML);
        if(type==='multiple'){
          var multiples = info.querySelectorAll(':scope > multiple');
          var newARRAY = [];
          Array.prototype.forEach.call(multiples, function(multiple, i){
            newARRAY.push(GetJSON(multiple));
          });
          value = newARRAY;
        }
        newJSON[name] = value;
      });
    } else {
      newJSON = JSON.parse(parent.innerHTML);
    }
    return newJSON;
  }
  res(true);
}

export function RemoveError(editor,res){
  // var element = editor.selection.getNode();
  var element= editor.getBody();
  var errors = element.querySelectorAll('error');
  Array.prototype.forEach.call(errors, function(error, i){
    var pureText = document.createTextNode(error.textContent);
    error.parentNode.replaceChild(pureText, error);
  });
  res(true);
}

export function ClearErrorInteractionName(editor,res){
  // DELETA O PRIMEIRO ERRO NO NOME DA INTERAÇÃO
  // var element = editor.selection.getNode();
  var element = editor.getBody();
  var elements = element.querySelectorAll(':scope > *');
  Array.prototype.forEach.call(elements, function(el, i){
    if(el.getAttribute('data-interaction')==='true'){
      var error = el.querySelector(':scope > error');
      if(error){
        var pureText = document.createTextNode(error.textContent);
        error.parentNode.replaceChild(pureText, error);
      }
    }
  });
  var elements = element.querySelectorAll('info');
  Array.prototype.forEach.call(elements, function(el, i){
    if(el.innerHTML===''||el.innerHTML==='undefined'){
      el.setAttribute('data-hide','true');
    }
  });
  res(true);
}