import * as Yup from 'yup';

export function createSchema(interaction,config){
  const thisSchema = {};
  interaction.field.map(field=>{
    thisSchema[field.name] = readSchema(field,config);
    if(field.type==='multiple'){
      thisSchema[`${field.name}-shadow`] = readSchema({...field,name:`${field.name}-shadow`,field:[{name:'available',required:false}],required:false},config);
    }
  });
  return thisSchema;
}

export function readSchema(field,config){
  if(field.type==='multiple'){
    const multipleSchema = {};
    field.field.map(multipleField=>{
      multipleSchema[multipleField.name] = readSchema(multipleField,config);
      if(multipleField.type==='multiple'){
        multipleSchema[`${multipleField.name}-shadow`] = readSchema({...multipleField,name:`${multipleField.name}-shadow`,field:[{name:'available',required:false}],required:false},config);
      }
    });
    if(field.required){
      return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
        Yup.object().shape(multipleSchema)
      ).required(config.LANG['Required field']);
    } else {
      return Yup.array().of( // https://stackoverflow.com/questions/59197551/yup-deep-validation-in-array-of-objects
        Yup.object().shape(multipleSchema)
      );
    }
  } else if(field.required){
    return Yup.string().required(config.LANG['Required field']);
  } else {
    return Yup.string();
  }
}

export function DataEncoder(data){
  for (const key in data) {
    data[key] = EncodeData(data,key);
  }
  data = DeleteShadow(data);
  return data;
}

export function EncodeData(data,key){
  let isArray = Array.isArray(data[key]); 
  if(isArray){
    for (const index in data[key]) {
      for (const i in data[key][index]) {
        data[key][index][i] = EncodeData(data[key][index],i);

      }
    }
    if(!key.includes('-shadow')){
      data[key] = data[key].filter((item,index)=>{
        return data[`${key}-shadow`][index]['available']==='false';
      });
    }
  } else {
    data[key] = encodeURI(data[key]);
  }
  return data[key];
}

export function DecodeData(getValues,key){
  let isArray = Array.isArray(getValues[key]);
  if(isArray){
    for (const index in getValues[key]) {
      for (const i in getValues[key][index]) {
        getValues[key][index][i] = DecodeData(getValues[key][index],i);
      }
    }
  } else {
    getValues[key] = decodeURI(getValues[key]);
  }
  return getValues[key];
}

export function DeleteShadow(data){
  for (const key in data) {
    if(key.includes('-shadow')){
      delete data[key];
    } else {
      let isArray = Array.isArray(data[key]); 
      if(isArray){
        for (const i in data[key]) {
          data[key][i] = DeleteShadow(data[key][i]);
        }
      }
    }
  }
  return data;
}