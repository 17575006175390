import React, {useState,useEffect,useCallback} from 'react';

import api from '~/services/api';

import heart from '~/assets/icons/heart.svg';

export default function Convertions({postId}) {
  
  const [follower,setFollower] = useState(null);
  
  useEffect(()=>{
    api.get(`followers/single/units/${postId}`).then(response => {
      if(response.data.error === null){
        setFollower(null)
      }else{
        setFollower(response.data)
      }
    });
  },[postId]);

  // HANDLE FOLLOWER
  const handleFollower = useCallback( async () =>{
    if(follower){
      await api.delete(`followers/units/${postId}`);
      setFollower(null);
    } else {
      const response = await api.post(`followers/units/${postId}`);
      setFollower(response.data);
    }
  },[follower,postId]);


  return (
    <button className={follower?'yes':'none'} type="button" onClick={handleFollower}>
      <img src={heart} alt="Favoritar"/>
    </button>
  );
}
