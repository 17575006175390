import { all, put, takeLatest } from 'redux-saga/effects';
import socketio from 'socket.io-client';
import { setSocketSuccess } from './actions';


export function* setSocket({payload}){
  if(!payload) return;
  // console.tron.log('SOCKET CONNECTION ===========');
  // console.tron.log(payload);
  var user_id,user_data;
  if(payload.token){
    if(!payload.user) return;
    user_id = payload.user.id;
    const {password_hash,...data} = payload.user;
    user_data = JSON.stringify(data);
  } else {
    if(!payload.user.profile) return;
    user_id = payload.user.profile.id;
    const {password_hash,...data} = payload.user.profile;
    user_data = JSON.stringify(data);
  }
  // console.tron.log('USER ============',user_data);
  if(!user_id) return;
  // console.tron.log(user_id);
  
  // const url = process.env.NODE_ENV === 'development'?'http://localhost:3333':'https://dbanima.realize.pro.br';
  const url = process.env.REACT_APP_BACKEND;

  const socket = socketio(url, { query: { user_id, user_data } });

  // toast.success('Socket Sim!');

  yield put(setSocketSuccess(socket));
}

export default all([
  takeLatest('persist/REHYDRATE',setSocket),
  takeLatest('@auth/SIGN_IN_SUCCESS',setSocket)
]);