import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Block, Content } from './styles';

import logoWhite from '~/assets/logoWhite.svg';

export default function AuthLayout({children}) {
  return (
    <Wrapper>
      <Block>
        <img src={logoWhite} alt="Realize"/>
      </Block>
      <Block>
        <Content>
          {children}
        </Content>
      </Block>
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
};