import styled,{keyframes,css} from 'styled-components';

import {$} from '~/styles/global';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadRevision = styled.div`
  background: ${$.colorA};
  /* background: ${$.colorText($,0.8)}; */
  /* background: ${$.correct}; */
  padding: 10px;
  position: fixed;
  top: 42px;
  left: 0px;
  width: 50%;
  height: calc(100% - 42px);
  z-index: 10029;

  ${$.fontSize($,20)}

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: ${$.white};
  /* color: ${$.colorC}; */

  transition: all ${$.animate};
  /* opacity: 1; */
  border-radius: 0%;
  ${props=>props.Loading&&css`
    left: -50%;
    /* opacity: 0; */
    border-radius: 50%;
  `}

  ${props=>props.BehindModal&&css`
    z-index: 9990;
  `}

  img {
    max-width: 150px;
    margin-bottom: 10px;
    animation: ${rotate} 2s linear infinite;
  }
`;