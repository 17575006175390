import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: flex;
    > div+div {
      margin-left: 10px;
    }
  }
`;
