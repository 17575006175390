import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '~/services/api';
import history from '~/services/history';
import { signFailure, signInSuccess } from './actions';



export function* signIn({payload}){
  const {config} = payload;
  try{
    const {email,password} = payload;

    const response = yield call(api.post,'sessions',{
      email,
      password
    });

    const {token,user} = response.data;

    if(!user.available){
      toast.error('Usuário bloqueado.');
      yield put(signFailure());
      return;
    }

    api.defaults.headers['Authorization'] = `Bearer ${token}`;

    yield put(signInSuccess(token,user));

    history.push('/dashboard');
  } catch(err){
    toast.error(config.LANG['Authentication failed, check your data!']);
    yield put(signFailure());
  }
}

export function* signUp({payload}){
  try{
    const {name,email,password} = payload;
    yield call(api.post,'init',{
      name,
      email,
      password,
      role_id: 1
    });

    history.push('/');
  } catch(err){
    toast.error('Falha no cadastro, verifique os seus dados!');
    yield put(signFailure());
  }
}

export function* setToken({payload}){
  if(!payload) return;

  const {token} = payload.auth;
  if(token) {
    api.defaults.headers['Authorization'] = `Bearer ${token}`;
    // try{
    //   yield call(api.get,'variables');
    // } catch(err){
    //   yield put(signOut());
    // }
  };
}

export function signOutRefresh(){
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE',setToken),
  takeLatest('@auth/SIGN_IN_REQUEST',signIn),
  takeLatest('@auth/SIGN_UP_REQUEST',signUp),
  takeLatest('@auth/SIGN_OUT',signOutRefresh)
]);