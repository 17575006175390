import styled from 'styled-components';

import {$} from '~/styles/global';

export const FullText = styled.div`
  height: 150px;
  width: 100%;
  border: 1px solid ${$.colorText($,0.25)};
  background: ${$.white};
  padding: 10px;
  overflow: auto;
  margin-top: 5px;
`;