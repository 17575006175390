import { Form, Input } from '@rocketseat/unform';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import close from '~/assets/icons/close.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import search from '~/assets/icons/search.svg';
import TopLoader from '~/assets/loading.gif';
import { Title } from '~/components/Container';
import { FormColumn, FormRow } from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import { Block, Item, List, Perfil } from '~/pages/Dashboard/styles';
import api from '~/services/api';


export default function Projects({postId,parentId,callback}) {
  const config = useSelector(state=>state.config.value);
  // PARENT
  const [parent,setParent] = useState(true);
  useEffect(()=>{
    async function GetInitState(){
      const responseP = await api.get(`projects/${parentId}`);
      setParent(responseP.data);
    }
    GetInitState();
  },[parentId]);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  // useEffect(()=>{
  //   getPosts();
  // },[]);
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState(null);
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  useEffect(() => {
    async function getPosts(){
      const response = await api.get(`projects`,{
        params:{
          perPage: 5,
          page: page,
          search: searched,
        }
      });
      setPosts(response.data);
      setTopLoader(false);
    }
    getPosts();
  },[update,searched,page]);
 
  // UPDATE
  const [openUpdate,setOpenUpdate] = useState(false);
  function handleOpenUpdate(){
    setOpenUpdate(!openUpdate);
  }
  const [selectUpdate,setSelectUpdate] = useState(false);
  function handleSelectUpdate(e){
    const {value} = e.target;
    setSelectUpdate(value);
    handleOpenUpdate();
  }
  async function handleUpdate(){
    await api.put(`areas/${postId}`,{
      project_id: selectUpdate
    });
    callback(selectUpdate);
    handleOpenUpdate();
  }
  return (<>
    <hr className="separator" />
    <Title>{config.LANG['Change']} {config.LANG['Project']}</Title><br />
    <h3>{config.LANG['Project']} {config.LANG['Current']}: {parent&&parent.name}</h3><br />
    <Block Full Lefter>
      {topLoader?(
        <>
          <h2>{config.LANG['All']} {config.LANG['Projects']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <div>
            <h2>{config.LANG['All']} {config.LANG['Projects']} ({posts.paged.totalCount})</h2>
            <Form onSubmit={handleSearch}>
              <Input name="search" placeholder={`${config.LANG['Search']}…`} />
              <button type="submit">
                <img src={search} alt={config.LANG['Search']} />
              </button>
            </Form>
          </div>
            {posts.list[0]?(
              <List>
                {posts.list.map(post=>(
                  <Item key={post.id} color={post.color}>
                    <Perfil>
                      <div>
                        <p onClick={(e)=>e.preventDefault()}>{post.name}</p>
                      </div>
                      <button className={parent.id===post.id?('selected'):('add')} type="button" onClick={parent.id!==post.id&&handleSelectUpdate} value={post.id}>
                        {parent.id===post.id?(config.LANG['Selected']):(config.LANG['Select'])}
                      </button>
                    </Perfil>
                  </Item>
                ))}
              </List>
            ):(
              <p>{config.LANG['No projects found!']}</p>
            )}
          {posts.paged.pages>1&&(
            <nav>
              <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                <img src={leftArrow} alt={config.LANG['Backward']} />
              </button>
              <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                <img src={rightArrow} alt={config.LANG['Forward']} />
              </button>
            </nav>
          )}
        </>
      )}
    </Block>
    <PopUp Tiny Open={openUpdate} CallBack={handleOpenUpdate}>
      <button type="button" onClick={handleOpenUpdate}>
        <img src={close} alt={config.LANG['Close']} />
      </button>
      <h1>{config.LANG['Update']} {config.LANG['Project']}</h1>
      <br/><br/>
      <p>{config.LANG['Are you sure you want to choose a new Project for this Area?']}</p><br/>
      <br/><br/>
      <FormRow>
        <FormColumn>
          <button className="add" type="button" onClick={handleUpdate}>{config.LANG['YES']}</button>
        </FormColumn>
        <FormColumn>
          <button className="delete" type="button" onClick={handleOpenUpdate}>{config.LANG['NO']}</button>
        </FormColumn>
      </FormRow>
    </PopUp>
  </>);
}