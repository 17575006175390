import React, {useState,useRef,useEffect} from "react";
import {useSelector} from 'react-redux';
import * as S from '../styles';
import {MoreHolder,MorePopup} from '~/components/InternalList';

import {Link} from 'react-router-dom';
import more from '~/assets/icons/more.svg';

export default function More({post,callback,editLink}) {
  const config = useSelector(state=>state.config.value);
  // CLICK OUTSIDE
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // OPEN
  const [open,setOpen] = useState(false);
  function handleOpen(){
    setOpen(!open);
  }
  // DELETE
  function handleDelete(e){
    e.preventDefault();
    callback({
      type: 'delete',
      payload: { post }
    });
  }
  return (
    <MoreHolder Open={open} className="action-more" ref={wrapperRef} onClick={handleOpen}>
      <img src={more} alt={config.LANG['More']} />
      <MorePopup Open={open}>
        <Link to={editLink}>{config.LANG['Edit']}</Link>
        <S.Button onClick={handleDelete}>{post.available?config.LANG['Delete']:config.LANG['Recover']}</S.Button>
      </MorePopup>
    </MoreHolder>
  );
}