import React,{useEffect,useMemo} from 'react';
import socketio from 'socket.io-client';

export default function AutoRender({postId,post,handleReload}) {
  // console.log(`${process.env.REACT_APP_BACKEND}/unit_render`);
  const socketAutoRender = useMemo(()=>socketio(`${process.env.REACT_APP_BACKEND}/unit_render`,{
    query: {
      unit_id: postId,
      subject_id: post.subject.id,
      area_id: post.subject.area.id,
    }
  }),[postId,post]);
  useEffect(()=>{
    if(socketAutoRender){
      socketAutoRender.emit('render-unit');
      // console.log(`socketAutoRender.emit('render-unit');`);
      socketAutoRender.on('reload_unit',()=>{
        // console.log(`socketAutoRender.on('reload_unit',()=>{`);
        setTimeout(() => {
          handleReload();
        }, 1000);
      });
    }
    return () => {
      if(socketAutoRender){
        socketAutoRender.off('reload_unit');
        socketAutoRender.emit('disconnect');
        socketAutoRender.disconnect();
      }
    }
  },[socketAutoRender]);
  return (<></>);
}