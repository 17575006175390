import React, {useState,useRef,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Input,useField} from '@rocketseat/unform';

import api from '~/services/api';

import { Container, Parent, Button } from './styles';

import count from './count';

import logoWhite from '~/assets/logoWhite.svg';

import Pixabay from '~/components/Pixabay';

export default function AvatarInput(fieldValue) {
  count.num++;
  const config = useSelector(state=>state.config.value);
  const { defaultValue, registerField, error } = useField(fieldValue.name);

  const [file,setFile] = useState(defaultValue&&defaultValue);
  const [preview,setPreview] = useState(defaultValue&&defaultValue);

  const [loading,setLoading] = useState('');

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.file'
      });
    }
  },[ref,registerField]);

  useEffect(()=>{
    if(defaultValue==='undefined'){
      setFile(null);
      setPreview(null);
    } else {
      setFile(defaultValue&&defaultValue);
      setPreview(defaultValue&&defaultValue);
    }
  },[defaultValue]);

  async function handleChange(e){
    setLoading(`${config.LANG['Uploading file']}...`);
    const data = new FormData();

    data.append('file',e.target.files[0]);
    try {
      const response = await api.post('files',data);

      const {url} = response.data;

      setFile(url);
      setPreview(url);
      setLoading('');
    } catch(err){
      setLoading(config.LANG['An error has occurred. Try again or check the file size. Decrease the file size, if necessary.']);
    }
  }
  function handleRemove(){
    setFile(null);
    setPreview(null);
  }
  // STOCK IMAGE
  const [stock,setStock] = useState(false);
  function handleImageStock(){
    setStock(!stock);
  }
  // DOWNLOAD IMAGE
 async function handleDownload(link){
  setLoading(`${config.LANG['Uploading file']}...`);

  try {
    const response = await api.post('files/downloads',{url:link});

    const {url} = response.data;

    setFile(url);
    setPreview(url);
    setLoading('');
  } catch(err){
    setLoading(config.LANG['An error has occurred. Try again.']);
  }
 }
  return (<>
    <Parent>
      <div>
        <Container>
          <div>
            <label htmlFor={`avatar_${fieldValue.name}_${count.num}`}>
              <img src={preview||logoWhite} alt=""/>
              <input 
                type="file"
                id={`avatar_${fieldValue.name}_${count.num}`}
                accept="image/*"
                data-file={file}
                onChange={handleChange}
                ref={ref}
              />
            </label>
            <div>
              <button type="button" onClick={handleRemove}>{config.LANG['Remove Image']}</button>
              <label htmlFor={`avatar_${fieldValue.name}_${count.num}`}>{config.LANG['Choose image']}</label>
            </div>
          </div>
          {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={file} />)}
          { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
          <p>{loading}</p><br/>
        </Container>
        <Button type="button" onClick={handleImageStock}>{config.LANG['Image Stock']}</Button>
      </div>
      <Pixabay Stock Appear={stock} callback={handleDownload} />
    </Parent>
  </>);
}
