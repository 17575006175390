import axios from 'axios';
// axios.defaults.timeout = 0;

// var url = process.env.NODE_ENV === 'development'?'http://localhost:3333':'https://dbanima.realize.pro.br';
var url = process.env.REACT_APP_BACKEND;

const api = axios.create({
  baseURL: url,
  timeout: 0,
});

// api.defaults.headers['Access-Control-Allow-Origin'] = `*`;

export default api;