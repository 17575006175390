import styled, { css } from 'styled-components';

export const AccordionSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const AccordionItem = styled.button`
  background: #eee;
  color: #444;
  height: 60px;
  padding: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.4s ease;

  &:hover {
    background: #ccc;
  }

  .accordion-icon {
    margin-left: auto;
    transition: transform 0.4s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }
`;

export const AccordionTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
`;

export const AccordionContent = styled.div`
  background: #f6f6f6;
  padding: 18px;
  transition: max-height 0.4s ease;
  display: none;
  
  ${props=>props.active&&css`
    display: inherit;
  `}
`;