import React, {useState,useRef,useEffect} from 'react';
import {Input,useField} from '@rocketseat/unform';

import { SwatchesPicker, BlockPicker } from 'react-color';

import { Container } from './styles';

export default function Textarea(fieldValue) {
  const { defaultValue, registerField, error } = useField(fieldValue.name);
  const [value,setvalue] = useState(defaultValue&&defaultValue);

  const ref = useRef();

  useEffect(()=>{
    if(ref.current){ // Se existe algum valor
      registerField({
        name: fieldValue.name,
        ref: ref.current,
        path: 'dataset.value'
      });
    }
  },[ref,registerField,fieldValue.name]);

  useEffect(()=>{
    setvalue(defaultValue&&defaultValue);
  },[defaultValue]);

  // async function handleChange(e){
  //   setvalue(e.target.value);
  // }
  // COLOR
  function handleChangeComplete(c){
    setvalue(c.hex);
  }
  return (
    <Container>
      <div>
        <SwatchesPicker 
          color={value}
          onChangeComplete={handleChangeComplete}
          width="65%" 
        />
        <BlockPicker
          color={value}
          onChangeComplete={handleChangeComplete}
          colors={[]}
          triangle="hide"
          width="35%" 
        />
        <input type="hidden" data-value={value} ref={ref}/>
        {fieldValue.multiple&&(<Input type="hidden" name={fieldValue.name} value={value} />)}
        { (error&&!fieldValue.multiple) && <span className="error">{error}</span> }
      </div>
    </Container>
  );
}
