import styled from 'styled-components';
import {darken} from 'polished';

import {$} from '~/styles/global';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Block = styled.div`
  flex: 0.5;
  height: 100%;
  ${$.gradient($)}

  display: flex;
  justify-content: center;
  align-items: center;
  &+&{
    background: ${$.colorWhite};
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 300px;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    a {
      ${$.fontSize($,12)}
      color: ${$.colorC};
      margin: 15px 0 0;
      text-align: right;
      &:hover {
        color: ${darken(0.1,$.colorC)};
      }
    }
  }
`;