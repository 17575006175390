import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {parseISO,formatDistance} from 'date-fns';
import pt from 'date-fns/locale/pt';
import es from 'date-fns/locale/es';

import api from '~/services/api';

import {Block,List,Item} from '~/pages/Dashboard/styles';

import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import TopLoader from '~/assets/loading.gif';

export default function Convertions({parentId}) {
  const config = useSelector(state=>state.config.value);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
  
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update, setUpdate] = useState(true);
  
  useEffect(() => {
    async function getPosts(){
      const response = await api.get(`convertions/projects/${parentId}`,{
        params:{
          perPage: 5,
          page:page,
        }
      });
      const options = {addSuffix:true};
      if(config.LANGUAGE==='pt_br') {
        options.locale = pt;
      } else if(config.LANGUAGE==='es_es') {
        options.locale = es;
      }
      const datedList = response.data.list.map(convertion=>({
        ...convertion,
        timeDistance: formatDistance(
          parseISO(convertion.created_at),
          new Date(),
          options
        )
      }));
      setPosts({
        list: datedList,
        paged: response.data.paged
      });
      setTopLoader(false);
    }
    getPosts();
  }, [update,config.LANGUAGE,parentId,page]);
  
 
  return (
    <Block Big>
      {topLoader?(
        <>
          <h2>{config.LANG['Latest conversions']}</h2>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <h2>{config.LANG['Latest conversions']} ({posts.paged.totalCount})</h2>
          {posts.list[0]?(
            <List>
              {posts.list.map(convertion=>(
                <Item key={convertion.id} color={convertion.unit.subject.area.color} isConvertion>
                  <Link to={`/units/${convertion.unit.id}/view`}>
                    <span>{convertion.unit.name}</span>
                    <span>
                      <em>{config.LANG['By']} {convertion.user.name}</em>
                      <strong>{convertion.timeDistance}</strong>
                    </span>
                  </Link>
                </Item>
              ))}
            </List>
          ):(
            <p>{config.LANG['No conversions found!']}</p>
          )}
          {posts.paged.pages>1&&(
            <nav>
              <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                <img src={leftArrow} alt={config.LANG['Backward']} />
              </button>
              <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                <img src={rightArrow} alt={config.LANG['Forward']} />
              </button>
            </nav>
          )}
        </>
      )}
    </Block>
  );
}
