import React, {useState,useEffect,Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Form,Input} from '@rocketseat/unform';
import {toast} from 'react-toastify';

import api from '~/services/api';

import {Block,List,Item,Perfil} from '~/pages/Dashboard/styles';
import {Title} from '~/components/Container';

import TopLoader from '~/assets/loading.gif';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import search from '~/assets/icons/search.svg';
import {SelectContainer} from './styles';
import { useMemo } from 'react';


export default function Jobs({postId,openOutside}) {
  const config = useSelector(state=>state.config.value);
  const [listProjects, setListProjects] = useState([]);
  const [selected,setSeleted] = useState('');
  const [searched,setSearched] = useState('');
  const [projectID,setProjectID] = useState('');
  const [page,setPage] = useState(1);

  // INIT
  const [topLoader,setTopLoader] = useState(true);
  const [feedback,setFeedback] = useState({});

  
  useEffect(() => {
    api.get(`jobs/all/users/${postId}`).then(response => {
      const jobs = response.data;
      const oldFeedback = {...feedback};
      for (const key in jobs) {
        oldFeedback[jobs[key].procedure_id] = true;
      }
      setFeedback(oldFeedback);
    })
    api.get(`projects/detail`).then(response => {
    //  const array = [];
      // array.push({ project_id: 0, project_name: 'Padrão' })
      setListProjects(response.data.list);
      // response.data.list.map(item => array.push(item));
      
    })
  },[postId]);

  useEffect(() => {
    api.get(`procedures`,{
      params: {
        perPage: 10,
        page:page,
        search: searched,
        project_id: Number(selected),
      }}).then(response => {
        if(response.data.list.length === 0){
          setProjectID('');
        }else{
          setProjectID(selected);
        }
      });
  },[searched,selected,page])

  useEffect(() => {
    if(projectID === '' || projectID === '0'){
      api.get(`procedures`,{
        params: {
          perPage: 10,
          page,
          search: searched,
          order: 'order',
          ordernate: 'ASC',
          project_id: 0
        }
      }).then(response => {
        const nullProcedures = response.data.list.filter(item => item.project_id === null);
        const newDatas = {
          list: nullProcedures,
          paged: response.data.paged
        }
        setTopLoader(false);
        setPosts(newDatas);
      })
    }else{
      api.get(`procedures`,{
        params: {
          perPage: 10,
          page,
          search: searched,
          project_id: Number(selected),
          id_user: ''
        }
      }).then(response => {
        //quandor for um projeto default
        if(response.data.list.length === 0){
            api.get(`procedures`,{
              params: {
                perPage: 10,
                page:page,
                search: searched,
              }
            }).then(response => {
              const nullProcedures = response.data.list.filter(item => item.project_id === null);
              const newDatas = {
                list: nullProcedures,
                paged: response.data.paged
              }
              setTopLoader(false);
              setPosts(newDatas);
            })
        }else{
          setTopLoader(false);
          setPosts(response.data);
        }
      })
    }
    // getJobs();
  },[feedback,selected,searched,projectID,page])
  // USERS
  // PAGE
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }
  // POSTS
  const [posts,setPosts] = useState({
    users: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  
  const personalizeProjets = useMemo(() => {
    
    let array = [];
    let current;
   
    listProjects.map(item => {
      if (current !== item.project_id) {
        array.push(item);
      }
      current = item.project_id;
    })
    const dataDefault = {
      project_id: 0,
      project_name: 'Padrão'
    }
    
    array.push(dataDefault);
    return array;
  }, [listProjects]);
  // HANDLE JOBS
  async function handleAddJob(procedure){
    try {
      if(procedure.project_id !== null){
        await api.post(`jobs/users/${postId}`,{
          procedure_id:procedure.id,
          project_id: procedure.project_id
        });
      }else{
          await api.post(`jobs/users/${postId}`,{
          procedure_id:procedure.id,
          project_id: 0
        });
      }
      toast.success(`${config.LANG['Users have been successfully nominated for the obligation']} ${procedure.name} `);
      const oldFeedback = {...feedback};
      oldFeedback[procedure.id] = true;
      setFeedback(oldFeedback);
    } catch (error) {
      toast.error(`Usuário já obrigado para esse status ${procedure.name}`)
    }

  }
  async function handleDeleteJob(procedure){
    const oldFeedback = {...feedback};
    try {
      if(procedure.project_id !== null){
        await api.delete(`jobs/users/${postId}`,{
          params: {
            procedure_id: procedure.id,
            project_id: procedure.project_id
          }
        })
      }else{
        await api.delete(`jobs/users/${postId}`,{
          params: {
            procedure_id: procedure.id,
            project_id: 0
          }
        })
      }
      toast.success(`${config.LANG['Users have been successfully removed from the obligation']} ${procedure.name}`);
    } catch (error) {
      toast.error(`Usuário não pode ser removido desse status pois não está obrigado ${procedure.name}`)
    }
    delete oldFeedback[procedure.id];
    setFeedback(oldFeedback);
  }
  // FEEDBACK
  useEffect(()=>{
    async function Setup(){
      if(!openOutside){
        setFeedback({});
      }
    }
    Setup();
  },[openOutside]);

  return (<>
    <hr className="separator" />
    <Title>{config.LANG['Indicate Obligations']}</Title><br />
    <Block Full Lefter>
      {topLoader?(
        <>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <div>
            <h2>{config.LANG['Production Status']} ({posts.paged.totalCount})</h2>
            <Form onSubmit={handleSearch}>
              <Input name="search" placeholder={`${config.LANG['Search']}…`} />
              <button type="submit">
                <img src={search} alt={config.LANG['Search']} />
              </button>
            </Form>
          </div>
            <SelectContainer value={selected} onChange={e => setSeleted(e.target.value)} id="obligations">
              {/* <optgroup label="Default">  */}
                {personalizeProjets.map(item => (
                  item.project_id === 0 && 
                      <Fragment key={item.project_id}>
                          <option value={item.project_id}>{item.project_name}</option>
                      </Fragment>
                ))}  
              {/* </optgroup> */}
              <optgroup label="Personalizados">
                {personalizeProjets.map(item => (
                    item.project_id !== 0 && item.custom_project === true && 
                      <Fragment key={item.project_id}>
                          <option value={item.project_id}>{item.project_name}</option>
                      </Fragment>
                ))}  
              </optgroup>      
            </SelectContainer>
            {posts.list?(
              <List>
                {posts.list.map(post=>(
                  <Item key={post.id}>
                    <Perfil>
                      <div>
                        <strong>{post.name}</strong>
                      </div>
                      {!feedback[post.id]?(
                        <button type="button" onClick={()=>handleAddJob(post)}>{config.LANG['Indicate']}</button>
                      ):(
                        <button className="remove" type="button" onClick={()=>handleDeleteJob(post)}>{config.LANG['Remove']}</button>
                      )}
                    </Perfil>
                  </Item>
                ))}
              </List>
            ):(
              <p>{config.LANG['No users found!']}</p>
            )}
          {posts.paged.pages>1&&(
            <nav>
              <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                <img src={leftArrow} alt={config.LANG['Backward']} />
              </button>
              <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                <img src={rightArrow} alt={config.LANG['Forward']} />
              </button>
            </nav>
          )}
        </>
      )}
    </Block>
  </>);
}
