import React from 'react';
import {useSelector,useDispatch} from 'react-redux';

import {updateConfig} from '~/store/modules/config/actions';

import { Container } from './styles';

import lang from '~/lang';

const Language = ({callback}) => {
  const config = useSelector(state=>state.config.value);
  const dispatch = useDispatch();
  function GetConfig(language){
    const variables = {...config};
    variables.LANGUAGE = language;
    variables.LANG = lang[variables.LANGUAGE];
    dispatch(updateConfig(variables));
  }
  return (<Container>
    <button type="button" onClick={()=>GetConfig('pt_br')}><span role="img" aria-label="Português">🇧🇷</span></button>
    <button type="button" onClick={()=>GetConfig('en_us')}><span role="img" aria-label="English">🇺🇸</span></button>
    <button type="button" onClick={()=>GetConfig('es_es')}><span role="img" aria-label="Español">🇪🇸</span></button>
  </Container>);
};

export default Language;