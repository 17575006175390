import styled,{css} from 'styled-components';
import {darken} from 'polished';

import {$} from '~/styles/global';

export const Extras = styled.div`
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  a {
    ${$.buttonStyle($,css`
      display: flex;
      justify-content: center;
      align-items: center;
    `)}
    img {
      margin-right: 10px;
      max-width: 20px;
    }
  }
`;

export const Heart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  button {
    background: none;
    border: none;
    padding: 0px;
    margin: 0px;
    line-height: 0px;
    &.none {
      img {
        filter: saturate(0) brightness(1.4);
      }
    }
    img {
      padding: 10px;
      margin: 0px;
      line-height: 0px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  button {
    ${$.buttonStyle($)}
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: ${$.container}px;
  margin: 0 auto;
  padding: 30px 0;
  > a {
    display: block;
    border: 0;
    background: none;
    color: ${$.colorC};
    margin-bottom: 10px;
    img {
      margin-right: 10px;
    }
  }
  &.unavailable > * {
    opacity: 0.5;
  }
  p.loaderHolder {
    text-align: center;
    .toploader {
      max-width: 150px;
    }
  }
  ${props=>props.isEditor&&css`
      max-width: inherit;
      flex: 0.5;
      padding: 0px;
  `}
  ${props=>props.device&&css`
    flex: ${1-Number(props.device)};
  `}
  ${props=>props.device&&props.device==='1'&&css`
    width: 0px;
    overflow: hidden;
  `}
`;

export const Title = styled.h1`
  display: flex;
  span {
    font-size: 0.46em;
    font-weight: ${$.regular};
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 40px;
  }
`;

export const Subtitle = styled.h2`
  margin: 0;
  h1 + & {
    margin-top: -20px;
    margin-bottom: 40px;
    ${props=>props.noTop&&css`
      margin-top: 0px;
    `}
  }
`;

export const Canvas = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${props=>props.saving&&css`
    opacity: 0.5;
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${$.colorC};
  .middleWord {
    width: 50%;
    display: flex;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
    }
  }
  .status {
    color: ${$.white};
    padding-right: 10px;
  }
  button, a {
    color: ${$.white};
    background: none;
    border: 0;
    padding: 10px;
    ${$.fontSize($,16)}
    &.save {
      background: ${$.correct};
      img {
        height: 15px;
      }
    }
    &.finalize {
      background: ${$.error};
      img {
        height: 15px;
      }
    }
    &.add {
      ${$.buttonStyle($,css`
        background: ${$.correct};
      `,css`
        background: ${darken(0.15,$.correct)};
        color: ${$.white};
      `)}
    }
    &.delete {
      ${$.buttonStyle($,css`
        background: ${$.error};
      `,css`
        background: ${darken(0.15,$.error)};
        color: ${$.white};
      `)}
    }
    img {
      margin-right: 10px;
      filter: brightness(100);
    }
    &:hover, &.selected {
      background: ${$.white};
      color: ${$.colorC};
      img {
        filter: inherit;
      }
    }
  }
`;

export const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const Preview = styled.div`
  flex: 0.5;
  background: ${$.colorD};
  ${props=>props.device&&css`
    flex: ${props.device};
  `}
  iframe {
    width: 100%;
    padding-left: 10px;
    height: 100%;
    border: none;
    background: ${$.colorC};
    ${props=>props.device&&props.device==='1'&&css`
      padding-left: 0px;
    `}
  }
  div {
    opacity: 0.5;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${$.white};
    ${$.fontSize($,25)}
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 50px);
      height: calc(100% - 50px);
      border: 2.5px dashed ${$.white};
    }
  }
`;

export const Loading = styled.div`
  z-index: 10030;
  position: fixed;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100%;
  ${$.gradient($)};
  > * {
    font-weight: ${$.regular};
    color: ${$.white};
  }
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all ${$.animate};
  /* opacity: 1; */
  border-radius: 0%;

  ${props=>props.fade&&css`
    /* opacity: 0; */
    top: -100%;
    border-radius: 50%;
  `}

  img {
    max-width: 120px;
    margin-bottom: 20px;
  }
`;

export const ButtonBack = styled.button`
  border: 1px solid transparent;
  color: ${$.colorC};
  background: transparent;
  img{
    margin-right: 10px;
  }
`;