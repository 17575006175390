import axios from 'axios';

// http://wiki.languagetool.org/integration-on-websites
// https://languagetool.org/http-api/swagger-ui/#!/default/post_check
// https://any-api.com/languagetool_org/languagetool_org/docs/_check/POST

// CONFIG =====
export function languageToolDataGenerator(config){
  var language = 'en'; // https://languagetool.realize.pro.br/v2/languages
  if(config.LANGUAGE==='pt_br'){
    language = 'pt-BR';
  } else if(config.LANGUAGE==='es_es'){
    language = 'es';
  }
  return {
    // text: 'Um <b>teste feioso sempra!</b>',
    // data: `{"annotation":[
    //   {"text": "Um "},
    //   {"markup": "<b>"},
    //   {"text": "teste feioso sempra!"},
    //   {"markup": "</b>"}
    //  ]}`,
    language,// https://languagetool.realize.pro.br/v2/languages
    disabledRules: "WHITESPACE_RULE,ELLIPSIS,SMART_QUOTES,COMMA_PARENTHESIS_WHITESPACE,DOUBLE_PUNCTUATION,UPPERCASE_AFTER_COMMA,TOO_LONG_SENTENCE",
  };
}

// REVISION =====
export async function LanguageTool(editor,res,languageToolData){
  // START ============================================================
  // https://languagetool.org/http-api/swagger-ui/#!/default/post_check
  var clear = ClearHTMLEntities(editor.getContent());
  var annotation = CreateAnnotation(clear);
  languageToolData.data = annotation;
  const encodedParams = Object.keys(languageToolData).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(languageToolData[key]);
  }).join('&');
  axios({
    method: 'post',
    url: 'https://languagetool.realize.pro.br/v2/check',
    timeout: 100000,
    // data: 'text=Veja%20esta%20solu%C3%A7%C3%A3o%20no%20ar&#33;&language=pt-BR&enabledOnly=false',
    data: encodedParams,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept": "application/json",
    }
  })
  .then(function (response) {
      //handle success
      // console.log('data',response.data);
      // console.log('matches',response.data.matches);
      var matches = response.data.matches;
      var offsetExtra = 0;
      if(matches.length>0){
        for (let i in matches) {
          // console.log(matches[i]);
          // console.log(encodeURI(JSON.stringify(matches[i].replacements)));
          var errorTagStart = `<error class="revise" data-message="${matches[i].message}" data-code="${matches[i].rule.id}" data-replacements="${encodeURI(JSON.stringify(matches[i].replacements))}" style="display:inline-block;background-color: #e03e2d;">`;
          clear = Insert(clear,errorTagStart,matches[i].offset+offsetExtra);
          offsetExtra += errorTagStart.length;
          var errorTagEnd = `</error>`;
          clear = Insert(clear,errorTagEnd,matches[i].offset+matches[i].length+offsetExtra);
          offsetExtra += errorTagEnd.length;
        }
        // console.log(clear);
        clear = clear
          .split('<lt>').join('&lt;')
          .split('<gt>').join('&gt;');
          // console.log(clear);
          // console.log(response.data);
        editor.setContent(clear);
        res(clear);
      } else {
        res(clear);
      }
  })
  .catch(function (response) {
      //handle error
      // console.log('error',response);
      res(response);
  });
}
export async function LanguageToolNovo(editor,res,languageToolData){
  // GET ALL ELEMENTS REQUESTS ============================================================
  const requests = [];
  const elements = editor.getBody().querySelectorAll(':scope > *');
  Array.prototype.forEach.call(elements, function(el, i){
    if(el.getAttribute('data-interaction')==='true'){
      const infos = el.querySelectorAll('info');
      Array.prototype.forEach.call(infos, function(info, i){
        const type = info.getAttribute('data-type');
        const wordcount = info.getAttribute('data-wordcount');
        if(
          wordcount!=='false'&&
          type!=='boolean'&&
          type!=='file'&&
          !type.includes('file')
        ){
          if(type.includes('editor')){
            const infoElements = info.querySelectorAll(':scope > *');
            Array.prototype.forEach.call(infoElements, function(infoElement, i){
              createRequest(info);
            });
          } else {
            createRequest(info);
          }
        }
      });
    } else { // if(el.classList.contains('E-TOPICO'))
      createRequest(el);
    }
  });

  function createRequest(el){
    const clear = ClearHTMLEntities(el.innerHTML);
    const annotation = CreateAnnotation(clear);
    const data = {
      ...languageToolData,
      data: annotation,
    };
    const encodedParams = Object.keys(data).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join('&');

    requests.push({
      el,
      clear,
      encodedParams,
    });
  }

  // SEND ALL REQUESTS TO LANGUAGETOOL ============================================================
  requests.forEach((request,i)=>{
    axios({
      method: 'post',
      url: 'https://languagetool.realize.pro.br/v2/check',
      timeout: 100000,
      // data: 'text=Veja%20esta%20solu%C3%A7%C3%A3o%20no%20ar&#33;&language=pt-BR&enabledOnly=false',
      data: request.encodedParams,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json",
      }
    })
    .then(function (response) {
        //handle success
        // console.log('matches',response.data.matches);
        const matches = response.data.matches;
        if(matches.length>0){
          var offsetExtra = 0;
          for (let i in matches) {
            var errorTagStart = `<error class="revise" data-message="${matches[i].message}" data-code="${matches[i].rule.id}" data-replacements="${encodeURI(JSON.stringify(matches[i].replacements))}">`;
            request.clear = Insert(request.clear,errorTagStart,matches[i].offset+offsetExtra);
            offsetExtra += errorTagStart.length;
            var errorTagEnd = `</error>`;
            request.clear = Insert(request.clear,errorTagEnd,matches[i].offset+matches[i].length+offsetExtra);
            offsetExtra += errorTagEnd.length;
          }
          request.clear = request.clear
            .split('<lt>').join('&lt;')
            .split('<gt>').join('&gt;');
          request.el.innerHTML = request.clear;
          editor.insertContent('');
        }
        requests[i].done = true;
        EndRequests();
    })
    .catch(function (response) {
        //handle error
        // console.log('error',response);
    });
  });


  function EndRequests(){
    let done = true;
    for (let i = 0; i < requests.length; i++) {
      const request = requests[i];
      if(!request.done) done = false;
    }
    if(done) res(true);
  }
}

// CLEAR HTML ENTITIES - Transforma isso [Introdu&ccedil;&atilde;o] em isso [Introdução]
function ClearHTMLEntities(string){
  var decodeEntities = (function() {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');
    function decodeHTMLEntities (str) {
      if(str && typeof str === 'string') {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
      }
      return str;
    }
    return decodeHTMLEntities;
  })();
  var breakStart = string
    .split('&lt;').join('{==CODEOPEN==}')
    .split('&gt;').join('{==CODECLOSE==}')
    .split('<');
  for (let i in breakStart) {
    if(breakStart[i].includes('>')){
      var breakEnd = breakStart[i].split('>');
      breakEnd[1] = decodeEntities(breakEnd[1]);
      breakStart[i] = breakEnd
        .join('>')
        .split('{==CODEOPEN==}').join('<lt>')
        .split('{==CODECLOSE==}').join('<gt>');

    }
  }
  return breakStart.join('<');
}

// CREATE ANNOTATION - Transforma o html na linguagem de annotation do LanguageTool
function CreateAnnotation(string){
  var final = '';
  var breakStart = string.split('<');
  for (let i in breakStart) {
    if(breakStart[i].includes('>')){
      var breakEnd = breakStart[i].split('>');
      final += setValue(breakEnd[0],'markup');
      final += setValue(breakEnd[1]);
    } else {
      final += setValue(breakStart[i]);
    }
  }
  return `{"annotation":[${final.substring(0, final.length - 1)}]}`;
  function setValue(str,val='text'){
    if(val!=='text'||str!==''){
      if(str==='/info'){
        return `{"${val}": "<${str.split('"').join('\\"').split('\n').join(' ')}>", "interpretAs": "."},`;
      } else {
        return `{"${val}": "${(val!=='text')?'<':''}${str.split('"').join('\\"').split('\n').join(' ')}${(val!=='text')?'>':''}"},`;
      }
    } else {
      return '';
    }
  }
}

// INSERT - Insere a tag <span> com erros em volta do erro.
function Insert(main_string,ins_string,pos) {
  if(typeof(pos) == "undefined") {
    pos = 0;
  }
  if(typeof(ins_string) == "undefined") {
    ins_string = '';
  }
  return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
}