import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
  &>div {
    display: flex;
    align-items: center;

    background: ${$.white};
    border: 1px solid ${$.colorText($,0.15)};
    border-radius: 5px;
    padding: 15px;
    color: ${$.colorText($)};
    margin: 0 0 10px;
    &::placeholder {
      color: ${$.colorText($,0.4)};
    }
    &>div {
      width: 100%;
    }
  }
`;
