export function convertedUpdate(value){
    return {
      type: '@convertion/CONVERTION_UPDATE',
      payload: value
    };
}
  
export function convertedUpdateEnd(value){
    return {
      type: '@convertion/CONVERTION_END',
      payload: value
    };
  }