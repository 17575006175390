import produce from 'immer';

const INITIAL_STATE = { value: 'dashboard' };

export default function menu(state=INITIAL_STATE,action){
  return produce(state,draft=>{
    switch (action.type){
      case '@menu/UPDATE_MENU': {
        draft.value = action.payload;
        break;
      }
      default:
    }
  });
}