import React, {useEffect,useMemo,useState,Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {Form,Input} from '@rocketseat/unform';

import api from '~/services/api';

import {Container,Title} from '~/components/Container';
import {ListHeader,ListFilter,ListTable,ListPage,ListNone} from '~/components/InternalList';
import {FormRow,FormColumn,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import Select from '~/components/Select';
import ImportUsers from '~/components/ImportUsers';

import user from '~/assets/user.svg';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import add from '~/assets/icons/add.svg';
import search from '~/assets/icons/search.svg';
import grid from '~/assets/icons/grid.svg';
import list from '~/assets/icons/list.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

import More from '~/pages/Users/index/more';
import {SelectContainer} from './styles';
import Jobs from '~/pages/Users/index/jobs';
import Profile from '~/pages/Users/index/profile';

import ObjectSize from '~/util/ObjectSize';


export default function Users() {
  const config = useSelector(state=>state.config.value);
  const profile = useSelector(state=>state.user.profile);
  // INIT
  const url = `users`;
  const [topLoader,setTopLoader] = useState(true);
  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState('');
  const [searchFinal,setSearchedFinal] = useState(null);
  async function handleSearch({search}){
    setSearchedFinal(search);
    setSearched(search);
    handlePage(1);
  }
  function handleSearchChanges(e){
    setSearchedFinal(e.target.value);
  }
  // FILTERS
  const filterStart = [
    { href: 'date', url: `${url}`, order: 'id', ordernate: 'DESC', text: config.LANG['DATE'], class: 'selected'},
    { href: 'asc', url: `${url}`, order: 'name', ordernate: 'ASC', text: 'A—Z', class: null},
    { href: 'desc', url: `${url}`, order: 'name', ordernate: 'DESC', text: 'Z—A', class: null},
    { href: 'trash', url: `${url}/trash`, order: 'id', ordernate: 'DESC', text: config.LANG['TRASH'], class: null}  
  ];
  
  const [filters, setFilters] = useState(filterStart);
  
  // useEffect(() => {
  //   setFilters(filterStart);
  // },[config]);
  async function handleFilter(e,href){
    e&&e.preventDefault();
    const newFilters = filters.map(filter=>{
      if(filter.href===href){
        filter.class='selected';
      } else {
        filter.class=null;
      }
      return filter;
    });
    setFilters(newFilters);
    handleSearch({search:''});
  }
  // VIEW
  useEffect(()=>{
    async function GetView(){
      var viewFilter = localStorage.getItem('viewFilter');
      if(viewFilter) handleView({preventDefault:()=>true},viewFilter);
    }
    GetView();
  }, []);
  
  const [views,setViews] = useState([
    {'href':'grid','src':grid,'text':config.LANG['Block'],'class':null},
    {'href':'list','src':list,'text':config.LANG['List'],'class':'choosed'}
  ]);
  const [isBlock,setIsBlock] = useState(false);
  function handleView(e,href){
    e.preventDefault();
    localStorage.setItem('viewFilter',href);
    const newViews = views.map(view=>{
      if(view.href===href){
        view.class='choosed';
      } else {
        view.class=null;
      }
      return view;
    });
    setViews(newViews);
    setIsBlock((href==='grid')?true:false);
  }
  // POSTS
  const [posts,setPosts] = useState({
    users: [],
    paged: {}
  });
  const [update, setUpdate] = useState(true);
  
  const filter = filters.find(function(item,index){
    return item['class']==='selected';
  });

  useEffect(() => {
    async function getPosts(){
      setChecked({});
      setDeleteAll(false);
  
      const response = await api.get(filter.url,{
        params:{
          order: filter.order,
          ordernate: filter.ordernate,
          perPage: 20,
          page:page,
          search: searched,
        }
      });
      setPosts(response.data);
      setTopLoader(false);
    }
    getPosts();
  },[update,filter.order,filter.ordernate,searched,filter.url,page]);
  
  const pageArray = useMemo(()=>{
    const thisArray = [];
    for (let i = 1; i <= posts.paged.pages; i++) {
      thisArray.push(i);
    }
    return thisArray;
  },[posts]);
  // CREATE
  const [openCreate,setOpenCreate] = useState(false);
  function handleOpenCreate(){
    setOpenCreate(!openCreate);
  }
  async function handleAdd(data){
    try {
      const reply = await api.post(`/users`,data);

      handleFilter(null,'date');

      handleOpenCreate();

      toast.success(`${config.LANG['We have sent a creation email to']} ${reply.data.email}.`);
    } catch(err){
      toast.error(`${config.LANG['Incorrect information. Try again.']} ${config.LANG['Check the user limit']}`);
    }
  }
  // CHECK
  const [checked,setChecked] = useState({});
  function handleChecked(u){
    const newChecked = checked;
    if(checked[u]){
      delete newChecked[u];
    } else {
      newChecked[u] = true;
    }
    var countChecked = 0;
    for (const key in newChecked) {
      key==='essa função é'&&console.log('sem utilidade');
      countChecked++;
    }
    if(countChecked!==0){
      setDeleteAll(true);
    } else {
      setDeleteAll(false);
    }
    setChecked({...newChecked});
  }
  function handleCheckAll(conditional){
    if(conditional){
      setChecked({});
      setDeleteAll(false);
    } else {
      if(posts.users[0]) {
        const newChecked = {};
        for (const key in posts.users) {
          newChecked[posts.users[key].id] = true;
        }
        setChecked({...newChecked});
        setDeleteAll(true);
      }
    }
  }
  // DELETE ALL
  const [deleteAll,setDeleteAll] = useState(false);
  const [openDelete,setOpenDelete] = useState(false);
  function handleOpenDelete(){
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    const updatingUsers = [];
    for (const key in checked) { updatingUsers.push(Number(key)); }
    try {
      await api.put(`users/bulk`,{
        users: updatingUsers,
        available: false,
      });
      toast.success(config.LANG['Successfully deleted users!']);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  async function handleRecover(){
    setPosts({
      users: [],
      paged: {}
    });
    const updatingUsers = [];
    for (const key in checked) { updatingUsers.push(Number(key)); }
    try {
      await api.put(`users/bulk`,{
        users: updatingUsers,
        available: true,
      });
      toast.success(config.LANG['Users successfully recovered!']);
    } catch(err){
      toast.error(`${config.LANG['Incorrect information. Try again.']} ${config.LANG['Check the number of users on your plan.']}`);
    }
    handleFilter(null,'date');
    handleOpenDelete();
  }
  // DELETE SINGLE
  const [deleteSingle,setDeleteSingle] = useState(false);
  const [openDeleteSingle,setOpenDeleteSingle] = useState(false);
  const [listProjects, setListProjects] = useState([]);

  function handleOpenDeleteSingle(){
    setOpenDeleteSingle(!openDeleteSingle);
  }
  async function handleDeleteSingle(){
    try {
      await api.delete(`users/${deleteSingle.id}`);
      toast.success(config.LANG['User successfully deleted!']);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  async function handleRecoverSingle(){
    try {
      const thisPost = posts.users.find(u=>u.id===parseInt(deleteSingle.id));
      await api.put(`users/${deleteSingle.id}`,{
        available: true,
        role_id: thisPost.role_id
      });
      toast.success(config.LANG['User successfully recovered!']);
    } catch(err){
      toast.error(config.LANG['An error has occurred. Try again.']);
    }
    handleFilter(null,'date');
    handleOpenDeleteSingle();
  }
  function handleCallbackMore({type,payload}){
    if(type==='delete'){
      setDeleteSingle(payload.post);
      handleOpenDeleteSingle();
    }
  }
  // SCHEMAS
  const schemaCreate = Yup.object().shape({
    name: Yup.string().required(config.LANG['Required field']),
    email: Yup.string().email(config.LANG['Enter a valid email']).required(config.LANG['Email is required']),
    password: Yup.string().min(6,config.LANG['At least 6 characters']).required(config.LANG['Password is required']),
    confirmPassword: Yup.string().when('password',(password,field)=>
      password?field.required(config.LANG['Confirm your password']).oneOf([Yup.ref('password')],config.LANG['Password is different from the one entered above.']):field
    ),
    role_id: Yup.number()
  });
  // JOB
  const [openJob,setOpenJob] = useState(false);
  const [selected,setSeleted] = useState('');
  function handleOpenJob(){
    setOpenJob(!openJob);
  }
  // PROFILE
  const [openProfile,setOpenProfile] = useState(false);
  function handleOpenProfile(){
    setOpenProfile(!openProfile);
  }
  async function handleProfile(){
    setPosts({
      users: [],
      paged: {}
    });
    setUpdate(!update);
    handleOpenProfile();
  }
  useEffect(() => {
    api.get(`projects/detail`).then(response => {
      setListProjects(response.data.list)
    })
  }, []);
  
  const namesOfProject = useMemo(() =>{
   const namesFilter = listProjects.filter(a => a.id === Number(selected));
   return namesFilter[0]?.name
  }, [selected, listProjects])
  
    
  const personalizeProjets = useMemo(() => {
    
    let array = [];
    
    let current;
    listProjects.map(item => {
      if (current !== item.project_id) {
        array.push(item);
      }
      current = item.project_id;
    })
    const dataDefault = {
      project_id: 0,
      project_name: 'Padrão'
    }
    
    array.push(dataDefault);
    return array;
  }, [listProjects]);

  // RENDER
    return (
      <>
        {topLoader?(
          <Container>
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          </Container>
        ):(
          <Container>
            <Link to="/dashboard">
              <img src={leftArrow} alt={config.LANG['Backward']} />
              {config.LANG['Dashboard']}
            </Link>
            <Title>{config.LANG['List of']} {config.LANG['Users']} <span>({posts.paged.totalCount})</span></Title>

            <ListHeader>
              <div>
                <button className="icon add" type="button" onClick={handleOpenCreate}>
                  <img src={add} alt={config.LANG['Add']} />
                  {config.LANG['Add']} {config.LANG['User']}
                </button>
                {deleteAll&&(<>
                  <button className="outline delete" type="button" onClick={handleOpenDelete}>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?config.LANG['Delete']
                        :config.LANG['Recover']
                    }
                  </button>
                  <button className="outline delete" type="button" onClick={handleOpenJob}>{config.LANG['Obligations']}</button>
                  <button className="outline delete" type="button" onClick={handleOpenProfile}>{config.LANG['Profile']}</button>
                </>)}
              </div>
              <Form onSubmit={handleSearch}>
                <Input onChange={handleSearchChanges} name="search" placeholder={`${config.LANG['Search']}…`} value={searchFinal} />
                <button type="submit">
                  <img src={search} alt={config.LANG['Search']} />
                </button>
              </Form>
            </ListHeader>
            
            {(profile&&profile.role.level<=2)&&(<>
              <ImportUsers Close={() => handlePage(1)}/>
            </>)}

            <ListFilter>
              <div>
                {filters.map(filter=>(
                  <a key={filter.href} href={filter.href} className={filter.class||'none'} onClick={(e)=>handleFilter(e,filter.href)}>{filter.text}</a>
                ))}
              </div>
              <div>
                {views.map(view=>(
                  <a key={view.href} href={view.href} className={view.class||'none'} onClick={(e)=>handleView(e,view.href)}><img src={view.src} alt={view.text}/></a>
                ))}
              </div>
            </ListFilter>

            {posts.users[0]?(
              <ListTable isBlock={isBlock}>
                <thead>
                  <tr>
                    <th>
                      <button className={`checker${(ObjectSize(checked)===posts.users.length)?` selected`:''}`} type="button" onClick={()=>handleCheckAll(ObjectSize(checked)===posts.users.length)}></button>
                    </th>
                    <th className="image"/>
                    <th>
                      <h3>{config.LANG['User']}</h3>
                    </th>
                    <th>
                      <h3>{config.LANG['Email']}</h3>
                    </th>
                    <th>
                      <h3>{config.LANG['Function']}</h3>
                    </th>
                    <th/>
                  </tr>
                </thead>
                <tbody>
                  {posts.users.map(u=>(
                    <tr key={u.id}>
                      <td>
                        <button className={`checker${checked[u.id]?` selected`:''}`} type="button" onClick={()=>handleChecked(u.id)}></button>
                      </td>
                      <td className="image">
                        <img src={u.avatar?u.avatar.url:user} alt={u.name}/>
                      </td>
                      <td>
                        <p><strong>{u.name}</strong></p>
                      </td>
                      <td>
                        <p>{u.email}</p>
                      </td>
                      <td className="last">
                        <p><em>{u.role.name}</em></p>
                      </td>
                      <td className="more">
                        {(profile&&profile.id===u.id)
                          ?(
                            <More editLink={`/profile`} post={u} callback={handleCallbackMore} />
                          )
                          :(
                            <More editLink={`/users/${u.id}`} post={u} callback={handleCallbackMore} />
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ListTable>
            ):(
              <ListNone>{config.LANG['No users found!']}</ListNone>
            )}

            {posts.paged.pages>1&&(
              <ListPage data-totalpage={posts.paged.pages} data-page={posts.paged.page}>
                <div>
                  <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>handlePage(1)}>
                    <img src={leftArrow} alt={config.LANG['Backward']} />
                    <img src={leftArrow} alt=""/>
                  </button>

                  <button className={posts.paged.page!==1?null:'none'} type="button">
                    <img src={leftArrow} onClick={()=>handlePage(page-1)} alt={config.LANG['Backward']} />
                  </button>
                </div>

                <div>
                  {pageArray.map(i=>
                    <button key={i} className={i===posts.paged.page?'this':null} type="button" onClick={()=>i!==posts.paged.page&&handlePage(i)}>{i}</button>
                  )}
                </div>

                <div>
                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(page+1)}>
                    <img src={rightArrow} alt={config.LANG['Forward']} />
                  </button>

                  <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>handlePage(posts.paged.pages)}>
                    <img src={rightArrow} alt={config.LANG['Forward']} />
                    <img src={rightArrow} alt=""/>
                  </button>
                </div>
              </ListPage>
            )}
            
            <PopUp Open={openCreate} CallBack={handleOpenCreate}>
              <button type="button" onClick={handleOpenCreate}>
                <img src={close} alt={config.LANG['Close']} />
              </button>
              <Form schema={schemaCreate} onSubmit={handleAdd}>
                <h2>{config.LANG['Add']} {config.LANG['User']}</h2>
                <FormRow>
                  <FormColumn>
                    <label htmlFor="name">{config.LANG['Full Name']}:*</label>
                    <Input name="name" placeholder={config.LANG['Full Name']} autoComplete="off" />
                  </FormColumn>
                  <FormColumn>
                    <label htmlFor="password">{config.LANG['Password']}:*</label>
                    <Input type="password" name="password" placeholder={config.LANG['Password']} autoComplete="off" />
                  </FormColumn>
                  <FormColumn>
                    <label htmlFor="email">{config.LANG['Email']}:*</label>
                    <Input name="email" type="email" placeholder={config.LANG['Your e-mail address']} autoComplete="off" />
                  </FormColumn>
                  <FormColumn>
                    <label htmlFor="password">{config.LANG['Confirm your password']}:*</label>
                    <Input type="password" name="confirmPassword" placeholder={config.LANG['Confirm your password']} autoComplete="off" />
                  </FormColumn>
                  <FormColumn>
                    <label htmlFor="role_id">{config.LANG['Profile']}:*</label>
                    <Select name="role_id" />
                  </FormColumn>
                </FormRow>
                
                <SubmitRow>
                  <p>*{config.LANG['Required Fields']}</p>
                  <div>
                    <button type="submit">{config.LANG['Add']}</button>
                  </div>
                </SubmitRow>
              </Form>
            </PopUp>

            <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
              <button type="button" onClick={handleOpenDelete}>
                <img src={close} alt={config.LANG['Close']} />
              </button>
              {
                !filters.find(f=>f.href==='trash'&&f.class!==null)
                  ?(
                    <>
                      <h1>{config.LANG['Delete']} {config.LANG['Users']}</h1>
                      <br/><br/>
                      <p>{config.LANG['Are you sure you want to delete']} {config.LANG['these']} {config.LANG['Users']}?</p><br/>
                      <p>{config.LANG['They will be saved in the trash and you can recover them later.']}</p>
                      <br/><br/>
                    </>
                  )
                  :(
                    <>
                      <h1>{config.LANG['Recover']} {config.LANG['Users']}</h1>
                      <br/><br/>
                      <p>{config.LANG['Are you sure you want to recover']} {config.LANG['these']} {config.LANG['Users']}?</p><br/>
                      <p>{config.LANG['Check the number of users on your plan.']}</p>
                      <br/><br/>
                    </>
                  )
              }
              <FormRow>
                <FormColumn>
                  {
                    !filters.find(f=>f.href==='trash'&&f.class!==null)
                      ?(<button className="add" type="button" onClick={handleDelete}>{config.LANG['YES']}</button>)
                      :(<button className="add" type="button" onClick={handleRecover}>{config.LANG['YES']}</button>)
                  }
                </FormColumn>
                <FormColumn>
                  <button className="delete" type="button" onClick={handleOpenDelete}>{config.LANG['NO']}</button>
                </FormColumn>
              </FormRow>
            </PopUp>

            <PopUp Tiny Open={openDeleteSingle} CallBack={handleOpenDeleteSingle}>
                <button type="button" onClick={handleOpenDeleteSingle}>
                  <img src={close} alt={config.LANG['Close']}/>
                </button>
                {
                  !filters.find(f=>f.href==='trash'&&f.class!==null)
                    ?(
                      <>
                        <h1>{config.LANG['Delete']} {config.LANG['User']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to delete']} {config.LANG['this']} {config.LANG['User']}?</p><br/>
                        <p>{config.LANG['It will be saved in the trash and you can recover it later.']}</p>
                        <br/><br/>
                      </>
                    )
                    :(
                      <>
                        <h1>{config.LANG['Recover']} {config.LANG['User']}</h1>
                        <br/><br/>
                        <p>{config.LANG['Are you sure you want to recover']} {config.LANG['this']} {config.LANG['User']}?</p><br/>
                        <p>{config.LANG['Check the number of users on your plan.']}</p>
                        <br/><br/>
                      </>
                    )
                }
                <FormRow>
                  <FormColumn>
                    {
                      !filters.find(f=>f.href==='trash'&&f.class!==null)
                        ?(<button className="add" type="button" onClick={handleDeleteSingle}>{config.LANG['YES']}</button>)
                        :(<button className="add" type="button" onClick={handleRecoverSingle}>{config.LANG['YES']}</button>)
                    }
                  </FormColumn>
                  <FormColumn>
                    <button className="delete" type="button" onClick={handleOpenDeleteSingle}>{config.LANG['NO']}</button>
                  </FormColumn>
                </FormRow>
              </PopUp>
            
            {/* AAAAAQUIII */}
            <PopUp Open={openJob} CallBack={handleOpenJob}>
              <button type="button" onClick={handleOpenJob}>
                <img src={close} alt={config.LANG['Close']}/>
              </button>
              <h1>{config.LANG['Indicate Obligations']}</h1>
              <br/><br/>
              <h3>{config.LANG['Attention']}</h3>

              <p>{config.LANG['The selected users will be appointed for the following obligations:']}</p><br/>
              <FormColumn>
              <label for="obligations">Configurar as obrigações:</label>
              <FormRow>
                <SelectContainer value={selected} onChange={e => setSeleted(e.target.value)} id="obligations">
                {/* <optgroup label="Default">  */}
                  {personalizeProjets.map(item => (
                    item.project_id === 0 && 
                        <Fragment key={item.project_id}>
                            <option value={item.project_id}>{item.project_name}</option>
                        </Fragment>
                  ))}  
                {/* </optgroup> */}
                <optgroup label="Personalizados">
                  {personalizeProjets.map(item => (
                      item.project_id !== 0 && item.custom_project === true && 
                        <Fragment key={item.project_id}>
                            <option value={item.project_id}>{item.project_name}</option>
                        </Fragment>
                  ))}  
                </optgroup>
                </SelectContainer>     
              </FormRow>
                </FormColumn>
              <Jobs namesOfProject={namesOfProject} selectedList={selected} postsId={checked} openOutside={openJob} />
              <br/><br/>
            </PopUp>

            <PopUp Open={openProfile} CallBack={handleOpenProfile}>
              <button type="button" onClick={handleOpenProfile}>
                <img src={close} alt={config.LANG['Close']}/>
              </button>
              <h1>{config.LANG['Update Profile']}</h1>
              <br/><br/>
              <h3>{config.LANG['Attention']}</h3>
              <p>{config.LANG['The selected users will have their profile updated']}</p><br/>
              <Profile postsId={checked} openOutside={handleProfile} />
              <br/><br/>
            </PopUp>
          </Container>
        )}
      </>
    );
}