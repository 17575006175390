import React, {useCallback,useRef,useState} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

import close from '~/assets/icons/close.svg';
import download from '~/assets/icons/download.svg';
import downloadGreen from '~/assets/icons/dowload_green.svg';
import TopLoader from '~/assets/loading.gif';
import Accordion from '~/components/Accordion';
import {Container} from '~/components/Container';
import {FormRow,FormColumn} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';
import api from '~/services/api';
import Async from '~/services/Async';
import SheetToJson from '~/util/SheetToJson';

import * as S from './styles';

export default function ImportProjects({Close}) {
  const config = useSelector(state=>state.config.value);
  const [table,setTable] = useState(false);
  const [projects,setProjects] = useState([]);
  const [topLoader,setTopLoader] = useState(false);
  const inputFileRef = useRef(null);
  const [openSpreadsheet, setOpenSpreadsheet] = useState(false);
  const profile = useSelector(state=>state.user.profile);

  
  const handleOpenSpreadsheet = useCallback(()=>setOpenSpreadsheet(!openSpreadsheet),[openSpreadsheet]);

  const handleClickSpreadsheet = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  const handleDownloadSpreadsheet = useCallback(() => {
    window.location.href = `${window.location.origin}/spreadsheets/projetos.xlsx`; 
  }, []);

  const handleFileChangeSpreadsheet = useCallback(async (e) => {
    if (e.target.files[0]){
      const extension = e.target.files[0].type;

      if (extension === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        const spreadsheet = e.target.files[0];

        try {
          const firstColumn = 0;
          const lastColumn = 3;
          const spreadsheetInJson = await Async((response) => SheetToJson(response, spreadsheet, firstColumn, lastColumn));
          const projectsFormatted = [];
          let hasError = false;

          for(const row of spreadsheetInJson) {
            const projectSpreadsheet = row['Projeto'].trim();
            const areaSpreadsheet = row['Área de conhecimento'].trim();
            const subjectSpreadsheet = row['Disciplina'].trim();
            const unitSpreadsheet = row['Unidade'].trim();

            if (!projectSpreadsheet || !areaSpreadsheet || !subjectSpreadsheet || !unitSpreadsheet) {
              hasError = true;
              break;
            }

            const checkProjectExists = projectsFormatted.filter(project => project.name === projectSpreadsheet);

            if (checkProjectExists.length === 0) {
              projectsFormatted.push({
                name: projectSpreadsheet,
                areas: [{ 
                  name: areaSpreadsheet,
                  subjects: [{
                    name: subjectSpreadsheet,
                    units: [{
                      name: unitSpreadsheet
                    }]
                  }]
                }]
              });

              continue;
            } else {
              let projectIndex;
              let areaIndex;
              
              projectsFormatted.filter((project, index) => {
                if (project.name === projectSpreadsheet) {
                  projectIndex = index;

                  project.areas.filter((area, index) => {
                    if (area.name === areaSpreadsheet) {
                       areaIndex = index;
                    }
                    return null;
                  });
                }
                return null;
              });

              if (typeof areaIndex === 'undefined') {
                projectsFormatted[projectIndex].areas.push({
                  name: areaSpreadsheet,
                  subjects: [{
                    name: subjectSpreadsheet,
                    units: [{name: unitSpreadsheet}]
                  }]
                });

                continue;
              } else {
                let subjectIndex;
                
                projectsFormatted[Number(projectIndex)]
                  .areas[Number(areaIndex)]
                  .subjects.filter((subject, index) => {
                  if (subject.name === subjectSpreadsheet) {
                    subjectIndex = index;
                  }
                  return null;
                });
  
                if (typeof subjectIndex === 'undefined') {
                  projectsFormatted[projectIndex].areas[areaIndex].subjects.push({
                    name: subjectSpreadsheet,
                    units: [{name: unitSpreadsheet}]
                  });

                  continue;
                } else {
                  let unitIndex;

                  projectsFormatted[Number(projectIndex)]
                    .areas[Number(areaIndex)]
                    .subjects[Number(subjectIndex)]
                    .units.filter((unit, index) => {
                    if (unit.name === unitSpreadsheet) {
                      unitIndex = index;
                    }
                    return null;
                  });

                  if (typeof unitIndex === 'undefined') {
                    projectsFormatted[projectIndex].areas[areaIndex].subjects[subjectIndex]
                      .units.push({ name: unitSpreadsheet});
                  }
                }
              }
            }
          }

          if (hasError) {
            inputFileRef.current.value = "" 
            toast.error(`${config.LANG['Your spreadsheet is inconsistent. Try again.']}`);
          } else {
            setProjects(projectsFormatted);
            setTable(true);
          }
        } catch (err) {
          toast.error(`${config.LANG['Failed to import the spreadsheet. Contact support.']}`);
        }
      } else {
        toast.error(`${config.LANG['The selected file has the wrong extension.']}`);
      }
    }
  }, [config.LANG]);

  const handleProjectsBulkInsert = useCallback(async () => {
    try {
      setTopLoader(true);
      await api.post(`/organize/bulk`, { projects });
      setTopLoader(false);
      handleOpenSpreadsheet();
      Close();
      toast.success(`${config.LANG['Spreadsheet imported successfully.']}`);
    } catch(err){
      toast.error(`${config.LANG['Incorrect information. Try again.']}`);
    }

    setProjects([]);
    setTable(false);
  }, [config.LANG, Close,projects, handleOpenSpreadsheet]);

  const handleCancel = useCallback(() => {
    setProjects([]);
    setTable(false);
    setTopLoader(false);
    handleOpenSpreadsheet();
  }, [handleOpenSpreadsheet]);

  return (
    <>
      <div>
        {(profile && profile.role.level === 0) && (
          <S.Button type="button" onClick={handleOpenSpreadsheet}>
            <img src={downloadGreen} alt={config.LANG['Download']}/>
            {config.LANG['add multiple projects']}
          </S.Button>  
        )}
      </div>

      <PopUp Open={openSpreadsheet} CallBack={handleOpenSpreadsheet}>
        {topLoader?(
          <>
            <Container>
              <p className="loaderHolder">
                <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
              </p>
            </Container>
          </>
        ):(
          <>
            <button type="button" onClick={handleOpenSpreadsheet}>
              <img src={close} alt={config.LANG['Close']} />
            </button>

            {table?(
              <>
                <h1>Preview</h1>
                <br/>
                <p>{config.LANG['See below the projects who will be registered in the system']}:</p>
                <br/>

                {projects.map((project, projectIndex) => (
                  <Accordion title={project.name} key={projectIndex}>
                    <S.Content key={projectIndex}>
                      {project.areas.map((area, areaIndex) => (
                        <S.Card key={areaIndex}>
                          <p className="area">{area.name}</p>

                          {area.subjects.map((subject, subjectIndex) => (
                            <div key={subjectIndex}>
                              <p className="subject">{subject.name}</p>
                            
                              {subject.units.map((unit, unitIndex) => (
                                <p key={unitIndex}>{unit.name}</p>
                              ))}
                            </div>
                          ))}   
                        </S.Card>
                      ))}
                    </S.Content>
                  </Accordion>
                ))}
                
                <FormRow>
                  <FormColumn>
                    <button className="icon add" type="button" onClick={handleProjectsBulkInsert}>
                      {config.LANG['REGISTER PROJECTS']}
                    </button>
                  </FormColumn>
                  <FormColumn>
                    <button className="icon delete" type="button" onClick={handleCancel}>
                      {config.LANG['CANCEL']}
                    </button>
                  </FormColumn>
                </FormRow>
              </>
            ):(
              <>
                <h1>{config.LANG['Import Project Spreadsheet']}</h1>
                <br/>
                <p>{config.LANG['See below how to complete and import the spreadsheet to register projects']}:</p>
                <br/>
                {/* <S.IFrame 
                  src="https://www.youtube.com/embed/x9xBKM1A-nU" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                /> */}
                <br/><br/>

                <FormRow>
                  <FormColumn>
                    <button className="icon normal" type="button" onClick={handleDownloadSpreadsheet}>
                      <img src={download} alt={config.LANG['Download']} />
                      {config.LANG['DOWNLOAD TEMPLATE SPREADSHEET']}
                    </button>
                  </FormColumn>
                  <FormColumn>
                    <input hidden type="file" ref={inputFileRef} accept=".xlsx" onChange={handleFileChangeSpreadsheet}/>
                    <button className="icon add" type="button" onClick={handleClickSpreadsheet}>
                      <img src={download} alt={config.LANG['Download']} style={{transform: "rotate(180deg)"}} />
                      {config.LANG['IMPORT SPREADSHEET']}
                    </button>
                  </FormColumn>
                </FormRow>
              </>
            )}
          </>
        )}
      </PopUp>
    </>
  );
}
