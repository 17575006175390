import React, {useState,useRef,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useField} from '@rocketseat/unform';

import api from '~/services/api';

import downArrow from '~/assets/icons/downArrow.svg';

import { Container } from './styles';

export default function Select() {
  const config = useSelector(state=>state.config.value);
  // CLICK OUTSIDE
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // INIT
  const {defaultValue,registerField} = useField('role');
  const [open,setOpen] = useState(false);
  const [roles,setRoles] = useState([]);
  const [role,setRole] = useState(defaultValue?defaultValue.level:null);
  const ref = useRef();
  useEffect(()=>{
    async function GetInitState(){
      const response = await api.get('roles');

      const theRole = role!==null
        ?response.data.find(r=>r.level===role)
        :response.data[response.data.length-1];

      setRoles(response.data);
      setRole(theRole);
    }
    GetInitState();
  },[]);
  useEffect(()=>{
    if(ref.current){
      registerField({
        name: 'role_id',
        ref: ref.current,
        path: 'dataset.role'
      });
    }
  },[ref,registerField]);
  useEffect(()=>{
    async function GetInitState(){
      const response = await api.get('roles');
      const theRole = defaultValue!==null&&defaultValue!==undefined
        ?response.data.find(r=>r.level===defaultValue.level)
        :response.data[response.data.length-1];
      setRoles(response.data);
      setRole(theRole);
    }
    GetInitState();
  },[defaultValue]);
  function handleChange(r){
    setRole(r);
  }
  function handleOpen(){
    setOpen(!open);
  }
  return (
    <Container Open={open} ref={wrapperRef}>
      <div>
        <button type="button" onClick={handleOpen}>
          {config.LANG[role&&role.name]}
          <img src={downArrow} alt={config.LANG['More']} />
        </button>
        <ul>
          {roles.map(r=><li key={r.id}><button type="button" onClick={()=>{handleChange(r);handleOpen();}}>{config.LANG[r.name]}</button></li>)}
        </ul>
      </div>
      <input type="hidden" id="role" data-role={role&&role.id} ref={ref} />
    </Container>
  );
}
