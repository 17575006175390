import React from 'react';
import {useSelector} from 'react-redux';

import d000 from '~/assets/icons/000-device.svg';
import d025 from '~/assets/icons/025-device.svg';
import d050 from '~/assets/icons/050-device.svg';
import d075 from '~/assets/icons/075-device.svg';
import d100 from '~/assets/icons/100-device.svg';

import { Container } from './styles';

const Device = ({device,callback}) => {
  const config = useSelector(state=>state.config.value);
  return (<Container>
    <span class="status">{config.LANG['Preview']}:</span>
    <button className={device==='1'?'selected':''} type="button" onClick={()=>callback('1')}>
      <img src={d100} alt="100%"/>
    </button>
    <button className={device==='0.65'?'selected':''} type="button" onClick={()=>callback('0.65')}>
      <img src={d075} alt="65%"/>
    </button>
    <button className={device==='0.5'?'selected':''} type="button" onClick={()=>callback('0.5')}>
      <img src={d050} alt="50%"/>
    </button>
    <button className={device==='0.25'?'selected':''} type="button" onClick={()=>callback('0.25')}>
      <img src={d025} alt="25%"/>
    </button>
    <button className={device==='0'?'selected':''} type="button" onClick={()=>callback('0')}>
      <img src={d000} alt="0%"/>
    </button>
  </Container>);
};

export default Device;