import React from 'react';
import {useSelector} from 'react-redux';

import {Block,StatusBig} from '../styles';

import bigC from '~/assets/icons/bigC.svg';
import TopLoader from '~/assets/loading.gif';

export default function Units({topLoader,convertions}) {
  const config = useSelector(state=>state.config.value);

  return (
    <Block Middle>
      {topLoader?(
        <>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <StatusBig>
            <div>
              <img src={bigC} alt=""/>
              <div>
                <strong><span>{config.CONVERT_NUMBER-convertions}</span>/{config.CONVERT_NUMBER}</strong>
                <p>{config.LANG['Available conversions']}</p>
              </div>
            </div>
          </StatusBig>
        </>
      )}
    </Block>
  );
}
