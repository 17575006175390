import styled from 'styled-components';

import {$} from '~/styles/global';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: ${$.colorC};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${$.white};

  .cover {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;

    text-align: center;
    color: ${$.white};

    h1 {
      color: ${$.white};
      margin-bottom: 10px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;