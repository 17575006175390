import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Form,Input} from '@rocketseat/unform';
import * as Yup from 'yup';
import {toast} from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import AvatarInput from '~/components/AvatarInput';
import Select from '~/components/Select';

import {Container,Title} from '~/components/Container';
import {FormRow,FormColumn,Separator,SubmitRow} from '~/components/InternalForm';
import PopUp from '~/components/PopUp';

import leftArrow from '~/assets/icons/leftArrow.svg';
import close from '~/assets/icons/close.svg';
import TopLoader from '~/assets/loading.gif';

import Jobs from '~/pages/Users/edit/jobs';

export default function EditUser({match:{params:{id:userId}}}) {
  const config = useSelector(state => state.config.value);
  const [topLoader,setTopLoader] = useState(true);
  const [profile,setProfile] = useState({});
  const [openDelete,setOpenDelete] = useState(false);
  
  useEffect(() => {
    api.get(`users/${userId}`).then(response => {
      setProfile(response.data);
      setTopLoader(false);
    });
  }, [userId]);
  
  function handleOpenDelete(){ 
    setOpenDelete(!openDelete);
  }
  async function handleDelete(){
    try {
      await api.delete(`/users/${userId}`);
      toast.success(`${config.LANG['User']} ${config.LANG['SINGLE successfully deleted!']}`);
      history.push('/users');
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  async function handleRecover(){
    try {
      const response = await api.put(`/users/${userId}`,{
        available: true,
        role_id: profile.role_id
      });
      toast.success(`${config.LANG['User']} ${response.data.name} ${config.LANG['SINGLE successfully recovered!']}`);
      history.push('/users');
    } catch(err){
      toast.error(`${config.LANG['Incorrect information. Try again.']} ${config.LANG['Check the user limit']}`);
    }
  }
  async function handleSubmit(data){
    try {
      if(data.password===''){
        delete data.password;
        delete data.oldPassword;
        delete data.confirmPassword;
      }
      if(!data.avatar_id){
        data.avatar_id = null;
      }
      const response = await api.put(`/users/${userId}`,data);
      const {name} = response.data;
        toast.success(`${config.LANG['User']} ${name} ${config.LANG['successfully edited!']}`);
        history.push('/users');
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  // SCHEMAS =====
  const schema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório'),
    email: Yup.string().email('Insira um email válido').required('O email é obrigatório'),
    oldPassword: Yup.string(),
    password: Yup.string().when('oldPassword',(oldPassword,field)=>
      oldPassword!==''?field.min(6,'No mínimo 6 caracteres').required('A nova senha é obrigatória'):field
    ),
    confirmPassword: Yup.string().when('password',(password,field)=>
      password?field.required('Você deve confirmar a senha').oneOf([Yup.ref('password')],'Senha digitada é diferente da informada acima.'):field
    ),
    avatar_id: Yup.number(),
    role_id: Yup.number()
  });
  return (
    <>
      {topLoader?(
        <Container>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </Container>
      ):(
        <Container className={!profile.available&&`unavailable`}>
          <Link to="/users">
            <img src={leftArrow} alt={config.LANG['Backward']} />
            {config.LANG['List of']} {config.LANG['Users']}
          </Link>
          <Title>{config.LANG['Edit']} {config.LANG['User']}</Title>
          <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>

            <h2>{config.LANG['Redefine data']}:</h2>
            <FormRow>
              <FormColumn>
                <label htmlFor="name">{config.LANG['Full Name']}:*</label>
                <Input name="name" placeholder={config.LANG['Full Name']} />
              </FormColumn>
              <FormColumn>
                <label htmlFor="email">{config.LANG['Email']}:*</label>
                <Input name="email" type="email" placeholder={config.LANG['Email']} />
              </FormColumn>
                <FormColumn>
                  {profile.role_id !== 6 ? 
                    <>
                      <label htmlFor="role_id">{config.LANG['Profile']}:*</label>
                      <Select name="role_id" />
                    </>  
                  :
                    <>
                      <label htmlFor="asdas">{config.LANG['Profile']}:*</label>
                      <p>Perfil de teste (não alterável)</p>
                    </>
                  }
              </FormColumn>
              <FormColumn>
                <label htmlFor="avatar_id">{config.LANG['Change profile picture']}:</label>
                <AvatarInput name="avatar_id" />
              </FormColumn>
            </FormRow>

            <Separator />

            <h2>{config.LANG['Redefine password']}:</h2>
            <FormRow>
              <FormColumn>
                <label htmlFor="oldPassword">{config.LANG['Current Password']}:</label>
                <Input type="password" name="oldPassword" placeholder={config.LANG['Current Password']} />
              </FormColumn>
              <FormColumn>
                <label htmlFor="password">{config.LANG['New password']}:</label>
                <Input type="password" name="password" placeholder={config.LANG['New password']} />
              </FormColumn>
              <FormColumn></FormColumn>
              <FormColumn>
                <label htmlFor="password">{config.LANG['Confirm your password']}:</label>
                <Input type="password" name="confirmPassword" placeholder={config.LANG['Confirm your password']} />
              </FormColumn>
            </FormRow>
            
            <SubmitRow>
              <p>*{config.LANG['Required Fields']}</p>
              <div>
                {
                  profile.available
                    ?(<button className="delete" type="button" onClick={handleOpenDelete}>{config.LANG['Delete']}</button>)
                    :(<button className="add" type="button" onClick={handleOpenDelete}>{config.LANG['Recover']}</button>)
                }
                <button type="submit">{config.LANG['Update Profile']}</button>
              </div>
            </SubmitRow>
          </Form>

          <Jobs postId={userId} />

          <PopUp Tiny Open={openDelete} CallBack={handleOpenDelete}>
            <button type="button" onClick={handleOpenDelete}>
              <img src={close} alt={config.LANG['Close']} />
            </button>
            {
              profile.available
                ?(
                  <>
                    <h1>{config.LANG['Delete']} {config.LANG['User']}</h1>
                    <br/><br/>
                    <p>{config.LANG['Are you sure you want to delete']} {config.LANG['this']} {config.LANG['User']}?</p><br/>
                    <p>{config.LANG['It will be saved in the trash and you can recover it later.']}</p>
                    <br/><br/>
                  </>
                )
                :(
                  <>
                    <h1>{config.LANG['Recover']} {config.LANG['User']}</h1>
                    <br/><br/>
                    <p>{config.LANG['Are you sure you want to recover']} {config.LANG['this']} {config.LANG['User']}?</p><br/>
                    <p>{config.LANG['Check the number of users on your plan.']}</p>
                    <br/><br/>
                  </>
                )
            }
            <FormRow>
              <FormColumn>
                {
                  profile.available
                    ?(<button className="add" type="button" onClick={handleDelete}>{config.LANG['YES']}</button>)
                    :(<button className="add" type="button" onClick={handleRecover}>{config.LANG['YES']}</button>)
                }
              </FormColumn>
              <FormColumn>
                <button className="delete" type="button" onClick={handleOpenDelete}>{config.LANG['NO']}</button>
              </FormColumn>
            </FormRow>
          </PopUp>
        </Container>
      )}
    </>
  );
}
