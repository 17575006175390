import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Form,Input} from '@rocketseat/unform';
import {toast} from 'react-toastify';

import api from '~/services/api';

import {Row,Block,List,Item,Perfil} from '~/pages/Dashboard/styles';

import TopLoader from '~/assets/loading.gif';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import userImage from '~/assets/user.svg';
import search from '~/assets/icons/search.svg';

export default function Delegates({ postsId, openOutside }) {
  
  const config = useSelector(state=>state.config.value);
  const [topLoader,setTopLoader] = useState(true);
  const [update, setUpdate] = useState(true);
  const [searched,setSearched] = useState('');
  const [page,setPage] = useState(1);
  const [posts,setPosts] = useState({
    users: [],
    paged: {}
  });

  async function handlePage(i) {
    setPage(i);
    setUpdate(!update);
  }
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }
  
  useEffect(()=>{
    async function getPosts(){
      const response = await api.get(`users`,{
        params:{
          perPage: 10,
          page:page,
          search: searched,
        }
      });
      setPosts(response.data);
      setTopLoader(false);
    }
    getPosts();
  },[searched,page]);
  
  async function handleAddDelegate(user){
    const updatingUnits = [];
    for (const key in postsId) { updatingUnits.push(Number(key)); }
    try {
      const oldFeedback = {...feedback};
      await api.put(`delegates/bulk/units`,{
        units: updatingUnits,
        user_id:user.id,
        available: true,
      });
      oldFeedback[user.id] = true;
      setFeedback(oldFeedback);
      toast.success(`${user.name} ${config.LANG['was successfully delegated as responsible!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  async function handleDeleteDelegate(user){
    const updatingUnits = [];
    for (const key in postsId) { updatingUnits.push(Number(key)); }
    try {
      const oldFeedback = {...feedback};
      await api.put(`delegates/bulk/units`,{
        units: updatingUnits,
        user_id: user.id,
        available: false,
      });
      delete oldFeedback[user.id];
      setFeedback(oldFeedback);
      toast.success(`${user.name} ${config.LANG['has been removed as responsible successfully!']}`);
    } catch(err){
      toast.error(config.LANG['Incorrect information. Try again.']);
    }
  }
  // FEEDBACK
  const [feedback,setFeedback] = useState({});
  useEffect(()=>{
    async function Setup(){
      if(!openOutside){
        setFeedback({});
      }
    }
    Setup();
  },[openOutside]);
  return (
    <Row>
      {topLoader?(
        <>
          <Block Full>
            <p className="loaderHolder">
              <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
            </p>
          </Block>
        </>
      ):(
        <>
          <Block Full>
            <div>
              <h2>{config.LANG['Users']} ({posts.paged.totalCount})</h2>
              <Form onSubmit={handleSearch}>
                <Input name="search" placeholder={`${config.LANG['Search']}…`} />
                <button type="submit">
                  <img src={search} alt={config.LANG['Search']} />
                </button>
              </Form>
            </div>
              {posts.users[0]?(
                <List>
                  {posts.users.map(user=>(
                    <Item key={user.id}>
                      <Perfil>
                        <div>
                          <img src={user.avatar?user.avatar.url:userImage} alt={user.name}/>
                          <strong>{user.name}</strong>
                          <em>{user.role.name}</em>
                        </div>
                        {!feedback[user.id]?(
                          <button type="button" onClick={()=>handleAddDelegate(user)}>{config.LANG['Delegate']}</button>
                        ):(
                          <button className="remove" type="button" onClick={()=>handleDeleteDelegate(user)}>{config.LANG['Remove']}</button>
                        )}
                      </Perfil>
                    </Item>
                  ))}
                </List>
              ):(
                <p>{config.LANG['No users found!']}</p>
              )}
            {posts.paged.pages>1&&(
              <nav>
                <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                  <img src={leftArrow} alt={config.LANG['Backward']} />
                </button>
                <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                  <img src={rightArrow} alt={config.LANG['Forward']} />
                </button>
              </nav>
            )}
          </Block>
        </>
      )}
    </Row>
  );
}
