import React, {useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Form,Input} from '@rocketseat/unform';

import api from '~/services/api';

import {Block,List,Item,Perfil} from '~/pages/Dashboard/styles';

import TopLoader from '~/assets/loading.gif';
import leftArrow from '~/assets/icons/leftArrow.svg';
import rightArrow from '~/assets/icons/rightArrow.svg';
import userImage from '~/assets/user.svg';
import search from '~/assets/icons/search.svg';

export default function Users({postId,callback}) {
  const config = useSelector(state=>state.config.value);
  // INIT
  const [topLoader,setTopLoader] = useState(true);
 

  // PAGE
  const [page,setPage] = useState(1);
  async function handlePage(i){
    setPage(i);
    setUpdate(!update);
  }
  // SEARCH
  const [searched,setSearched] = useState('');
  async function handleSearch({search}){
    setSearched(search);
    handlePage(1);
  }
  // POSTS
  const [posts,setPosts] = useState({
    list: [],
    paged: {}
  });
  const [update,setUpdate] = useState(true);
  
  useEffect(()=>{
    api.get(`users`,{
          params:{
            perPage: 5,
            page:page,
            search: searched,
          }
        }).then(response =>{
          setPosts(response.data);
          setTopLoader(false);
        });
  },[update,page,searched]);
  
  // HANDLE DELEGATES
  async function handleAddDelegate(id){
    await api.post(`delegates/units/${postId}`,{user_id:id});
    callback();
  }
  return (
    <Block Middle>
      {topLoader?(
        <>
          <p className="loaderHolder">
            <img className="toploader" src={TopLoader} alt={`${config.LANG['Loading']}...`} />
          </p>
        </>
      ):(
        <>
          <div>
            <h2>{config.LANG['Users']} ({posts.paged.totalCount})</h2>
            <Form onSubmit={handleSearch}>
              <Input name="search" placeholder={`${config.LANG['Search']}...`} />
              <button type="submit">
                <img src={search} alt={config.LANG['Search']} />
              </button>
            </Form>
          </div>
            {posts.users[0]?(
              <List>
                {posts.users.map(post=>(
                  <Item key={post.id}>
                    <Perfil>
                      <div>
                        <img src={post.avatar?post.avatar.url:userImage} alt={post.name}/>
                        <strong>{post.name}</strong>
                        <em>{config.LANG[post.role.name]}</em>
                      </div>
                      <button type="button" onClick={()=>handleAddDelegate(post.id)}>{config.LANG['Delegate']}</button>
                    </Perfil>
                  </Item>
                ))}
              </List>
            ):(
              <p>{config.LANG['No users found!']}</p>
            )}
          {posts.paged.pages>1&&(
            <nav>
              <button className={posts.paged.page!==1?null:'none'} type="button" onClick={()=>posts.paged.page!==1&&handlePage(page-1)}>
                <img src={leftArrow} alt={config.LANG['Backward']} />
              </button>
              <button className={posts.paged.page<posts.paged.pages?null:'none'} type="button" onClick={()=>posts.paged.page<posts.paged.pages&&handlePage(page+1)}>
                <img src={rightArrow} alt={config.LANG['Forward']} />
              </button>
            </nav>
          )}
        </>
      )}
    </Block>
  );
}