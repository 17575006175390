import produce from 'immer';

import lang from '~/lang';

const INITIAL_STATE = { value: { LANG: lang['pt_br'] } };

export default function config(state=INITIAL_STATE,action){
  return produce(state,draft=>{
    switch (action.type){
      case '@config/UPDATE_CONFIG': {
        draft.value = action.payload;
        break;
      }
      default:
    }
  });
}